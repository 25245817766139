import { object, string } from "yup";

import { searchMinLength } from "constants/form.constants";
import { TagFormData } from "api/tags/tags.interface";

export const initialData: TagFormData = {
  name: "",
  assigneeId: null,
};

export const validationSchema = object().shape({
  name: string()
    .matches(/^(?!\s+$)/, "Name should contain not just spaces")
    .min(searchMinLength, "Name is too short")
    .required("Name is required"),
  assigneeId: string().nullable(),
});

export const pageTitle = "Tag";
