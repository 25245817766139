import { FC, useMemo, useState } from "react";
import { matchSorter } from "match-sorter";
import { Modal, Table, useDidUpdate, useFetch } from "@epcnetwork/core-ui-kit";

import { setListElement } from "utils";
import { ColumnsType } from "types";
import { UsersForm } from "pages/users/form/users-form.page";
import { ArrayElement, UserModel } from "models";
import { getUsers } from "api";
import { UsersListTableRow } from "./users-list-table-row/users-list-table-row";

type ListUsersProps = {
  searchValue: string;
  isAdding: boolean;
  setAdding: (value: boolean) => void;
};

const tableColumns: ColumnsType = [
  { label: "Email", required: true },
  { label: "Roles" },
  { label: "Group" },
  { label: "Created at" },
  { label: "" },
];

const ListUsers: FC<ListUsersProps> = ({ searchValue, isAdding, setAdding }) => {
  const [editId, setEditId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserListUpdated, setIsUserListUpdated] = useState(false);

  const { payload, loading, refresh, error, actions } = useFetch(getUsers);

  useDidUpdate(() => {
    if (isUserListUpdated) {
      handleCloseModal();
    }
  }, [isUserListUpdated]);

  const handleEdit = (id: number) => {
    setEditId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditId(null);
    setAdding(false);
    setIsUserListUpdated(false);
  };

  const handleItemChange = (values: UserModel) => {
    const list = payload || [];
    const newData = setListElement(list, values);

    actions.setData(newData);
    setIsUserListUpdated(true);
  };

  const filteredList = useMemo(() => {
    let list: NonNullable<typeof payload> = payload || [];

    if (searchValue) {
      const keys: (keyof ArrayElement<NonNullable<typeof payload>>)[] = ["email"];
      list = matchSorter(list, searchValue, { keys });
    }

    return list;
  }, [searchValue, payload]);

  return (
    <>
      <Table
        entityName="users-user-list"
        columns={tableColumns}
        list={filteredList}
        error={error?.message}
        refresh={refresh}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        row={(item, index) => (
          <UsersListTableRow index={index} item={item} handleEdit={handleEdit} />
        )}
      />
      <Modal isOpen={isModalOpen || isAdding} setClose={handleCloseModal}>
        <UsersForm
          id={isAdding ? null : editId}
          handleItemChange={handleItemChange}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export { ListUsers };
