import { FC, useState } from "react";
import {
  Menu,
  MenuButton,
  notification,
  TableCell,
  TableRow,
  TextEllipsis,
  useCall,
  useDevice,
  useWindowSize,
} from "@epcnetwork/core-ui-kit";

import { capitalize, formatDate } from "utils";
import { TagModel } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { DELETE_ERROR_TEXT } from "constants/notification.constants";
import { timeFormat } from "constants/general.constants";
import { StatusIndicator } from "components/ui/status-indicator";
import { deleteTag } from "api";

import clockImg from "assets/images/clock-grey.svg";
import styles from "./tags-table-row.module.css";

type TagsTableRowProps = {
  index: number;
  tag: TagModel;
  handleEdit: (id: number) => void;
  onDeleteSuccess: () => void;
};

const TagsTableRow: FC<TagsTableRowProps> = ({ index, tag, handleEdit, onDeleteSuccess }) => {
  const ability = useAuthorization();

  const { width } = useWindowSize();
  const { isMobileDevice } = useDevice();
  const { id, name, status, assignee, jobs, createdAt } = tag;

  const [isDeleted, setDeleted] = useState(false);

  const { submit, onCallSuccess, onCallError } = useCall(deleteTag);
  onCallSuccess(() => {
    onDeleteSuccess();
    notification.success("Tag deleted", "Successfully deleted tag");
  });
  onCallError(() => {
    setDeleted(false);
    notification.error(DELETE_ERROR_TEXT, "Cannot delete tag");
  });

  const handleRemoveItem = () => {
    notification.confirm("Delete tag?", "Are you sure?", {
      onOk: () => {
        setDeleted(true);
        submit({ params: { tagId: id } });
      },
      icon: "delete",
    });
  };

  const handleEditItem = () => handleEdit(id);

  const isMobile = width < MAX_MOBILE_WIDTH;

  const isUsedTag = status === "used";

  const jobsCount = jobs?.length ?? 0;

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={index}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell>{assignee?.email ?? "-"}</TableCell>
      <TableCell>
        <StatusIndicator type={status}>{capitalize(status)}</StatusIndicator>
      </TableCell>
      <TableCell>{jobsCount}</TableCell>
      <TableCell className={styles.dateCell}>
        {formatDate(createdAt, "EEE, MMM dd, yyyy")}
        <img src={clockImg} alt="" />
        {formatDate(createdAt, timeFormat)}
      </TableCell>
      <TableCell>
        {!isMobileDevice && !isMobile && (
          <Menu>
            {ability.can("create", "Jobs") && (
              <MenuButton onClick={handleEditItem} icon="edit" disabled={isUsedTag}>
                Edit
              </MenuButton>
            )}
            {ability.can("delete", "Jobs") && (
              <MenuButton
                appearance="delete"
                onClick={handleRemoveItem}
                icon="delete"
                disabled={isUsedTag}
              >
                Delete
              </MenuButton>
            )}
            {!ability.can("create", "Jobs") && !ability.can("delete", "Jobs") && (
              <div style={{ padding: "10px" }}>No Actions available</div>
            )}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export { TagsTableRow };
