import { RefObject, useCallback, useEffect } from "react";

export type GeneralScrollElement = RefObject<Element>;

export type UIInfiniteScrollOptions<RefType extends GeneralScrollElement> = {
  containerRef: RefType;
  onReachEndOfList: () => void;
};

const useUIInfiniteScroll = <ElementType extends GeneralScrollElement>({
  containerRef,
  onReachEndOfList,
}: UIInfiniteScrollOptions<ElementType>): void => {
  const handler = useCallback(() => {
    if (!containerRef?.current) return;
    const scrollHeight = containerRef.current.scrollHeight;
    const scrollTop = containerRef.current.scrollTop;
    const clientHeight = containerRef.current.clientHeight;

    const performNextRequest = scrollHeight - scrollTop <= clientHeight;

    if (performNextRequest) {
      onReachEndOfList?.();
    }
  }, [containerRef, onReachEndOfList]);

  useEffect(() => {
    const element = containerRef.current;
    element?.addEventListener("scroll", handler);

    return () => {
      element?.removeEventListener("scroll", handler);
    };
  }, [containerRef, handler]);
};

export { useUIInfiniteScroll };
