import { FC, useState } from "react";
import { notification, ParamType, Switch, Tooltip, useCall } from "@epcnetwork/core-ui-kit";

import { getJobStatusText } from "utils";
import { JobModel } from "models";
import { JOB_PROGRESS_STATUSES } from "constants/jobs.constants";
import { CircularProgress } from "components";
import { postEditJob } from "api";
import {
  autofreezeNotificationOffErrorText,
  autofreezeNotificationOffSuccessText,
  autofreezeNotificationOnErrorText,
  autofreezeNotificationOnSuccessText,
  autofreezeSwitchText,
  autofreezeTooltipOffText,
  autofreezeTooltipOnText,
} from "./info-panel.constants";

import styles from "./info-panel.module.css";

type StatusInfoProps = {
  status: JobModel["status"];
  progress: number;
  allowAutofreeze: boolean;
  jobId: ParamType;
};

const StatusInfo: FC<StatusInfoProps> = ({ progress, status, allowAutofreeze, jobId }) => {
  const { submit, onCallSuccess, onCallError } = useCall(postEditJob.setParams({ jobId }));

  const [isAutofreezeOn, setIsAutofreezeOn] = useState(allowAutofreeze);

  const isPaused = status === JOB_PROGRESS_STATUSES.paused;
  const isFinished = status === JOB_PROGRESS_STATUSES.finished;
  const isManualFrozen = status === JOB_PROGRESS_STATUSES.frozen;
  const isAutoFrozen = status === JOB_PROGRESS_STATUSES.autofrozen;

  const onAutofreezeChange = async (_: string, isChecked: boolean) => {
    setIsAutofreezeOn(isChecked);

    await submit({
      data: {
        allowAutofreeze: isChecked,
      },
    });
  };

  onCallSuccess(() => {
    notification.success(
      isAutofreezeOn ? autofreezeNotificationOnSuccessText : autofreezeNotificationOffSuccessText,
      "",
    );
  });

  onCallError(() => {
    notification.error(
      isAutofreezeOn ? autofreezeNotificationOnErrorText : autofreezeNotificationOffErrorText,
      "",
    );
  });

  return (
    <div className={styles.jobStatusWrap}>
      <div className={styles.jobStatusCircleWrap}>
        <CircularProgress
          className={styles.jobStatusCircularProgress}
          value={progress}
          isPaused={isPaused}
          isManualFrozen={isManualFrozen}
          isAutoFrozen={isAutoFrozen}
          isFinished={isFinished}
        />
        <p className={styles.jobStatusInfoText}>{getJobStatusText(status, progress)}</p>
      </div>
      {!isFinished && (
        <div>
          <Tooltip
            triggerElement={
              <Switch
                label={autofreezeSwitchText}
                value="autofreeze"
                disableError
                checked={isAutofreezeOn}
                onChange={onAutofreezeChange}
              />
            }
            position="bottom-left"
            trigger="hover"
            contentClassName={styles.tooltipContent}
          >
            {isAutofreezeOn ? autofreezeTooltipOnText : autofreezeTooltipOffText}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export { StatusInfo };
