import { useNavigate } from "react-router-dom";
import { FC } from "react";
import cn from "classnames";
import {
  getLinkPath,
  Button,
  getFillColor,
  notification,
  Tooltip,
  useCall,
} from "@epcnetwork/core-ui-kit";

import { JobModel, JobModelConfiguration } from "models";
import { JOBS_FORM_RECONFIGURATION_PAGE } from "constants/routes.constants";
import { JOB_PROGRESS_STATUSES } from "constants/jobs.constants";
import { postJobPauseStatus } from "api";
import { getFreezeData } from "./info-panel.utils";
import {
  btnPauseText,
  btnReconfigureText,
  btnResumeText,
  errorPauseTitle,
} from "./info-panel.constants";

import tooltipStyles from "assets/styles/tooltip.module.css";
import { ReactComponent as WrenchIcon } from "assets/images/wrench-blue.svg";
import { ReactComponent as PlayBlueIcon } from "assets/images/play-blue.svg";
import { ReactComponent as PauseBlueIcon } from "assets/images/pause-circle-blue.svg";
import { ReactComponent as FreezeIcon } from "assets/images/freeze-icon.svg";
import styles from "./info-panel.module.css";

type ActionsProps = {
  status: JobModel["status"];
  className?: string;
  progress: number;
  jobId: number;
  showReconfigureBtn: boolean;
  jobPayload: JobModelConfiguration;
  onTriggerJobStatusSuccess: () => void;
};

const ActionButtons: FC<ActionsProps> = ({
  jobId,
  status,
  progress,
  onTriggerJobStatusSuccess,
  showReconfigureBtn,
  jobPayload,
  className,
}) => {
  const navigate = useNavigate();

  const isJobInProgress = status === JOB_PROGRESS_STATUSES.inProgress;
  const isPaused = status === JOB_PROGRESS_STATUSES.paused;
  const isManualFrozen = status === JOB_PROGRESS_STATUSES.frozen;
  const isAutoFrozen = status === JOB_PROGRESS_STATUSES.autofrozen;

  const {
    freezeToggleApiCall,
    notificationSuccessTitle,
    notificationSuccessMessage,
    notificationErrorTitle,
    freezeBtnText,
    freezeTooltipText,
  } = getFreezeData(isManualFrozen || isAutoFrozen);

  const { submit, submitting, onCallSuccess, onCallError } = useCall(postJobPauseStatus);
  onCallSuccess(() => onTriggerJobStatusSuccess());
  onCallError((error) => notification.error(errorPauseTitle, error?.message));

  const {
    submit: submitFreeze,
    submitting: submittingFreeze,
    onCallSuccess: onCallSuccessFreeze,
    onCallError: onCallErrorFreeze,
  } = useCall(freezeToggleApiCall.setParams({ jobId }));
  onCallSuccessFreeze(() => {
    notification.success(notificationSuccessTitle, notificationSuccessMessage);
    onTriggerJobStatusSuccess();
  });
  onCallErrorFreeze((error) => {
    notification.error(notificationErrorTitle, error?.message);
  });

  const handleJobStatusTrigger = () => submit({ data: { id: jobId, paused: !isPaused } });

  const handleReconfigureClick = () => {
    navigate(getLinkPath(JOBS_FORM_RECONFIGURATION_PAGE.path, { jobId: jobPayload.id.toString() }));
  };

  const handleFreezeSubmit = () => submitFreeze();

  const resumeBtnIcon = <PlayBlueIcon className={getFillColor({ status: "primary" })} />;

  const pausedBtnIcon = isPaused ? (
    resumeBtnIcon
  ) : (
    <PauseBlueIcon className={getFillColor({ status: "primary" })} />
  );
  const freezeBtnIcon = <FreezeIcon className={getFillColor({ status: "primary" })} />;

  const pausedBtnText = isPaused ? btnResumeText : btnPauseText;

  const showFreezeBtn =
    ((isJobInProgress && !isPaused) || isManualFrozen || isAutoFrozen) && progress !== 1;
  const showPausedBtn =
    ((isJobInProgress && (!isManualFrozen || !isAutoFrozen)) || isPaused) && progress !== 1;

  return (
    <div className={styles.actionsWrap}>
      {(showPausedBtn || showFreezeBtn || showReconfigureBtn) && (
        <h3 className={styles.actionsTitle}>Actions</h3>
      )}

      <div className={cn(styles.actionsBtnWrapper, className)}>
        {showPausedBtn && (
          <Button
            appearance="transparent"
            onClick={handleJobStatusTrigger}
            disabled={submitting}
            btnSize="small"
            className={cn(styles.actionButton, styles.override)}
          >
            {pausedBtnIcon}
            {pausedBtnText}
          </Button>
        )}
        {showFreezeBtn && (
          <Tooltip
            triggerElement={
              <Button
                appearance="transparent"
                onClick={handleFreezeSubmit}
                disabled={submittingFreeze}
                btnSize="small"
                className={cn(styles.actionButton, styles.override)}
              >
                {freezeBtnIcon}
                {freezeBtnText}
              </Button>
            }
            position="bottom"
            trigger="hover"
            contentClassName={cn(styles.tooltipContent, tooltipStyles.tooltip)}
          >
            {freezeTooltipText}
          </Tooltip>
        )}
        {showReconfigureBtn && (
          <Button
            appearance="transparent"
            btnSize="small"
            onClick={handleReconfigureClick}
            className={cn(styles.actionButton, styles.reconfigureButton, styles.override)}
            imageBtn
          >
            <WrenchIcon />
            {btnReconfigureText}
          </Button>
        )}
      </div>
    </div>
  );
};

export { ActionButtons };
