import { FC, useState } from "react";
import {
  Button,
  MessageField,
  useFormValues,
  useSubmit,
  FormField,
  Form,
  FormButtons,
  useAutocomplete,
  notification,
} from "@epcnetwork/core-ui-kit";

import { getFormPageTitle, getSuccessNotificationMessage } from "utils";
import { NonNullableKeys } from "models";
import { CANCEL_TEXT, SUCCESS_TEXT } from "constants/notification.constants";
import { TagFormData } from "api/tags/tags.interface";
import { postTag, patchTag, getTag, getUsers } from "api";
import { initialData, pageTitle, validationSchema } from "./tags-form.constants";

import styles from "./tags-form.module.css";

type TagsFormProps = {
  id: number | null;
  handleSuccessCreate: VoidFunction;
  handleCloseModal: VoidFunction;
};

const TagsForm: FC<TagsFormProps> = ({ id, handleSuccessCreate, handleCloseModal }) => {
  const [error, setError] = useState("");

  const { formProps, mapInitialValues } = useFormValues(
    initialData,
    getTag.setParams({ tagId: id as number }),
    !!id,
  );

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postTag,
    patchTag.setParams({ tagId: id as number }),
    !!id,
  );

  mapInitialValues(({ name, assignee }) => ({
    name,
    assigneeId: assignee ? String(assignee.id) : null,
  }));

  onSubmitSuccess(() => {
    handleSuccessCreate();
    notification.success(SUCCESS_TEXT, getSuccessNotificationMessage(pageTitle, !!id));
  });

  onSubmitError((error) => {
    setError(error?.message || error?.message || "Server error");
  });

  const usersAutocomplete = useAutocomplete(getUsers, "id", "email", {
    infinite: true,
  });

  const onSubmit = onSubmitMapping((values: NonNullableKeys<TagFormData>) => {
    const { name, assigneeId } = values;

    return {
      name,
      assigneeId: assigneeId || null,
    };
  });

  return (
    <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema}>
      <h2 className={styles.title}>{getFormPageTitle(pageTitle, !!id)}</h2>
      <MessageField message={error} />
      <FormField type="text" name="name" label="Tag Name" placeholder="Tag name" />
      <FormField
        type="select"
        name="assigneeId"
        label="Assignee (optional)"
        placeholder="Select assignee"
        asyncOptions={usersAutocomplete}
      />
      <FormButtons>
        <Button appearance="secondary" onClick={handleCloseModal}>
          {CANCEL_TEXT}
        </Button>
        <Button type="submit">Submit</Button>
      </FormButtons>
    </Form>
  );
};

export { TagsForm };
