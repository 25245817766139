import { io, Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { notification, useDidMount } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { appEnvironment } from "config/environment.config";
import {
  ListenerHandlers,
  EmitHandlers,
  ListenerEventsKeys,
  JobIdArgument,
  SocketInstance,
  EventEmitter,
} from "./use-job-socket.types";

const SOCKET_JOB_NAMESPACE = "/job";

export const useJobSocketHook = ({ jobId }: JobIdArgument) => {
  const socketEmitFactory = useRef<EventEmitter | null>(null);

  const [socket, setSocket] = useState<SocketInstance | null>(null);
  const [isConnected, setConnected] = useState<boolean>(false);

  const { token } = useSelector((state: RootState) => state.auth);

  const emitFactory = (socket: SocketInstance): EventEmitter => ({
    joinRoomEmit: (data: JobIdArgument) => {
      socket.emit("roomJoin", data);
    },
  });

  const listenerHandlers: Partial<ListenerHandlers> = {
    connect: () => {
      setConnected(true);

      if (jobId) {
        const roomJoinData: JobIdArgument = { jobId };
        socketEmitFactory.current?.joinRoomEmit(roomJoinData);
      }
    },
    connectError: () => {
      setConnected(false);
    },
    disconnect: () => {
      setConnected(false);
    },
    exception: (error: Error) => {
      notification.error("Socket exception", "Error while connecting to the socket. " + error);
    },
  };

  const addJobListener =
    (socketInstance: Socket<Partial<ListenerHandlers>>) =>
    <E extends ListenerEventsKeys>(event: E) => {
      socketInstance.on<ListenerEventsKeys>(event, listenerHandlers[event]);

      return () => {
        socketInstance.off<ListenerEventsKeys>(event, listenerHandlers[event]);
      };
    };

  const mountListeners = (socketInstance: Socket<ListenerHandlers>) => {
    const connectUnmount = addJobListener(socketInstance)("connect");
    const connectErrorUnmount = addJobListener(socketInstance)("connectError");
    const disconnectUnmount = addJobListener(socketInstance)("disconnect");
    const exceptionUnmount = addJobListener(socketInstance)("exception");

    return () => {
      connectUnmount();
      connectErrorUnmount();
      disconnectUnmount();
      exceptionUnmount();
    };
  };

  useDidMount(() => {
    const socketInstance: Socket<ListenerHandlers, EmitHandlers> = io(
      appEnvironment.apiUrl + SOCKET_JOB_NAMESPACE,
      {
        autoConnect: false,
        extraHeaders: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    const unmountListeners = mountListeners(socketInstance);
    socketEmitFactory.current = emitFactory(socketInstance);
    socketInstance.connect();

    setSocket(socketInstance);

    return () => {
      socket?.disconnect();
      unmountListeners();
    };
  });

  return { socket, isConnected };
};
