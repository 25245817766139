import { FC, useMemo } from "react";
import { matchSorter } from "match-sorter";
import cn from "classnames";
import { Button, TextEllipsis, useWindowSize } from "@epcnetwork/core-ui-kit";

import { ArrayElement, RoleModel } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";

import styles from "./sidebar.module.css";

type SidebarProps = {
  list: RoleModel[];
  selected: RoleModel | null;
  onSelect: (role: RoleModel) => void;
  onAddNew: () => void;
  searchValue: string;
};

const Sidebar: FC<SidebarProps> = ({ list, selected, onSelect, onAddNew, searchValue }) => {
  const ability = useAuthorization();

  const { width } = useWindowSize();

  const filteredList = useMemo(() => {
    let newList: RoleModel[] = list;

    if (searchValue) {
      const keys: (keyof ArrayElement<RoleModel>)[] = ["name", "email"];
      newList = matchSorter(list, searchValue, { keys: keys as string[] });
    }

    return newList;
  }, [searchValue, list]);

  const btnStyles = cn(styles.addBtn, { [styles.addingBtn]: !selected });
  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <div className={styles.sidebar}>
      <div className={styles.buttons}>
        {filteredList.map((role) => (
          <button
            key={role.id}
            className={`${styles.roleBtn} ${selected?.id === role.id ? styles.activeBtn : ""}`}
            onClick={() => onSelect(role)}
          >
            <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
              {role.name}
            </TextEllipsis>
          </button>
        ))}
        {!filteredList.length && (
          <button className={styles.roleBtn} disabled>
            No items
          </button>
        )}
        {!isMobile && ability.can("create", "Users") && (
          <Button className={btnStyles} appearance="text" btnSize="small" onClick={onAddNew}>
            + Add new role
          </Button>
        )}
      </div>
    </div>
  );
};

export { Sidebar };
