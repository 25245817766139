import { boolean, object, TestContext } from "yup";

import { DaysAllocationValues, DaySpread } from "./days-allocation.types";

export const daysAllocationValues: DaysAllocationValues = {
  dateHoursSpread: [],
};

export const initialDisplayCalendarView = true;
export const showAllocationCalendar = "showAllocationCalendar";

export const daysAllocationSchema = object({
  allEmails: boolean().test(
    "checkAllocation",
    "Please spread remaining emails between days to continue",
    (value: boolean | unknown, context: TestContext<DaysAllocationValues> | unknown) => {
      const schemaContext = context ? (context as TestContext<DaysAllocationValues>) : null;
      if (!schemaContext || !value) return true;

      const { dateHoursSpread } = schemaContext.parent;

      const hasRemainingItems = dateHoursSpread.find((spread: DaySpread) => spread.remaining);

      return !hasRemainingItems;
    },
  ),
});
