import createSagaMiddleware from "redux-saga";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import ui from "./reducers/ui";
import jobs from "./reducers/jobs";
import auth from "./reducers/auth";

const reducer = combineReducers({
  auth,
  ui,
  jobs,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
});

export * from "./reducers/auth";
export * from "./reducers/ui";
export * from "./reducers/jobs";

export type RootState = ReturnType<typeof reducer>;
export default store;
