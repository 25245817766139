import { useNavigate } from "react-router-dom";
import { FC } from "react";

import { LOGOUT_PAGE } from "constants/routes.constants";

import styles from "../navbar.module.css";

const Menu: FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => navigate(LOGOUT_PAGE.path);

  return (
    <div className={styles.menu} data-testid="navbar-menu">
      <button className={styles.item} onClick={handleLogout}>
        Log out
      </button>
    </div>
  );
};

export { Menu };
