import { BarProps } from "recharts";
import { PartialBy, SelectOption } from "@epcnetwork/core-ui-kit";

import { JobModelConfiguration, JobTrafficModel, JobTrafficTypesKeys } from "models";
import { chartTrafficTypes } from "./chart.constants";

export type ChartTrafficType = {
  all: string;
} & Record<JobTrafficTypesKeys, string>;

export type ChartTrafficTypesKeys = keyof typeof chartTrafficTypes;

export type ChartTrafficOption = SelectOption<ChartTrafficTypesKeys>;

export type ChartPoint = {
  date: Date;
  xAxis: string;
  frozenTraffic?: number;
} & Record<JobTrafficTypesKeys, number> &
  Pick<JobTrafficModel, "rescheduledFrom">;

export type ChartFigureConfig = {
  dataKey: JobTrafficTypesKeys;
};

export type ChartBarConfig = ChartFigureConfig &
  PartialBy<
    Pick<BarProps, "barSize" | "radius" | "stackId" | "className">,
    "stackId" | "className"
  >;

export enum ChartTimeUnits {
  hourly = "hourly",
  daily = "daily",
}

export type CreateBarConfigParams = Pick<ChartBarConfig, "className" | "radius"> & {
  dataKey: JobTrafficTypesKeys;
};

export type ChartBarDataType = ChartPoint & {
  isTrafficManualFrozen: boolean;
  isTrafficAutoFrozen: boolean;
  wasTrafficManualFrozen: boolean;
  wasUnfrozen?: boolean;
  frozenStartDate?: number;
  frozenEndDate?: number;
};
export type ParsedJobData = {
  originalData: ChartBarDataType[]; // differs by targetTraffic value
  chartStackedBarsData: ChartBarDataType[]; // differs by targetTraffic value
};

export type ParseJobDataToChartDataParams = {
  jobTrafficData: JobTrafficModel[];
  yAxisMaxValue: number;
  jobConfiguration?: JobModelConfiguration;
};

export type BarShapeProps = Pick<BarProps, "radius" | "animationEasing"> & {
  className?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  isAnimationActive?: boolean;
  isUpdateAnimationActive?: boolean;
  animationBegin?: number;
  animationDuration?: number;
  payload: ChartBarDataType;
};

export type FrozenGroupItem = Omit<JobTrafficModel, "date" | "rescheduledFrom"> & {
  date: number; // timestamp
  groupItemIndex?: number;
};

export type FrozenGroup = FrozenGroupItem[];

export type FrozenGroupsBoundaryDate = { startDate: number; endDate: number };

export type RangeType = number[];
