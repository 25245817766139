import { useNavigate, useParams } from "react-router-dom";
import { FC, useState } from "react";
import { Button, useWindowSize } from "@epcnetwork/core-ui-kit";

import { JobModelConfiguration } from "models";
import { useJobSocketHook } from "hooks";
import { JOBS_LIST_PAGE } from "constants/routes.constants";
import { Container } from "components";
import { MetaTables } from "./meta-tables/meta-tables";
import { LogsInfoPanel } from "./logs-info-panel/logs-info-panel";
import { LogsActivityPanel } from "./logs-activity-panel/logs-activity-panel";
import { backToJobsListText } from "./jobs-details.constants";
import { InfoPanel } from "./info-panel";
import { Chart } from "./chart/chart";

import { ReactComponent as ArrowIcon } from "assets/images/arrow-base-right.svg";
import styles from "./jobs-details.module.css";

type ParamsType = {
  jobId: string;
};

const JobDetailsPage: FC = () => {
  const navigate = useNavigate();

  const { jobId = "" } = useParams<ParamsType>();
  const { width: windowWidth } = useWindowSize();
  const { socket } = useJobSocketHook({ jobId });

  const [jobConfiguration, setJobConfiguration] = useState<JobModelConfiguration>();

  const backToJobsListHandler = () => {
    navigate(JOBS_LIST_PAGE.path);
  };

  const id = Number(jobId);
  const shouldChangeRenderOrder = windowWidth <= 945;

  const header = (
    <Container className={`${styles.headerContainer} ${styles.override}`}>
      <Button className={styles.backToJobListBtn} onClick={backToJobsListHandler} appearance="none">
        <ArrowIcon className={styles.backToJobListBtnIcon} />
        <span>{backToJobsListText}</span>
      </Button>
    </Container>
  );

  if (shouldChangeRenderOrder) {
    return (
      <div className={styles.jobDetailsPage}>
        {header}
        <Container contentClassName={styles.containerWrap} className={styles.container}>
          <div className={styles.leftPanelWrap}>
            <Chart jobId={id} jobConfiguration={jobConfiguration} socket={socket} />
            <InfoPanel jobId={id} setJobConfiguration={setJobConfiguration} socket={socket} />
          </div>
          <div className={styles.rightPanelWrap}>
            <MetaTables jobId={id} socket={socket} />
            <LogsInfoPanel jobId={id} socket={socket} />
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className={styles.jobDetailsPage}>
      {header}
      <Container contentClassName={styles.containerWrap} className={styles.container}>
        <div className={styles.leftPanelWrap}>
          <Chart jobId={id} jobConfiguration={jobConfiguration} socket={socket} />
          <MetaTables jobId={id} socket={socket} />
        </div>
        <div className={styles.rightPanelWrap}>
          <InfoPanel jobId={id} setJobConfiguration={setJobConfiguration} socket={socket} />
          <LogsActivityPanel jobId={id} />
          <LogsInfoPanel jobId={id} socket={socket} />
        </div>
      </Container>
    </div>
  );
};

export { JobDetailsPage };
