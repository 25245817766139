import { Route as RouterRoute, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FC } from "react";
import { useDidUpdate } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { RouteConfig } from "config/routes.config";
import { Navigation } from "components";

const PageRoute: FC<RouteConfig> = ({ element, showNavigation, ...route }) => {
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const isValidRoute = localStorage.getItem(TOKEN_STORAGE_FIELD) && user;

  useDidUpdate(
    () => {
      if (route.auth && !isValidRoute) {
        navigate("/login");
      }
    },
    [route.auth, isValidRoute],
    true,
  );

  return <Navigation element={element} showNavigation={showNavigation} {...route} />;
};

export { PageRoute };
