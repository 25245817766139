import { array, boolean, number, object, date, TestContext } from "yup";
import { isAfter, addHours } from "date-fns";

import { InitialValues } from "../allocation-form.types";
import {
  EndpointsAllocationStatuses,
  EndpointsAllocationValues,
} from "./endpoints-allocation.types";

export const allocationJobDaysLimit = 31;

export const endpointsAllocationSchema = object({
  endpoints: array(),
  endpointsRanges: array(),
  startDate: date().nullable().required("Start date is required"),
  endDate: date()
    .test("is-greater", "End date must be at least one hour after start date", function (value) {
      const { startDate } = this.parent;
      if (startDate && value) {
        return isAfter(new Date(value), addHours(new Date(startDate), 0.5));
      }
      return true;
    })
    .nullable()
    .required("End date is required"),
  totalAmount: number(),
  remainingAmount: number(),
  usedAmount: number(),
  allEmails: boolean().test(
    "checkAllocation",
    "Please spread remaining emails between Jobs to continue",
    (value: boolean | unknown, context: TestContext<InitialValues> | unknown) => {
      const schemaContext = context ? (context as TestContext<InitialValues>) : null;
      if (!schemaContext || !value) return true;

      const { totalAmount, usedAmount, copyFile, endpointsRanges } = schemaContext.parent;

      const copyFileCondition =
        usedAmount && copyFile && totalAmount * endpointsRanges.length === usedAmount;

      const defaultCondition = !copyFile && totalAmount === usedAmount;

      return defaultCondition || copyFileCondition;
    },
  ),
  copyFile: boolean(),
  sameSpread: boolean(),
});

export const endpointsAllocationInitialValues: EndpointsAllocationValues = {
  startDate: null,
  endDate: null,
  toBeSelectedEmails: 0,
  totalAmount: 0,
  remainingAmount: 0,
  usedAmount: 0,
  endpoints: [],
  endpointsRanges: [],
  allEmails: true,
  copyFile: false,
  sameSpread: false,
  status: "ready-to-allocate",
  minSpreadHour: null,
  initialDateSpread: [],
  maxHoursAllocationLimit: new Set(),
  maxDaysAllocationLimit: new Map(),
};

export const ENDPOINT_STOPPED_STATUSES: Record<string, EndpointsAllocationStatuses> = {
  paused: "paused",
  autofrozen: "autofrozen",
  frozen: "frozen",
};

export const ENDPOINT_STATUSES: Record<string, EndpointsAllocationStatuses> = {
  inProgress: "in-progress",
  ...ENDPOINT_STOPPED_STATUSES,
};

export const allEndpointsLabel = "Same spread for all endpoints";
export const acrossEndpointsLabel = "Copy file across endpoints";
export const confirmReuseJobText =
  "The start/end date and time would be changed according to reused job allocation.";
export const reuseJobErrorTitleText = "Cannot reuse job";

export const daysOfOneYear = 365;
