import { FC } from "react";
import { TextEllipsis, Tooltip } from "@epcnetwork/core-ui-kit";

import { errorEndpointText } from "../allocation-form.constants";

import { AttentionIcon } from "assets/images/components";
import styles from "./item-name-component.module.css";

type ItemNameComponentProps = {
  name: string;
  index: number;
  error?: boolean;
};

const ItemNameComponent: FC<ItemNameComponentProps> = ({ index, name, error }) => {
  return (
    <div className={styles.componentWrapper}>
      {error && (
        <Tooltip
          triggerElement={<AttentionIcon type="error" />}
          position="bottom-left"
          trigger="hover"
          triggerClassName={styles.errorTooltipTrigger}
          contentClassName={styles.errorTooltipContent}
        >
          {errorEndpointText}
        </Tooltip>
      )}
      <span>#{index + 1}</span>
      <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
        {name}
      </TextEllipsis>
    </div>
  );
};

export { ItemNameComponent };
