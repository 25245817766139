import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { QueryType, StorageTableType } from "types";
import { EmailStatuses } from "models";
import { defaultOffset } from "constants/query.constants";
import { IPSTypeOption, LogStatuses, LogStatusTypes, LogTypeOption } from "./logs-info-panel.types";

import xCross from "assets/images/x-cross-red.svg";
import suppress from "assets/images/suppress.svg";
import selectAll from "assets/images/select-all.svg";
import clock from "assets/images/clock-grey.svg";
import success from "assets/images/checkmark.svg";
import styles from "./logs-info-panel.module.css";

export const perPageOptionsList = [6, 12, 18, 24];

export const TABLE_ENTITY_NAME = "logs-info-table";

export const noData = "No data provided";
export const noContentFound = "No Logs were found";
export const fetchingErrorText = "Fetching Error";
export const someErrorText = "Some error happened, please try again later";

export const ispNameLabel = "ISP Name";
export const statusNameLabel = "Contact status";
export const exportBtnText = "Export CSV file";
export const labelCheckboxExport = "Export with Responses";
export const copyToClipboardText = "Copy to Clipboard";

export const logTypesOptions: LogTypeOption[] = [
  { value: "all", label: "All", icon: <img src={selectAll} alt="" className={styles.icon} /> },
  {
    value: "successed",
    label: "Succeeded",
    icon: <img src={success} alt="" className={styles.icon} />,
  },
  { value: "failed", label: "Failed", icon: <img src={xCross} alt="" className={styles.icon} /> },
  { value: "not_sent", label: "Pending", icon: <img src={clock} alt="" className={styles.icon} /> },
  {
    value: "suppressed",
    label: "Suppressed",
    icon: <img src={suppress} alt="" className={styles.icon} />,
  },
];

export const logTypes: LogStatusTypes = {
  all: "All",
  successed: "Successed",
  failed: "Failed",
  suppressed: "Suppressed",
  not_sent: "Pending",
};

export const defaultIPSOption: IPSTypeOption = { value: "all", label: "All" };

export const failedEmailStatusText = "Not Sent";
export const failedEmailStatus: Extract<EmailStatuses, "failed"> = "failed";
export const notSentLogStatus: Extract<LogStatuses, "not_sent"> = "not_sent";

export const getInitialFilters = (): UseFiltersProps<QueryType> => {
  const tableDataJson = localStorage.getItem(TABLE_ENTITY_NAME);
  const limit =
    tableDataJson && (JSON.parse(tableDataJson) as StorageTableType).tableElementsPerPage;

  return {
    initialState: {
      limit: String(limit ?? perPageOptionsList[0]),
      offset: defaultOffset,
      type: "all",
      provider: "all",
      order: "desc",
    },
  };
};
