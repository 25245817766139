import { FC, useMemo, useState } from "react";
import { Table, useDidMount, useDidUpdate, useFetch } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types/common.types";
import { JobMetaDomainModel, JobMetaStatsModel } from "models";
import {
  LastHourStatsUpdateData,
  ListenerEventsKeys,
  SocketInstance,
  WebhookResponseEventData,
} from "hooks";
import { getJobMetaStats } from "api";
import { GlobalMetaTableRow } from "./global-meta-table-row/global-meta-table-row";
import { DomainTableRow } from "./domain-table-row/domain-table-row";

import styles from "./meta-tables.module.css";

export type GlobalMetaTableData = {
  name: string;
  totalEmailCount: number;
  remainingEmailCount: number;
  failedEmailCount: number;
  successEmailCount: number;
  totalSuppressed: number;
  suppressedButSent: string | number;
};

type MetaTablesProps = {
  jobId: number;
  className?: string;
  socket: SocketInstance | null;
};

const globalMetaTableColumns: ColumnsType = [
  { label: "Information" },
  { label: "Total Count" },
  { label: "Successed" },
  { label: "Left" },
  { label: "Failed" },
  { label: "Suppressed" },
  { label: "Suppressed but sent" },
];

const domainsTableColumns: ColumnsType = [
  { label: "IPS" },
  { label: "Total Count" },
  { label: "Successed" },
  { label: "Left" },
  { label: "Failed" },
  { label: "Suppressed" },
];

const MetaTables: FC<MetaTablesProps> = ({ jobId, className, socket }) => {
  const [jobStats, setJobStats] = useState<JobMetaStatsModel | null>(null);

  const { payload, loading, error, refresh, actions } = useFetch(
    getJobMetaStats.setParams({ jobId }),
  );

  useDidUpdate(() => {
    setJobStats(payload);
  }, [payload]);

  const { globalMetaData, domainsData } = useMemo(() => {
    if (!jobStats)
      return {
        globalMetaData: [],
        domainsData: [],
      };

    const { aggregatedByDomain, ...rest } = jobStats.meta;

    const globalMetaData: GlobalMetaTableData[] = [
      {
        name: "Global",
        totalEmailCount: Number(rest.totalToBeSent),
        remainingEmailCount: Number(rest.totalLeft),
        failedEmailCount: Number(rest.totalFailed),
        successEmailCount: Number(rest.totalSuccessful),
        totalSuppressed: Number(rest.totalSuppressed),
        suppressedButSent: Number(rest.suppressedButSent),
      },
      {
        name: "Should be sent in the last hour",
        totalEmailCount: Number(rest.lastHourToBeSent),
        remainingEmailCount: Number(rest.lastHourLeft),
        failedEmailCount: Number(rest.lastHourFailed),
        successEmailCount: Number(rest.lastHourSuccessful),
        totalSuppressed: Number(rest.lastHourSuppressed),
        suppressedButSent: "-", // added not to show 0
      },
    ];

    const domainsData: JobMetaDomainModel[] = [...aggregatedByDomain].sort((email) => {
      if (email.domain.toLowerCase() === "others") {
        return 1;
      }
      return -1;
    });

    return { globalMetaData, domainsData };
  }, [jobStats]);

  useDidUpdate(
    () => {
      const handleData = (data: WebhookResponseEventData) => {
        setJobStats((prevStats) => {
          if (!prevStats) return null;

          const { meta } = prevStats;
          const isSuccess = data.status === "success";
          const isFailed = data.status === "failed";

          const prevDomains = [...prevStats.meta.aggregatedByDomain];
          const domainIndex = prevDomains.findIndex((item) => item.domain === data.isp);

          const domainItemToReplace = domainIndex !== -1 ? prevDomains[domainIndex] : null;

          const increment = (value: number | string, condition = true): string => {
            if (condition) return (Number(value) + 1).toString();
            return value.toString();
          };
          const decrement = (value: number | string): string => (Number(value) - 1).toString();

          if (domainItemToReplace) {
            prevDomains[domainIndex] = {
              ...domainItemToReplace,
              failedEmailCount: increment(domainItemToReplace.failedEmailCount, isFailed),
              remainingEmailCount: decrement(domainItemToReplace.remainingEmailCount),
              sentEmailCount: increment(domainItemToReplace.sentEmailCount),
              successEmailCount: increment(domainItemToReplace.successEmailCount, isSuccess),
            };
          }

          return {
            ...prevStats,
            meta: {
              ...prevStats.meta,
              aggregatedByDomain: prevDomains,
              totalFailed: increment(meta.totalFailed, isFailed),
              totalLeft: decrement(meta.totalLeft),
              totalSent: increment(meta.totalSent),
              totalSuccessful: increment(meta.totalSuccessful, isSuccess),
            },
          };
        });
      };

      const handleLastHourData = (data: LastHourStatsUpdateData) => {
        setJobStats((prevStats) => {
          if (prevStats === null) return prevStats;

          return {
            ...prevStats,
            meta: {
              ...prevStats.meta,
              lastHourFailed: data.lastHourFailed.toString(),
              lastHourSent: data.lastHourSent.toString(),
              lastHourLeft: data.lastHourLeft.toString(),
              lastHourSuccessful: data.lastHourSuccessful.toString(),
              lastHourSuppressed: data.lastHourSuppressed.toString(),
              lastHourToBeSent: +data.lastHourToBeSent,
            },
          };
        });
      };

      if (socket) {
        socket.on<ListenerEventsKeys>("webhookResponse", handleData);
        socket.on<ListenerEventsKeys>("lastHourStatsUpdate", handleLastHourData);

        return () => {
          socket.off<ListenerEventsKeys>("webhookResponse", handleData);
          socket.off<ListenerEventsKeys>("lastHourStatsUpdate", handleLastHourData);
        };
      }
    },
    [socket],
    true,
  );

  return (
    <div className={className}>
      <Table
        entityName="job-details-global-meta"
        className={styles.globalMetaTable}
        columns={globalMetaTableColumns}
        list={globalMetaData}
        error={error?.message}
        loading={loading}
        refresh={refresh}
        row={(item, index) => <GlobalMetaTableRow index={index} item={item} />}
      />
      <Table
        entityName="job-details-domains-meta"
        className={styles.domainMetaTable}
        columns={domainsTableColumns}
        list={domainsData}
        error={error?.message}
        loading={loading}
        refresh={refresh}
        row={(item, index) => <DomainTableRow index={index} item={item} />}
      />
    </div>
  );
};

export { MetaTables };
