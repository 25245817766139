import { FC } from "react";
import { Tooltip } from "@epcnetwork/core-ui-kit";

import { ReactComponent as InfoIcon } from "assets/images/toastr-confirm-info.svg";
import styles from "./info-tooltip.module.css";

const tooltipText =
  "Allocation by days can be pasted from another source, click on calendar cell to paste data";
const InfoTooltip: FC = () => {
  return (
    <Tooltip
      triggerElement={<InfoIcon />}
      trigger="hover"
      position="bottom-left"
      portalClassName={styles.tooltip}
      triggerClassName={styles.trigger}
    >
      {tooltipText}
    </Tooltip>
  );
};

export { InfoTooltip };
