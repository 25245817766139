import { FC, useState } from "react";
import {
  TableRow,
  TableCell,
  MenuButton,
  Menu,
  notification,
  useCall,
  Chip,
  TextEllipsis,
  useWindowSize,
} from "@epcnetwork/core-ui-kit";

import { formatDate } from "utils";
import { GroupModel } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { DELETE_ERROR_TEXT } from "constants/notification.constants";
import { timeFormat } from "constants/general.constants";
import { deleteGroup } from "api";

import globalStyles from "assets/styles/global.module.css";
import clockImg from "assets/images/clock-grey.svg";
import styles from "./group-list-table-row.module.css";

type GroupListTableRowProps = {
  index: number;
  item: GroupModel;
  handleEdit: (id: number) => void;
};

const GroupListTableRow: FC<GroupListTableRowProps> = ({ index, item, handleEdit }) => {
  const { width } = useWindowSize();
  const ability = useAuthorization();
  const { id, name, userCount = "0", createdAt } = item;

  const [isDeleted, setDeleted] = useState(false);

  const { submit, onCallSuccess, onCallError } = useCall(deleteGroup.setParams({ groupId: id }));

  onCallSuccess(() => {
    notification.success("Group deleted", "Successfully deleted group");
  });

  onCallError(() => {
    setDeleted(false);
    notification.error(DELETE_ERROR_TEXT, "Cannot delete group");
  });

  const handleRemoveItem = () => {
    notification.confirm("Deleting group?", "Are you sure?", {
      onOk: () => {
        setDeleted(true);
        submit();
      },
      icon: "delete",
    });
  };

  const handleEditItem = () => handleEdit(id);

  const deletedStyles = isDeleted ? globalStyles.deleteElement : "";
  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <TableRow id={index} className={deletedStyles}>
      <TableCell>#{id}</TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <Chip appearance="warning" text={userCount} />
      </TableCell>
      <TableCell className={styles.dateCell}>
        {formatDate(createdAt, "EEE, MMM dd, yyyy")}
        <img src={clockImg} alt="" />
        {formatDate(createdAt, timeFormat)}
      </TableCell>
      <TableCell>
        {!isMobile && (
          <Menu>
            {ability.can("create", "Users") && (
              <MenuButton onClick={handleEditItem} icon="edit">
                Edit
              </MenuButton>
            )}
            {ability.can("delete", "Users") && (
              <MenuButton appearance="delete" onClick={handleRemoveItem} icon="delete">
                Delete
              </MenuButton>
            )}
            {!ability.can("create", "Users") && !ability.can("delete", "Users") && (
              <div style={{ padding: "10px" }}>No Actions available</div>
            )}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export { GroupListTableRow };
