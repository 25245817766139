import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useState, useRef, FC, useMemo } from "react";
import cn from "classnames";
import {
  TableRow,
  TableCell,
  useCall,
  getProgressBetweenDates,
  notification,
  MenuButton,
  Menu,
  TextEllipsis,
  getLinkPath,
  useWindowSize,
  useWillUnmount,
  Checkbox,
} from "@epcnetwork/core-ui-kit";

import { formatDate, formatEmptyText, getJobStatusText } from "utils";
import { setAllocatedBatchesIds, RootState } from "store";
import { JobModel } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { JOBS_FORM_RECONFIGURATION_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { JOB_PROGRESS_STATUSES } from "constants/jobs.constants";
import { timeFormat } from "constants/general.constants";
import { CircularProgress } from "components";
import { postHideJob, deleteJob } from "api";
import {
  deleteJobConfirmMessage,
  deleteJobConfirmTitle,
  deleteJobErrorMessage,
  deleteJobSuccessMessage,
  deleteJobSuccessTitle,
  hideJobErrorMessage,
} from "./job-list-table-row.constants";

import clockImg from "assets/images/clock-grey.svg";
import startDateImg from "assets/images/bracket-arrow-start-grey.svg";
import endDateImg from "assets/images/bracket-arrow-end-grey.svg";
import styles from "./job-list-table-row.module.css";

type JobListTableRowProps = {
  index: number;
  job: JobModel;
  isSelectable: boolean; // is particular item selectable
  isListSelectable: boolean; // is any item from a list selectable
  isSelected: boolean;
  onSelect: (value: number, isChecked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  refresh: VoidFunction;
  handleOpenEdit: (jobId: string) => void;
};

const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
  if (completed) {
    return "In progress";
  } else {
    return (
      <span>
        {!!days && `${days}d`} {!!hours && `${hours}h`} {!!minutes && `${minutes}m`}{" "}
        {!!seconds && `${seconds}s`} left
      </span>
    );
  }
};

const JobListTableRow: FC<JobListTableRowProps> = ({
  index,
  job,
  isSelectable,
  isListSelectable,
  isSelected,
  onSelect,
  handleOpenEdit,
  refresh,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useAuthorization();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const { width } = useWindowSize();
  const { allocatedBatchesIds } = useSelector((state: RootState) => state.jobs);

  const [isChanged, setIsChanged] = useState(false);

  useWillUnmount(() => {
    allocatedBatchesIds && dispatch(setAllocatedBatchesIds(null));
  });

  const {
    submit: hideJob,
    onCallError: onHideCallError,
    onCallSuccess: onHideCallSuccess,
  } = useCall(postHideJob);
  onHideCallSuccess((response) => {});
  onHideCallError((error) => {
    setIsChanged(false);
    notification.error(hideJobErrorMessage, error.message);
  });

  const {
    submit: deleteSubmit,
    onCallError: onDeleteCallError,
    onCallSuccess: onDeleteCallSuccess,
  } = useCall(deleteJob);
  onDeleteCallSuccess(() => {
    notification.success(deleteJobSuccessTitle, deleteJobSuccessMessage);
    refresh();
  });
  onDeleteCallError((error) => {
    setIsChanged(false);
    notification.error(deleteJobErrorMessage, error.message);
  });

  const handleEdit = () => {
    handleOpenEdit(String(job.id));
  };

  const handleReconfiguration = () => {
    navigate(getLinkPath(JOBS_FORM_RECONFIGURATION_PAGE.path, { jobId: job.id.toString() }));
  };

  const handleDelete = () => {
    notification.confirm(deleteJobConfirmTitle, deleteJobConfirmMessage, {
      onOk: () => {
        setIsChanged(true);
        deleteSubmit({ params: { jobId: job.id } });
      },
      icon: "delete",
    });
  };

  const handleHide = async () => {
    setIsChanged(true);
    await hideJob({ data: { hidden: !job.hidden }, params: { jobId: job.id } });
  };

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const element = menuRef.current;
    const containsButton = element && element.contains(e.target as HTMLElement);
    const isRef = element === e.target;
    if (!containsButton && !isRef) {
      navigate(`${JOBS_LIST_PAGE.path}/${job.id}`);
    }
  };

  const isMobile = width < MAX_MOBILE_WIDTH;
  const hideBtnText = job.hidden ? "Show" : "Hide";

  const { isPending, isPaused, isFinished, isFrozen, isAutoFrozen, isStatusForDelete } =
    useMemo(() => {
      const isPending = job.status === JOB_PROGRESS_STATUSES.pending;
      const isPaused = job.status === JOB_PROGRESS_STATUSES.paused;
      const isFinished = job.status === JOB_PROGRESS_STATUSES.finished;
      const isFrozen = job.status === JOB_PROGRESS_STATUSES.frozen;
      const isAutoFrozen = job.status === JOB_PROGRESS_STATUSES.autofrozen;

      const isStatusForDelete = isFinished || isPaused || isPending;

      return { isPending, isPaused, isFinished, isFrozen, isAutoFrozen, isStatusForDelete };
    }, [job.status]);

  const progress = useMemo(() => {
    // TODO: calculate progress based on sent emails (?)
    return getProgressBetweenDates(new Date(job.startDate), new Date(job.endDate));
  }, [job.stats, job.startDate, job.endDate]);

  const rowStyles = cn(styles.row, {
    [styles.allocatedAnimation]: allocatedBatchesIds?.has(job.id),
  });

  const handleSelectClick = (
    value: string,
    isChecked: boolean,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onSelect(job.id, isChecked, e);
  };

  if (isChanged) {
    return null;
  }

  return (
    <TableRow id={index} className={rowStyles} onRowClick={handleRowClick}>
      {isSelectable && (
        <TableCell className={styles.checkboxCell}>
          <Checkbox
            value={job.id}
            onChange={handleSelectClick}
            onClick={(e) => e.stopPropagation()}
            className={styles.checkbox}
            checked={isSelected}
            name={`job-${job.id}`}
            inputSize="big"
            disableError
            showBox
          />
        </TableCell>
      )}
      {isListSelectable && !isSelectable && (
        <TableCell className={styles.checkboxCell}>
          <div />
        </TableCell>
      )}
      <TableCell className={styles.id}>#{job.id}</TableCell>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {job.name}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.info}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {job.fileIds?.join(", ")}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <CircularProgress
          value={progress}
          isPaused={isPaused}
          isManualFrozen={isFrozen}
          isAutoFrozen={isAutoFrozen}
          isFinished={isFinished}
        />
      </TableCell>
      <TableCell className={styles.info}>{formatEmptyText(job.stats.total)}</TableCell>
      <TableCell className={styles.info}>{formatEmptyText(job.stats.totalSent)}</TableCell>
      <TableCell className={styles.info}>{formatEmptyText(job.stats.totalFailed)}</TableCell>
      <TableCell className={styles.info}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {formatEmptyText(job.tag?.name)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <div className={styles.dateCell}>
          <img className={styles.dateImg} src={startDateImg} alt="start date" />
          {formatDate(job.startDate, "EEE, MMM dd, yyyy")}
          <img src={clockImg} alt="" />
          {formatDate(job.startDate, timeFormat)}
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.dateCell}>
          <img className={styles.dateImg} src={endDateImg} alt="end date" />
          {formatDate(job.endDate, "EEE, MMM dd, yyyy")}
          <img src={clockImg} alt="" />
          {formatDate(job.endDate, timeFormat)}
        </div>
      </TableCell>
      <TableCell className={styles.eta}>
        {isPending ? (
          <Countdown date={new Date(job.startDate)} renderer={renderer} />
        ) : (
          getJobStatusText(job.status)
        )}
      </TableCell>
      <TableCell>
        <div ref={menuRef}>
          {!isMobile && (
            <Menu>
              {ability.can("create", "Jobs") && (
                <MenuButton onClick={handleEdit} disabled={isFinished}>
                  Edit
                </MenuButton>
              )}
              {ability.can("create", "Jobs") && (
                <MenuButton
                  appearance="default"
                  onClick={handleReconfiguration}
                  disabled={isFinished}
                >
                  Reconfiguration
                </MenuButton>
              )}
              {ability.can("create", "Jobs") && (
                <MenuButton appearance="default" onClick={handleHide} disabled={!isFinished}>
                  {hideBtnText}
                </MenuButton>
              )}
              {ability.can("delete", "Jobs") && (
                <MenuButton
                  appearance="delete"
                  onClick={handleDelete}
                  disabled={!isStatusForDelete}
                >
                  Delete
                </MenuButton>
              )}
              {!ability.can("delete", "Jobs") && !ability.can("create", "Jobs") && (
                <div style={{ padding: "10px" }}>No Actions available</div>
              )}
            </Menu>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export { JobListTableRow };
