import { useAbility } from "@casl/react";
import { Ability, AbilityTuple, MongoQuery } from "@casl/ability";

import { AuthorizationActionType, AuthorizationEntityNameType } from "models";
import { AbilityContext } from "components/client/authorization/authorization";

type UseAuthorizationReturn = Ability<
  AbilityTuple<AuthorizationActionType, AuthorizationEntityNameType>,
  MongoQuery<Record<string | number | symbol, unknown>>
>;

const useAuthorization = (): UseAuthorizationReturn => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useAbility(AbilityContext) as any;
};

export { useAuthorization };
