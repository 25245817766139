import { useNavigate } from "react-router-dom";
import { FC } from "react";
import cn from "classnames";
import { Sidebar as SidebarComponent } from "@epcnetwork/core-ui-kit";

import { LOGOUT_PAGE } from "constants/routes.constants";
import { collapsedDesktopWidth, entityName, placement, sidebarWidth } from "./sidebar.constants";
import { Menu } from "./components/menu";
import { Logo } from "./components/logo";

import { ReactComponent as CrossIcon } from "assets/images/x-cross-rounded-grey.svg";
import { ReactComponent as ToggleImg } from "assets/images/arrow-right-grey.svg";
import styles from "./sidebar.module.css";

type SidebarProps = {
  collapsed: boolean;
  isMobile: boolean;
  handleSidebarCollapsed: (value: boolean) => void;
  toggleSidebar: () => void;
};

const Sidebar: FC<SidebarProps> = ({
  collapsed,
  isMobile,
  handleSidebarCollapsed,
  toggleSidebar,
}) => {
  const navigate = useNavigate();
  const handleLogout = () => navigate(LOGOUT_PAGE.path);

  const getCollapsedWidth = () => (isMobile ? sidebarWidth : collapsedDesktopWidth);

  const collapsedWidth = getCollapsedWidth();

  const togglerStyles = cn(styles.toggler, styles[placement], { [styles.collapsed]: collapsed });
  const sidebarStyles = cn(styles.sidebar, styles.override, { [styles.collapsed]: collapsed });
  const backDropStyles = cn(styles.backdrop, { [styles.collapsed]: collapsed });

  const closeSidebarMobile = () => {
    if (isMobile) {
      handleSidebarCollapsed(true);
    }
  };

  return (
    <>
      <div className={backDropStyles} onClick={closeSidebarMobile} />
      <SidebarComponent
        wrapperClassName={cn(styles.sidebarWrapper, styles.override, {
          [styles.collapsed]: collapsed,
        })}
        contentClassName={sidebarStyles}
        setIsCollapsed={handleSidebarCollapsed}
        isCollapsed={collapsed}
        sidebarWidth={sidebarWidth}
        collapsedWidth={collapsedWidth}
        entityName={entityName}
        isContentOffset={true}
      >
        <Logo />

        {isMobile ? (
          <button onClick={closeSidebarMobile} className={styles.closeSidebarBtn}>
            <CrossIcon className={styles.closeIcon} />
          </button>
        ) : (
          <button className={togglerStyles} onClick={toggleSidebar}>
            <ToggleImg className={styles.togglerImage} />
          </button>
        )}
        <Menu closeSidebarMobile={closeSidebarMobile} />
        <div className={styles.logoutBtnBox}>
          <button className={styles.logoutBtn} onClick={handleLogout}>
            Log out
          </button>
        </div>
      </SidebarComponent>
    </>
  );
};

export { Sidebar };
