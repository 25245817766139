import { FC, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import {
  Button,
  Checkbox,
  FetchingError,
  List,
  Modal,
  NoContent,
  notification,
  Pagination,
  Select,
  TransitionWrapper,
  useAutocomplete,
  useCall,
  useDevice,
  useDidUpdate,
  useFetch,
  useFilters,
  UseFiltersProps,
  usePagination,
  useQuery,
} from "@epcnetwork/core-ui-kit";

import { generateOptions, getInitialStorageFilters } from "utils";
import { QueryType, StorageTableType } from "types";
import { EmailStatuses, JobIPSModel, JobLogModel } from "models";
import {
  ListenerEventsKeys,
  usePayload,
  useShowContent,
  useTablePagination,
  WebhookResponseEventData,
} from "hooks";
import { defaultOffset } from "constants/query.constants";
import { Loader } from "components";
import { getJobIPS, getJobLogs, getJobLogsDownload } from "api";
import {
  IPSTypeOption,
  JobIPSDataType,
  LogsInfoPanelProps,
  LogStatuses,
  LogTypeOption,
} from "./logs-info-panel.types";
import {
  defaultIPSOption,
  exportBtnText,
  fetchingErrorText,
  ispNameLabel,
  labelCheckboxExport,
  logTypes,
  noContentFound,
  noData,
  notSentLogStatus,
  perPageOptionsList,
  someErrorText,
  statusNameLabel,
  TABLE_ENTITY_NAME,
  getInitialFilters,
  logTypesOptions,
} from "./logs-info-panel.constants";
import { LogsInfoItemModal } from "./logs-info-item-modal";
import { LogsInfoItem } from "./logs-info-item";

import { ReactComponent as ExportIcon } from "assets/images/export-icon.svg";
import styles from "./logs-info-panel.module.css";

const getStatusOption = (type: string): LogTypeOption => {
  const option = logTypesOptions.find(({ value }) => value === type);
  return option ? option : logTypesOptions[0];
};

const checkIPSTypeValidity = (type: string, options: IPSTypeOption[] = []): boolean =>
  !!type && type !== "all" && options.some(({ value }) => value === type);

const LogsInfoPanel: FC<LogsInfoPanelProps> = ({ jobId, className, socket }) => {
  const initialFilters = getInitialFilters();
  const statusRef = useRef<string>("all");
  const ispRef = useRef<string>("all");

  const { stringify } = useQuery();
  const { isMobileDevice } = useDevice();

  const [jobLogsList, setJobLogList] = useState<List<JobLogModel> | null>(null);
  const [jobLogResponseId, setJobLogResponseId] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  const { state, currentState, setValue } = useFilters<QueryType>({
    ...getInitialStorageFilters<QueryType>(TABLE_ENTITY_NAME, initialFilters),
    isStateBased: true,
  });

  const logsResponse = useFetch(getJobLogs.setParams({ jobId }).setQueryParams(stringify(state)), {
    dependencies: [state],
  });
  const { list, payload, loading, error } = usePayload({ ...logsResponse, payload: jobLogsList });
  const { showContent, showNoContent, showError } = useShowContent({
    ...logsResponse,
    payload: jobLogsList,
  });

  useDidUpdate(() => {
    setJobLogList(logsResponse.payload);
  }, [logsResponse.payload]);

  useDidUpdate(
    () => {
      const handleData = (webhookResponseData: WebhookResponseEventData) => {
        setJobLogList((prevState) => {
          if (!prevState) return null;

          // update logs only on 1st page
          if (prevState.offset === 0) {
            const updatedLogs: JobLogModel[] = [...prevState.data].map((item) => ({
              ...item,
              newlyAdded: false,
            }));

            const mapStatus = (status: "success" | "failed") => {
              if (status === "success") return "successed";
              return status;
            };

            const isProviderFilterMatched =
              ispRef.current === "all" || ispRef.current === webhookResponseData.isp;
            const isStatusFilterMatched =
              statusRef.current === "all" ||
              statusRef.current === mapStatus(webhookResponseData.status);

            const item: JobLogModel = {
              ...webhookResponseData,
              newlyAdded: true,
            };

            if (isProviderFilterMatched && isStatusFilterMatched) {
              updatedLogs.unshift(item);
            }

            if (updatedLogs.length > +prevState.limit) {
              updatedLogs.pop();
            }

            return {
              ...prevState,
              data: updatedLogs,
              total: prevState.total + 1,
            };
          }

          return prevState;
        });
      };

      if (socket) {
        socket.on<ListenerEventsKeys>("webhookResponse", handleData);

        return () => {
          socket.off<ListenerEventsKeys>("webhookResponse", handleData);
        };
      }
    },
    [socket],
    true,
  );

  const pagination = usePagination({
    listPayload: jobLogsList,
    isStateBased: true,
    initialState: currentState,
  });

  useEffect(() => {
    setValue("offset")(pagination.state.offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.state.offset]);

  useEffect(() => {
    setValue("limit")(pagination.state.limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.state.limit]);

  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_ENTITY_NAME,
    updateElementsPerPage: true,
  });

  const jobIPSAutocomplete = useAutocomplete(getJobIPS, "name", "name");

  useDidUpdate(() => {
    if (state.type === notSentLogStatus) {
      setIsChecked(false);
    }
  }, [state.type]);

  const handleDataError = (errorText?: string) =>
    notification.error("Error", errorText || someErrorText);

  const handleModalClose = () => setJobLogResponseId(0);

  const handleLogTypeChange = ({ value }: LogTypeOption) => {
    setValue("type")(value);
    statusRef.current = value;
  };

  const handleIPSTypeChange = ({ value }: IPSTypeOption) => {
    setValue("provider")(value);
    ispRef.current = value;
  };

  const handleExportClick = () => submit();

  const totalLogsAmount = payload?.total || 0;

  const jobLogTypeOption = useMemo(() => {
    return getStatusOption(state.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  const { submit, submitting, onCallSuccess, onCallError } = useCall(
    getJobLogsDownload.setQueryParams(
      stringify({
        id: jobId,
        type: state.type || "all",
        provider: state.provider || "all",
        responses: isChecked,
      }),
    ),
  );

  onCallError((error) => handleDataError(error.message));

  onCallSuccess((data) => {
    if (!data) return handleDataError(noData);

    const blob = new Blob([data], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const element = document.createElement("a");
    element.download = `job-${jobId}-logs-response.csv`;
    element.href = url;
    element.click();
    element.href = "";
    window.URL.revokeObjectURL(url);
  });

  const onChange = (_: string, checked: boolean) => setIsChecked(checked);

  const disableSelects = loading || submitting;
  const disableExport = loading || submitting || !list.length;

  return (
    <>
      <div className={cn(styles.logsInfoPanelWrap, className)}>
        <span className={styles.totalFoundText}>Total found: {totalLogsAmount}</span>
        <div className={styles.logsActionsWrap}>
          <Select
            label={ispNameLabel}
            initiallySelectedOptions={defaultIPSOption}
            selectedOptionsKeys={
              checkIPSTypeValidity(
                state.provider,
                // Issue with ui-kit type DataType. It should be rewritten to accept generic for SelectOption type
                jobIPSAutocomplete.fetchOptions as Array<JobIPSDataType<JobIPSModel>>,
              )
                ? state.provider
                : defaultIPSOption.value
            }
            options={[defaultIPSOption]}
            asyncOptions={jobIPSAutocomplete as any}
            onChange={handleIPSTypeChange}
            disableSingleOptionUncheck
            disableClearing
            disableError
            disabled={disableSelects}
          />
          <Select
            label={statusNameLabel}
            initiallySelectedOptions={jobLogTypeOption}
            selectedOptionsKeys={jobLogTypeOption.value}
            options={logTypesOptions}
            sortOptions={false}
            moveSelectedOptionToTop={false}
            onChange={handleLogTypeChange}
            disableSingleOptionUncheck
            disableClearing
            disableError
            disabled={disableSelects}
          />
          {!isMobileDevice && (
            <>
              <Button
                appearance="secondary"
                onClick={handleExportClick}
                disabled={disableExport}
                loading={submitting}
              >
                <ExportIcon />
                {exportBtnText}
              </Button>
              <Checkbox
                label={labelCheckboxExport}
                value="responses"
                checked={isChecked}
                name="responses"
                inputSize="big"
                onChange={onChange}
                disableError
                showBox
                disabled={state.type === notSentLogStatus}
              />
            </>
          )}
        </div>
        <div className={styles.logItemsWrap}>
          {loading && <Loader size={50} />}
          {showContent &&
            list.map((item) => (
              <LogsInfoItem key={item.id} item={item} onItemClick={setJobLogResponseId} />
            ))}
          {showNoContent && (
            <TransitionWrapper isIn>
              <NoContent subtitle={noContentFound} size="small" showButton={false} />
            </TransitionWrapper>
          )}
          {showError && error && (
            <TransitionWrapper isIn>
              <FetchingError title={fetchingErrorText} subtitle={error.message} size="small" />
            </TransitionWrapper>
          )}
        </div>
        {showContent && (
          <div className={styles.paginationWrap}>
            <Pagination
              {...pagination}
              elementsPerPage={currentElementsPerPage}
              perPageTitle=""
              perPageInfo={false}
              shouldScrollAfterPageChange={false}
              controls={isMobileDevice ? "arrows" : "text"}
              pageNumControls={!isMobileDevice}
              perPageSelectClassName={styles.perPageSelect}
              onElementsPerPageChange={handlePerPageChange}
              perPageOptionsList={generateOptions(perPageOptionsList)}
              className={styles.logsInfoPagination}
              dropdownPositionCalculationDeps={[list]}
            />
          </div>
        )}
      </div>
      <Modal isOpen={!!jobLogResponseId} setClose={handleModalClose}>
        <LogsInfoItemModal jobId={jobId} jobLogResponseId={jobLogResponseId} />
      </Modal>
    </>
  );
};

export { LogsInfoPanel };
