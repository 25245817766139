import { FC } from "react";

import { LoginSlider } from "../login-slider/login-slider";

import styles from "../login.module.css";

const LoginSidebar: FC = () => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarBg} />
      <div className={styles.gradientBg} />
      <div className={styles.content}>
        <LoginSlider />
        <div className={styles.info}>
          <div className={styles.option}>&#169;{new Date().getFullYear()} Traffic</div>
        </div>
      </div>
    </div>
  );
};

export { LoginSidebar };
