import { IThumbProps } from "react-range/lib/types";
import { FC } from "react";
import cn from "classnames";

import styles from "./chart.module.css";

type ChartPreviewThumbProps = {
  props: IThumbProps;
  label: string;
  index: number;
};

const ChartPreviewThumb: FC<ChartPreviewThumbProps> = ({ props, label, index }) => {
  return (
    <div {...props} className={cn(styles.chartPreviewRangeThumb, { [styles.firstThumb]: !index })}>
      <div className={styles.chartPreviewRangeThumbLabel}>{label}</div>
    </div>
  );
};

export { ChartPreviewThumb };
