import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types/common.types";
import { defaultLimit, defaultOffset } from "constants/query.constants";
import { searchMinLength } from "constants/form.constants";
import { AllocationSupportedStatuses, FiltersQueryType } from "./allocation-list.types";

export const tableColumns = (isMobile: boolean): ColumnsType => [
  { label: "Id" },
  { label: "Jobs", queryKey: "endpointsAmount" },
  { label: "Tags", required: true, queryKey: "tagName" },
  { label: "File name", required: true },
  { label: "Creation Date", queryKey: "createdAt" },
  { label: "Status", queryKey: "status" },
  { label: "Action", required: !isMobile, selected: !isMobile },
  { label: "" },
];

export const tabStatusesMap: Record<number, AllocationSupportedStatuses> = {
  0: "all",
  1: "ready-to-allocate",
  2: "calculation",
  3: "waiting-for-calculation",
};

export const TABLE_ENTITY_NAME = "allocation";

export const initialFilters: UseFiltersProps<FiltersQueryType> = {
  searchOptions: { searchKey: "search", searchDebounce: 1200, searchMinLength },
  initialState: {
    search: "",
    status: "all",
    limit: defaultLimit,
    offset: defaultOffset,
  },
};
