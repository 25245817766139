import { useMemo, useState } from "react";
import {
  ApiErrorResponse,
  ApiStatusResponse,
  getJSDate,
  isEmpty,
  useCall,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { JobTrafficModel } from "models";
import { ChartUpdateData, ListenerEventsKeys, SocketInstance } from "hooks";
import { DEFAULT_TIME_ZONE } from "constants/timezones.constants";
import { ServerErrorTypes } from "api/api.middleware";
import { getJobChartStatsDaily, getJobChartStatsHourly } from "api";
import { ChartTimeUnits } from "./chart.types";
import { ChartProps } from "./chart";

type UseChartFetchParams = Pick<ChartProps, "jobId"> & {
  chartTimeUnit?: ChartTimeUnits;
  socket: SocketInstance | null;
};

export type JobTrafficMap = Map<number /* timestamp */, JobTrafficModel>;

type ChartFetchData = {
  trafficStatsMap: JobTrafficMap;
  trafficStats: JobTrafficModel[];
  trafficStatsError: ApiErrorResponse<ServerErrorTypes> | null;
  trafficStatsDetails?: ApiStatusResponse;
  isTrafficStatsError: boolean;
  isTrafficStatsSuccess: boolean;
};

type UseChartFetchReturn = ChartFetchData & {
  isTrafficStatsLoading: boolean;
};

const chartFetchData: ChartFetchData = {
  trafficStatsMap: new Map(),
  trafficStats: [],
  trafficStatsError: null,
  isTrafficStatsError: false,
  isTrafficStatsSuccess: false,
};

const useChartFetch = ({
  jobId,
  chartTimeUnit,
  socket,
}: UseChartFetchParams): UseChartFetchReturn => {
  const isHourlyTimeUnit = chartTimeUnit === ChartTimeUnits.hourly;
  const isDailyTimeUnit = chartTimeUnit === ChartTimeUnits.daily;

  const [chartResponseDataHourlyCache, setChartResponseDataHourlyCache] =
    useState<ChartFetchData>(chartFetchData);
  const [chartResponseDataDailyCache, setChartResponseDataDailyCache] =
    useState<ChartFetchData>(chartFetchData);

  const chartResponseData: ChartFetchData = useMemo(() => {
    return isHourlyTimeUnit ? chartResponseDataHourlyCache : chartResponseDataDailyCache;
  }, [isHourlyTimeUnit, chartResponseDataHourlyCache, chartResponseDataDailyCache]);

  const {
    submit: requestHourly,
    onCallSuccess: onCallSuccessHourly,
    onCallError: onCallErrorHourly,
    submitting: isLoadingHourly,
  } = useCall(getJobChartStatsHourly.setParams({ jobId }));

  const {
    submit: requestDaily,
    onCallSuccess: onCallSuccessDaily,
    onCallError: onCallErrorDaily,
    submitting: isLoadingDaily,
  } = useCall(
    getJobChartStatsDaily.setParams({ jobId }).setQueryParams(`?tz=${DEFAULT_TIME_ZONE}`),
  );

  const onCallSuccess = isHourlyTimeUnit ? onCallSuccessHourly : onCallSuccessDaily;
  const onCallError = isHourlyTimeUnit ? onCallErrorHourly : onCallErrorDaily;

  onCallSuccess((payload) => {
    if (!payload) return;
    if (isHourlyTimeUnit) {
      setChartResponseDataHourlyCache((prev) => {
        return {
          ...prev,
          isTrafficStatsSuccess: true,
          isTrafficStatsError: false,
          trafficStats: payload.traffic ?? [],
        };
      });
    }
    if (isDailyTimeUnit) {
      setChartResponseDataDailyCache((prev) => {
        return {
          ...prev,
          isTrafficStatsSuccess: true,
          isTrafficStatsError: false,
          trafficStats: payload.traffic ?? [],
        };
      });
    }
  });

  onCallError((error) => {
    if (isHourlyTimeUnit) {
      setChartResponseDataHourlyCache((prev) => ({
        ...prev,
        trafficStatsError: error,
        isTrafficStatsSuccess: false,
        isTrafficStatsError: true,
      }));
    } else if (isDailyTimeUnit) {
      setChartResponseDataDailyCache((prev) => ({
        ...prev,
        trafficStatsError: error,
        isTrafficStatsSuccess: false,
        isTrafficStatsError: true,
      }));
    }
  });

  useDidUpdate(
    () => {
      if (isHourlyTimeUnit && isEmpty(chartResponseDataHourlyCache.trafficStats)) {
        requestHourly();
      }
      if (isDailyTimeUnit && isEmpty(chartResponseDataDailyCache.trafficStats)) {
        requestDaily();
      }
    },
    [isHourlyTimeUnit, isDailyTimeUnit],
    true,
  );

  const updateChart = ({ date, dailyDate, success, failed }: ChartUpdateData) => {
    const modifyChartState =
      ({ hourly }: { hourly: boolean }) =>
      (state: ChartFetchData) => {
        const itemIndex = state.trafficStats.findIndex((item) => {
          if (hourly) return new Date(item.date).getTime() === new Date(date).getTime();
          return new Date(item.date).getTime() === new Date(dailyDate).getTime();
        });

        if (itemIndex === -1) return state;

        const allItems = [...state.trafficStats];
        const itemToReplace = allItems[itemIndex];

        allItems[itemIndex] = {
          ...itemToReplace,
          successful: itemToReplace.successful + success,
          failed: itemToReplace.failed + failed,
        };

        return {
          ...state,
          trafficStats: allItems,
        };
      };

    setChartResponseDataHourlyCache(modifyChartState({ hourly: true }));
    setChartResponseDataDailyCache(modifyChartState({ hourly: false }));
  };

  useDidUpdate(
    () => {
      if (socket) {
        socket.on<ListenerEventsKeys>("chartUpdate", updateChart);

        return () => {
          socket.off<ListenerEventsKeys>("chartUpdate", updateChart);
        };
      }
    },
    [socket],
    true,
  );

  const chartDataMap = useMemo(() => {
    if (!chartResponseData.trafficStats.length) {
      return new Map();
    }

    return new Map(
      chartResponseData.trafficStats.map(({ date: trafficStatDate, ...restTrafficStats }) => {
        const date = getJSDate(trafficStatDate);

        const timestamp = Number(date);

        return [timestamp, restTrafficStats];
      }),
    );
  }, [chartResponseData]);

  return {
    trafficStatsMap: chartDataMap,
    trafficStats: chartResponseData.trafficStats,
    isTrafficStatsLoading: isLoadingHourly || isLoadingDaily,
    trafficStatsError: chartResponseData.trafficStatsError,
    isTrafficStatsError: chartResponseData.isTrafficStatsError,
    isTrafficStatsSuccess: chartResponseData.isTrafficStatsSuccess,
  };
};

export { useChartFetch };
