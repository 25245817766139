import { memo, useCallback, useMemo, useRef, useState } from "react";
import {
  Button,
  Modal,
  Search,
  TableProps,
  Tabs,
  notification,
  useCall,
  useDidUpdate,
  useFetch,
  useFilters,
  usePagination,
  List,
} from "@epcnetwork/core-ui-kit";

import { getInitialStorageFilters } from "utils";
import { ArrayElement, JobCountModel, JobModel } from "models";
import {
  JobListListenerEventsKeys,
  JobListUpdateData,
  useJobListSocketHook,
  usePayload,
  useTablePagination,
} from "hooks";
import { defaultOffset } from "constants/query.constants";
import { Container } from "components";
import { getJobs, getJobsRefresh, postHideJobsBatch } from "api";
import { JobForm } from "../form";
import { getInitialActiveTab, getActiveStatus, jobListTabs } from "./jobs-list.utils";
import { JobFiltersQueryType } from "./jobs-list.types";
import {
  hideJobsBatchErrorTitle,
  hideJobsBatchSuccessMessage,
  hideJobsBatchSuccessTitle,
  initialFilters,
  statuses,
  TABLE_ENTITY_NAME,
  tableColumns,
  paginationKeys,
  jobPayloadInitialValue,
} from "./jobs-list.constants";
import { JobListTableRow } from "./job-list-table-row/job-list-table-row";

import eyeOpen from "assets/images/eye-open.svg";
import eyeClosed from "assets/images/eye-closed.svg";
import styles from "./jobs-list.module.css";

const JobListPage = memo(() => {
  const { socket } = useJobListSocketHook();
  const { query, updateQueryParams, queryString, searchValue, setSearch } =
    useFilters<JobFiltersQueryType>(
      getInitialStorageFilters<JobFiltersQueryType>(TABLE_ENTITY_NAME, initialFilters),
    );

  const activeTabRef = useRef(getInitialActiveTab(query.status));
  const [activeTab, setActiveTab] = useState(getInitialActiveTab(query.status));

  const [jobId, setIsJobId] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [jobPayload, setJobPayload] = useState<List<JobModel> & JobCountModel>(
    jobPayloadInitialValue,
  );

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const {
    submit: hideJobs,
    onCallSuccess: onHideJobsSuccess,
    onCallError: onHideJobsError,
  } = useCall(postHideJobsBatch);
  onHideJobsSuccess(() => {
    setSelectedRows([]);
    notification.success(hideJobsBatchSuccessTitle, hideJobsBatchSuccessMessage);
  });
  onHideJobsError((error) => {
    notification.error(hideJobsBatchErrorTitle, error.message);
  });

  const { submit: refreshJobs, onCallSuccess } = useCall(
    getJobsRefresh.setQueryParams(queryString),
  );
  onCallSuccess((payload) => {
    setJobPayload(payload);
  });

  const jobsResponse = useFetch(getJobs.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { payload, loading, error, refresh } = usePayload(jobsResponse);

  useDidUpdate(() => {
    // we need to do it to increment the jobs stats state dynamically using socket events
    setJobPayload(payload);
  }, [payload]);

  useDidUpdate(
    () => {
      const handleJobListUpdate = ({ jobId, success, failed }: JobListUpdateData) => {
        setJobPayload((prevPayload) => {
          const jobsData = prevPayload.data.map((job) => {
            const { stats } = job;
            if (job.id === jobId) {
              return {
                ...job,
                stats: {
                  ...stats,
                  totalSent: stats.totalSent + success + failed,
                  totalFailed: stats.totalFailed + failed,
                },
              };
            }
            return job;
          });

          return { ...prevPayload, data: jobsData };
        });
      };

      const handleJobStatusUpdate = () => {
        refreshJobs();
      };

      const handleHiddenUpdate = () => {
        refreshJobs();
      };

      if (socket) {
        socket.on<JobListListenerEventsKeys>("jobListUpdate", handleJobListUpdate);
        socket.on<JobListListenerEventsKeys>("jobListStatusUpdate", handleJobStatusUpdate);
        socket.on<JobListListenerEventsKeys>("jobHiddenUpdate", handleHiddenUpdate);

        return () => {
          socket.off<JobListListenerEventsKeys>("jobListUpdate", handleJobListUpdate);
          socket.off<JobListListenerEventsKeys>("jobListStatusUpdate", handleJobStatusUpdate);
          socket.off<JobListListenerEventsKeys>("jobHiddenUpdate", handleHiddenUpdate);
        };
      }
    },
    [socket],
    true,
  );

  const getTotalItems = useMemo(() => {
    if (!jobPayload) return 0;

    const payloadKey = paginationKeys[query?.status || "all"];

    const total = jobPayload[payloadKey];
    return +total;
  }, [jobPayload, query?.status]);

  const pagination = usePagination({
    listPayload: { ...jobPayload, total: getTotalItems },
  });

  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_ENTITY_NAME,
  });

  const onTabClick = (index: number) => {
    const status = getActiveStatus(index);
    updateQueryParams({
      status,
      offset: defaultOffset,
    });
    setActiveTab(index);
    activeTabRef.current = index;
    setSelectedRows([]);
  };

  const onItemChange = (editedJobs: Pick<JobModel, "name" | "id">[]) => {
    setJobPayload((prevPayload) => ({
      ...prevPayload,
      data: prevPayload.data.map((job) => {
        const partialJob = editedJobs.find((editedJob) => editedJob.id === job.id);
        return { ...job, ...partialJob };
      }),
    }));
  };

  useDidUpdate(() => {
    if (!query.status) {
      setActiveTab(0);
      activeTabRef.current = 0;
    }
  }, [queryString]);

  useDidUpdate(() => {
    setSelectedRows([]);
  }, [pagination.currentOffset]);

  const handleSelect = (id: number, checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => {
    const rows = checked ? [id, ...selectedRows] : selectedRows.filter((row) => row !== id);
    setSelectedRows(rows);
  };

  const totalSelectable = useCallback((): number => {
    if (activeTab === statuses.all)
      return jobPayload.data.filter((item) => item.status === "finished").length;
    return jobPayload.data.length;
  }, [jobPayload.data, activeTab]);

  const handleSelectAll = () => {
    if (selectedRows.length === totalSelectable()) {
      setSelectedRows([]);
    } else {
      const allSelectable = jobPayload.data.filter((item) => item.status === "finished");
      setSelectedRows(allSelectable.map((job) => job.id));
    }
  };

  const handleOpenEdit = (jobId: string) => {
    setIsOpen(true);
    setIsJobId(jobId);
  };

  const handleCloseEdit = () => {
    setIsOpen(false);
  };

  const handleHideSelected = async () => {
    if (!selectedRows.length) return;

    if (activeTab === statuses.hidden) {
      await hideJobs({ data: { jobIds: selectedRows, hide: false } });
      return;
    }

    await hideJobs({ data: { jobIds: selectedRows, hide: true } });
  };

  const isItemSelectable = (item: ArrayElement<NonNullable<typeof jobPayload.data>>) => {
    return item.status === "finished";
  };

  const isListSelectable = useMemo(() => {
    return jobPayload?.data.some((item) => item.status === "finished") || false;
  }, [jobPayload?.data]);
  const areAllRowsSelected = useMemo(
    () => selectedRows.length && selectedRows.length === totalSelectable(),
    [selectedRows, totalSelectable],
  );
  const noItemsSelected = useMemo(() => !selectedRows.length, [selectedRows]);
  const isHiddenTabOpen = useMemo(() => activeTab === statuses.hidden, [activeTab]);

  const tableProps: TableProps<typeof jobPayload.data> = {
    loading,
    columns: tableColumns,
    error: error?.message,
    list: jobPayload.data,
    resetColumnsOnMount: false,
    pagination: {
      ...pagination,
      elementsPerPage: currentElementsPerPage,
      onElementsPerPageChange: handlePerPageChange,
    },
    row: (item: ArrayElement<NonNullable<typeof jobPayload.data>>, index: number) => (
      <JobListTableRow
        index={index}
        job={item}
        key={item.id}
        isSelectable={isItemSelectable(item)}
        isListSelectable={isListSelectable}
        isSelected={selectedRows.includes(item.id)}
        onSelect={handleSelect}
        refresh={refresh}
        handleOpenEdit={handleOpenEdit}
      />
    ),
    isTabTable: true,
  };

  const tabs = jobListTabs(jobPayload, tableProps);

  return (
    <Container>
      <div className={styles.controlsContainer}>
        <Search searchValue={searchValue} setSearch={setSearch} className={styles.search} />
        {isListSelectable && (
          <div className={styles.controlsButtonsContainer}>
            <Button
              onClick={handleSelectAll}
              appearance="secondary"
              btnSize="small"
              className={styles.selectButton}
            >
              {areAllRowsSelected ? "Unselect All" : "Select All"}
            </Button>
            <Button
              onClick={handleHideSelected}
              btnSize="small"
              disabled={noItemsSelected}
              className={styles.hideButton}
            >
              <img src={isHiddenTabOpen ? eyeOpen : eyeClosed} alt="Hide job" />
              {isHiddenTabOpen ? "Show" : "Hide"}
              <span className={styles.totalSelectedChip}>{selectedRows.length}</span>
            </Button>
          </div>
        )}
      </div>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
      <Modal isOpen={isOpen} setClose={handleCloseEdit} contentClassName={styles.modal}>
        <JobForm id={jobId} handleCloseEditJob={handleCloseEdit} onItemChange={onItemChange} />
      </Modal>
    </Container>
  );
});

export { JobListPage };
