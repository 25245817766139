import { memo, ReactNode } from "react";
import { TreeFileTabs, useDidUpdate, Button, useDebounce, Nullable } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { Breadcrumbs, BreadcrumbsDataType, CircleGraph, Hint } from "components";
import { ItemNameComponent } from "../item-name-component";
import { InfoTooltip } from "../days-allocation/info-tooltip";
import {
  getErrorTextAllocatedExtra,
  getDataForCircleGraph,
  getDateHoursDataName,
  maxDayAllocationErrorText,
} from "../allocation-form.utils";
import { useAllocationData } from "../allocation-form.hook";
import {
  circleGraphTitle,
  circleGraphLegendSubTitle,
  allocationSetupPages,
  breadcrumbDayName,
  breadcrumbTimeName,
  treeFileTabsTitle,
  maxHourAllocationErrorText,
} from "../allocation-form.constants";
import { AllocationChart } from "../allocation-chart";
import { BackButton } from "../allocation-buttons";
import { WeeksList } from "./weeks-list";
import { useHoursAllocation } from "./hours-allocation.hook";
import { HoursAllocationForm } from "./hours-allocation-form";

import "../allocation.css";
import styles from "./hours-allocation.module.css";

type HoursAllocationProps = {
  children: ReactNode;
  allocationButton: ReactNode;
  onSetPage: (page: number, index?: number) => void;
  onRemainingChange: (value: number) => void;
  onRemainingIndexChange: (value: number) => void;
  backToGeneral: VoidFunction;
  onNextNestItemClick: (callback: VoidFunction) => void;
  onSelectedIndex: (index: number) => void;
  setLoadedHourlySpread: (loadedHours: boolean) => void;
  loadedHourlySpread: boolean;
  selectedIndex: number;
  checkLastEndpoint: (isLastEndpoint: boolean) => void;
  reusedJob: Nullable<ReusableFileModel>;
};

const HoursAllocation = memo<HoursAllocationProps>(
  ({
    children,
    allocationButton,
    backToGeneral,
    onRemainingChange,
    onRemainingIndexChange,
    checkLastEndpoint,
    onSetPage,
    onNextNestItemClick,
    onSelectedIndex,
    selectedIndex,
    setLoadedHourlySpread,
    loadedHourlySpread,
    reusedJob,
  }) => {
    const {
      dateHoursSpread,
      onSelectedValues,
      remainingValue,
      indexRemainingValue,
      onNextNextItem,
      nextNestItem,
      currentActiveItemName,
    } = useAllocationData();

    const {
      selectedDayIndex,
      onHoursSelectedValues,
      handleHoursChange,
      setSelectedDayIndex,
      isLastEndpoint,
      onClear,
      maxHoursAllocationLimit,
      maxDaysAllocationLimit,
      errors,
      touched,
      submitCount,
    } = useHoursAllocation(
      onSelectedValues,
      currentActiveItemName,
      setLoadedHourlySpread,
      loadedHourlySpread,
      reusedJob,
    );

    const { debounce } = useDebounce();

    debounce(() => {
      onRemainingChange(remainingValue);
      onRemainingIndexChange(indexRemainingValue);
      checkLastEndpoint(isLastEndpoint);
    });

    const onNextItemClick = (endpointIndex: number, dateHoursDataName: string) => {
      onSelectedIndex(endpointIndex + 1);
      dateHoursDataName && onNextNextItem([endpointIndex + 1, dateHoursDataName]);
      onSetPage(allocationSetupPages.day);
    };

    const allEmailsError = ((touched.allEmails || submitCount) && errors.allEmails) || "";

    const maxAllocationError = maxHoursAllocationLimit.size
      ? maxHourAllocationErrorText
      : maxDaysAllocationLimit.size
        ? maxDayAllocationErrorText(maxDaysAllocationLimit.values().next().value)
        : "";

    useDidUpdate(() => {
      if (currentActiveItemName.length) {
        const currentActiveItemIndex = Number(currentActiveItemName[0][0]);
        const dateHoursDataName = getDateHoursDataName(dateHoursSpread, currentActiveItemIndex);
        if (dateHoursDataName) {
          onNextNestItemClick?.(() => onNextItemClick(currentActiveItemIndex, dateHoursDataName));
        }
      }
    }, [currentActiveItemName]);

    const treeTabs = dateHoursSpread.map(
      ({ name, used, dayUnits, total, remaining }, endpointIndex) => {
        const description = `(${total})`;
        const selectedDayUnit = dayUnits[selectedDayIndex];
        const { hoursUnits } = selectedDayUnit;
        const { colors, data, centralGraphText } = getDataForCircleGraph({
          remaining,
          used,
        });

        const dateHoursDataName = getDateHoursDataName(dateHoursSpread, endpointIndex);
        const breadcrumbs: BreadcrumbsDataType[] = [
          {
            name: breadcrumbDayName,
            icon: <InfoTooltip />,
            onClick: () => {
              if (!maxHoursAllocationLimit.size) {
                onSelectedIndex(endpointIndex);
                onSetPage(allocationSetupPages.day);
              }
            },
          },
          {
            name: breadcrumbTimeName,
            active: true,
          },
        ];

        if (dateHoursDataName) {
          breadcrumbs.push({
            name: `${dateHoursDataName}`,
            onClick: () => onNextItemClick(endpointIndex, dateHoursDataName),
          });
        }

        return {
          name: `#${endpointIndex + 1} ${name}`,
          itemNameComponent: (
            <ItemNameComponent name={name} index={endpointIndex} error={remaining !== 0} />
          ),
          description,
          component: (
            <div className={styles.componentWrapper}>
              <Breadcrumbs breadcrumbsData={breadcrumbs}>
                {!reusedJob && (
                  <Button
                    appearance="transparent"
                    btnSize="small"
                    onClick={() => onClear(endpointIndex)}
                  >
                    Clear all
                  </Button>
                )}
              </Breadcrumbs>
              {!reusedJob && (
                <div className={styles.statisticBar}>
                  <CircleGraph
                    total={total}
                    dataGraph={data}
                    colors={colors}
                    centralGraphValue={remaining}
                    centralGraphText={centralGraphText}
                    legendSubTitle={circleGraphLegendSubTitle}
                    title={circleGraphTitle}
                  />
                  <AllocationChart data={hoursUnits} />
                </div>
              )}
              <div className={styles.daysTabWrapper}>
                <WeeksList
                  dayUnits={dayUnits}
                  onDaySelect={setSelectedDayIndex}
                  selectedDayIndex={selectedDayIndex}
                />
                <HoursAllocationForm
                  reusedJob={reusedJob}
                  units={hoursUnits}
                  onChange={handleHoursChange(endpointIndex, selectedDayIndex)}
                />
              </div>
            </div>
          ),
        };
      },
    );

    return (
      <>
        <BackButton backToGeneral={backToGeneral} />
        <TreeFileTabs
          onSelectedValues={onHoursSelectedValues}
          className="allocationWrapper"
          treeTabs={treeTabs}
          title={`${treeFileTabsTitle} (${dateHoursSpread.length})`}
          initiallyOpenedItem={[selectedIndex]}
          disableUnselectComponent
          nextNestItem={nextNestItem}
          onNextNestItem={onNextNextItem}
        >
          {allocationButton}
        </TreeFileTabs>
        {(remainingValue < 0 || maxAllocationError || allEmailsError) && (
          <div className={styles.hintWrapper}>
            <Hint type="errorWarn">
              {maxAllocationError || allEmailsError || getErrorTextAllocatedExtra(remainingValue)}
            </Hint>
          </div>
        )}
        {children}
      </>
    );
  },
);

export { HoursAllocation };
