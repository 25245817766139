import { FC, useState } from "react";
import cn from "classnames";
import { Select, Checkbox, CollapseSelfControlled } from "@epcnetwork/core-ui-kit";

import { allocationPresetsOptions } from "./allocation-presets.constants";

import { ReactComponent as CollapseArrowIcon } from "assets/images/collapse-arrow.svg";
import styles from "./allocation-presets.module.css";

type ValueArgument = { value: string; label: string };

type AllocationPresetsProps = {
  onChange: (value: ValueArgument) => void;
  onApplyAllocation: (value: string) => void;
  value: string;
};

const AllocationPresets: FC<AllocationPresetsProps> = ({ onChange, onApplyAllocation, value }) => {
  const [applyToAllJobs, setApplyToAllJobs] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const handleApplyCheckboxChange = () => {
    !applyToAllJobs && onApplyAllocation(value);
    setApplyToAllJobs(!applyToAllJobs);
  };

  const handlePresetChange = (value: { value: string; label: string }) => {
    onChange(value);
    applyToAllJobs && setApplyToAllJobs(false);
  };

  return (
    <section className={cn(styles.presetsWrapper, { [`${styles.isOpened}`]: isOpened })}>
      <CollapseSelfControlled
        triggerElement={
          <div className={styles.presetsHeadlineBox} onClick={() => setIsOpened((prev) => !prev)}>
            <h2 className={styles.presetsHeadline}>Set up the preset (optional)</h2>
            <CollapseArrowIcon
              className={cn(styles.collapseIcon, { [`${styles.isOpened}`]: isOpened })}
            />
          </div>
        }
        hideDuration={150}
        openDuration={150}
        initiallyOpened={false}
        triggerClassName={styles.presetTrigger}
      >
        <div className={styles.controlsWrapper}>
          <Select
            name="dailySpreadStrategy"
            label="Preset"
            placeholder="Enter something"
            selectedOptionsKeys={value}
            options={allocationPresetsOptions}
            onChange={handlePresetChange}
            className={styles.presetsField}
            inputClassName={styles.presetsInput}
            disableError
          />
          <Checkbox
            value="Apply to all jobs"
            label="Apply to all jobs"
            onChange={handleApplyCheckboxChange}
            checked={applyToAllJobs}
            className={styles.applyCheckbox}
            inputSize="big"
            disableError
            showBox
          />
        </div>
      </CollapseSelfControlled>
    </section>
  );
};

export { AllocationPresets };
