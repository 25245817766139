import { FC } from "react";
import { TableRow, TableCell } from "@epcnetwork/core-ui-kit";

import { GlobalMetaTableData } from "../meta-tables";

import globalStyles from "assets/styles/global.module.css";
import styles from "./global-meta-table-row.module.css";

type GlobalMetaTableRowProps = {
  index: number;
  item: GlobalMetaTableData;
};

const GlobalMetaTableRow: FC<GlobalMetaTableRowProps> = ({ index, item }) => {
  const {
    name,
    totalEmailCount,
    successEmailCount,
    remainingEmailCount,
    failedEmailCount,
    totalSuppressed,
    suppressedButSent,
  } = item;

  return (
    <TableRow id={index}>
      <TableCell className={styles.nameCell}>
        <span className={globalStyles.applyMultiOverflow}>{name}</span>
      </TableCell>
      <TableCell>{totalEmailCount}</TableCell>
      <TableCell>{successEmailCount}</TableCell>
      <TableCell>{remainingEmailCount}</TableCell>
      <TableCell>{failedEmailCount}</TableCell>
      <TableCell>{totalSuppressed}</TableCell>
      <TableCell>{suppressedButSent}</TableCell>
    </TableRow>
  );
};

export { GlobalMetaTableRow };
