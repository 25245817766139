import { memo, ReactNode, useMemo } from "react";
import { useFormikContext } from "formik";
import { differenceInHours } from "date-fns";
import classNames from "classnames";
import { FormField, getJSDate, Nullable, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { ReuseAllocationJob } from "../reuse-allocation-job";
import { CustomInfo } from "../information/custom-info";
import { AllocationSpreadTable } from "../allocation-spread-table/allocation-spread-table";
import { maxHourAllocation } from "../allocation-form.constants";
import { getCustomInfoText, getMinDateForReusedJob } from "./endpoints-allocation.utils";
import { EndpointsAllocationValues } from "./endpoints-allocation.types";
import { useEndpointsAllocationData } from "./endpoints-allocation.hook";
import {
  acrossEndpointsLabel,
  allEndpointsLabel,
  ENDPOINT_STATUSES,
} from "./endpoints-allocation.constants";

import styles from "./endpoints-allocation.module.css";

type EndpointsAllocationProps = {
  children: ReactNode;
  maxEmails: number;
  reusedJob: Nullable<ReusableFileModel>;
  setReusedJob: (job: Nullable<ReusableFileModel>) => void;
};

const EndpointsAllocation = memo<EndpointsAllocationProps>(
  ({ children, maxEmails, reusedJob, setReusedJob }) => {
    const { values, setFieldValue } = useFormikContext<EndpointsAllocationValues>();

    const {
      totalAmount,
      hasMoreEmailsError,
      allEmails,
      minDate,
      maxDateTo,
      handleCopyFileChange,
      handleRangeChange,
      canShowSwitched,
      handleSameSpreadChange,
      sameSpread,
      allEmailsError,
      remainingAmount,
      usedAmount,
      endpointsRanges,
      endpoints,
      copyFile,
      status,
      timezone,
      handleReuseJob,
      resetReusedJob,
      jobId,
      maxDateFrom,
      isStatusStopped,
    } = useEndpointsAllocationData(maxEmails, setReusedJob, reusedJob);

    const showError =
      !hasMoreEmailsError && allEmails && maxEmails !== 0 && maxEmails < totalAmount;

    const isStatusProgress = status === ENDPOINT_STATUSES.inProgress;
    const isStartDateSet = !!values.startDate;

    const minDateValue = reusedJob ? getMinDateForReusedJob(reusedJob) : minDate;

    const minEndDateValue = useMemo(() => {
      if (!minDateValue) return new Date();
      if (Array.isArray(minDateValue)) return minDateValue;

      const addOneHour = (date: string | number | Date) => {
        const newDate = new Date(date);
        newDate.setHours(newDate.getHours() + 1);

        return newDate;
      };

      if (values.startDate) return addOneHour(values.startDate);
      return addOneHour(minDateValue);
    }, [minDateValue, values.startDate]);

    useDidUpdate(
      () => {
        // Update range values when startDate and endDate change
        if (values.startDate && values.endDate) {
          const start = getJSDate(values.startDate);
          const end = getJSDate(values.endDate);

          const getDatesLimit = (startDate: Date | null, endDate: Date | null) => {
            const hours =
              startDate && endDate ? differenceInHours(new Date(endDate), new Date(startDate)) : 0;

            return hours * maxHourAllocation;
          };

          const maxEmails = getDatesLimit(start, end);

          //* MULTIPLE JOBS
          if (values.endpoints.length > 1 && !values.sameSpread && !values.sameSpread) {
            const calculateMultipleJobs = () => {
              const jobsLength = values.endpoints.length;

              // Check to avoid division by zero
              if (jobsLength === 0) {
                throw new Error("Array length cannot be zero.");
              }

              const quotient = Math.floor(values.totalAmount / jobsLength); // Integer division
              const remainder = values.totalAmount % jobsLength; // Calculate remainder

              // Create an array where the first 'remainder' elements get an extra 1
              const valuesPerJob = values.endpoints.map((_, index) => {
                if (quotient > maxEmails) return maxEmails;
                return index < remainder ? quotient + 1 : quotient;
              });

              return { total: values.totalAmount, valuesPerJob };
            };

            const { total, valuesPerJob } = calculateMultipleJobs();

            setFieldValue("usedAmount", total);
            setFieldValue("remainingAmount", values.totalAmount - total);
            setFieldValue(
              "endpointsRanges",
              values.endpoints.map((endpoint, index) => ({
                ...endpoint,
                value: valuesPerJob[index],
              })),
            );
          }

          //* SINGLE JOB
          if (values.endpoints.length <= 1) {
            const enoughEndpointsAllocation = Math.min(values.totalAmount, maxEmails);

            setFieldValue("usedAmount", enoughEndpointsAllocation);
            setFieldValue(
              "remainingAmount",
              enoughEndpointsAllocation
                ? values.totalAmount - enoughEndpointsAllocation
                : values.totalAmount,
            );

            setFieldValue(
              "endpointsRanges",
              values.endpoints.map((endpoint) => ({
                ...endpoint,
                value: enoughEndpointsAllocation,
              })),
            );
          }
        }
      },
      [values.startDate, values.endDate],
      false,
    );

    const noDatesSelected = !values.startDate || !values.endDate;

    return (
      <>
        <div className={styles.endpointsFormWrapper}>
          <div className={styles.endpointsFormSection}>
            {showError && <CustomInfo text={getCustomInfoText(maxHourAllocation)} />}
            <div className={styles.title}>Choose time for Jobs</div>
            <div className={styles.row}>
              <FormField
                type="date-input"
                names={["startDate", "endDate"]}
                labels={["Start Date", "End Date"]}
                timezone={timezone}
                min={minDateValue}
                max={maxDateFrom}
                minTo={minEndDateValue}
                showPredefinedValues
                maxTo={maxDateTo}
                disabledFrom={isStatusProgress || isStatusStopped}
                disabledTo={isStatusStopped ? false : !isStartDateSet || !!reusedJob}
                disabledFromClock={!!reusedJob}
                className={styles.dateInputsContainer}
                required
                enableClockTime
                isMulti
              />
              {/*{!jobId && (*/}
              {/*  <ReuseAllocationJob*/}
              {/*    onSubmitClick={handleReuseJob}*/}
              {/*    reusedJobName={reusedJob?.name}*/}
              {/*    onClearBtnClick={resetReusedJob}*/}
              {/*    disableSubmitBtn={endpoints.length > 1}*/}
              {/*  />*/}
              {/*)}*/}
            </div>
          </div>
          <div className={styles.endpointsFormSection}>
            <div className={styles.fileSetUpTitle}>Set up file usage</div>
            <div className={styles.switchersWrapper}>
              <FormField
                type="switch"
                name="allEmails"
                label="Use all emails"
                disableError
                className={styles.switchInput}
              />
              {canShowSwitched && (
                <>
                  <FormField
                    type="switch"
                    name="copyFile"
                    label={acrossEndpointsLabel}
                    onChange={handleCopyFileChange}
                    disabled={sameSpread || noDatesSelected}
                    disableError
                    className={classNames(styles.switchInput, {
                      [styles.switchInputDisabled]: sameSpread || noDatesSelected,
                    })}
                  />
                  <FormField
                    type="switch"
                    name="sameSpread"
                    label={allEndpointsLabel}
                    disabled={noDatesSelected}
                    onChange={handleSameSpreadChange}
                    disableError
                    className={classNames(styles.switchInput, {
                      [styles.switchInputDisabled]: noDatesSelected,
                    })}
                  />
                </>
              )}
            </div>
            <div>
              <AllocationSpreadTable
                allEmailsError={allEmailsError}
                total={totalAmount}
                remaining={remainingAmount}
                used={usedAmount}
                units={endpointsRanges}
                onChange={handleRangeChange}
                showRemainingValue={allEmails || (!copyFile && allEmails)}
                disableRestrict={copyFile}
                maxValue={maxEmails}
              />
            </div>
          </div>
        </div>
        {children}
      </>
    );
  },
);

export { EndpointsAllocation };
