import { createRoot } from "react-dom/client";

import { Providers } from "components";
import * as serviceWorker from "./serviceWorker";
import { App } from "./app";

import "assets/styles/theme.css";
import "assets/styles/index.css";
import "@epcnetwork/core-ui-kit/dist/assets/styles/index.css";

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Providers>
    <App />
  </Providers>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
