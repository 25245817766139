import { ApiBuilder } from "@epcnetwork/core-ui-kit";

import { appEnvironment } from "config/environment.config";
import { LoginResponse } from "api/auth/auth.interface";

export const apiRefreshEndpoint = "/auth/token";

export const refreshTokenMiddleware = new ApiBuilder({ baseUrl: appEnvironment.apiUrl }).build();

export const postRefreshToken = refreshTokenMiddleware<
  LoginResponse,
  Omit<LoginResponse, "token">
>()({
  endpoint: apiRefreshEndpoint,
  method: "post",
});
