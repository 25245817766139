import { FC } from "react";
import { Table, TableRow, TableCell, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { AllocationEndpointType } from "models";

import styles from "./allocation-table-row.module.css";

type JobsListProps = {
  list: AllocationEndpointType[];
};

const columns = [{ label: "Id" }, { label: "Job" }];

const JobsList: FC<JobsListProps> = ({ list }) => {
  return (
    <div className={styles.jobList}>
      <Table
        columns={columns}
        list={list}
        row={(item, index: number) => (
          <TableRow id={index}>
            <TableCell className={styles.id}>#{item.jobId}</TableCell>
            <TableCell className={styles.job}>
              <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                {item.name}
              </TextEllipsis>
            </TableCell>
          </TableRow>
        )}
      />
    </div>
  );
};

export { JobsList };
