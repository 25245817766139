import { FC } from "react";

import { ReactComponent as ArrowSortingIcon } from "assets/images/arrow-sorting-to-change.svg";
import styles from "./logs-activity-panel.module.css";

type LogsActivityStatusItemProps = {
  oldValue?: string;
  newValue: string;
};

export const LogsActivityStatusItem: FC<LogsActivityStatusItemProps> = ({ oldValue, newValue }) => {
  return (
    <div className={styles.logItemStatusesWrap}>
      {oldValue && (
        <>
          <span>{oldValue}</span>
          <ArrowSortingIcon className={styles.arrowIconStyles} />
        </>
      )}
      <span>{newValue}</span>
    </div>
  );
};
