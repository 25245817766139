import { hoursAllocationSchema } from "./hours-allocation/hours-allocation.constants";
import {
  endpointsAllocationSchema,
  endpointsAllocationInitialValues,
} from "./endpoints-allocation/endpoints-allocation.constants";
import {
  daysAllocationValues,
  daysAllocationSchema,
} from "./days-allocation/days-allocation.constants";
import { InitialValues } from "./allocation-form.types";

export enum allocationSetupPages {
  general = 1,
  day = 2,
  time = 3,
}

export const pagesAmount = 3;

export const validationSchemas = [
  endpointsAllocationSchema,
  daysAllocationSchema,
  hoursAllocationSchema,
];

export const initialValues: InitialValues = {
  ...endpointsAllocationInitialValues,
  ...daysAllocationValues,
};

// This is the current limit for our services to operate
// If we set up more emails for an hour - our servers will crash
export const maxHourAllocation = 7100;

export const allocatedCircleGraphColor = "var(--palette-primary)";
export const remainCircleGraphColor = "var(--palette-primary-disabled)";
export const allocatedErrorCircleGraphColor = "var(--palette-error)";
export const overlimitCircleGraphColor = "var(--palette-error-focus)";
export const circleGraphTitle = "Overall Emails:";
export const circleGraphLegendSubTitle = "emails";

export const breadcrumbDayName = "Day schedule";
export const breadcrumbTimeName = "Time schedule";

export const allocationButtonInfoText =
  "You need to schedule sending for all jobs before proceed to Allocation step";
export const nextJobBtnText = "Next job";
export const goBackMessage = "Nope, go back from this page";

export const jobReconfiguredSuccessText = "Job successfully reconfigured";
export const jobAllocatedSuccessText = "You allocated job";

export const errorEndpointText = "Please check this job and spread the emails correctly";

export const treeFileTabsTitle = "jobs";

export const confirmAllocatePopupText = "Are you sure that you allocated all the emails properly?";
export const confirmAllocatePopupTitle = "Proceed?";
export const confirmAllocatePopupOkBtnText = "Confirm";
export const confirmAllocatePopupCancelBtnText = "Go back";
export const maxHourAllocationErrorText = `Too much emails were allocated per hour, max per hour ${maxHourAllocation}`;

export const confirmBackToGeneralPopupTitle =
  "Are you sure you want to return to General Settings?";
export const confirmBackToGeneralPopupText = "Your current settings will not be saved";
export const confirmBackToGeneralPopupOkBtnText = "Yes, return";
export const confirmBackToGeneralPopupCancelBtnText = "No, stay";

export const previewBtn = "Preview";

export const baseHoursDayCount = 24;
export const springHoursDayCount = 23;
export const autumnHoursDayCount = 25;
export const timeShiftIndex = 2;
