import { FC, useMemo } from "react";
import { Nullable } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { AllocationUnitType } from "../../allocation-unit/allocation-unit";
import { HoursAllocationCol } from "./hours-allocation-col";

import styles from "./hours-allocation-form.module.css";

type HoursAllocationFormProps = {
  units: AllocationUnitType[];
  onChange: (value: number, rangeIndex: number) => void;
  reusedJob: Nullable<ReusableFileModel>;
};

const HoursAllocationForm: FC<HoursAllocationFormProps> = ({ units, onChange, reusedJob }) => {
  const unitRows: null | AllocationUnitType[][] = useMemo(() => {
    if (!units?.length) {
      return null;
    }

    if (units.length === 1) {
      return [units];
    }
    const unitsAmount = units.length;
    const unitsHalfIndex = Math.ceil(unitsAmount / 2);

    return [units.slice(0, unitsHalfIndex), units.slice(unitsHalfIndex)];
  }, [units]);

  return (
    <div className={styles.formWrapper}>
      <div className={styles.form}>
        {unitRows?.map((unitRow, index) => {
          return (
            <HoursAllocationCol
              key={index}
              colShift={unitRows[0].length}
              rowIndex={index}
              units={unitRow}
              onChange={onChange}
              reusedJob={reusedJob}
            />
          );
        })}
      </div>
    </div>
  );
};

export { HoursAllocationForm };
