import { List, UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType, JobStatuses } from "types";
import { JobCountModel, JobModel } from "models";
import { defaultLimit, defaultOffset } from "constants/query.constants";
import { searchMinLength } from "constants/form.constants";
import { JobFiltersQueryType } from "./jobs-list.types";

export const tableColumns: ColumnsType = [
  { label: "ID", required: true },
  { label: "Job title", required: true, queryKey: "jobName" },
  { label: "File name" },
  { label: "Progress" },
  { label: "Total to send", columnHeader: "Total #" },
  { label: "Has been sent", columnHeader: "Sent #" },
  { label: "Failed to be sent", columnHeader: "Failed" },
  { label: "Tags", queryKey: "tagName" },
  { label: "Start", queryKey: "startDate" },
  { label: "End", queryKey: "endDate" },
  { label: "Status" },
  { label: "" },
];

export const selectableTableColumns = [{ label: "" }, ...tableColumns];

export const TABLE_ENTITY_NAME = "jobs-list";

export const initialFilters: UseFiltersProps<JobFiltersQueryType> = {
  searchOptions: { searchKey: "search", searchMinLength },
  initialState: { status: "all", search: "", limit: defaultLimit, offset: defaultOffset },
};

export const statuses: Readonly<Record<JobStatuses, number>> = {
  all: 0,
  pending: 1,
  "reconfiguration-needed": 2,
  "in-progress": 3,
  paused: 4,
  frozen: 5,
  autofrozen: 6,
  finished: 7,
  hidden: 8,
};

export const paginationKeys: Record<JobStatuses, keyof JobCountModel> = {
  all: "all",
  pending: "pending",
  "reconfiguration-needed": "reconfigurationNeeded",
  "in-progress": "inProgress",
  paused: "paused",
  frozen: "frozen",
  autofrozen: "autofrozen",
  finished: "finished",
  hidden: "hidden",
};

export const jobPayloadInitialValue: List<JobModel> & JobCountModel = {
  all: "0",
  autofrozen: "0",
  data: [],
  deleted: "0",
  failed: "0",
  finished: "0",
  frozen: "0",
  hidden: "0",
  inProgress: "0",
  limit: 0,
  offset: 10,
  paused: "0",
  pending: "0",
  preconfigured: "0",
  preparation: "0",
  reconfigurationNeeded: 0,
  total: 0,
  afterEmailCalculation: "0",
  jobPreconfigured: "0",
};

export const hideJobsBatchErrorTitle = "Cannot move selected jobs";
export const hideJobsBatchSuccessTitle = "Success";
export const hideJobsBatchSuccessMessage = "Selected jobs were moved";

export const deleteJobsBatchSuccessTitle = "Success";
export const deleteJobsBatchSuccessMessage = "Selected jobs were deleted";
export const deleteJobsBatchErrorTitle = "Cannot delete selected jobs";
export const deleteJobsBatchConfirmTitle = "Delete Jobs?";
export const deleteJobsBatchConfirmMessage = "Are you sure?";
