import { JobProgressStatuses } from "types";
import { JobTrafficTypesKeys } from "models";

export const JOB_PROGRESS_STATUSES: Record<string, JobProgressStatuses> = {
  pending: "pending",
  preconfigured: "preconfigured",
  preparation: "preparation",
  inProgress: "in-progress",
  finished: "finished",
  paused: "paused",
  frozen: "frozen",
  autofrozen: "autofrozen",
  hidden: "hidden",
};

export const JOB_TRAFFIC_LABELS: Record<JobTrafficTypesKeys, string> = {
  succeededTraffic: "Successed",
  failedTraffic: "Failed",
  suppressedButSentTraffic: "Suppressed",
  targetTraffic: "Target Traffic",
} as const;
