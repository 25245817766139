import { array, object, string } from "yup";
import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Button,
  CollapseSelfControlled,
  Form,
  FormButtons,
  FormField,
  MessageField,
  notification,
  useCall,
  useWindowSize,
} from "@epcnetwork/core-ui-kit";

import { ObjectValues } from "types";
import { PermissionsModel, RoleModel } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import {
  JOBS_PERMISSIONS,
  jobsPermissionsMap,
  USERS_PERMISSIONS,
  usersPermissionsMap,
} from "constants/roles.constants";
import { CANCEL_TEXT } from "constants/notification.constants";
import { deleteRole, postRole, putRole } from "api";
import { HeadRow } from "./components/head-row";
import { FormTable } from "./components/form-table";

import deleteImg from "assets/images/bin-red.svg";
import styles from "./roles-form.module.css";

export type RoleFormValues = {
  name: string;
  permissions: string[];
};

export type RoleEntitiesType = {
  name: string;
  permissionAll: string;
  permission: PermissionsModel;
};

type RolesFormProps = {
  selected: RoleModel | null;
  disabled?: boolean;
  setRole: (role: RoleModel) => void;
  addRole: (role: RoleModel) => void;
  removeRole: (role: RoleModel) => void;
};

const jobsPermissionsList = Object.values(jobsPermissionsMap);
const usersPermissionsList = Object.values(usersPermissionsMap);

const validationSchema = object({
  name: string().required("Name is required"),
  permissions: array()
    .of(string())
    .test({
      name: "permissionsTest",
      message: 'At least one permission in "Jobs" and "User Management" sections should be chosen',
      exclusive: true,
      test: (formPermissions) => {
        if (!formPermissions || !formPermissions.length) return false;

        const typeCastedFormPermissions = formPermissions as ObjectValues<
          typeof jobsPermissionsMap & typeof usersPermissionsMap
        >[];

        const hasAtLeastOneJobPermission = typeCastedFormPermissions.some(
          (permissionName) => jobsPermissionsList.indexOf(permissionName) !== -1,
        );

        const hasAtLeastOneUserPermission = typeCastedFormPermissions.some(
          (permissionName) => usersPermissionsList.indexOf(permissionName) !== -1,
        );

        return hasAtLeastOneJobPermission && hasAtLeastOneUserPermission;
      },
    }),
});

const RolesForm: FC<RolesFormProps> = ({ selected, disabled, setRole, addRole, removeRole }) => {
  const { width } = useWindowSize();
  const ability = useAuthorization();
  const [submitValues, setSubmitValues] = useState({});
  const fields = selected?.permissions.reduce((acc: Record<string, boolean>, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const initialValues: RoleFormValues = {
    ...fields,
    name: selected?.name || "",
    permissions: selected?.permissions || [],
  };

  const isSystemRole = ["Admin"].includes(selected?.name || "");

  const putCall = useCall(putRole);
  const postCall = useCall(postRole);
  const deleteCall = useCall(deleteRole);

  postCall.onCallSuccess((role) => {
    addRole({ ...role, ...submitValues });
  });

  putCall.onCallSuccess(() => {
    const role = selected;
    if (role) {
      setRole({ ...role, ...submitValues });
    }
  });

  deleteCall.onCallSuccess(() => {
    if (selected) {
      removeRole(selected);
    }
  });

  postCall.onCallError((error) => {
    notification.error("Cannot create Role", error.message);
  });

  putCall.onCallError((error) => {
    notification.error("Cannot update Role", error.message);
  });

  deleteCall.onCallError((error) => {
    notification.error("Cannot delete Role", error.message);
  });

  const onSubmit = async (
    { name, permissions }: RoleFormValues,
    helpers: FormikHelpers<RoleFormValues>,
  ) => {
    const dataToSend: RoleFormValues = {
      name: name,
      permissions: permissions,
    };

    setSubmitValues(dataToSend);
    if (selected) {
      await putCall.submit({ params: { roleId: selected.id }, data: dataToSend });
    } else {
      await postCall.submit({ data: dataToSend });
    }
    helpers.setSubmitting(false);
  };

  const handleDelete = () => {
    if (!isSystemRole && selected) {
      notification.confirm("Delete role", "Are you sure you want to delete this role?", {
        onOk: () => {
          deleteCall.submit({ params: { roleId: selected.id } });
        },
        icon: "delete",
      });
    }
  };

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <Form
      className={styles.form}
      initialValues={initialValues}
      onSubmit={onSubmit}
      disabled={disabled || isMobile}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => {
        const isTouched = !!Object.values(touched).length;
        const permissionError = Array.isArray(errors?.permissions)
          ? errors.permissions.join(" ")
          : errors?.permissions;

        return (
          <>
            {!isMobile && ability.can("delete", "Users") && !isSystemRole && selected && (
              <div className={styles.actions}>
                <Button
                  appearance="transparent"
                  onClick={handleDelete}
                  loading={deleteCall.submitting}
                  className={styles.delete}
                >
                  <img src={deleteImg} alt="" />
                  Delete
                </Button>
              </div>
            )}
            <FormField type="text" name="name" label="Name" placeholder="Name" />
            {roleEntities.map((entity) => (
              <CollapseSelfControlled
                key={entity.name}
                triggerClassName={styles.trigger}
                triggerElement={
                  <HeadRow entity={entity} disabled={disabled} initiallyCollapsed={false} />
                }
                initiallyOpened
              >
                <FormTable permissions={entity.permission} permissionAll={entity.permissionAll} />
              </CollapseSelfControlled>
            ))}
            {ability.can("create", "Users") && (
              <>
                <FormButtons className={styles.formButtons}>
                  <Button type="reset" appearance="secondary">
                    {CANCEL_TEXT}
                  </Button>
                  <Button type="submit">Submit</Button>
                </FormButtons>
                {isTouched && permissionError && (
                  <MessageField
                    className={styles.permissionsMessage}
                    message={permissionError}
                    align="right"
                  />
                )}
              </>
            )}
          </>
        );
      }}
    </Form>
  );
};

export { RolesForm };

const roleEntities: RoleEntitiesType[] = [
  {
    name: "Jobs",
    permissionAll: "JOBS",
    permission: JOBS_PERMISSIONS,
  },
  {
    name: "User Management",
    permissionAll: "USERS",
    permission: USERS_PERMISSIONS,
  },
];
