import { List } from "@epcnetwork/core-ui-kit";

import {
  AllocationBatchModel,
  AllocationCountModel,
  ReuseJobHourlySpreadModel,
  JobHourlySpreadModel,
} from "models";
import { apiMiddleware } from "api";
import {
  DailySpreadReturnType,
  PostJobConfirmation,
  PostBatchDailySpread,
  PostBatchHourlySpread,
  PostReuseHourlySpread,
} from "./allocation.interface";

export const getAllocationBatches = apiMiddleware<
  List<AllocationBatchModel> & AllocationCountModel
>()({
  method: "get",
  endpoint: "/job/batches",
});

export const getAllocationBatch = apiMiddleware<AllocationBatchModel>()({
  method: "get",
  endpoint: `/job/batches/:batchId`,
});

export const deleteBatch = apiMiddleware<null>()({
  method: "delete",
  endpoint: `/job/batches/:batchId`,
});

export const postBatchDailySpread = apiMiddleware<
  DailySpreadReturnType,
  PostBatchDailySpread | null
>()({
  method: "post",
  endpoint: "/job-configuration/daily-spread",
});

export const postBatchHourlySpread = apiMiddleware<JobHourlySpreadModel, PostBatchHourlySpread>()({
  method: "post",
  endpoint: "/job-configuration/hourly-spread",
});

export const postBatchSchedule = apiMiddleware<null, PostJobConfirmation>()({
  method: "post",
  endpoint: "/job-configuration/traffic-schedule",
});

export const postReconfigurationBatchSchedule = apiMiddleware<null, PostJobConfirmation>()({
  method: "post",
  endpoint: "/job-reconfiguration/confirm",
});

export const getJobHourlySpreadRecalculation = apiMiddleware<JobHourlySpreadModel>()({
  method: "get",
  endpoint: "/job-reconfiguration/:jobId/hourly-spread",
});

export const postReuseHourlySpread = apiMiddleware<
  ReuseJobHourlySpreadModel,
  PostReuseHourlySpread
>()({
  method: "post",
  endpoint: "/job-configuration/hourly-spread/reuse",
});

export const getJobDailyChangedSpread = apiMiddleware<DailySpreadReturnType>()({
  method: "get",
  endpoint: "/job-reconfiguration/:jobId/day-spread",
});

export const getJobReconfiguration = apiMiddleware<AllocationBatchModel>()({
  method: "get",
  endpoint: "/job-reconfiguration/:jobId",
});
