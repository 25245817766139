import { RoleFormValues } from "pages/users/list/list-roles/form/roles-form";
import { RoleModel } from "models";
import { apiMiddleware } from "api";

export const getRoles = apiMiddleware<RoleModel[]>()({
  method: "get",
  endpoint: "/roles",
});

export const putRole = apiMiddleware<null, RoleFormValues>()({
  method: "put",
  endpoint: `/roles/:roleId`,
});

export const postRole = apiMiddleware<RoleModel, RoleFormValues>()({
  method: "post",
  endpoint: "/roles",
});

export const deleteRole = apiMiddleware<RoleModel>()({
  method: "delete",
  endpoint: `/roles/:roleId`,
});
