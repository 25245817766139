import { FC } from "react";
import { Nullable } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { HoursAllocationFieldProps } from "../hours-allocation-field/hours-allocation-field";
import { HoursAllocationField } from "../hours-allocation-field";
import { AllocationUnitType } from "../../../allocation-unit/allocation-unit";

import styles from "../hours-allocation-form.module.css";

type HoursAllocationColProps = Pick<HoursAllocationFieldProps, "onChange"> & {
  units: AllocationUnitType[];
  rowIndex: number;
  colShift: number;
  reusedJob: Nullable<ReusableFileModel>;
};

const HoursAllocationCol: FC<HoursAllocationColProps> = ({
  units,
  colShift,
  rowIndex,
  onChange,
  reusedJob,
}) => {
  return (
    <div className={styles.hoursAllocationCol}>
      {units.map((unit, index) => (
        <HoursAllocationField
          key={unit.name}
          unit={unit}
          reusedJob={reusedJob}
          index={rowIndex ? rowIndex * colShift + index : index}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export { HoursAllocationCol };
