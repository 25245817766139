import { Range } from "react-range";
import { FC, useMemo, useState } from "react";
import cn from "classnames";
import { NoContent, Select, useDevice, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { formatDate } from "utils";
import { JobModelConfiguration } from "models";
import { SocketInstance } from "hooks";
import { Loader } from "components";
import {
  chartTrafficOptions,
  getChartTrafficOptionFromType,
  parseJobDataToChartData,
} from "./chart.utils";
import { ChartBarDataType, ChartTimeUnits, RangeType } from "./chart.types";
import { useChartFetch } from "./chart.hook";
import { ChartTimeUnitSwitcher } from "./chart-time-unit-switcher";
import { ChartPreviewTrack } from "./chart-preview-track";
import { ChartPreviewThumb } from "./chart-preview-thumb";
import { ChartPreview } from "./chart-preview";
import { ChartMain } from "./chart-main";

import styles from "./chart.module.css";

export type ChartProps = {
  jobId: number;
  jobConfiguration?: JobModelConfiguration;
  className?: string;
  socket: SocketInstance | null;
};

const initialTrafficType = getChartTrafficOptionFromType("all");

const Chart: FC<ChartProps> = ({ jobId, jobConfiguration, className, socket }) => {
  const { isMobileDevice } = useDevice();

  const [currentChartTimeUnit, setChartTimeUnit] = useState<ChartTimeUnits>(ChartTimeUnits.hourly);
  const [trafficType, setTrafficType] = useState(initialTrafficType);
  const [rangeValue, setRangeValue] = useState<RangeType>([0, 0]);
  const [rangeFinalValue, setRangeFinalValue] = useState<RangeType>([0, 0]);
  const [chartYAxisMaxValue, setChartYAxisMaxValue] = useState(0);

  const { trafficStatsMap, trafficStats, isTrafficStatsLoading, isTrafficStatsError } =
    useChartFetch({
      jobId,
      chartTimeUnit: currentChartTimeUnit,
      socket,
    });

  const parsedChartData = useMemo(() => {
    return parseJobDataToChartData({
      jobTrafficData: trafficStats,
      yAxisMaxValue: chartYAxisMaxValue,
      jobConfiguration,
    });
  }, [trafficStats, chartYAxisMaxValue, jobConfiguration]);

  const rangeMaxValue = useMemo(
    () => parsedChartData.originalData.length || 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parsedChartData.originalData.length, currentChartTimeUnit],
  );

  const chartPreviewDataByTrafficType = useMemo(() => {
    if (trafficType.value === "all") {
      return parsedChartData.chartStackedBarsData;
    }
    return parsedChartData.originalData;
  }, [parsedChartData.chartStackedBarsData, parsedChartData.originalData, trafficType.value]);

  const chartData: ChartBarDataType[] = useMemo(() => {
    const [leftValue, rightValue] = rangeFinalValue;

    if (leftValue === rightValue) {
      return [];
    }

    return chartPreviewDataByTrafficType.filter(
      (_, index) => index >= leftValue && index < rightValue,
    );
  }, [chartPreviewDataByTrafficType, rangeFinalValue]);

  // parsedChartData.originalData will not change over time, so here we set initial range value according to data from server
  // and after changing time unit
  useDidUpdate(() => {
    const dataMaxValue = parsedChartData.originalData.length;
    setRangeValue([0, dataMaxValue]);
    setRangeFinalValue([0, dataMaxValue]);
  }, [parsedChartData.originalData.length, currentChartTimeUnit]);

  const handleFinalRangeChange = (values: RangeType) => {
    const [leftValue, rightValue] = values;

    const isReversed = leftValue > rightValue;

    const valueToSet = isReversed ? [rightValue, leftValue] : [leftValue, rightValue];

    setRangeValue(valueToSet);
    setRangeFinalValue(valueToSet);
  };

  const thumbLabel = (index: number): string => {
    const indexToCheck = index > 0 ? index - 1 : index;

    if (!chartPreviewDataByTrafficType[indexToCheck]) return "";

    const chartPointDate = chartPreviewDataByTrafficType[indexToCheck].date;
    return formatDate(chartPointDate, "MMM d");
  };

  const setChartHourlyUnitHandler = () => {
    setChartTimeUnit(ChartTimeUnits.hourly);
  };
  const setChartDailyUnitHandler = () => {
    setChartTimeUnit(ChartTimeUnits.daily);
  };

  const isError =
    isTrafficStatsError || (!chartPreviewDataByTrafficType.length && !isTrafficStatsLoading);

  const handleChangeValue = (values: RangeType) => {
    if (values[0] === values[1]) return;
    setRangeValue(values);
  };

  return (
    <div className={cn(styles.jobChartWrap, className)}>
      <div className={styles.jobChartSelectsWrap}>
        <Select
          className={styles.jobChartSelect}
          selectedOptionsKeys={chartTrafficOptions[0].value}
          onChange={setTrafficType}
          options={chartTrafficOptions}
          disableSingleOptionUncheck
          disableClearing
          disableError
          inputSize="small"
        />
        <ChartTimeUnitSwitcher
          currentChartTimeUnit={currentChartTimeUnit}
          setHourlyUnit={setChartHourlyUnitHandler}
          setDailyUnit={setChartDailyUnitHandler}
        />
      </div>
      {isTrafficStatsLoading ? (
        <Loader />
      ) : isError ? (
        <NoContent size="medium" subtitle="Data is incorrect" showButton={false} />
      ) : (
        <>
          <ChartMain
            trafficType={trafficType.value}
            data={chartData}
            parsedJobMap={trafficStatsMap}
            currentChartTimeUnit={currentChartTimeUnit}
            setYAxisMaxValue={setChartYAxisMaxValue}
          />

          <div className={styles.chartPreviewWrap}>
            {!isMobileDevice && (
              <ChartPreview
                trafficType={trafficType.value}
                data={chartPreviewDataByTrafficType}
                currentChartTimeUnit={currentChartTimeUnit}
              />
            )}
            <Range
              step={1}
              min={0}
              max={rangeMaxValue}
              allowOverlap={false}
              values={rangeValue}
              onChange={handleChangeValue}
              onFinalChange={handleFinalRangeChange}
              renderTrack={({ props, children }) => (
                <ChartPreviewTrack props={props}>{children}</ChartPreviewTrack>
              )}
              renderThumb={({ props, value, index }) => (
                <ChartPreviewThumb
                  key={index}
                  props={props}
                  label={thumbLabel(value)}
                  index={index}
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { Chart };
