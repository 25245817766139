import { HeaderValues } from "../files-list.types";
import { essentialColumn } from "../files-list.constants";
import { ErrorStatus } from "./file-upload.types";

export const defaultOption = { value: "", label: "" };

export const selectInitialOptions: HeaderValues = [
  { value: essentialColumn.toLowerCase(), label: essentialColumn },
  { value: "firstName", label: "First name" },
  { value: "lastName", label: "Last name" },
  { value: "city", label: "City" },
  { value: "fullName", label: "Full name" },
  { value: "phone", label: "Phone" },
  { value: "country", label: "Country" },
  { value: "other", label: "Other" },
];

export const initialErrorStatus: ErrorStatus = {
  isError: true,
  reason: "initial",
};
