import React, { memo, ClipboardEvent, useState } from "react";
import cn from "classnames";
import { Nullable } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { DayUnitType } from "../days-allocation.types";
import { getCalendarDays } from "./calendar.utils";
import { weekdays } from "./calendar.constants";
import { CalendarDay } from "./calendar-day";

import { ClipboardPaste } from "assets/images";
import styles from "./calendar.module.css";

type CalendarProps = {
  activeDays: DayUnitType[];
  onChange: (value: number, rangeIndex: number) => void;
  onCopy: (value: number, activeIndexes: number[]) => void;
  className: string;
  onPaste: (event: ClipboardEvent<HTMLInputElement>, activeDayIndex: number) => void;
  reusedJob: Nullable<ReusableFileModel>;
};

const Calendar = memo<CalendarProps>(
  ({ activeDays, onPaste, onCopy, className, onChange, reusedJob }) => {
    // used for keeping the state of currently opened tooltip
    const [activeTooltipIndex, setActiveTooltipIndex] = useState<number | null>(null);

    const calendarDays = getCalendarDays(activeDays);
    const calendarClassNames = cn(styles.calendar, className);

    const handleCopyToAll = (value: number) => {
      if (!calendarDays) return;
      const activeIndexes = calendarDays
        .filter((day) => day.active)
        .map((day) => day.activeDayIndex);

      onCopy(value, activeIndexes);
    };

    return (
      <div className={calendarClassNames}>
        <ul className={styles.weekdays}>
          {weekdays.map((day) => (
            <li className={styles.weekday} key={day}>
              <abbr className={styles.weekdayTitle} title={day}>
                {day.slice(0, 3)}
              </abbr>
            </li>
          ))}
        </ul>

        {calendarDays && (
          <ol className={styles.dayGrid}>
            {calendarDays.map((day, index) => (
              <CalendarDay
                key={index}
                onPaste={onPaste}
                onChange={onChange}
                handleCopyToAll={handleCopyToAll}
                activeTooltipIndex={activeTooltipIndex}
                setActiveTooltipIndex={setActiveTooltipIndex}
                {...day}
                reusedJob={reusedJob}
              />
            ))}
          </ol>
        )}

        <div className={styles.legend}>
          <ClipboardPaste className={styles.legendIcon} />
          <span>You can use right click to open the context menu</span>
        </div>
      </div>
    );
  },
);

export { Calendar };
