import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FC, useEffect } from "react";

import { resetUserStore } from "store";
import { LOGIN_PAGE } from "constants/routes.constants";
import { TOKEN_STORAGE_FIELD, REFRESH_TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { Loader } from "components";

const Logout: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // remove tokens
    localStorage.removeItem(TOKEN_STORAGE_FIELD);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_FIELD);

    // reset redux
    dispatch(resetUserStore());

    // redirect to sign in page
    navigate(LOGIN_PAGE.path);
  }, [dispatch, navigate]);

  return <Loader />;
};

export { Logout };
