import { io, Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { useState } from "react";
import { notification, useDidMount } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { appEnvironment } from "config/environment.config";
import {
  BatchFileSocketInstance,
  BatchFileListenerEventsKeys,
  BatchFileListenerHandlers,
} from "./use-batch-file-socket.types";

const SOCKET_BATCH_FILE_NAMESPACE = "/job";

export const useBatchFileSocket = () => {
  const [socket, setSocket] = useState<BatchFileSocketInstance | null>(null);

  const [isConnected, setConnected] = useState<boolean>(false);

  const { token } = useSelector((state: RootState) => state.auth);

  const listenerHandlers: Partial<BatchFileListenerHandlers> = {
    connect: () => {
      setConnected(true);
    },
    connectError: () => {
      setConnected(false);
    },
    disconnect: () => {
      setConnected(false);
    },
    exception: (error: Error) => {
      notification.error("Socket exception", "Error while connecting to the socket. " + error);
    },
  };

  const addJobListener =
    (socketInstance: Socket<Partial<BatchFileListenerHandlers>>) =>
    <E extends BatchFileListenerEventsKeys>(event: E) => {
      socketInstance.on<BatchFileListenerEventsKeys>(event, listenerHandlers[event]);

      return () => {
        socketInstance.off<BatchFileListenerEventsKeys>(event, listenerHandlers[event]);
      };
    };

  const mountListeners = (socketInstance: Socket<BatchFileListenerHandlers>) => {
    const connectUnmount = addJobListener(socketInstance)("connect");
    const connectErrorUnmount = addJobListener(socketInstance)("connectError");
    const disconnectUnmount = addJobListener(socketInstance)("disconnect");
    const exceptionUnmount = addJobListener(socketInstance)("exception");

    return () => {
      connectUnmount();
      connectErrorUnmount();
      disconnectUnmount();
      exceptionUnmount();
    };
  };

  useDidMount(() => {
    const socketInstance: Socket<BatchFileListenerHandlers> = io(
      appEnvironment.apiUrl + SOCKET_BATCH_FILE_NAMESPACE,
      {
        autoConnect: false,
        extraHeaders: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    const unmountListeners = mountListeners(socketInstance);
    socketInstance.connect();

    setSocket(socketInstance);

    return () => {
      socket?.disconnect();
      unmountListeners();
    };
  });

  return { socket, isConnected };
};
