import { MouseEvent, FC, useEffect, useState } from "react";
import cn from "classnames";
import { AlignTypeKeys, Button, FormButtons } from "@epcnetwork/core-ui-kit";

import { submitTextMap } from "./allocation-buttons.constants";

import { AttentionIcon } from "assets/images/components";
import { ReactComponent as ArrowBaseRight } from "assets/images/arrow-base-right.svg";
import styles from "./allocation-buttons.module.css";

type AllocationButtonsProps = {
  handleNextClick: (e: MouseEvent<HTMLButtonElement>) => void;
  page: number;
  align?: AlignTypeKeys;
  handleBackClick?: () => void;
  className?: string;
  btnClassName?: string;
  overlimit?: boolean;
  isLastPage?: boolean;
  buttonText?: string;
  disableButton?: boolean;
};

type BackButtonProps = {
  backToGeneral: VoidFunction;
};

const AllocationButtons: FC<AllocationButtonsProps> = ({
  page,
  handleNextClick,
  isLastPage = false,
  align = "right",
  handleBackClick,
  buttonText = submitTextMap[page],
  className,
  btnClassName,
  disableButton = false,
  overlimit = false,
}) => {
  const [loading, setLoading] = useState(isLastPage);

  useEffect(() => {
    if (isLastPage) {
      const timer = setTimeout(() => setLoading(false), 1500);
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormButtons
      className={cn(styles.actionsWrap, className)}
      align={align}
      disabled={disableButton}
      loading={loading}
    >
      {handleBackClick && (
        <Button appearance="secondary" onClick={handleBackClick} className={styles.backButton}>
          Back
        </Button>
      )}
      <Button type="button" onClick={handleNextClick} className={btnClassName} loading={true}>
        {buttonText}
        {overlimit && <AttentionIcon type="infoTextTertiary" />}
      </Button>
    </FormButtons>
  );
};

const BackButton: FC<BackButtonProps> = ({ backToGeneral }) => {
  return (
    <Button appearance="text" onClick={backToGeneral} className={styles.backToGeneralButton}>
      <ArrowBaseRight />
      Back to general settings
    </Button>
  );
};

export { AllocationButtons, BackButton };
