import { ChangeEvent, FC, useState } from "react";
import cn from "classnames";
import { Nullable, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { AllocationUnitType } from "../../../allocation-unit/allocation-unit";
import { maxHourAllocation } from "../../../allocation-form.constants";

import globalStyles from "assets/styles/global.module.css";
import clockIconUrl from "assets/images/clock-grey.svg";
import styles from "../hours-allocation-form.module.css";

export type HoursAllocationFieldProps = {
  unit: AllocationUnitType;
  index: number;
  onChange: (value: number, rangeIndex: number) => void;
  reusedJob: Nullable<ReusableFileModel>;
};

const HoursAllocationField: FC<HoursAllocationFieldProps> = ({
  unit,
  index,
  onChange,
  reusedJob,
}) => {
  const { value, name } = unit;
  const [allocateValue, setAllocateValue] = useState(String(value));

  useDidUpdate(() => {
    setAllocateValue(String(value));
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAllocateValue(String(value));
    onChange(Number(value), index);
  };

  const inputStyles = cn(styles.hoursInput, globalStyles.numberFieldAsTextField, {
    [styles.hoursInputError]: value > maxHourAllocation,
    [styles.disabled]: reusedJob,
  });

  return (
    <div className={styles.formFieldWrapper}>
      <label htmlFor={`input-allocate-${name}`} className={styles.label}>
        <img width="14" height="14" src={clockIconUrl} alt="Clock Icon" />
        <span className={styles.name}>{name}</span>
      </label>
      <input
        className={inputStyles}
        value={allocateValue}
        id={`input-allocate-${name}`}
        onChange={handleChange}
        type="number"
        min={0}
        disabled={!!reusedJob}
      />
    </div>
  );
};

export { HoursAllocationField };
