export const btnResumeText = "Resume";
export const btnPauseText = "Pause";
export const btnFreezeText = "Freeze";
export const btnUnfreezeText = "Unfreeze";

export const tooltipFreezeText =
  "If you freeze the job, the job should be unfroze manually, the recalculation will be in the next hour, all the unsent emails will be sent at the end of the job";
export const tooltipUnfreezeText =
  "If you unfreeze the job, the recalculation will be in the next hour, all the unsent emails will be sent at the end of the job";

export const notificationSuccessFreezeTitle = "You froze the job successfully";
export const notificationSuccessFreezeMessage = "The job should be unfreeze manually";
export const notificationSuccessUnfreezeTitle = "You unfroze the job successfully";
export const notificationSuccessUnfreezeMessage =
  "The recalculation will be in the next hour, all the unsent emails will be sent at the end of the job";

export const errorFreezeTitle = "Freezing job error";
export const errorUnfreezeTitle = "Unfreezing job error";
export const errorPauseTitle = "Pausing job error";

export const btnReconfigureText = "Reconfigure";
export const copyText = "Click to copy";
export const copyNotificationSuccessTitle = "URL was copied successfully";
export const copyNotificationSuccessText = "URL was copied to clipboard";
export const autofreezeSwitchText = "Autofreeze";
export const autofreezeTooltipOnText = "Autofreeze will be turn on for this job";
export const autofreezeTooltipOffText = "Autofreeze will be turn off for this job";
export const autofreezeNotificationOnSuccessText = "Autofreeze was turned on successfully";
export const autofreezeNotificationOffSuccessText = "Autofreeze was turned off successfully";
export const autofreezeNotificationOffErrorText = "Autofreeze wasn't turned off. Please try again";
export const autofreezeNotificationOnErrorText = "Autofreeze wasn't turned on. Please try again";
