import { FC } from "react";
import cn from "classnames";
import { Button } from "@epcnetwork/core-ui-kit";

import { getBtnAriaLabelText } from "./calendar-list-switcher.utils";

import { ReactComponent as ListIcon } from "assets/images/list-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import styles from "./calendar-list-switcher.module.css";

type CalendarListSwitcherProps = {
  displayCalendarView: boolean;
  onChangeDisplayCalendarView: (displayCalendarView: boolean) => void;
};

const CalendarListSwitcher: FC<CalendarListSwitcherProps> = ({
  displayCalendarView,
  onChangeDisplayCalendarView,
}) => {
  const calendarIconStyles = cn(styles.icon, { [styles.active]: displayCalendarView });
  const listIconStyles = cn(styles.icon, { [styles.active]: !displayCalendarView });

  return (
    <div className={styles.switcherWrapper}>
      <Button
        appearance="transparent"
        className={styles.switcherButton}
        onClick={() => onChangeDisplayCalendarView(false)}
        aria-label={getBtnAriaLabelText("list")}
      >
        <ListIcon className={listIconStyles} />
      </Button>
      <Button
        appearance="transparent"
        className={styles.switcherButton}
        onClick={() => onChangeDisplayCalendarView(true)}
        aria-label={getBtnAriaLabelText("calendar")}
      >
        <CalendarIcon className={calendarIconStyles} />
      </Button>
    </div>
  );
};

export { CalendarListSwitcher };
