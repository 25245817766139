import { FC } from "react";
import { NotFound } from "@epcnetwork/core-ui-kit";

import styles from "./not-found.module.css";

const NotFoundPage: FC = () => {
  return (
    <div className={styles.container}>
      <NotFound
        btnText="Back to home"
        size="big"
        title="Oops! Page not found!"
        subtitle="We are very sorry for inconvenience. It looks like you are trying to access a page that was has been deleted or never even existed"
      />
    </div>
  );
};

export { NotFoundPage };
