import { FC } from "react";
import { TableRow, TableCell } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { RestAPIType } from "../../connectors/rest-api";

import globalStyles from "assets/styles/global.module.css";
import styles from "./reuse-job-table-row.module.css";

type ReuseJobTableRowProps = {
  item: ReusableFileModel;
};

const ReuseJobTableRow: FC<ReuseJobTableRowProps> = ({ item }) => {
  const { name, connector } = item;
  const httpMethod = (connector?.properties as RestAPIType)?.httpMethod;

  return (
    <TableRow id={name}>
      <TableCell className={styles.fileNameWrap}>
        <span className={globalStyles.applyMultiOverflow}>{name}</span>
      </TableCell>
      <TableCell>{httpMethod || "-"}</TableCell>
      <TableCell>{connector.apiType}</TableCell>
    </TableRow>
  );
};

export { ReuseJobTableRow };
