import { object, SchemaOf, string } from "yup";
import { FC } from "react";
import { FormField } from "@epcnetwork/core-ui-kit";

import { requiredFieldText } from "constants/form.constants";

export const remarketySchema: SchemaOf<RemarketyType> = object({
  storeId: string().required(requiredFieldText),
  apiKey: string().required(requiredFieldText),
});

export type RemarketyType = {
  storeId: string;
  apiKey: string;
};

type RemarketyProps = {
  endpointOrdinal: number;
};

const Remarkety: FC<RemarketyProps> = ({ endpointOrdinal }) => {
  return (
    <>
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.storeId`}
        label="Store ID"
        placeholder="Store ID"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="Api Key"
        placeholder="Api Key"
      />
    </>
  );
};

export { Remarkety };
