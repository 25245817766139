import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { StorageTableType, FiltersQueryType } from "types";

export const getStorageElementsPerPage = (tableName: string): undefined | number => {
  const tableDataJson = localStorage.getItem(tableName);
  if (tableDataJson) {
    const storageElementsPerPage = (JSON.parse(tableDataJson) as StorageTableType)
      .tableElementsPerPage;
    if (storageElementsPerPage) {
      return +storageElementsPerPage;
    }
  }
};

export const getInitialStorageFilters = <T extends FiltersQueryType>(
  tableName: string,
  initialFilters: UseFiltersProps<T>,
): UseFiltersProps<T> => {
  const storageElementsPerPage = getStorageElementsPerPage(tableName);

  if (storageElementsPerPage && initialFilters.initialState) {
    return {
      ...initialFilters,
      initialState: {
        ...initialFilters.initialState,
        limit: storageElementsPerPage,
      },
    };
  }

  return initialFilters;
};
