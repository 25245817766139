import { Fragment, ReactNode } from "react";

import { SearchParamsMap } from "utils";
import { essentialColumn } from "pages/files/list/files-list.constants";
import { AvailableFileModel } from "models";

import styles from "./rest-api.module.css";

export function convertMapInPreviewContent(mapSearchParams: SearchParamsMap): ReactNode[] {
  const resArr: ReactNode[] = [];
  let urlKey: string;

  function convert(mapSearchParams: SearchParamsMap) {
    for (const [key, value] of mapSearchParams) {
      if (typeof value === "string") {
        const urlValueParam = value.startsWith("{{") ? styles.urlValueParam : styles.urlValue;

        if (urlKey) {
          resArr.push(
            <Fragment key={value}>
              <br />
              <b className={styles.urlNestedKey}>{key}</b>=
              <span className={urlValueParam}>{value}</span>
            </Fragment>,
          );
          continue;
        }

        resArr.push(
          <Fragment key={value}>
            <br />
            <b className={styles.urlKey}>{key}</b>=<span className={urlValueParam}>{value}</span>
          </Fragment>,
        );
        continue;
      }

      urlKey = key;
      // separator from formatSearchParamsToMap
      // should be ${key}=${origin} format
      const [urlKeyValue, urlOrigin] = key.split("=");
      resArr.push(
        <Fragment key={urlKeyValue}>
          <br />
          <b className={styles.urlKey}>{urlKeyValue}</b>=
          <span className={styles.urlValue}>{urlOrigin}</span>
        </Fragment>,
      );
      convert(value);
    }
  }

  convert(mapSearchParams);

  return resArr;
}

export const isValueVariable = (value: string | SearchParamsMap, file: AvailableFileModel) => {
  if (typeof value !== "string") return false;

  const isDynamicValue = value.startsWith("{{") && value.endsWith("}}");
  const dynamicValue = value.substring(2, value.length - 2);
  const columns = [essentialColumn.toLowerCase(), ...file.columns];

  return isDynamicValue && !columns.includes(dynamicValue);
};
