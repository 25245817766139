import { FC } from "react";

import { SliderInfoType, SlideType } from "../login-slider";

import styles from "./slider-controllers.module.css";

type Props = {
  sliderInfo: SliderInfoType;
  currentSlide: SlideType;
  setCurrentSlide: (slide: SlideType) => void;
};

const SliderControllers: FC<Props> = ({ setCurrentSlide, sliderInfo, currentSlide }) => (
  <div className={styles.slideControllers}>
    {sliderInfo.map((slide) => (
      <div
        onClick={() => setCurrentSlide(slide)}
        key={slide.id}
        className={
          currentSlide === slide
            ? `${styles.slideController} ${styles.slideControllerActive}`
            : styles.slideController
        }
      />
    ))}
  </div>
);

export { SliderControllers };
