import { List } from "@epcnetwork/core-ui-kit";

import { FileModel, FileCountModel, SuppressionListModel } from "models";
import { apiMiddleware } from "api/api.middleware";
import { FileData } from "./upload.interface";

export const getUploadedFiles = apiMiddleware<List<FileModel> & FileCountModel>()({
  method: "get",
  endpoint: "/files",
});

export const postUploadedFiles = apiMiddleware<undefined, FileData>()({
  method: "post",
  endpoint: "/suppression/file",
});

export const getSuppressionList = apiMiddleware<SuppressionListModel[]>()({
  method: "get",
  endpoint: "/suppression-lists",
});

export const deleteFile = apiMiddleware()({
  method: "delete",
  endpoint: "/files/:fileId",
});
