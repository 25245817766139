import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { EmailsSuppressionType } from "./emails-suppression-form.types";

export const supportedFormats = [
  ".csv",
  "text/csv",
  "application/csv",
  "text/x-csv",
  "application/x-csv",
];
export const subtitle = "Accepted csv files";

export const getInitialData = (item: File): EmailsSuppressionType => ({
  fileName: sanitizeFileName(item.name),
});
