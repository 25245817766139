import { useRef } from "react";
import memoize from "memoizee";
import { useFormikContext } from "formik";
import { ApiMiddleware, useCall, useDidMount, useFormContext } from "@epcnetwork/core-ui-kit";

type MapDataCallback<T> = (
  callback: (data: T) => [key: string, value: any],
  dependencies: any[],
) => void;

const memoizeOptions = {
  normalizer: (args: any) => JSON.stringify(args[1]),
};

const initialMapper = () => ["", ""];

const useSetFormikField = <R extends ApiMiddleware<any, any, any, any, any, any, any>>(
  apiCall: R,
  isApiCallNeeded = true,
): MapDataCallback<any> => {
  const formikContext = useFormikContext();
  const [, actions] = useFormContext();

  const { submit, onCallSuccess, onCallError } = useCall(apiCall as any);

  const mapData = useRef<(values: any) => any>(initialMapper);

  onCallSuccess((payload) => {
    actions.setLoading(false);

    if (!payload) return;

    const [key, value] = mapData.current(payload);
    key && formikContext.setFieldValue(key, value);
  });

  onCallError(() => {
    actions.setLoading(false);
  });

  useDidMount(() => {
    if (isApiCallNeeded) {
      actions.setLoading(true);
      submit();
    }
  });

  return memoize((mapDataCall) => {
    mapData.current = mapDataCall;
  }, memoizeOptions);
};

export { useSetFormikField };
