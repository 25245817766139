import { isEqual } from "@epcnetwork/core-ui-kit";

type Element = {
  [key: string]: unknown;
};

export const setListElement = <T extends Element>(
  list: T[],
  insertedItem: T,
  idKey?: string,
): T[] => {
  const key = idKey || "id";
  const insertedKeyValue = insertedItem[key];

  const found = list.find((element) => element[key] === insertedKeyValue);
  if (found) {
    return list.map((element) => {
      if (element[key] === insertedKeyValue) {
        return { ...element, ...insertedItem };
      }
      return element;
    });
  }
  return [...list, insertedItem];
};

export const findKeyByValue = <T extends Record<string, unknown>>(
  obj: T,
  value: T[keyof T],
): keyof T | undefined => Object.keys(obj).find((key) => isEqual(obj[key], value));
