import { FC } from "react";
import pretty from "pretty";
import { FetchingError, Button, useFetch, notification, CodeInput } from "@epcnetwork/core-ui-kit";

import { SUCCESS_TEXT } from "constants/notification.constants";
import { Loader } from "components";
import { getJobLogResponse } from "api";
import { copyToClipboardText } from "./logs-info-panel.constants";

import styles from "./logs-info-panel.module.css";

type LogsInfoItemModalProps = {
  jobId: number;
  jobLogResponseId: number;
};

const LogsInfoItemModal: FC<LogsInfoItemModalProps> = ({ jobId, jobLogResponseId }) => {
  const { payload, loading, error } = useFetch(
    getJobLogResponse.setParams({ jobId, responseId: jobLogResponseId }),
  );

  const isError = !loading && error;

  const handleJSON = (json: string | undefined): string => {
    try {
      return JSON.parse(json || "");
    } catch {
      return json || "";
    }
  };

  const prettify = (json: string): string => {
    let response = "";
    const object = handleJSON(json);

    Object.entries(object).forEach(([key, value]) => {
      const prettyValue = pretty(value);

      if (prettyValue?.length < 100) {
        response += `"${key}": "${prettyValue}"`;
      } else {
        response += `"${key}":
${prettyValue}
        `;
      }
    });
    return response;
  };

  const handleParseResponse = (json: string) => {
    try {
      return prettify(json);
    } catch {
      return handleJSON(json);
    }
  };

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text || "").then(() => {
      notification.success(SUCCESS_TEXT, "Text was copied to clipboard");
    });
  };

  if (loading) {
    return (
      <div className={styles.logResponseModalWrap}>
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.logResponseModalWrap}>
        <FetchingError title="Error" subtitle={error?.message || "Error while fetching data"} />
      </div>
    );
  }

  const data = handleParseResponse(payload?.response || "");

  return (
    <div className={styles.logResponseModalWrap}>
      <div className={styles.title}>Email #{jobLogResponseId} response</div>
      <div className={styles.logResponseWrap} style={{ whiteSpace: "pre" }}>
        <CodeInput
          value={data}
          options={{ readOnly: true, autoCloseBrackets: true, cursorBlinkRate: -1 }}
          codeFormat="js"
          onCopyText={handleCopyText}
        />
      </div>
      <div className={styles.logResponseActionsWrap}>
        <Button onClick={() => handleCopyText(payload?.response || "")}>
          {copyToClipboardText}
        </Button>
      </div>
    </div>
  );
};

export { LogsInfoItemModal };
