import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts";
import { FC, useMemo } from "react";
import cn from "classnames";

import { formatDate } from "utils";
import { JobTrafficTypes, JobTrafficTypesKeys } from "models";
import { timeFormat } from "constants/general.constants";
import { ChartBarDataType } from "./chart.types";
import { chartTooltipFrozenTimeFormat, chartTrafficTypes } from "./chart.constants";
import { ChartMainProps } from "./chart-main";

import styles from "./chart.module.css";

type ChartMainTooltipProps = Pick<ChartMainProps, "parsedJobMap"> & {
  tooltipData: TooltipProps<ValueType, NameType>;
};
type CandleInfo = Omit<ChartBarDataType, "rescheduleReason">;

const getCandleInfo = ({
  rescheduledFrom,
  frozenStartDate,
  frozenEndDate,
  isTrafficManualFrozen,
  isTrafficAutoFrozen,
  wasTrafficManualFrozen,
}: CandleInfo): string | null => {
  const freezeTypeName =
    isTrafficManualFrozen || wasTrafficManualFrozen
      ? "Frozen"
      : isTrafficAutoFrozen
        ? "Autofrozen"
        : null;

  // case: unfrozen emails
  if (freezeTypeName && rescheduledFrom) {
    return `${freezeTypeName} emails were from ${formatDate(
      frozenStartDate,
      chartTooltipFrozenTimeFormat,
    )} to ${formatDate(frozenEndDate, chartTooltipFrozenTimeFormat)}`;
  }

  // case: emails, that were not frozen
  if ((!isTrafficManualFrozen && !isTrafficAutoFrozen) || !freezeTypeName) return null;

  // case: frozen emails
  return `${freezeTypeName} period has started`;
};

const ChartMainTooltip: FC<ChartMainTooltipProps> = ({ tooltipData, parsedJobMap }) => {
  const candleData = useMemo((): CandleInfo | null => {
    const tooltipDataItems = tooltipData.payload;
    const chartBarData: ChartBarDataType | null = tooltipDataItems?.length
      ? (tooltipDataItems[0].payload as ChartBarDataType)
      : null;

    if (!chartBarData) return null;

    const tooltipTrafficData = parsedJobMap.get(+chartBarData.date);

    if (!tooltipTrafficData) return null;

    const newChartBarData: CandleInfo = {
      date: chartBarData.date,
      xAxis: chartBarData.xAxis,
      targetTraffic: tooltipTrafficData.target,
      failedTraffic: tooltipTrafficData.failed,
      succeededTraffic: tooltipTrafficData.successful,
      suppressedButSentTraffic: tooltipTrafficData.suppressed,
      rescheduledFrom: chartBarData.rescheduledFrom,
      isTrafficManualFrozen: chartBarData.isTrafficManualFrozen,
      isTrafficAutoFrozen: chartBarData.isTrafficAutoFrozen,
      wasTrafficManualFrozen: chartBarData.wasTrafficManualFrozen,
    };

    if (chartBarData.frozenStartDate) {
      newChartBarData.frozenStartDate = chartBarData.frozenStartDate;
    }
    if (chartBarData.frozenEndDate) {
      newChartBarData.frozenEndDate = chartBarData.frozenEndDate;
    }
    if (chartBarData.wasUnfrozen) {
      newChartBarData.wasUnfrozen = chartBarData.wasUnfrozen;
    }

    return newChartBarData;
  }, [parsedJobMap, tooltipData.payload]);

  if (!candleData) return null;

  if (candleData.wasUnfrozen) return null;

  const candleInfo = getCandleInfo(candleData);
  return (
    <div className={styles.tooltipWrap}>
      <div className={styles.tooltipDate}>
        {formatDate(candleData.date, `dd MMM | ${timeFormat}`)}
      </div>
      <ChartTooltipCandleData
        signColorType={JobTrafficTypes.targetTraffic}
        name={chartTrafficTypes.targetTraffic}
        value={candleData.targetTraffic}
      />
      {!candleData.isTrafficManualFrozen && !candleData.isTrafficAutoFrozen && (
        <>
          <ChartTooltipCandleData
            signColorType={JobTrafficTypes.succeededTraffic}
            name={chartTrafficTypes.succeededTraffic}
            value={candleData.succeededTraffic}
          />
          <ChartTooltipCandleData
            signColorType={JobTrafficTypes.failedTraffic}
            name={chartTrafficTypes.failedTraffic}
            value={candleData.failedTraffic}
          />
          <ChartTooltipCandleData
            signColorType={JobTrafficTypes.suppressedButSentTraffic}
            name={chartTrafficTypes.suppressedButSentTraffic}
            value={candleData.suppressedButSentTraffic}
          />
        </>
      )}
      {candleInfo && (
        <>
          <div className={styles.tooltipDivider}></div>
          <div className={styles.tooltipFrozenInfo}>{candleInfo}</div>
        </>
      )}
    </div>
  );
};

export { ChartMainTooltip };

type CandleProps = {
  signColorType: JobTrafficTypesKeys;
  name: string;
  value: number;
};

const ChartTooltipCandleData: FC<CandleProps> = ({ name, value, signColorType }) => {
  return (
    <div className={styles.tooltipItem}>
      <div className={styles.tooltipCaptionWrap}>
        <div className={cn(styles.tooltipVisualSign, styles[signColorType])} />
        {name}
      </div>
      <div>{value}</div>
    </div>
  );
};
