import { sub, add, isFirstDayOfMonth, isLastDayOfMonth } from "date-fns";
import { getDayOfMonth, getDayOfWeekName, getMonthName } from "@epcnetwork/core-ui-kit";

import { DayUnitType } from "../days-allocation.types";
import { weekdays } from "./calendar.constants";
import { CalendarDayType } from "./calendar-day/calendar-day.type";

export const weeksToShow = 5;
const maxWeeksToShow = 6;
export const weekDaysCount = weekdays.length;
const midOfWeeksToShow = Math.ceil(weeksToShow / 2);

export const getCalendarDays = (activeDays: DayUnitType[]): CalendarDayType[] | null => {
  const activeDaysCount = activeDays.length;
  if (activeDaysCount) {
    const firstActiveDay = activeDays[0].date;
    const lastActiveDay = activeDays[activeDaysCount - 1].date;
    const firstActiveDayName = getDayOfWeekName(firstActiveDay);
    const firstActiveDayIndex = weekdays.indexOf(firstActiveDayName);
    const activeWeeksNumber = Math.ceil(activeDaysCount / weekDaysCount);
    const weeksToShift = Math.floor((weeksToShow - activeWeeksNumber) / 2);
    const shiftCellDays =
      (activeWeeksNumber > midOfWeeksToShow ? 0 : weeksToShift) * weekDaysCount +
      firstActiveDayIndex;
    let activeDayIndex = 0;

    let calendarCells = weeksToShow * weekDaysCount;
    const activeDaysCalendarCount = weekDaysCount - shiftCellDays + 4 * weekDaysCount;
    if (activeDaysCount > activeDaysCalendarCount) {
      calendarCells = maxWeeksToShow * weekDaysCount;
    }

    return Array.from(Array(calendarCells)).map((_, index) => {
      const activeDay = activeDays[activeDayIndex];
      const isPrevDay = index < shiftCellDays;
      const isNextDay = index > shiftCellDays + activeDaysCount - 1;
      const isActiveDay = !isPrevDay && !isNextDay;
      const prevDay = sub(firstActiveDay, { days: shiftCellDays - index });
      const nextDay = add(lastActiveDay, { days: index + 1 - shiftCellDays - activeDaysCount });

      const day = isActiveDay ? activeDays[activeDayIndex++].date : isPrevDay ? prevDay : nextDay;

      const showMonth =
        isFirstDayOfMonth(day) || day === firstActiveDay || (isLastDayOfMonth(day) && isActiveDay);

      return {
        active: isActiveDay,
        day: getDayOfMonth(day),
        hoursLength: isActiveDay ? activeDay?.hoursUnits.length : 0,
        month: getMonthName(day),
        activeDayIndex: activeDayIndex - 1,
        showMonth,
        value: isActiveDay ? activeDay.value : 0,
      };
    });
  }

  return null;
};
