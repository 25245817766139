import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { FC } from "react";
import cn from "classnames";
import { Tooltip } from "@epcnetwork/core-ui-kit";

import { capitalize } from "utils";
import { DataGraph, GraphColors } from "./circle-graph.types";
import { overlimitEmailAllocatedText, shortageEmailAllocatedText } from "./circle-graph.constants";

import { AttentionIcon } from "assets/images/components";
import styles from "./circle-graph.module.css";

type CircleGraphProps = {
  dataGraph: DataGraph;
  colors: GraphColors;
  title: string;
  legendSubTitle?: string;
  centralGraphText?: string;
  centralGraphValue?: number;
  lineWeight?: number;
  cornerRadius?: number;
  paddingAngle?: number;
  graphWrapperWidth?: number;
  graphWrapperHeight?: number;
  dataKey?: string;
  graphSize?: number;
  total?: number;
  showTooltip?: boolean;
  legendPosition?: "right" | "top" | "bottom" | "left";
};

const CircleGraph: FC<CircleGraphProps> = ({
  total = 0,
  legendPosition = "right",
  graphSize = 58,
  lineWeight = 6,
  cornerRadius = 15,
  paddingAngle = 2,
  graphWrapperWidth = 82,
  graphWrapperHeight = 82,
  dataKey = "value",
  dataGraph,
  showTooltip = false,
  colors,
  centralGraphText,
  legendSubTitle,
  centralGraphValue = 0,
  title,
}) => {
  const contentWrapperStyles = cn(
    styles.contentWrapper,
    styles[`legendPosition${capitalize(legendPosition)}`],
  );

  const tooltipType =
    centralGraphValue < 0 ? "error" : centralGraphValue > 0 ? "infoPrimary" : null;
  const tooltipText =
    centralGraphValue < 0
      ? overlimitEmailAllocatedText
      : centralGraphValue > 0
        ? shortageEmailAllocatedText
        : "";

  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.graphTitle}>
          {title} {total}
        </div>
      )}
      <div className={contentWrapperStyles}>
        <div
          className={styles.graphWrapper}
          style={{ width: `${graphWrapperWidth}px`, height: `${graphWrapperHeight}px` }}
        >
          <ResponsiveContainer className={styles.graphContainer} width="100%" height="100%">
            <PieChart>
              <Pie
                data={dataGraph}
                innerRadius={graphSize * 0.5 - lineWeight}
                outerRadius={graphSize * 0.5}
                paddingAngle={paddingAngle}
                dataKey={dataKey}
                cornerRadius={cornerRadius}
              >
                {dataGraph.map(({ name }) => (
                  <Cell key={`cell-${name}`} className={styles[name]} fill={colors[name]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div className={styles.centralValueWrapper}>
            <div className={styles.centralValue}>{centralGraphValue}</div>
            {centralGraphText && <div className={styles.centralValueText}>{centralGraphText}</div>}
          </div>
        </div>
        <ul className={styles.legendWrapper}>
          {dataGraph.map(({ name, value }) => (
            <li key={name} className={styles.legendItem}>
              <div className={styles.legendTitle}>
                <div style={{ backgroundColor: colors[name] }} className={styles.colorIndicator} />
                <span>{name}</span>
              </div>
              {legendSubTitle && (
                <span className={styles.legendValue}>
                  {value} {legendSubTitle}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
      {showTooltip && tooltipType && tooltipText && (
        <Tooltip
          triggerElement={<AttentionIcon type={tooltipType} />}
          position="bottom"
          trigger="hover"
          triggerClassName={styles.tooltipTrigger}
          contentClassName={styles.tooltipContent}
        >
          {tooltipText}
        </Tooltip>
      )}
    </div>
  );
};

export { CircleGraph };
