import { useNavigate } from "react-router-dom";
import { FC } from "react";

import { DEFAULT_PAGE } from "constants/routes.constants";

import { ReactComponent as LogoImg } from "assets/images/logo.svg";
import styles from "../sidebar.module.css";

const Logo: FC = () => {
  const navigate = useNavigate();

  const handleRedirectHome = () => {
    navigate(DEFAULT_PAGE.path);
  };

  return (
    <div className={styles.logo} onClick={handleRedirectHome}>
      <LogoImg />
      Traffic
    </div>
  );
};

export { Logo };
