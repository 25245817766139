import { useLocalStorage } from "@epcnetwork/core-ui-kit";

import { entityName } from "../sidebar/sidebar.constants";

type UseSidebarReturnType = {
  isMenuClosed: boolean;
  setIsMenuClosed: (value: boolean) => void;
};

const defaultMenuState = false;

const useSidebar = (): UseSidebarReturnType => {
  const { value, setValue } = useLocalStorage<boolean>(entityName);

  return {
    isMenuClosed: value ?? defaultMenuState,
    setIsMenuClosed: setValue,
  };
};

export { useSidebar };
