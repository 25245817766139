import { List, Table, TableProps } from "@epcnetwork/core-ui-kit";

import { JobStatuses } from "types";
import { JobCountModel, JobModel } from "models";
import { JobFiltersQueryType } from "./jobs-list.types";
import {
  selectableTableColumns,
  statuses,
  TABLE_ENTITY_NAME,
  tableColumns,
} from "./jobs-list.constants";

export const getInitialActiveTab = (status?: JobFiltersQueryType["status"]): number => {
  let index = 0;
  if (status) {
    index = statuses[status];
  }
  return index;
};

export const getActiveStatus = (index: number): JobFiltersQueryType["status"] => {
  const statusesEntries = Object.entries(statuses) as [JobStatuses, number][];
  const status = statusesEntries.find(([, value]) => value === index);
  if (status) {
    return status[0];
  }
  return statusesEntries[0][0];
};

export const jobListTabs = (
  payload: List<JobModel> & JobCountModel,
  tableProps: TableProps<any>,
) => {
  const {
    all = 0,
    pending = 0,
    reconfigurationNeeded = 0,
    inProgress = 0,
    paused = 0,
    frozen = 0,
    autofrozen = 0,
    finished = 0,
    hidden = 0,
  } = payload || {};

  const isSelectable = payload?.data.some((item) => item.status === "finished");
  const columns = isSelectable ? selectableTableColumns : tableColumns;

  return [
    {
      tab: {
        name: `All Jobs (${all})`,
      },
      tabComponent: <Table {...tableProps} columns={columns} />,
    },
    {
      tab: {
        name: `Pending (${pending})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: {
        name: `Reconfiguration (${reconfigurationNeeded})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: {
        name: `In Progress (${inProgress})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: {
        name: `Paused (${paused})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: {
        name: `Frozen (${frozen})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: {
        name: `Autofrozen (${autofrozen})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: {
        name: `Finished (${finished})`,
      },
      tabComponent: (
        <Table {...tableProps} columns={columns} entityName={`${TABLE_ENTITY_NAME}-finished`} />
      ),
    },
    {
      tab: {
        name: `Hidden (${hidden})`,
      },
      tabComponent: <Table {...tableProps} columns={columns} />,
    },
  ];
};
