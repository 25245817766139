import { memo, useState } from "react";
import {
  Button,
  ExtractComponent,
  Modal,
  Search,
  Table,
  TableProps,
  Tabs,
  useDevice,
  useDidUpdate,
  useFetch,
  useFilters,
  usePagination,
  useWindowSize,
} from "@epcnetwork/core-ui-kit";

import { findKeyByValue, getInitialStorageFilters } from "utils";
import { TagModel } from "models";
import { useAuthorization, usePayload, useTablePagination } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { defaultOffset } from "constants/query.constants";
import { Container } from "components";
import { getTagsList } from "api";
import { TagsForm } from "../form";
import { TagsTableRow } from "./tags-table-row";
import { FiltersQueryType } from "./tags-list.types";
import {
  initialFilters,
  TABLE_ENTITY_NAME,
  tableColumns,
  tabStatusesMap,
} from "./tags-list.constants";

import styles from "./tags-list.module.css";

const TagsListPage = memo(() => {
  const ability = useAuthorization();

  const { width } = useWindowSize();
  const { isMobileDevice } = useDevice();
  const { query, queryString, searchValue, setSearch, updateQueryParams } =
    useFilters<FiltersQueryType>(
      getInitialStorageFilters<FiltersQueryType>(TABLE_ENTITY_NAME, initialFilters),
    );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(
    Number(findKeyByValue(tabStatusesMap, query.status)) || 0,
  );

  const tagsResponse = useFetch(getTagsList.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { list, payload } = usePayload(tagsResponse);

  const pagination = usePagination({ listPayload: payload });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_ENTITY_NAME,
  });

  const { total = 0, used = 0, unused = 0 } = payload || {};

  const onTabClick = (index: number) => {
    updateQueryParams({
      status: tabStatusesMap[index],
      offset: defaultOffset,
    });
    setActiveTab(index);
  };

  const handleAdd = () => {
    setEditId(null);
    setIsModalOpen(true);
  };

  const handleEdit = (id: number) => {
    setEditId(id);
    setIsModalOpen(true);
  };

  const onDeleteSuccess = () => {
    tagsResponse.refresh();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditId(null);
  };

  const handleItemChange = () => {
    handleCloseModal();
    tagsResponse.refresh();
  };

  useDidUpdate(() => {
    if (!query.status) {
      setActiveTab(0);
    }
  }, [queryString]);

  const tableProps: TableProps<TagModel[]> = {
    entityName: TABLE_ENTITY_NAME,
    columns: tableColumns,
    itemIdKey: "id",
    list,
    error: tagsResponse.error?.message,
    loading: tagsResponse.loading,
    refresh: tagsResponse.refresh,
    isTabTable: true,
    resetColumnsOnMount: false,
    pagination: {
      ...pagination,
      elementsPerPage: currentElementsPerPage,
      onElementsPerPageChange: handlePerPageChange,
    },
    row: (item, index) => (
      <TagsTableRow
        index={index}
        tag={item}
        handleEdit={handleEdit}
        onDeleteSuccess={onDeleteSuccess}
      />
    ),
  };

  const tabs: ExtractComponent<typeof Tabs>["tabs"] = [
    {
      tab: { name: `All Tags (${total})` },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: { name: `Used (${used})` },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: { name: `Unused (${unused})` },
      tabComponent: <Table {...tableProps} />,
    },
  ];

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <Container>
      <div className={styles.head}>
        <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
        {!isMobile && ability.can("create", "Jobs") && !isMobileDevice && (
          <Button className={styles.addTagButton} onClick={handleAdd}>
            + Add Tag
          </Button>
        )}
      </div>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
      <Modal isOpen={isModalOpen} setClose={handleCloseModal}>
        <TagsForm
          id={editId}
          handleSuccessCreate={handleItemChange}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </Container>
  );
});

export { TagsListPage };
