import { FC, useState } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  useCall,
  TextEllipsis,
  useWindowSize,
} from "@epcnetwork/core-ui-kit";

import { formatDate } from "utils";
import { UserModel } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { DELETE_ERROR_TEXT } from "constants/notification.constants";
import { timeFormat } from "constants/general.constants";
import { deleteUser } from "api";

import globalStyles from "assets/styles/global.module.css";
import clockImg from "assets/images/clock-grey.svg";
import styles from "./users-list-table-row.module.css";

type UsersListTableRowProps = {
  index: number;
  item: UserModel;
  handleEdit: (id: number) => void;
};

const UsersListTableRow: FC<UsersListTableRowProps> = ({ index, item, handleEdit }) => {
  const { id, email, role, groups, createdAt } = item;

  const ability = useAuthorization();
  const { width } = useWindowSize();
  const { submit, onCallSuccess, onCallError } = useCall(deleteUser);

  const [isDeleted, setDeleted] = useState(false);

  onCallSuccess(() => {
    notification.success("User deleted", "Successfully deleted user");
  });

  onCallError(() => {
    setDeleted(false);
    notification.error(DELETE_ERROR_TEXT, "Cannot delete user");
  });

  const handleRemoveItem = () => {
    notification.confirm("Deleting user?", "Are you sure?", {
      onOk: () => {
        setDeleted(true);
        submit({ params: { userId: id } });
      },
      icon: "delete",
    });
  };

  const handleEditItem = () => handleEdit(id);

  const date = new Date(createdAt);

  const deletedStyles = isDeleted ? globalStyles.deleteElement : "";

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <TableRow id={index} className={deletedStyles}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {email}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {role}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {groups?.map((group) => group.name)?.join(", ")}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.dateCell}>
        {createdAt && (
          <>
            {formatDate(date, "EEE, MMM dd, yyyy")}
            <img src={clockImg} alt="" />
            {formatDate(date, timeFormat)}
          </>
        )}
      </TableCell>
      <TableCell>
        {!isMobile && (
          <Menu>
            {ability.can("create", "Users") && (
              <MenuButton onClick={handleEditItem} icon="edit">
                Edit
              </MenuButton>
            )}
            {ability.can("delete", "Users") && (
              <MenuButton appearance="delete" onClick={handleRemoveItem} icon="delete">
                Delete
              </MenuButton>
            )}
            {!ability.can("create", "Users") && !ability.can("delete", "Users") && (
              <div style={{ padding: "10px" }}>No Actions available</div>
            )}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export { UsersListTableRow };
