import { FC, useState } from "react";
import { format } from "date-fns";
import cn from "classnames";

import { convertToTimezone } from "utils";
import { daysOnOneWeek } from "constants/general.constants";
import { DayUnitType } from "../../../days-allocation/days-allocation.types";

import styles from "./week.module.css";

type WeekProps = {
  startDate: string;
  endDate: string;
  totalWeekUsed: number;
  dayUnitsOnWeek: DayUnitType[];
  onDaySelect: (index: number) => void;
  weekIndex: number;
  selectedDayIndex: number;
};

const Week: FC<WeekProps> = ({
  startDate,
  endDate,
  totalWeekUsed,
  dayUnitsOnWeek,
  weekIndex,
  onDaySelect,
  selectedDayIndex,
}) => {
  const [collapsed, setCollapsed] = useState(!weekIndex);
  const dayListStyle = cn(styles.daysList, { [styles.collapsed]: collapsed });

  const getDayIndex = (dayIndex: number) => {
    return dayIndex + weekIndex * daysOnOneWeek;
  };

  const isDaySelectedOnCurrentWeek =
    Math.ceil((selectedDayIndex + 1) / daysOnOneWeek) === weekIndex + 1;

  const onDayClick = (index: number) => {
    onDaySelect(getDayIndex(index));
  };

  const weekStyles = cn(styles.week, { [styles.weekSelected]: isDaySelectedOnCurrentWeek });

  const getDayStyles = (dayIndex: number) => {
    return cn(styles.day, {
      [styles.selectedDay]: getDayIndex(dayIndex) === selectedDayIndex,
    });
  };

  return (
    <li className={weekStyles}>
      <h2 className={styles.weekTitle} onClick={() => setCollapsed(!collapsed)}>
        {startDate} - {endDate} ({totalWeekUsed})
      </h2>
      <ul className={dayListStyle}>
        {dayUnitsOnWeek.map(({ name, date, used }, dayIndex) => (
          <li
            key={name}
            onClick={() => {
              onDayClick(dayIndex);
            }}
            className={getDayStyles(dayIndex)}
          >
            <span className={styles.dayWeekName}>
              {format(convertToTimezone(new Date(date)), "E")}
            </span>{" "}
            {name} <span className={styles.usedValue}>({used})</span>
          </li>
        ))}
      </ul>
    </li>
  );
};

export { Week };
