import { ApiResponse, ApiStatusResponse, ApiErrorResponse } from "@epcnetwork/core-ui-kit";

type showContentDataType<T> = {
  payload: ApiResponse<T>;
  loading: boolean;
  error: ApiErrorResponse<T> | null;
  details: ApiStatusResponse;
};

type UseShowContentReturnType = {
  showContent: boolean;
  showNoContent: boolean;
  showError: boolean;
  showLoader: boolean;
  showPagination: boolean;
};

function useShowContent<T>({
  payload,
  loading,
  error,
  details,
}: showContentDataType<T>): UseShowContentReturnType {
  function checkPayloadData() {
    if (payload?.data) {
      return Boolean(payload.data.length);
    } else {
      return Boolean(payload);
    }
  }

  const hasPayload = checkPayloadData();
  const showLoader = loading;
  const showContent = !error && hasPayload;
  const showNoContent = !loading && !error && !hasPayload;
  const showError = Boolean(!loading && error && !details.isCanceled && !hasPayload);
  const showPagination = hasPayload && !showError && !showNoContent;

  return {
    showContent,
    showNoContent,
    showError,
    showLoader,
    showPagination,
  };
}

export { useShowContent };
