import { FC } from "react";

import { UsersListPage } from "pages/users/list";
import { TagsListPage } from "pages/tags/list";
import { JobListPage } from "pages/jobs/list";
import { JobForm } from "pages/jobs/form";
import { JobDetailsPage } from "pages/jobs/details";
import { FilesListPage } from "pages/files/list";
import { EmailsSuppressionFormPage } from "pages/emails-suppression/form";
import { Logout } from "pages/auth/logout";
import { LoginPage } from "pages/auth/login";
import { ForgotPassword, ResetPassword } from "pages/auth/forgot-password";
import { AllocationListPage } from "pages/allocation/list";
import { AllocationFormPage } from "pages/allocation/form";
import { MyAccountPage } from "pages/account/my-account";
import { NotFoundPage } from "pages/404";
import { AuthorizationEntityNameType, AuthorizationActionType } from "models";
import {
  LOGIN_PAGE,
  LOGOUT_PAGE,
  FORGOT_PASSWORD_PAGE,
  RESET_PASSWORD_PAGE,
  NOT_FOUND_PAGE,
  DEFAULT_PAGE,
  UPLOAD_LIST_PAGE,
  JOBS_FORM_PAGE,
  JOBS_FORM_EDIT_PAGE,
  ALLOCATION_PAGE,
  ALLOCATION_FORM_PAGE,
  JOBS_LIST_PAGE,
  JOBS_DETAILS_PAGE,
  USERS_LIST_PAGE,
  MY_ACCOUNT_PAGE,
  EMAILS_SUPPRESSION_LIST_PAGE,
  TAGS_LIST_PAGE,
  ALLOCATION_EDIT_PAGE,
  JOBS_FORM_RECONFIGURATION_PAGE,
} from "constants/routes.constants";

export interface RouteConfig {
  path: string;
  element: FC;
  name: string;
  exact: boolean;
  auth: boolean;
  showNavigation: boolean;
  authEntity: AuthorizationEntityNameType | null;
  authAction: AuthorizationActionType | null;
  pageNameTooltipText?: string;
}

export const routes: RouteConfig[] = [
  { ...DEFAULT_PAGE, element: FilesListPage },
  { ...UPLOAD_LIST_PAGE, element: FilesListPage },
  { ...TAGS_LIST_PAGE, element: TagsListPage },
  { ...JOBS_LIST_PAGE, element: JobListPage },
  { ...JOBS_FORM_PAGE, element: JobForm },
  { ...JOBS_DETAILS_PAGE, element: JobDetailsPage },
  { ...JOBS_FORM_EDIT_PAGE, element: JobForm },
  { ...JOBS_FORM_RECONFIGURATION_PAGE, element: AllocationFormPage },
  { ...ALLOCATION_PAGE, element: AllocationListPage },
  { ...ALLOCATION_FORM_PAGE, element: AllocationFormPage },
  { ...ALLOCATION_EDIT_PAGE, element: JobForm },
  { ...EMAILS_SUPPRESSION_LIST_PAGE, element: EmailsSuppressionFormPage },
  { ...USERS_LIST_PAGE, element: UsersListPage },
  { ...MY_ACCOUNT_PAGE, element: MyAccountPage },
  { ...LOGIN_PAGE, element: LoginPage },
  { ...LOGOUT_PAGE, element: Logout },
  { ...FORGOT_PASSWORD_PAGE, element: ForgotPassword },
  { ...RESET_PASSWORD_PAGE, element: ResetPassword },
  { ...NOT_FOUND_PAGE, element: NotFoundPage },
];
