import { EndpointType } from "./endpoint.types";
import { initialEndpointsProperties } from "./endpoint.constants";

export const createInitialEndpoint = (): EndpointType => ({
  name: "",
  webhookId: 0,
  proxyValue: "",
  proxyId: null,
  connector: {
    apiType: "restApi",
    properties: initialEndpointsProperties.restApi,
  },
});
