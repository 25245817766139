import { useFormikContext } from "formik";
import { notification } from "@epcnetwork/core-ui-kit";

import { getActiveJobName } from "../allocation-form.utils";
import { InitialValues } from "../allocation-form.types";
import { DaySpread } from "./days-allocation.types";

const formatTableDataByRows = (pastedInput: string) => {
  return (
    pastedInput
      .replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm, function (match, p1) {
        // This function runs for each cell with multi lined text.
        return (
          p1
            // Replace any double double-quotes with a single
            // double-quote
            .replace(/""/g, '"')
            // Replace all new lines with spaces.
            .replace(/\r\n|\n\r|\n|\r/g, " ")
        );
      })
      // Split each line into rows
      .split(/\r\n|\n\r|\n|\r/g)
  );
};

export const formatClipboardText = (text: string) =>
  formatTableDataByRows(text)
    .flatMap((item) => item.split("\t"))
    .map((item) => Number(item));

export type UpdateCalendarPatedDataType = {
  event: React.ClipboardEvent<HTMLInputElement> | ClipboardEvent;
  currentActiveItemName: string[];
  dateHoursSpread: DaySpread[];
  activeDayIndex?: number;
  setFieldValue: ReturnType<typeof useFormikContext<InitialValues>>["setFieldValue"];
};

const checkPasteValueValidity = (item: number) => {
  return isNaN(item) || !Number.isInteger(item) || (Number.isInteger(item) && item < 0);
};

export const updateCalendarPatedData = ({
  event,
  currentActiveItemName,
  dateHoursSpread,
  setFieldValue,
  activeDayIndex = 0,
}: UpdateCalendarPatedDataType) => {
  const pastedText =
    event.clipboardData?.getData("text") || event.clipboardData?.getData("text/plain");

  if (!pastedText || !pastedText.length) {
    return;
  }

  const pastedValues = formatClipboardText(pastedText);

  const isPastedValuesInvalid = pastedValues.some((item) => checkPasteValueValidity(item));

  if (isPastedValuesInvalid) {
    notification.error("Invalid data", "Please re-check copied data");
    return;
  }

  const currentActiveName = getActiveJobName(currentActiveItemName);
  const spread = dateHoursSpread.map((currentJob) => {
    // we need to find job in which we will change days values
    if (currentJob.name === currentActiveName) {
      let currentJobUsed = currentJob.used;
      let currentJobRemaining = currentJob.remaining;
      // we always need to start from a first element in the clipboard array
      let startPatedIndex = 0;

      const dayUnits = currentJob.dayUnits.map((item, index) => {
        let value;
        let remaining;
        let used;

        // if the day should be updated with value from clipboard
        if (index >= activeDayIndex && index < pastedValues.length + activeDayIndex) {
          value = pastedValues[startPatedIndex];
          remaining = pastedValues[startPatedIndex];
          used = 0;
          currentJobUsed = currentJobUsed - item.value + value;
          currentJobRemaining = currentJobRemaining + item.value - value;
          startPatedIndex++;
        } else {
          value = item.value;
          remaining = item.remaining;
          used = item.used;
        }

        return {
          ...item,
          value,
          used,
          remaining,
        };
      });

      return {
        ...currentJob,
        used: currentJobUsed,
        remaining: currentJobRemaining,
        dayUnits,
      };
    }

    return currentJob;
  });

  setFieldValue("dateHoursSpread", spread);
};
