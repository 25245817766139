import { FC } from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import { ConfirmMessageProps } from "../send-email.types";

import letter from "assets/images/letter.svg";
import styles from "../../send-email/send-email.module.css";

const subtitle =
  "Didn’t receive the email? Please check the email address you used to make sure it matches the address on your account, look in your spam folder, or request another email below.";

const ConfirmMessage: FC<ConfirmMessageProps> = ({ handleSent }: ConfirmMessageProps) => {
  return (
    <div className={styles.resend}>
      <div className={styles.title}>
        Recovery email sent
        <img className={styles.titleImage} src={letter} alt="recovery mail" />
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
      <Button btnSize="medium" className={styles.btn} onClick={() => handleSent(false)}>
        Send again
      </Button>
    </div>
  );
};

export { ConfirmMessage };
