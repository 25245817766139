import { CSSProperties, FC, memo } from "react";
import cn from "classnames";
import { useWindowSize } from "@epcnetwork/core-ui-kit";

import { mobileWidth } from "constants/general.constants";
import { collapsedDesktopWidth, sidebarWidth } from "../sidebar/sidebar.constants";
import { Sidebar } from "../sidebar/sidebar";
import { Navbar } from "../navbar";
import { useSidebar } from "./use-sidebar.hook";

import styles from "./navigation.module.css";

type NavigationProps = {
  element: FC;
  showNavigation: boolean;
};

const Navigation = memo<NavigationProps>(({ element: Component, showNavigation, ...rest }) => {
  const { isMenuClosed, setIsMenuClosed } = useSidebar();
  const { width: windowWidth } = useWindowSize();

  const isMobile = windowWidth <= mobileWidth;

  const toggleSidebar = () => {
    setIsMenuClosed(!isMenuClosed);
  };

  const handleSidebarCollapsed = (value: boolean) => setIsMenuClosed(value);

  const contentStyles = cn(styles.content, { [styles.closed]: isMenuClosed });

  if (!showNavigation) {
    return <Component {...rest} />;
  }

  const width = isMenuClosed && !isMobile ? collapsedDesktopWidth : sidebarWidth;

  const inlineStyles: CSSProperties = {
    maxWidth: `calc(100vw - ${width})`,
  };

  return (
    <div className={styles.container} data-testid="app">
      <Sidebar
        collapsed={isMenuClosed}
        isMobile={isMobile}
        handleSidebarCollapsed={handleSidebarCollapsed}
        toggleSidebar={toggleSidebar}
      />
      <div className={contentStyles} data-testid="content" style={inlineStyles}>
        <Navbar toggleSidebar={toggleSidebar} isMenuClosed={isMenuClosed} />
        <Component {...rest} />
      </div>
    </div>
  );
});

export { Navigation };
