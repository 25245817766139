import { List } from "@epcnetwork/core-ui-kit";

import { TagModel, TagShortModel, TagCountModel } from "models";
import { apiMiddleware } from "api";
import { TagFormData } from "./tags.interface";

export const getTagsShort = apiMiddleware<TagShortModel[]>()({
  method: "get",
  endpoint: "/tag/names",
});

export const getTagsList = apiMiddleware<List<TagModel> & TagCountModel>()({
  method: "get",
  endpoint: "/tag/list",
});

export const getTag = apiMiddleware<TagModel>()({
  method: "get",
  endpoint: `/tag/:tagId`,
});

export const postTag = apiMiddleware<TagModel, TagFormData>()({
  method: "post",
  endpoint: "/tag",
});

export const patchTag = apiMiddleware<TagModel, TagFormData>()({
  method: "post",
  endpoint: `/tag/:tagId`,
});

export const deleteTag = apiMiddleware()({
  method: "delete",
  endpoint: `/tag/:tagId`,
});
