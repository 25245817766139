import { readString } from "react-papaparse";
import { FC, useCallback, useState } from "react";
import { ParseResult } from "papaparse";
import cn from "classnames";
import {
  Button,
  Select,
  Switch,
  MessageField,
  FileItem,
  FileUpload,
  useDidMount,
  useDidUpdate,
  useWillUnmount,
  useFileQueue,
  arrayOf,
  isDeepEmpty,
  bytesConverter,
  CoreKitFilesReader,
  SelectOption,
  DropAcceptedFunc,
  useAutocomplete,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { capitalize } from "utils";
import { CANCEL_TEXT } from "constants/notification.constants";
import { getSuppressionList } from "api";
import {
  supportedFormats,
  fileUploadTitleText,
  listTypesSelectLabelText,
  essentialColumn,
} from "../files-list.constants";
import {
  createInitialArray,
  formatHeaderValue,
  isFileHasValidHeaders,
} from "./file-uploading.utils";
import { ErrorReasons, FileItemModalProps } from "./file-upload.types";
import { defaultOption, selectInitialOptions, initialErrorStatus } from "./file-upload.constants";

import globalStyles from "assets/styles/global.module.css";
import styles from "./file-upload.module.css";

const FileItemModal: FC<FileItemModalProps> = ({ fileItem, onCancelClick, onSubmitClick }) => {
  const { originalFile, data } = fileItem;
  const { suppressionList, headers, fileParsedContent } = data;

  const [columnsAmount, setColumnsAmount] = useState(0);
  const [hasHeaders, setHasHeaders] = useState(!!headers.length);
  const [headersArr, setHeadersArr] = useState(headers);
  const [isOptionCreated, setIsOptionCreated] = useState(false);
  const [errorStatus, setErrorStatus] = useState(initialErrorStatus);

  const [listTypes, setListTypes] = useState<SelectOption[]>([]);
  const [selectOptions, setSelectOptions] = useState(selectInitialOptions);

  const suppressionListAutocomplete = useAutocomplete(getSuppressionList, "id", "name");

  const formattedSuppressionListAutocomplete = {
    ...suppressionListAutocomplete,
    fetchOptions: suppressionListAutocomplete.fetchOptions.map((fetchOption) => {
      const { emailsNum } = fetchOption;
      const emailsCounter = `(${emailsNum} ${+emailsNum === 1 ? "email" : "emails"})`;

      return {
        ...fetchOption,
        label: `${fetchOption.name} ${emailsCounter}`,
      };
    }),
  };

  useDidUpdate(() => {
    !hasHeaders && isFileHasValidHeaders(fileParsedContent) && handleSwitchToggle("", true);
  }, [fileParsedContent, columnsAmount]);

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    deleteEntity,
    hasInvalidFile,
  } = useFileQueue("file-suppression", undefined, suppressionList);

  useWillUnmount(deleteEntity);

  useDidMount(() => {
    const lengthMap = fileParsedContent.map((strArr) => strArr.map(Boolean).filter(Boolean).length);

    const length = Math.max(...lengthMap);

    setColumnsAmount(length);

    if (headers.length) return;

    setHeadersArr(createInitialArray(length));
  });

  useDidUpdate(() => {
    const emailColumnCondition = headersArr.find(
      (option) => option.value === essentialColumn.toLowerCase(),
    );

    const isError = !emailColumnCondition;
    const getReason = (): ErrorReasons => {
      if (isError) return "email missing";
      return "initial";
    };

    const reason = getReason();

    setErrorStatus({ isError, reason });
  }, [headersArr, columnsAmount, selectOptions]);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      const entityFiles = acceptedFiles.map((file) => createInitialFile(file, { isLoading: true }));

      new CoreKitFilesReader(entityFiles)
        .onSuccess(({ id, result }) => {
          readString(result, {
            preview: 6,
            worker: true,
            complete: function (parsedResult: ParseResult<string[]>) {
              const parsedFileData = parsedResult.data || [];

              const isArrErr = !Array.isArray(parsedFileData);
              const parsingError = isArrErr && parsedResult.errors?.[0];
              const hasError = parsingError || isArrErr;

              if (!hasError && Array.isArray(parsedFileData) && !isDeepEmpty(parsedFileData)) {
                updateFiles({
                  id,
                  file: {
                    isLoading: false,
                    parsed: "valid",
                    data: { fileParsedContent: parsedFileData },
                    isTuned: true,
                  },
                });
              } else {
                updateFiles({
                  id,
                  file: {
                    isLoading: false,
                    parsed: "invalid",
                    error: "File is invalid",
                    data: { fileParsedContent: [] },
                  },
                });
              }
            },
          });
        })
        .read();

      addFiles(entityFiles);
    },
    [addFiles, createInitialFile, updateFiles],
  );

  const onCreateOption = (value: string): SelectOption<string> => {
    const optionValue = value;
    const option: SelectOption<string> = {
      value: formatHeaderValue(optionValue),
      label: capitalize(optionValue),
    };
    setSelectOptions((prevState) => [...prevState, option]);
    return option;
  };

  const handleCreateNewOption = (selectIndex: number) => (value: string) => {
    const option: SelectOption<string> = onCreateOption(value);
    setIsOptionCreated(true);
    handleSelectChange(selectIndex)(option);
  };

  const handleSwitchToggle = (_: string, isChecked: boolean) => {
    setHasHeaders(isChecked);
  };

  // logic for setup in selects headers from file
  useDidUpdate(() => {
    if (!isOptionCreated && !headers.length) {
      const newHeaders = headersArr.map((_, index) => {
        const firstRow = formatHeaderValue(fileParsedContent[0][index]);
        return (
          selectOptions.find((item) => {
            return item.value === firstRow;
          }) ?? defaultOption
        );
      });
      setHeadersArr(newHeaders);
    }
  }, [selectOptions, hasHeaders, isOptionCreated]);

  // logic for creation new options
  useDidUpdate(
    () => {
      if (hasHeaders) {
        const firstRow = fileParsedContent[0];
        firstRow.forEach((rowName) => {
          const item = selectOptions.find((item) => item.value === formatHeaderValue(rowName));
          if (!item && rowName.length) {
            onCreateOption(rowName);
          }
        });

        return;
      }

      columnsAmount && setHeadersArr(createInitialArray(columnsAmount));
    },
    [hasHeaders],
    true,
  );

  useDidMount(() => {
    if (hasHeaders && headersArr.length) {
      headersArr.forEach((header) => {
        const item = selectOptions.find((item) => item.value === header.value);
        if (!item && header.value) {
          onCreateOption(header.value);
        }
      });
    }
  });

  const handleSubmitClick = () => {
    onSubmitClick({
      ...fileItem,
      isTuned: true,
      data: {
        ...fileItem.data,
        emailColumn: "email",
        hasHeaders,
        headers: headersArr,
        suppressionList: files,
        listIds: listTypes.map((item) => item.value as string),
      },
    });
  };

  const handleSelectChange = (selectColumnId: number) => (option: SelectOption<string>) => {
    const headersArrToSet = headersArr.map((header, index) => {
      if (index !== selectColumnId) return header;
      return option ?? defaultOption;
    });
    setHeadersArr(headersArrToSet);
  };

  const handleSuppressionList = (options: SelectOption[]) => {
    setListTypes(options);
  };

  const hasEmailSelected = !!headersArr.find(
    ({ value }) => value.toLowerCase() === essentialColumn.toLowerCase(),
  );

  return (
    <div className={styles.itemModal}>
      <div className={styles.itemModalHeader}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          Set values for&nbsp; {originalFile.name}
        </TextEllipsis>
      </div>
      <div className={styles.itemModalContentWrap}>
        <div className={styles.itemModalTableHeaderWrap}>
          <div className={cn(styles.itemModalTableHeaderHint, styles.itemModalWrapTitle)}>
            Set column(s) data type(s)
          </div>
          <div className={styles.itemModalTableHasHeaderWrap}>
            Has header
            <Switch
              checked={hasHeaders}
              value="has-headers"
              onChange={handleSwitchToggle}
              inputSize="small"
              disableError
            />
            <span>{hasHeaders ? "Yes" : "No"}</span>
          </div>
        </div>
        {columnsAmount > 1 && !hasHeaders && (
          <MessageField
            status="info"
            message="Your file has more than 1 column, please define all of them"
          />
        )}
        <div className={cn(styles.itemModalContentGridContainer, styles.hasItems)}>
          <div className={cn(styles.itemModalContentGrid, globalStyles.addScrollStyles)}>
            <div className={cn(styles.itemModalGridRow, styles.itemModalGridSelectsRow)}>
              {arrayOf(columnsAmount, (i) => {
                const selectValue = headersArr[i].value;

                const optionsToShow = selectOptions.filter(({ value }) => {
                  if (selectValue.toLowerCase() === essentialColumn.toLowerCase()) return true;
                  return (
                    (hasEmailSelected && value.toLowerCase() !== essentialColumn.toLowerCase()) ||
                    !hasEmailSelected
                  );
                });

                return (
                  <div
                    key={i}
                    className={cn(styles.itemModalGridCell, styles.itemModalGridSelectCell)}
                  >
                    <Select
                      placeholder="Column name"
                      inputSize="small"
                      options={optionsToShow}
                      initiallySelectedOptions={headersArr[i]}
                      selectedOptionsKeys={selectValue}
                      onChange={handleSelectChange(i)}
                      onCreateNewOption={handleCreateNewOption(i)}
                      showFullNewOption={false}
                      disableArrow
                      disableError
                      isTextEditable
                    />
                  </div>
                );
              })}
            </div>
            {fileParsedContent.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className={cn(styles.itemModalGridRow, {
                  [styles.headerRow]: !rowIndex && hasHeaders,
                })}
              >
                {row.map((column, columnIndex) => (
                  <div className={styles.itemModalGridCell} key={columnIndex}>
                    <span className={globalStyles.applySingleOverflow}>{column}</span>
                  </div>
                ))}
              </div>
            ))}
            <div className={styles.gridScrollBGRow} />
          </div>
        </div>
      </div>
      {errorStatus.isError && errorStatus.reason === "email missing" && (
        <div className={styles.itemModalMessageFieldWrap}>
          <MessageField
            message={`${essentialColumn} column is missing, please provide it from select options`}
          />
        </div>
      )}
      <div className={styles.itemModalSuppressionWrap}>
        <div className={styles.itemModalWrapTitle}>{fileUploadTitleText}</div>
        <FileUpload
          className={cn(styles.dropZone, styles.override)}
          uploadedFilesLength={files.length}
          subtitle="Accepted csv files up to 30Mb"
          accept={supportedFormats}
          maxFiles={5}
          maxSize={bytesConverter(30, "Bytes", "MB")}
          onDropAccepted={handleAcceptedDrop}
          multiple
        />
        {files.map(({ id, originalFile, ...rest }) => (
          <FileItem {...rest} key={id} id={id} file={originalFile} onCrossClick={removeFiles} />
        ))}
      </div>
      <div>
        <Select
          placeholder="Select from the list"
          label={listTypesSelectLabelText}
          inputSize="small"
          selectedOptionsKeys={fileItem.data.listIds}
          disableFloatingLabel
          asyncOptions={formattedSuppressionListAutocomplete}
          onChange={handleSuppressionList}
          disableError
          isMulti
          className={styles.listTypes}
        />
        <span className={styles.listsInfo}>Global list is always included.</span>
      </div>
      <div className={styles.itemModalActionsWrap}>
        <Button appearance="secondary" onClick={onCancelClick}>
          {CANCEL_TEXT}
        </Button>
        <Button
          appearance="primary"
          onClick={handleSubmitClick}
          disabled={errorStatus.isError || hasInvalidFile}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export { FileItemModal };
