import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { FC, useMemo } from "react";
import cn from "classnames";

import "react-circular-progressbar/dist/styles.css";
import { ReactComponent as AutoFrozenIcon } from "assets/images/stop-refresh-circle-icon.svg";
import { ReactComponent as ManualFrozenIcon } from "assets/images/stop-circle-icon.svg";
import { ReactComponent as PauseIcon } from "assets/images/pause-icon.svg";
import { ReactComponent as DoneIcon } from "assets/images/checkmark-green-thin.svg";
import styles from "./circular-progress.module.css";

type CircularProgressProps = {
  value: number;
  isPaused?: boolean;
  isManualFrozen?: boolean;
  isAutoFrozen?: boolean;
  className?: string;
  isFinished?: boolean;
};

const CircularProgress: FC<CircularProgressProps> = ({
  className,
  value,
  isPaused,
  isManualFrozen,
  isAutoFrozen,
  isFinished,
}) => {
  const isDone = Boolean(isFinished);

  const circleStyles = useMemo(() => getStyles(isDone, isPaused), [isDone, isPaused]);

  const percentage = Math.round(value * 100);

  return (
    <div className={cn(styles.container, className)}>
      {!isManualFrozen && !isAutoFrozen && !isPaused && !isDone && (
        <CircularProgressbarWithChildren
          value={value}
          maxValue={1}
          styles={circleStyles}
          strokeWidth={6}
        >
          <div className={styles.text}>
            {percentage}
            <span>%</span>
          </div>
        </CircularProgressbarWithChildren>
      )}

      {isManualFrozen && !isDone && <ManualFrozenIcon />}

      {isAutoFrozen && !isDone && <AutoFrozenIcon />}

      {isPaused && !isDone && <PauseIcon />}

      {isDone && <DoneIcon />}
    </div>
  );
};

export { CircularProgress };

const getStyles = (isDone: boolean, isPaused?: boolean) =>
  buildStyles({
    strokeLinecap: "round",
    textSize: "10px",
    pathTransitionDuration: 0.5,
    pathColor: isDone ? "#59AD78" : isPaused ? "#DB9A2E" : "#1A56C5",
    trailColor: isDone
      ? "rgba(89, 173, 120, 0.15)"
      : isPaused
        ? "rgba(219, 154, 46, 0.15)"
        : "rgba(26, 86, 197,0.15)",
  });
