import { Bar, ComposedChart, Line, ResponsiveContainer, XAxis } from "recharts";
import { FC } from "react";
import format from "date-fns/format";

import { AllocationUnitType } from "../allocation-unit/allocation-unit";
import { headerText, maxTickCount } from "./allocation-chart.constants";

import styles from "./allocation-chart.module.css";

type AllocationChartProps = {
  data: Array<Omit<AllocationUnitType, "date"> & { date: Date }>;
  dataKey?: string;
};

const AllocationChart: FC<AllocationChartProps> = ({ data, dataKey = "value" }) => {
  const startDate = format(data[0].date, "dd MMM");
  const endDate = format(data[data.length - 1].date, "dd MMM");
  const datePeriod = endDate !== startDate ? `${startDate} \u2013 ${endDate}` : startDate;

  const interval = data.length <= maxTickCount ? 0 : 2;

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartHeader}>
        {headerText} {datePeriod}
      </div>
      <div className={styles.graphWrapper}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data} margin={{ top: 2, left: 0, right: 0 }}>
            <Bar
              stroke="var(--table-row-selected)"
              dataKey={dataKey}
              barSize={24}
              radius={[3, 3, 0, 0]}
            />
            <Line
              type="monotoneX"
              strokeWidth={2}
              stroke="var(--palette-primary)"
              dataKey={dataKey}
              dot={false}
            />
            <XAxis
              padding={{ left: 15, right: 5 }}
              interval={interval}
              tickLine={false}
              tickMargin={10}
              dataKey="name"
              strokeWidth={2}
              stroke="var(--palette-primary-disabled)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export { AllocationChart };
