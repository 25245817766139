import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "@epcnetwork/core-ui-kit";

export type AllocatedBatchesIds = Nullable<Set<number>>;

type InitialState = {
  isJobDataNeeded: boolean;
  allocatedBatchesIds: AllocatedBatchesIds;
};

export const initialJobsState: InitialState = {
  isJobDataNeeded: true,
  allocatedBatchesIds: null,
};

const jobs = createSlice({
  name: "jobs",
  initialState: initialJobsState,
  reducers: {
    setIsJobDataNeeded: (state, action: PayloadAction<boolean>) => {
      state.isJobDataNeeded = action.payload;
    },
    setAllocatedBatchesIds: (state, action: PayloadAction<AllocatedBatchesIds>) => {
      state.allocatedBatchesIds = action.payload;
    },
  },
});

export const { setIsJobDataNeeded, setAllocatedBatchesIds } = jobs.actions;

export default jobs.reducer;
