import React, { useMemo, useRef } from "react";
import { Search, Table, TableContentRef, useDebounce, useDidMount } from "@epcnetwork/core-ui-kit";

import { AvailableFileModel } from "models";
import { useInfiniteScrollFetch } from "hooks/use-infinite-scroll-fetch";
import { getAvailableUsedFiles } from "api";
import { tableColumns } from "../attach-file.constants";
import { AttachFileTableRow } from "../attach-file-table-row/attach-file-table-row";

import globalStyles from "assets/styles/global.module.css";
import styles from "../attach-file.module.css";

interface Props {
  getSelectionIndex: (item: AvailableFileModel) => number;
  selectedItems: AvailableFileModel[];
  handleSelect: (items: AvailableFileModel[]) => void;
}

export const UsedFilesTable: React.FC<Props> = ({
  getSelectionIndex,
  selectedItems,
  handleSelect,
}) => {
  const tableContentRef = useRef<TableContentRef>(null);

  const { debounce } = useDebounce(250);

  const { loading, list, searchValue, setSearchValue, refresh, error } = useInfiniteScrollFetch<
    typeof tableContentRef,
    AvailableFileModel,
    typeof getAvailableUsedFiles
  >({
    containerRef: tableContentRef,
    asyncApiCall: getAvailableUsedFiles,
    searchDebounce: 0,
  });

  const handleSearchChange = (value: string) => {
    debounce(() => {
      selectedItems.length && handleSelect([]);
      setSearchValue(value);
    });
  };

  return (
    <>
      <div className={styles.attachFileModalControlsWrapper}>
        <Search
          className={styles.attachFileModalSearch}
          searchValue={searchValue}
          setSearch={handleSearchChange}
        />
      </div>
      <div className={`${styles.attachFileModalTableWrap} ${globalStyles.addScrollStyles}`}>
        <Table
          entityName="job-create-attach-file-modal"
          contentClassName={styles.attachFileModalTable}
          columns={tableColumns}
          list={list}
          refresh={refresh}
          loading={loading}
          multiSelect={true}
          error={error?.message}
          itemIdKey="id"
          row={(item) => <AttachFileTableRow item={item} index={getSelectionIndex(item)} />}
          onSelect={handleSelect}
          selectedList={selectedItems}
          sharedTableContentRef={tableContentRef}
        />
      </div>
    </>
  );
};
