import { object, SchemaOf, string, mixed } from "yup";
import { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import cn from "classnames";
import {
  CodeInputValue,
  FormField,
  useDebounce,
  useDidMount,
  MessageField,
} from "@epcnetwork/core-ui-kit";

import { SearchParamsMap, testJSONValidity } from "utils";
import { convertParamsToUrls, formatSearchParamsToMap, isValidHttpUrl } from "utils";
import {
  HttpMethodTypes,
  HttpMethodTypesKeys,
  BodyTypes,
  BodyTypesKeys,
  SelectOption,
} from "models";
import { requiredFieldText } from "constants/form.constants";
import { InitialValues } from "../../jobs-form.types";
import { convertMapInPreviewContent, isValueVariable } from "./rest-api.utils";

import styles from "./rest-api.module.css";

const whenConditionArr: string[] = [HttpMethodTypes.POST, HttpMethodTypes.PUT];

export const restAPISchema: SchemaOf<RestAPIType> = object({
  httpMethod: string().required(requiredFieldText).typeError(requiredFieldText),
  targetUrl: string().required(requiredFieldText),
  bodyType: string().when("httpMethod", (httpMethodOptions: HttpMethodTypesKeys) => {
    if (whenConditionArr.includes(httpMethodOptions)) {
      return string().typeError(requiredFieldText).required(requiredFieldText);
    }

    return mixed().nullable();
  }),
  custom_attributes: mixed().when("httpMethod", (httpMethodOptions: HttpMethodTypesKeys) => {
    if (whenConditionArr.includes(httpMethodOptions)) {
      return mixed()
        .required(requiredFieldText)
        .test("json", "JSON is invalid", (value) => testJSONValidity(value));
    }

    return mixed().nullable();
  }),
  requestHeaders: mixed().test("json", "JSON is invalid", (value) =>
    typeof value !== "undefined" ? testJSONValidity(value) : true,
  ),
}) as SchemaOf<RestAPIType>;

type HTTPMethodType = SelectOption<HttpMethodTypesKeys>;

type BodyType = SelectOption<BodyTypesKeys>;

export type RestAPIType = {
  httpMethod: HttpMethodTypesKeys;
  targetUrl: string;
  bodyType?: BodyTypesKeys;
  custom_attributes?: string;
  requestHeaders?: string;
};

type RestApiProps = {
  endpointOrdinal: number;
  handleDisableSubmit: (valid: boolean) => void;
};

const httpMethodOptions: HTTPMethodType[] = Object.entries(HttpMethodTypes).map(([key, value]) => ({
  value: key as HttpMethodTypesKeys,
  label: value,
}));

const bodyTypeOptions: BodyType[] = Object.entries(BodyTypes).map(([key, value]) => ({
  value: key as BodyTypesKeys,
  label: value,
}));

const RestApi: FC<RestApiProps> = ({ endpointOrdinal, handleDisableSubmit }) => {
  const { values, setFieldValue, setErrors } = useFormikContext<InitialValues>();
  const [urlPreview, setUrlPreview] = useState<SearchParamsMap | null>(null);
  const [filesErrorMessage, setFilesErrorMessage] = useState("");
  const restApiValues = values.endpoints[endpointOrdinal].connector
    .properties as RestAPIType | null;

  useDidMount(() => {
    const { httpMethod, bodyType } = restApiValues || {};

    !httpMethod &&
      setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.httpMethod`, "");
    !bodyType && setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.bodyType`, "");
  });

  const handleHttpChange = () => {
    setErrors({});
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`, "");
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.requestHeaders`, "");
  };

  const { debounce } = useDebounce(1000);

  const handleUrlChange = (value: CodeInputValue | undefined) => {
    debounce(() => {
      if (typeof value === "string" && value.length && isValidHttpUrl(value)) {
        const { searchParams } = new URL(value);
        const searchMap = formatSearchParamsToMap(searchParams);
        const urls = convertParamsToUrls(searchMap);

        setUrlPreview(urls);
      } else {
        setUrlPreview(null);
      }
    });
  };

  const isOpen = whenConditionArr.includes(restApiValues?.httpMethod || "");

  useEffect(() => {
    if (urlPreview) {
      const attachedFiles = values.attachedFiles;

      // generate an array with missed fields (important to have all elements)
      const keysMissedWithUrls: string[][] = [];
      for (const file of attachedFiles) {
        let keysPerFiles: string[] = [];
        let urlNestedKey = "";
        for (const [key, value] of urlPreview) {
          // push first level missed keys with dynamic values
          if (typeof value === "string" && isValueVariable(value, file)) {
            const dynamicValue = value.substring(2, value.length - 2);
            keysPerFiles.push(dynamicValue);
          }

          if (key.includes("=")) {
            urlNestedKey = key;
          }
        }

        const urlNestedMap = urlPreview.get(urlNestedKey);
        if (urlNestedMap) {
          for (const [key, value] of urlNestedMap) {
            // push second level missed keys with dynamic values
            if (typeof value === "string" && isValueVariable(value, file)) {
              const dynamicValue = value.substring(2, value.length - 2);
              keysPerFiles.push(dynamicValue);
            }
          }
        }
        keysMissedWithUrls.push(keysPerFiles);
        keysPerFiles = [];
      }

      // map keys with urls
      const keysMissed = keysMissedWithUrls.map((keys) => keys.map((key) => key.split("=")[0]));

      // generate an array with errors messages to show user
      const errorFiles: string[][] = [];
      for (let index = 0; index < attachedFiles.length; index++) {
        const file = attachedFiles[index];
        if (keysMissed[index].length > 0) {
          errorFiles.push([file.fileName, keysMissed[index].join(", ")]);
        }
      }

      if (errorFiles.length > 0) {
        const errorText = errorFiles.reduce((acc, [file, keys]) => {
          return acc + `${file} has missed columns: ${keys}\n`;
        }, "");
        handleDisableSubmit(true);
        setFilesErrorMessage(errorText);
      } else {
        handleDisableSubmit(false);
        setFilesErrorMessage("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPreview, values.attachedFiles]);

  useEffect(() => {
    if (urlPreview === null) {
      handleDisableSubmit(false);
      setFilesErrorMessage("");
    }
  }, [handleDisableSubmit, urlPreview]);

  return (
    <>
      <FormField
        type="select"
        name={`endpoints[${endpointOrdinal}].connector.properties.httpMethod`}
        label="HTTP Method"
        placeholder="HTTP Method"
        options={httpMethodOptions}
        onFieldChange={handleHttpChange}
      />
      <FormField
        type="code"
        name={`endpoints[${endpointOrdinal}].connector.properties.targetUrl`}
        label="Target URL"
        onFieldChange={handleUrlChange}
        className={cn(styles.targetUrlField, styles.targetUrlLabel)}
        inputClassName="inputclassname"
        options={{ mode: "http", autoCloseBrackets: true, lineNumbers: false }}
      />
      {filesErrorMessage && (
        <MessageField
          className={styles.filesErrorMessage}
          message={filesErrorMessage}
          align="left"
        />
      )}
      {urlPreview && (
        <div className={styles.urlPreviewWrapper}>
          <div className={styles.urlPreviewTitle}>Target URL Params structure</div>
          {convertMapInPreviewContent(urlPreview)}
        </div>
      )}
      {isOpen && (
        <>
          <FormField
            type="select"
            name={`endpoints[${endpointOrdinal}].connector.properties.bodyType`}
            label="Body Type"
            placeholder="Body Type"
            options={bodyTypeOptions}
          />
          <FormField
            key={restApiValues?.httpMethod}
            type="code"
            name={`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`}
            label="JSON Body:"
            className={styles.headersLabel}
          />
        </>
      )}
      <FormField
        type="code"
        name={`endpoints[${endpointOrdinal}].connector.properties.requestHeaders`}
        label="JSON Headers (optional):"
        className={styles.headersLabel}
      />
    </>
  );
};

export { RestApi };
