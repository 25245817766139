import { FC, useState } from "react";
import { useFormikContext } from "formik";
import cn from "classnames";
import { Checkbox, useFormContext } from "@epcnetwork/core-ui-kit";

import { useAuthorization } from "hooks";
import { RoleEntitiesType, RoleFormValues } from "../roles-form";

import { ReactComponent as CollapseArrowIcon } from "assets/images/collapse-arrow.svg";
import styles from "../roles-form.module.css";

type HeadRowProps = {
  entity: RoleEntitiesType;
  disabled?: boolean;
  initiallyCollapsed: boolean;
};

const HeadRow: FC<HeadRowProps> = ({ entity, disabled, initiallyCollapsed }) => {
  const ability = useAuthorization();

  const { values, setFieldValue } = useFormikContext<RoleFormValues>();
  const { permission } = entity;
  const [{ isDisabled }] = useFormContext();

  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);

  const permissions = [permission.create, permission.read, permission.delete];

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleSelect = (checked: boolean) => {
    if (checked) {
      setFieldValue("permissions", [...new Set([...values.permissions, ...permissions])]);
      setFieldValue(permission.create, true);
      setFieldValue(permission.read, true);
      setFieldValue(permission.delete, true);
    } else {
      const newPermissions = values.permissions.filter(
        (permission) => !permissions.includes(permission),
      );
      setFieldValue("permissions", newPermissions);
      setFieldValue(permission.create, false);
      setFieldValue(permission.read, false);
      setFieldValue(permission.delete, false);
    }
  };

  const isSelected =
    permissions.filter((permission) => values.permissions.includes(permission)).length ===
    permissions.length;

  return (
    <button className={styles.headSection} type="button" onClick={toggleCollapse}>
      <CollapseArrowIcon
        className={cn(styles.collapseIcon, { [`${styles.isCollapsed}`]: isCollapsed })}
      />
      <div className={styles.entityName}>{entity.name}</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          value={entity.permissionAll}
          checked={isSelected}
          disabled={isDisabled || disabled || ability.cannot("create", "Users")}
          placement="left"
          inputSize="big"
          label="Full access"
          onChange={() => handleSelect(!isSelected)}
          disableError
        />
      </div>
    </button>
  );
};

export { HeadRow };
