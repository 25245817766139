import { useSelector } from "react-redux";
import { FC, useState } from "react";
import {
  Button,
  MessageField,
  useFormValues,
  useSubmit,
  Form,
  FormButtons,
  FormField,
  useAutocomplete,
} from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { UserModel, NonNullableKeys } from "models";
import { CANCEL_TEXT } from "constants/notification.constants";
import { postUser, putUser, getUser, getRoles, getGroups } from "api";
import { Values, NonNullableSubmitValues } from "./users-form.types";
import { validationSchema, initialData } from "./users-form.constants";

import styles from "./users-form.module.css";

type UsersFormProps = {
  id: number | null;
  handleItemChange: (value: UserModel) => void;
  handleCloseModal: VoidFunction;
};

const UsersForm: FC<UsersFormProps> = ({ id, handleItemChange, handleCloseModal }) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [error, setError] = useState("");

  const { formProps, mapInitialValues, payload } = useFormValues(
    initialData,
    getUser.setParams({ userId: id as number }),
    !!id,
  );

  mapInitialValues((payload) => {
    return {
      email: payload.email,
      password: "",
      passwordConfirm: "",
      role_id: payload.roleId,
      groups_ids: payload.groups.map(({ id }) => id),
    } as Values;
  });

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postUser,
    putUser.setParams({ userId: id as number }),
    !!id,
  );
  onSubmitSuccess((payload) => handleItemChange(payload));
  onSubmitError((error) => {
    setError(error.message);
  });

  const rolesAutocomplete = useAutocomplete(getRoles, "id", "name");
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");

  const onSubmit = onSubmitMapping((values: NonNullableKeys<Values>) => {
    const { passwordConfirm, ...submitValues } = values;
    const data: NonNullableSubmitValues = {
      ...submitValues,
      role_id: values.role_id,
      groups_ids: values.groups_ids,
    };

    if (id) {
      delete data.password;
    }

    return data;
  });

  return (
    <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema(id)}>
      <div className={styles.title}>User Form</div>
      <MessageField message={error} />
      <div className={styles.row}>
        <FormField
          type="text"
          name="email"
          label="Email"
          placeholder="name@email.com"
          required
          autoComplete="off"
        />
        {!id && (
          <FormField
            type="password"
            name="password"
            placeholder="********"
            label="Password"
            required
            autoComplete="off"
          />
        )}
        {!id && (
          <FormField
            type="password"
            name="passwordConfirm"
            placeholder="********"
            label="Confirm Password"
            required
            autoComplete="off"
          />
        )}
        <FormField
          type="select"
          name="role_id"
          label="Roles"
          placeholder="Roles"
          asyncOptions={rolesAutocomplete}
          required
          disabled={payload?.id === user?.userId}
        />
        <FormField
          type="select"
          name="groups_ids"
          label="Groups"
          placeholder="Groups"
          asyncOptions={groupsAutocomplete}
          isMulti
          required
        />
      </div>
      <FormButtons>
        <Button appearance="secondary" onClick={handleCloseModal}>
          {CANCEL_TEXT}
        </Button>
        <Button type="submit">Submit</Button>
      </FormButtons>
    </Form>
  );
};

export { UsersForm };
