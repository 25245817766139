import { FC } from "react";

import styles from "./attention-icon.module.css";

type AttentionIconProps = {
  type: "error" | "infoPrimary" | "infoTertiary" | "infoTextTertiary";
  size?: number;
};

const AttentionIcon: FC<AttentionIconProps> = ({ size = 18, type }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles[type]}
        d="M8.99995 16.2C12.9744 16.2 16.2 12.9744 16.2 8.99995C16.2 5.02555 12.9744 1.79995 8.99995 1.79995C5.02555 1.79995 1.79995 5.02555 1.79995 8.99995C1.79995 12.9744 5.02555 16.2 8.99995 16.2ZM8.27995 5.39995L9.71995 5.39995L9.71995 9.71995L8.27995 9.71995L8.27995 5.39995ZM8.27995 11.16L9.71995 11.16L9.71995 12.6L8.27995 12.6L8.27995 11.16Z"
        fill="none"
      />
    </svg>
  );
};

export { AttentionIcon };
