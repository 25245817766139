import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useFormikContext, FormikErrors } from "formik";
import {
  isDate,
  isSameDate,
  isSameDay,
  NonNullableKeys,
  useCall,
  useDidMount,
  Nullable,
} from "@epcnetwork/core-ui-kit";

import { convertToTimezone, getDateWithoutGTM, getISODateWithAmericaTimezone } from "utils";
import store, { setIsJobDataNeeded } from "store";
import { ReusableFileModel } from "models";
import { useSetFormikField } from "hooks";
import { JobDailySpread } from "api/allocation/allocation.interface";
import { postBatchDailySpread, getJobDailyChangedSpread } from "api";
import {
  allocationPresetsOptions,
  defaultAllocationPreset,
  noneOptionValue,
} from "../allocation-presets/allocation-presets.constants";
import {
  getActiveJobName,
  getMaxEmails,
  getInitialDateHoursSpread,
} from "../allocation-form.utils";
import {
  InitialValues,
  ParamsType,
  MaxDaysAllocationLimit,
  MaxHoursAllocationLimit,
} from "../allocation-form.types";
import { updateCalendarPatedData } from "./days-allocation.utils";
import { DaySpread, DayUnitType } from "./days-allocation.types";
import { initialDisplayCalendarView, showAllocationCalendar } from "./days-allocation.constants";

type Jobs = { jobs: JobDailySpread[] };

const resetJob = (currentJob: DaySpread) => ({
  ...currentJob,
  used: 0,
  remaining: currentJob.total,
  dayUnits: currentJob.dayUnits.map((day) => {
    return {
      ...day,
      value: 0,
      remaining: 0,
    };
  }),
});

type DaysAllocationReturnType = {
  dateHoursSpread: DaySpread[];
  usedAmount: number;
  onClear: VoidFunction;
  onApplyAllocation: (value?: string) => void;
  handleChange: (tableIndex: number) => (value: number, rangeIndex: number) => void;
  handleCopy: (tableIndex: number) => (value: number, activeIndexes: number[]) => void;
  getPresetValue: (tableIndex: number) => string;
  onChangePresets: (option: { value: string; label: string }) => void;
  displayCalendarView: boolean;
  maxHoursAllocationLimit: MaxHoursAllocationLimit;
  maxDaysAllocationLimit: MaxDaysAllocationLimit;
  onChangeDisplayCalendarView: (displayCalendar: boolean) => void;
  touched: {
    allEmails?: boolean;
  };
  submitCount: number;
  handlePaste: (event: React.ClipboardEvent<HTMLInputElement>, activeDayIndex: number) => void;
  errors: FormikErrors<InitialValues>;
};

export const useDaysAllocation = (
  currentActiveItemName: string[],
  reusedJob: Nullable<ReusableFileModel>,
): DaysAllocationReturnType => {
  const dispatch = useDispatch();
  const state = store.getState();
  const isApplyToAllEndpoints = useRef<boolean>(false);
  const displayCalendarViewFromSessionStorage = sessionStorage.getItem(showAllocationCalendar);

  const { jobId } = useParams<ParamsType>();
  const { values, initialValues, setFieldValue, setFormikState, touched, submitCount, errors } =
    useFormikContext<InitialValues>();

  const calendarViewFromSessionStorage =
    displayCalendarViewFromSessionStorage === null ||
    JSON.parse(displayCalendarViewFromSessionStorage);

  const [displayCalendarView, setDisplayCalendarView] = useState<boolean>(
    initialDisplayCalendarView && calendarViewFromSessionStorage,
  );

  const { submit, onCallSuccess, onCallError } = useCall(postBatchDailySpread);
  onCallSuccess((res) => {
    const spread = dateHoursSpread.map((currentJob, index) => {
      const newJob = res.jobs[isApplyToAllEndpoints.current ? index : 0];

      if (currentJob.jobId === newJob?.jobId || isApplyToAllEndpoints.current) {
        const usedValue = newJob.spread.reduce((prevValue: number, { traffic }) => {
          return prevValue + traffic;
        }, 0);

        return {
          ...currentJob,
          total: newJob.emailCount,
          used: usedValue,
          remaining: newJob.emailCount - usedValue,
          dayUnits: currentJob.dayUnits.map((day, index) => ({
            ...day,
            value: newJob.spread[index]?.traffic || 0,
            remaining: newJob.spread[index]?.traffic || 0,
          })),
        };
      }

      return currentJob;
    });

    setFieldValue("dateHoursSpread", spread);
  });
  onCallError((error) => {
    console.error(error, " happens when trying to get postBatchDailySpread");
  });

  const onChangeDisplayCalendarView = (displayCalendarView: boolean) => {
    sessionStorage.setItem(showAllocationCalendar, JSON.stringify(displayCalendarView));
    setDisplayCalendarView(displayCalendarView);
  };

  const {
    copyFile,
    sameSpread,
    endpointsRanges,
    usedAmount,
    startDate,
    endDate,
    daysCacheData,
    dateHoursSpread,
    minSpreadHour,
    maxHoursAllocationLimit,
    maxDaysAllocationLimit,
  } = values as NonNullableKeys<InitialValues>;

  useDidMount(() => {
    if (!isDate(startDate) || !isDate(endDate) || reusedJob) return;

    const { cachedStartData, cachedEndData, cachedEndpointsRanges } = daysCacheData || {};
    const isStartDateChanged = !isSameDate(cachedStartData, startDate);
    const isEndDateChanged = !isSameDate(cachedEndData, endDate);
    const isRangesChanged =
      cachedEndpointsRanges &&
      cachedEndpointsRanges.some(
        ({ value, jobId }) => endpointsRanges.find((el) => el.jobId === jobId)?.value !== value,
      );

    // "Cache" conditions. To not rewrite values in case nothing changed
    if (
      cachedStartData &&
      !isStartDateChanged &&
      cachedEndData &&
      !isEndDateChanged &&
      cachedEndpointsRanges &&
      !isRangesChanged
    )
      return;

    setFormikState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        dateHoursSpread: getInitialDateHoursSpread(
          minSpreadHour ? convertToTimezone(minSpreadHour) : startDate,
          endDate,
          endpointsRanges,
        ),
        daysCacheData: {
          cachedStartData: startDate,
          cachedEndData: endDate,
          cachedEndpointsRanges: endpointsRanges,
        },
        endpointsRanges: prevState.values.endpointsRanges.map((endpoint) => ({
          ...endpoint,
          preset: defaultAllocationPreset,
        })),
      },
    }));
  });

  useEffect(() => {
    const handlePasteAnywhere = (event: ClipboardEvent) => {
      updateCalendarPatedData({ event, currentActiveItemName, dateHoursSpread, setFieldValue });
    };

    window.addEventListener("paste", handlePasteAnywhere);

    return () => {
      window.removeEventListener("paste", handlePasteAnywhere);
    };
  }, [currentActiveItemName, dateHoursSpread, setFieldValue, values]);

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, activeDayIndex: number) => {
    event.preventDefault();
    // for stopping double pasting from use effect
    event.stopPropagation();
    updateCalendarPatedData({
      event,
      currentActiveItemName,
      dateHoursSpread,
      setFieldValue,
      activeDayIndex,
    });
  };

  const hasChangedFirstStep = () => {
    const changedDateStart = initialValues.startDate !== values.startDate;
    const changedDateEnd = initialValues.endDate !== values.endDate;
    const changedSameSpread = initialValues.sameSpread !== values.sameSpread;
    const changedSpread = initialValues.endpointsRanges.find(
      (range, index) => range.value !== endpointsRanges[index].value,
    );

    return changedSpread || changedSameSpread || changedDateEnd || changedDateStart;
  };

  const postDailySpread = postBatchDailySpread.setData({
    copyFileAcrossEndpoints: copyFile,
    sameSpread: sameSpread,
    totalEmailCount: usedAmount,
    startDate: minSpreadHour || getISODateWithAmericaTimezone(startDate),
    endDate: getISODateWithAmericaTimezone(endDate),
    jobsEmailCount: endpointsRanges.map(({ jobId, value }) => ({ jobId, emailCount: value })),
  });

  const getJobSpread = hasChangedFirstStep()
    ? postDailySpread
    : getJobDailyChangedSpread.setParams({ jobId: jobId || "" });

  const postRequest = jobId ? getJobSpread : postDailySpread;

  const onSetFormikFieldSuccess = useSetFormikField(
    postRequest,
    state.jobs.isJobDataNeeded && !reusedJob,
  );

  const checkMaxDaysAllocationLimit = (value: number, dayUnit: DayUnitType): number => {
    const hoursLimit = getMaxEmails(dayUnit.hoursUnits.length);
    if (value > hoursLimit) {
      maxDaysAllocationLimit.set(dayUnit.date, hoursLimit);
      return hoursLimit;
    }
    if (maxDaysAllocationLimit.has(dayUnit.date)) {
      maxDaysAllocationLimit.delete(dayUnit.date);
    }

    return hoursLimit;
  };

  onSetFormikFieldSuccess(({ jobs }: Jobs) => {
    dispatch(setIsJobDataNeeded(false));
    setFieldValue("initialDateSpread", jobs);
    const dataToSet: DaySpread[] = values.dateHoursSpread.map((dateSpread) => {
      const foundJob = jobs.find((el) => String(el.jobId) === String(dateSpread.jobId));
      if (!foundJob) return dateSpread;

      let used = 0;

      const dayUnitsToSet: DayUnitType[] = dateSpread.dayUnits.map((dayUnit): DayUnitType => {
        const { traffic = 0 } =
          foundJob.spread.find(({ day }) => isSameDay(getDateWithoutGTM(day), dayUnit.date)) || {};

        checkMaxDaysAllocationLimit(traffic, dayUnit);

        used += traffic;

        return {
          ...dayUnit,
          value: traffic,
          remaining: traffic,
          date: dayUnit.date,
        };
      });

      return {
        ...dateSpread,
        dayUnits: dayUnitsToSet,
        used,
        remaining: dateSpread.total - used,
      };
    });

    setFieldValue("maxDaysAllocationLimit", maxDaysAllocationLimit);
    maxHoursAllocationLimit && setFieldValue("maxHoursAllocationLimit", new Set());

    return ["dateHoursSpread", dataToSet];
  }, []);

  const handleCopy = (tableIndex: number) => (value: number, activeIndexes: number[]) => {
    let usedEmails = 0;
    const calculateDailyValue = (spread: DaySpread, day: DayUnitType): number | null => {
      const { total } = spread;
      const remaining = total - usedEmails;
      const dailyLimit = checkMaxDaysAllocationLimit(value, day);

      if (value > dailyLimit) {
        usedEmails += 0;
        return null;
      }
      if (value >= remaining) {
        usedEmails += remaining;
        return remaining;
      }
      if (value >= total) {
        usedEmails += total;
        return total;
      }

      usedEmails += value;
      return value;
    };

    const newDateHoursSpread = dateHoursSpread.map((spread, index) => {
      if (tableIndex !== index) return spread;

      const newDayUnits = spread.dayUnits.map((day, index) => {
        const isActiveDay = activeIndexes.includes(index);
        if (!isActiveDay) return day;

        const newValue = calculateDailyValue(spread, day);
        if (newValue === null) return day;

        return {
          ...day,
          value: newValue,
          remaining: newValue,
          used: 0,
          hoursUnits: day.hoursUnits.map((hour) => ({ ...hour, value: 0 })),
        };
      });

      return {
        ...spread,
        remaining: spread.total - usedEmails,
        used: usedEmails,
        dayUnits: newDayUnits,
      };
    });

    setFieldValue("dateHoursSpread", newDateHoursSpread);
    setFieldValue("maxDaysAllocationLimit", maxDaysAllocationLimit);
  };

  const handleChange = (tableIndex: number) => (value: number, rangeIndex: number) => {
    const arrayToSet = dateHoursSpread.map((spread, index) => {
      if (tableIndex !== index) return spread;

      let used = 0;

      const dayUnits = spread.dayUnits.map((day, i) => {
        const isChanged = rangeIndex === i;
        used += isChanged ? value : day.value;
        isChanged && checkMaxDaysAllocationLimit(value, day);

        return isChanged
          ? {
              ...day,
              value,
              remaining: value,
              used: 0,
              hoursUnits: day.hoursUnits.map((hourSpread) => ({ ...hourSpread, value: 0 })),
            }
          : day;
      });

      return {
        ...spread,
        remaining: spread.total - used,
        used,
        dayUnits,
      };
    });

    setFieldValue("dateHoursSpread", arrayToSet);
    setFieldValue("maxDaysAllocationLimit", maxDaysAllocationLimit);
  };

  const onChangePresets = (option?: { value: string; label: string }, applyToAll?: boolean) => {
    isApplyToAllEndpoints.current = !!applyToAll;
    if (option) {
      const { value } = option;
      const currentActiveName = getActiveJobName(currentActiveItemName);

      const ranges = endpointsRanges.map((endpoint) => {
        if (
          (currentActiveName && currentActiveName === endpoint.name) ||
          isApplyToAllEndpoints.current
        ) {
          return { ...endpoint, preset: value };
        }

        return endpoint;
      });

      setFieldValue("endpointsRanges", ranges);

      if (value === noneOptionValue) {
        return onClear();
      }

      const dailySpread = endpointsRanges.flatMap(({ jobId, value, name }) => {
        if ((currentActiveName && currentActiveName === name) || isApplyToAllEndpoints.current) {
          return { jobId, emailCount: value };
        }

        return [];
      });

      postBatchDailySpread.setData(null);

      submit({
        data: {
          dailySpreadStrategy: value,
          copyFileAcrossEndpoints: copyFile,
          sameSpread: sameSpread,
          totalEmailCount: usedAmount,
          startDate: minSpreadHour || getISODateWithAmericaTimezone(startDate),
          endDate: getISODateWithAmericaTimezone(endDate),
          jobsEmailCount: dailySpread,
        },
      });
    }
  };

  const onClear = () => {
    const currentActiveName = getActiveJobName(currentActiveItemName);

    const spread = dateHoursSpread.map((currentJob) => {
      if (currentJob.name === currentActiveName || isApplyToAllEndpoints.current) {
        return resetJob(currentJob);
      }

      return currentJob;
    });
    setFieldValue("dateHoursSpread", spread);
  };

  const getPresetValue = (tableIndex: number) =>
    endpointsRanges[tableIndex].preset || defaultAllocationPreset;

  const onApplyAllocation = (value?: string) => {
    isApplyToAllEndpoints.current = true;
    onChangePresets(
      allocationPresetsOptions.find((item) => item.value === value),
      true,
    );
  };

  return {
    dateHoursSpread,
    usedAmount,
    handleChange,
    handleCopy,
    maxDaysAllocationLimit,
    maxHoursAllocationLimit,
    getPresetValue,
    onApplyAllocation,
    onChangePresets,
    onClear,
    touched,
    submitCount,
    errors,
    displayCalendarView,
    onChangeDisplayCalendarView,
    handlePaste,
  };
};
