import { FC, ReactNode } from "react";
import cn from "classnames";
import { Button } from "@epcnetwork/core-ui-kit";

import arrowIconUrl from "assets/images/arrow-base-right.svg";
import styles from "./breadcrumbs.module.css";

type BreadcrumbsProps = {
  breadcrumbsData: BreadcrumbsDataType[];
  children?: ReactNode;
};

export type BreadcrumbsDataType = {
  name: string;
  onClick?: VoidFunction;
  active?: boolean;
  icon?: ReactNode;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbsData, children }) => {
  const breadcrumbsLength = breadcrumbsData.length;

  return (
    <nav className={styles.breadcrumbs}>
      <ul className={styles.breadcrumbsList}>
        {breadcrumbsData.map(({ onClick, name, active, icon }, index) => {
          const crumbStyles = cn(styles.crumbButton, { [styles.crumbButtonActive]: active });
          return (
            <li className={styles.crumb} key={name}>
              <Button
                type="button"
                appearance="text"
                btnSize="small"
                onClick={onClick}
                className={crumbStyles}
              >
                {name}
                <span className={styles.icon}>{icon}</span>
              </Button>
              {breadcrumbsLength !== index + 1 && (
                <img
                  className={styles.separator}
                  src={arrowIconUrl}
                  width={20}
                  height={20}
                  alt=""
                />
              )}
            </li>
          );
        })}
      </ul>
      {children}
    </nav>
  );
};

export { Breadcrumbs };
