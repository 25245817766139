import { FC, useState } from "react";
import { useDidUpdate, useFetch, FetchingError, NoContent } from "@epcnetwork/core-ui-kit";

import { RoleModel } from "models";
import { Loader } from "components";
import { getRoles } from "api";
import { Sidebar } from "./sidebar/sidebar";
import { RolesForm } from "./form/roles-form";

import styles from "./list-roles.module.css";

type ListRolesProps = {
  searchValue: string;
};

const ListRoles: FC<ListRolesProps> = ({ searchValue }) => {
  const [selectedRole, setRole] = useState<RoleModel | null>(null);

  const { payload, error, loading, refresh, actions } = useFetch(getRoles);
  const list = payload || [];

  const showNoContent = !loading && !error?.message && !list.length;
  const showContent = !loading && !error?.message && list.length;
  const showError = !loading && error?.message;

  useDidUpdate(() => {
    if (list.length && !selectedRole) {
      setRole(list[0]);
    }
  }, [list]);

  const handleUpdate = (role: RoleModel) => {
    setRole(role);
    actions.setData(
      list.map((listRole) => {
        if (listRole.id === role.id) {
          return role;
        }
        return listRole;
      }),
    );
  };

  const handleAdd = (role: RoleModel) => {
    setRole(role);
    actions.setData([...list, role]);
  };

  const onSelect = (role: RoleModel) => {
    setRole(role);
  };

  const handleRemoveRole = (role: RoleModel) => {
    setRole(list[0] || null);
    actions.setData(list.filter((stateRole) => stateRole.id !== role.id));
  };

  const handleCreateRole = () => {
    setRole(null);
  };

  return (
    <div className={styles.container}>
      {showContent && (
        <div className={styles.content}>
          <Sidebar
            list={list}
            selected={selectedRole}
            searchValue={searchValue}
            onSelect={onSelect}
            onAddNew={handleCreateRole}
          />
          <RolesForm
            selected={selectedRole}
            disabled={selectedRole?.permissions?.includes("ADMIN")}
            setRole={handleUpdate}
            addRole={handleAdd}
            removeRole={handleRemoveRole}
          />
        </div>
      )}
      {loading && (
        <div className={styles.status}>
          <Loader />
        </div>
      )}
      {showNoContent && (
        <div className={styles.status}>
          <NoContent title="No users" subtitle="There are no users to show" />
        </div>
      )}
      {showError && (
        <div className={styles.status}>
          <FetchingError
            title="Cannot fetch users"
            subtitle={error?.message || ""}
            refreshClick={refresh}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

export { ListRoles };
