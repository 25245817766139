import { boolean, object, TestContext } from "yup";

import { DaySpread } from "../days-allocation/days-allocation.types";
import { InitialValues } from "../allocation-form.types";

export const hoursAllocationSchema = object({
  allEmails: boolean().test(
    "checkAllocation",
    "Please spread remaining emails between hours to continue",
    (value: boolean | unknown, context: TestContext<InitialValues> | unknown) => {
      const schemaContext = context ? (context as TestContext<InitialValues>) : null;
      if (!schemaContext || !value) return true;

      const { dateHoursSpread } = schemaContext.parent;

      const hasRemainingItems = dateHoursSpread.find((spread: DaySpread) => {
        spread.dayUnits.find((day) => day.remaining);
      });

      return !hasRemainingItems;
    },
  ),
});
