import { UserModel } from "models";
import { apiMiddleware } from "api";
import { UserFormData } from "./users.interface";

export const getUsers = apiMiddleware<UserModel[]>()({
  method: "get",
  endpoint: `/user`,
});

export const getUser = apiMiddleware<UserModel>()({
  method: "get",
  endpoint: `/user/:userId`,
});

export const postUser = apiMiddleware<UserModel, UserFormData>()({
  method: "post",
  endpoint: "/user",
});

export const putUser = apiMiddleware<UserModel, UserFormData>()({
  method: "put",
  endpoint: `/user/:userId`,
});

export const deleteUser = apiMiddleware<UserModel>()({
  method: "delete",
  endpoint: `/user/:userId`,
});
