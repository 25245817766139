import { useNavigate } from "react-router";
import { FC } from "react";
import { addHours, isBefore } from "date-fns";
import cn from "classnames";
import { getLinkPath, Button } from "@epcnetwork/core-ui-kit";

import { AllocationEndpointType } from "models";
import {
  ALLOCATION_EDIT_PAGE,
  ALLOCATION_PAGE,
  JOBS_FORM_EDIT_PAGE,
  JOBS_LIST_PAGE,
} from "constants/routes.constants";

import styles from "./information.module.css";

type InformationProps = {
  endpoints: AllocationEndpointType[];
  jobId?: string;
  batchId?: string;
  endDate?: string;
};

export const Information: FC<InformationProps> = ({ jobId, batchId, endpoints, endDate }) => {
  const navigate = useNavigate();

  const isFinished = endDate ? isBefore(new Date(endDate), addHours(new Date(), 2)) : false;

  const handleBack = () => {
    navigate(jobId ? JOBS_LIST_PAGE.path : ALLOCATION_PAGE.path);
  };

  const handleEdit = () => {
    if (jobId) {
      navigate(getLinkPath(JOBS_FORM_EDIT_PAGE.path, { jobId }));
    } else if (batchId) {
      navigate(getLinkPath(ALLOCATION_EDIT_PAGE.path, { batchId }));
    }
  };

  return (
    <>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>
          {jobId ? `Job #${jobId} reconfiguration` : `Batch #${batchId} allocation`}
        </div>
        <br />
        {isFinished && (
          <div className={cn(styles.info, styles.error)}>
            <div className={styles.title}>Warning</div>
            This {jobId ? "Job" : "Batch"} cannot be edited anymore. Its duration suggests that it
            has been completed or is nearing its end.
          </div>
        )}
        {!isFinished && (
          <div className={styles.info}>
            <div className={cn(styles.title, styles.messageTitle)}>Caution</div>
            {jobId &&
              `You cannot assign this job as it does not contain any email addresses available for
        assignment. This may be due to the tag you used and the fact that the file on which this job
        is based has only duplicate emails from other jobs with that tag and/or has emails added as
        exclusions for the tag. In this situation, you can delete or edit the job.`}
            {batchId &&
              `You can’t allocate this batch because it’s not containing any emails available for
        allocation. This may be due to the tag you used and the fact that the file on which this
        batch is based has only duplicate emails from other jobs with that tag and/or has emails
        added as exclusions for the tag. In this situation, you can delete or edit this batch.`}
          </div>
        )}

        <div className={styles.table}>
          <div className={styles.text}>{batchId ? `Included jobs:` : "Job:"}</div>
          {endpoints.map((endpoint) => (
            <div key={endpoint.jobId} className={styles.row}>
              {endpoint.name}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.actions}>
        <Button onClick={handleBack} appearance="secondary" className={styles.backButton}>
          Back
        </Button>
        {!isFinished && (
          <Button onClick={handleEdit} appearance="primary">
            Edit
          </Button>
        )}
      </div>
    </>
  );
};
