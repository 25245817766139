import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect, useRef, useState } from "react";
import { FormikErrors, FormikHelpers, FormikProps, FormikTouched } from "formik";
import {
  Form,
  notification,
  StatusColorsKeys,
  Tabs,
  useAutocomplete,
  useFetch,
  useSubmit,
  getLinkPath,
} from "@epcnetwork/core-ui-kit";

import { getSuccessNotificationMessage } from "utils";
import { AvailableFileModel, JobModel, NonNullableKeys } from "models";
import { ALLOCATION_PAGE, JOBS_FORM_PAGE, JOBS_DETAILS_PAGE } from "constants/routes.constants";
import { SUCCESS_TEXT } from "constants/notification.constants";
import { Container } from "components";
import { getTagsShort, getWebhooks, postEditBatch, postEditJob, postJobsInABatch } from "api";
import { InitialValues, QueryParamsType } from "./jobs-form.types";
import { initialValues, validationSchema } from "./jobs-form.constants";
import { createInitialEndpoint } from "./endpoint/endpoint.utils";
import { EndpointType } from "./endpoint/endpoint.types";
import { Endpoint } from "./endpoint/endpoint";
import { useEdit } from "./edit/edit.hook";
import { RestAPIType } from "./connectors/rest-api";

import styles from "./job-form.module.css";

type JobFormProps = {
  id?: string;
  handleCloseEditJob?: VoidFunction;
  onItemChange?: (job: Pick<JobModel, "id" | "name">[]) => void;
};

const JobForm: FC<JobFormProps> = ({ id, handleCloseEditJob, onItemChange }) => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<typeof initialValues>>(null);

  const { jobId, batchId } = useParams<QueryParamsType>();

  const [activeTab, setActiveTab] = useState(0);
  const [disableTab, setDisableTab] = useState(false);

  const currentJob = id || jobId;

  const isJobEditing = !!jobId || !!batchId;

  const isInReconfiguration = useEdit({
    jobId: currentJob,
    batchId,
    setInitialState: (endpoints: EndpointType[], fileIds: Array<string>, tagId?: number) => {
      formRef.current?.setFieldValue?.("endpoints", endpoints);
      formRef.current?.setFieldValue?.("fileIds", fileIds);
      formRef.current?.setFieldValue?.("tagId", tagId);
    },
  });

  useEffect(() => {
    formRef.current?.setFieldValue("isInReconfiguration", isInReconfiguration);
  }, [isInReconfiguration]);

  const onEndpointError = (isError: boolean) => {
    setDisableTab(isError);
  };

  const { payload } = useFetch(getWebhooks);

  const editRequest = postEditJob.setParams({ jobId: currentJob as string });
  const editingRequest = batchId ? postEditBatch.setParams({ batchId }) : editRequest;

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postJobsInABatch,
    editingRequest as typeof editRequest,
    isInReconfiguration,
  );

  const onSubmit = onSubmitMapping((values: NonNullableKeys<InitialValues>) => {
    const { tagId, endpoints: formEndpoints, fileIds, attachedFiles } = values;
    // @ts-ignore
    const endpoints: EndpointType[] = formEndpoints.map(
      ({ webhookId, connector, name, jobId, proxyId, proxyValue }) => {
        if ((connector.properties as RestAPIType)?.requestHeaders === "") {
          delete (connector.properties as RestAPIType).requestHeaders;
        }

        if ((connector.properties as RestAPIType)?.custom_attributes === "") {
          delete (connector.properties as RestAPIType).custom_attributes;
        }

        const connectorProperties: Record<string, unknown> = connector?.properties || {};
        const trimmedConnectorProperties = Object.entries(connectorProperties).reduce(
          (acc, [key, value]) => {
            if (typeof value === "string") {
              acc[key] = value.trim();
            } else {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, unknown>,
        );

        const connectorData =
          "properties" in connector
            ? {
                ...connector,
                properties: trimmedConnectorProperties,
              }
            : connector;

        const isProxySet = Boolean(proxyId || proxyValue);

        const baseValues = {
          connector: connectorData,
          name: name.trim(),
          jobId,
          webhookId,
        };

        return isProxySet
          ? {
              ...baseValues,
              proxyValue: proxyId ? undefined : proxyValue,
              proxyId: proxyId || undefined,
            }
          : baseValues;
      },
    );

    if (batchId) {
      return {
        tagId,
        fileIds,
        endpoints,
        batchId,
      };
    }

    return {
      tagId,
      fileIds: fileIds
        ? fileIds
        : attachedFiles.map((attachedFile: AvailableFileModel) => attachedFile.fileId),
      endpoints,
    };
  });

  onSubmitSuccess((_, values: NonNullableKeys<InitialValues>) => {
    if (currentJob || batchId) {
      onItemChange?.(
        values.endpoints.map((endpoint) => ({
          name: endpoint.name,
          id: Number(endpoint.jobId),
        })),
      );
    }

    if (currentJob) {
      handleCloseEditJob?.() ||
        navigate(getLinkPath(JOBS_DETAILS_PAGE.path, { jobId: Number(currentJob) }));
      notification.success(SUCCESS_TEXT, "Job updated successfully");
      return;
    }

    notification.confirm(SUCCESS_TEXT, getSuccessNotificationMessage("Batch", !!batchId), {
      onOk: () => {
        navigate(ALLOCATION_PAGE.path);
      },
      onCancel: () => {
        navigate(JOBS_FORM_PAGE.path);
      },
      okText: "Go to allocation",
      cancelText: "Create another job",
      icon: "success",
    });
  });

  onSubmitError(
    (
      error,
      _: NonNullableKeys<InitialValues>,
      helpers: FormikHelpers<NonNullableKeys<InitialValues>>,
    ) => {
      const { originalError, message } = error;

      if (typeof originalError === "object" && "err" in originalError) {
        const duplicatedNames = Object.keys(originalError.err);
        // to refactor
        helpers.setFormikState((state) => ({
          ...state,
          errors: {
            ...state.errors,
            endpoints: state.values.endpoints.map(({ name }) => {
              const jobName = name || "";
              const isDuplicate = duplicatedNames.some((name) => name === jobName);

              if (!isDuplicate) return null as any;

              return {
                name: originalError.err[jobName],
              };
            }),
          },
        }));
        notification.error("Error", duplicatedNames.join(", ") + " are already in use");
      } else {
        notification.error("Error", message);
      }
    },
  );

  const tagsAutocomplete = useAutocomplete(getTagsShort, "id", "name");

  const handleAddNewEndpoint = (
    values: InitialValues["endpoints"],
    setValue: FormikHelpers<InitialValues>["setFieldValue"],
  ) => {
    return () => {
      const dataToSet = values.concat(createInitialEndpoint());
      setValue("endpoints", dataToSet);
    };
  };

  const getEndpointTabs = (
    endpoints: InitialValues["endpoints"],
    setValue: FormikHelpers<InitialValues>["setFieldValue"],
    touched: FormikTouched<InitialValues>["endpoints"],
    errors: FormikErrors<InitialValues>,
    isInReconfiguration: boolean,
  ) => {
    const handleGetStatus = (index: number): StatusColorsKeys | undefined => {
      const { endpoints, attachedFiles } = errors;
      const errorCondition = attachedFiles || (endpoints && endpoints[index]);
      const touchedCondition = touched && touched[index];

      if (touchedCondition && errorCondition) return "error";
      if (touchedCondition && !errorCondition) return "success";
      return undefined;
    };

    const handleCrossClick = (index: number) => () => {
      const dataToSet = endpoints.filter((_, i) => i !== index);
      setValue("endpoints", dataToSet);
    };

    return endpoints?.map((endpoint, index) => ({
      tab: {
        name: `${endpoint.name?.trim() || "Job #" + (index + 1)}`,
        status: handleGetStatus(index),
        onCrossClick: handleCrossClick(index),
        disabledTab: disableTab,
      },
      tabComponent: (
        <Endpoint
          endpoint={endpoint}
          onEndpointError={onEndpointError}
          webhooks={payload || []}
          endpointOrdinal={index}
          tagsAutocomplete={tagsAutocomplete}
          isInReconfiguration={isInReconfiguration}
          isJobEditing={isJobEditing}
        />
      ),
    }));
  };

  return (
    <Container>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema(isInReconfiguration)}
        innerRef={formRef}
      >
        {({ values, setFieldValue, touched, errors }) => {
          const { endpoints } = values;

          if (id) {
            return endpoints.map((endpoint, index) => (
              <>
                <div className={styles.title}>Job editing</div>

                <Endpoint
                  endpoint={endpoint}
                  webhooks={payload || []}
                  endpointOrdinal={index}
                  tagsAutocomplete={tagsAutocomplete}
                  isInReconfiguration={isInReconfiguration}
                  isJobEditing={!!id}
                  onEndpointError={onEndpointError}
                />
              </>
            ));
          }

          return (
            <Tabs
              tabs={getEndpointTabs(
                endpoints,
                setFieldValue,
                touched.endpoints,
                errors,
                isInReconfiguration,
              )}
              activeTab={activeTab}
              onTabClick={setActiveTab}
              onAddNewTabClick={handleAddNewEndpoint(endpoints, setFieldValue)}
              canAddNewTab={!isInReconfiguration}
              maxTabsAmount={10}
              tabsClassName={styles.tabs}
            />
          );
        }}
      </Form>
    </Container>
  );
};

export { JobForm };
