import { FC } from "react";
import { Tooltip } from "@epcnetwork/core-ui-kit";

import { AttentionIcon } from "assets/images/components";
import styles from "./page-name-tooltip.module.css";

type PageNameTooltipProps = {
  tooltipText?: string;
};

export const PageNameTooltip: FC<PageNameTooltipProps> = ({ tooltipText = "" }) => {
  if (tooltipText)
    return (
      <Tooltip
        triggerElement={<AttentionIcon type="infoTertiary" />}
        position="bottom-left"
        trigger="hover"
        triggerClassName={styles.tooltipTrigger}
        contentClassName={styles.tooltipContent}
      >
        {tooltipText}
      </Tooltip>
    );

  return null;
};
