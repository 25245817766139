import { FC } from "react";
import cn from "classnames";

import styles from "./information.module.css";

type CustomInfoProps = {
  text: string;
  type?: "error" | "warning";
};

const CustomInfo: FC<CustomInfoProps> = ({ text, type }) => {
  return (
    <div className={cn(styles.info, styles.customInfo, { [styles.error]: type === "error" })}>
      <div className={styles.title}>Caution</div>
      {text}
    </div>
  );
};

export { CustomInfo };
