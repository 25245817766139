import { AnySchema, object, string } from "yup";
import { FC, useState } from "react";
import {
  Button,
  Form,
  FormButtons,
  MessageField,
  FormField,
  useFormValues,
  useSubmit,
  notification,
} from "@epcnetwork/core-ui-kit";

import { GroupModel } from "models";
import { CANCEL_TEXT } from "constants/notification.constants";
import { GroupData } from "api/groups/groups.interface";
import { postGroup, putGroup, getGroup } from "api";

import styles from "./groups-form.module.css";

const validationSchema: AnySchema = object()
  .strict()
  .shape({
    name: string()
      .required("Name is required")
      .matches(/[^\s]+/gi, "Spaces without other characters are not allowed"), // all except whitespaces
  });

const initialData: GroupData = {
  name: "",
};

type GroupsFormProps = {
  id: number | null;
  handleItemChange: (value: GroupModel) => void;
  handleCloseModal: () => void;
};

const GroupsForm: FC<GroupsFormProps> = ({ id, handleItemChange, handleCloseModal }) => {
  const [error, setError] = useState("");
  const { formProps, mapInitialValues } = useFormValues(
    initialData,
    getGroup.setParams({ groupId: id as number }),
    !!id,
  );
  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(
    postGroup,
    putGroup.setParams({ groupId: id as number }),
    !!id,
  );

  mapInitialValues((payload) => {
    return {
      name: payload.name,
    };
  });

  onSubmitSuccess((value) => {
    handleItemChange(value);
    handleCloseModal();
    notification.success("Group is created", "Successfully created group");
  });

  onSubmitError((error) => {
    setError(error?.message);
  });

  const submitHandler = (
    values: Parameters<typeof onSubmit>[0],
    helpers: Parameters<typeof onSubmit>[1],
  ) => {
    values.name = values.name.trim();
    onSubmit(values, helpers);
  };

  return (
    <Form {...formProps} validationSchema={validationSchema} onSubmit={submitHandler}>
      <div className={styles.title}>Group Form</div>
      <MessageField message={error} />
      <FormField type="text" name="name" label="Name" placeholder="Name" />
      <FormButtons>
        <Button appearance="secondary" onClick={handleCloseModal}>
          {CANCEL_TEXT}
        </Button>
        <Button type="submit">Submit</Button>
      </FormButtons>
    </Form>
  );
};

export { GroupsForm };
