import { arrayOf } from "@epcnetwork/core-ui-kit";

import { HeaderValues } from "../files-list.types";

export const isFileHasValidHeaders = (fileParsedContent: string[][]): boolean =>
  !!fileParsedContent.length &&
  !!fileParsedContent[0].length &&
  !fileParsedContent[0]?.find((cell: string) => cell.includes("@"));

export const createInitialArray = (arrayLength: number): HeaderValues =>
  arrayOf(arrayLength, () => ({ value: "", label: "" }));

export const formatHeaderValue = (value: string): string =>
  value ? value.trim().toLowerCase() : "";
