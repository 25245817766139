import { FC, useCallback } from "react";
import {
  Button,
  DropAcceptedFunc,
  FileItem,
  FileUpload,
  useFileQueue,
} from "@epcnetwork/core-ui-kit";

import { getFileUuidName } from "utils";
import { Container } from "components";
import { EmailsSuppression } from "api/jobs/jobs.interface";
import { postJobSuppressionEmails } from "api";
import { EmailsSuppressionItem } from "./emails-suppression-form.types";
import { getInitialData, subtitle, supportedFormats } from "./emails-suppression-form.constants";

import styles from "./emails-suppression-form.module.css";

const emailSuppressionEntity = "email-suppression";

const EmailsSuppressionFormPage: FC = () => {
  const {
    files,
    createInitialFile,
    addFiles,
    removeFiles,
    clearEntity,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllSuccessFiles,
    submitOneByOne,
  } = useFileQueue<EmailsSuppressionItem, EmailsSuppression>(
    emailSuppressionEntity,
    postJobSuppressionEmails,
  );

  const handleAcceptedDrop: DropAcceptedFunc = useCallback((acceptedFiles) => {
    addFiles(acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearClick = () => {
    clearEntity();
  };

  const handleSubmit = () => {
    const data = files.map((file) => {
      const formData = new FormData();
      formData.append("fileInfo", JSON.stringify(file.data));
      formData.append("file", file.originalFile, getFileUuidName(file));

      return {
        id: file.id,
        data: formData,
      };
    });

    submitOneByOne(data);
  };

  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.form}>
          <FileUpload
            className={styles.dropZone}
            uploadedFilesLength={files.length}
            subtitle={subtitle}
            accept={supportedFormats}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                isTuned
              />
            ))}
          </div>
          <div className={styles.buttons}>
            <Button appearance="secondary" onClick={handleClearClick}>
              Cancel
            </Button>
            <Button
              onClick={isEntityFinished ? clearEntity : handleSubmit}
              disabled={isEntityFinished && !hasAllSuccessFiles}
              loading={!isEntityInConfiguration && !isEntityFinished}
            >
              {isEntityFinished ? "Finish" : "Submit"}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export { EmailsSuppressionFormPage };
