const appEnv = process.env.REACT_APP_ENV || "local";

interface Environment {
  apiUrl: string;
  smartlookKey: string;
}

interface Environments {
  [key: string]: Environment;
}

const environments: Environments = {
  local: {
    apiUrl: process.env.REACT_APP_API || "http://0.0.0.0:3000",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
  },
  development: {
    apiUrl: process.env.REACT_APP_API || "https://in-dev.api.traffic.mergex.dev",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
  },
  staging: {
    apiUrl: process.env.REACT_APP_API || "https://traffic.mergex.dev",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
  },
  production: {
    apiUrl: process.env.REACT_APP_API as string,
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY as string,
  },
};

export const appEnvironment: Environment = environments[appEnv];
