import { FC, ReactNode } from "react";
import cn from "classnames";

import { TagStatuses } from "models";

import styles from "./tag-status.module.css";

type StatusIndicatorProps = {
  className?: string;
  type: TagStatuses;
  children?: ReactNode;
};

const StatusIndicator: FC<StatusIndicatorProps> = ({ type, className, children }) => (
  <div className={cn(styles.status, className, { [styles[type]]: type })}>{children}</div>
);

export { StatusIndicator };
