import { Dispatch, FC, RefObject, ReactNode } from "react";
import cn from "classnames";

import styles from "./container.module.css";

interface ContainerProps {
  className?: string;
  contentClassName?: string;
  contentForwardRef?: RefObject<HTMLDivElement> | Dispatch<HTMLDivElement>;
  children?: ReactNode;
}

const Container: FC<ContainerProps> = ({
  children,
  contentClassName,
  className,
  contentForwardRef,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.content, contentClassName)} ref={contentForwardRef}>
        {children}
      </div>
    </div>
  );
};

export { Container };
