import { FC, useState } from "react";
import cn from "classnames";
import { useDidUpdate } from "@epcnetwork/core-ui-kit";

import { formatDate } from "utils";
import { JobLogModel } from "models";
import { timeDetailsFormat } from "constants/general.constants";
import { failedEmailStatusText, failedEmailStatus } from "./logs-info-panel.constants";

import styles from "./logs-info-panel.module.css";

type LogInfoItemProps = {
  item: JobLogModel;
  onItemClick: (responseId: number) => void;
};

const LogsInfoItem: FC<LogInfoItemProps> = ({ item, onItemClick }) => {
  const [highlighted, setHighlighted] = useState(true);

  const { email, sentAt, status, responseId, newlyAdded } = item;

  const handleItemClick = () => onItemClick(responseId);

  useDidUpdate(
    () => {
      setHighlighted(newlyAdded || false);

      if (newlyAdded) {
        const handleTimeout = () => setHighlighted(false);
        const timeout = setTimeout(handleTimeout, 2);

        return () => {
          clearTimeout(timeout);
        };
      }
    },
    [newlyAdded],
    true,
  );

  return (
    <div className={cn(styles.logItemWrap, { [styles.logItemNewlyAdded]: highlighted })}>
      <button className={styles.logItemInfoWrap} onClick={handleItemClick}>
        <div className={`${styles.logItemStatusLine} ${styles[status]}`} />
        <div className={styles.logItemTextsWrap}>
          <div className={styles.logItemMailText}>{email}</div>
          {status === failedEmailStatus && (
            <div className={`${styles.logItemStatusText} ${styles[status]}`}>
              {failedEmailStatusText}
            </div>
          )}
          <div className={styles.logItemDateText}>
            {formatDate(sentAt, `MMM dd, yyyy | ${timeDetailsFormat}`)}
          </div>
        </div>
      </button>
    </div>
  );
};

export { LogsInfoItem };
