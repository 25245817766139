import { ObjectValues } from "types";
import { jobsPermissionsMap, usersPermissionsMap } from "constants/roles.constants";

export enum AuthorizationActionTypes {
  create = "create",
  read = "read",
  delete = "delete",
}

export type AuthorizationActionType = keyof typeof AuthorizationActionTypes;
export type AuthorizationEntityNameType = "Jobs" | "Emails Suppression" | "Users";

export type PermissionsModel = {
  name: AuthorizationEntityNameType;
} & Record<
  AuthorizationActionType,
  ObjectValues<typeof jobsPermissionsMap> | ObjectValues<typeof usersPermissionsMap> | string
>;
