import { SelectOption } from "@epcnetwork/core-ui-kit";

import { capitalize } from "./text-formatters.utils";

export const generateOption = <T extends number | string>(
  value: T,
  format?: boolean,
): SelectOption<T> => ({
  value,
  label: format && typeof value === "string" ? capitalize(value) : value.toString(),
});
export const generateOptions = <T extends number | string>(
  array: T[],
  format?: boolean,
): SelectOption<T>[] => array.map((option: T) => generateOption(option, format));
