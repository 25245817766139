import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types/common.types";
import { defaultLimit, defaultOffset } from "constants/query.constants";
import { searchMinLength } from "constants/form.constants";
import { FiltersQueryType, TagSupportedStatuses } from "./tags-list.types";

export const tableColumns: ColumnsType = [
  { label: "Tag", required: true, queryKey: "tagName" },
  {
    label: "Assignee",
    queryKey: "assignee.email",
  },
  { label: "Status", queryKey: "status" },
  { label: "Jobs Count", queryKey: "count" },
  { label: "Created At", queryKey: "createdAt" },
  { label: "" },
];

export const tabStatusesMap: Record<number, TagSupportedStatuses> = {
  0: "all",
  1: "used",
  2: "unused",
};

export const TABLE_ENTITY_NAME = "tag-manager";

export const initialFilters: UseFiltersProps<FiltersQueryType> = {
  searchOptions: { searchKey: "search", searchMinLength, searchDebounce: 1200 },
  initialState: {
    search: "",
    status: "all",
    limit: defaultLimit,
    offset: defaultOffset,
  },
};
