import { memo, useState } from "react";
import {
  List,
  Search,
  Table,
  TableProps,
  Tabs,
  useDevice,
  useDidUpdate,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { findKeyByValue, getInitialStorageFilters } from "utils";
import { AllocationBatchModel, AllocationCountModel } from "models";
import {
  AllocationFileListenerEventsKeys,
  BatchStatusData,
  useAllocationFileSocket,
  useTablePagination,
} from "hooks";
import { defaultOffset } from "constants/query.constants";
import { Container } from "components";
import { getAllocationBatches } from "api";
import { AllocationTableRow } from "./allocation-table-row/allocation-table-row";
import { FiltersQueryType } from "./allocation-list.types";
import {
  initialFilters,
  TABLE_ENTITY_NAME,
  tableColumns,
  tabStatusesMap,
} from "./allocation-list.constants";

import styles from "./allocation-list.module.css";

const AllocationListPage = memo(() => {
  const { isMobileDevice } = useDevice();
  const { query, queryString, searchValue, setSearch, updateQueryParams } =
    useFilters<FiltersQueryType>(
      getInitialStorageFilters<FiltersQueryType>(TABLE_ENTITY_NAME, initialFilters),
    );
  const { socket } = useAllocationFileSocket();

  const [listPayload, setListPayload] = useState<
    (List<AllocationBatchModel> & AllocationCountModel) | null
  >(null);
  const [activeTab, setActiveTab] = useState(
    Number(findKeyByValue(tabStatusesMap, query.status)) || 0,
  );

  const { payload, ...rest } = useFetch(getAllocationBatches.setQueryParams(queryString), {
    dependencies: [queryString],
  });

  useDidUpdate(() => {
    setListPayload(payload);
  }, [payload]);

  const pagination = usePagination({ listPayload });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_ENTITY_NAME,
  });

  const { total = 0, calculation = 0, readyToAllocate = 0 } = listPayload || {};

  const tableProps: TableProps<AllocationBatchModel[]> = {
    ...rest,
    entityName: TABLE_ENTITY_NAME,
    error: rest.error?.message || "",
    columns: tableColumns(isMobileDevice),
    list: listPayload?.data || [],
    pagination: {
      ...pagination,
      elementsPerPage: currentElementsPerPage,
      onElementsPerPageChange: handlePerPageChange,
    },
    isTabTable: true,
    showCollapses: true,
    resetColumnsOnMount: false,
    row: (item: AllocationBatchModel, index: number) => (
      <AllocationTableRow index={index} item={item} refresh={rest.refresh} />
    ),
  };

  const onTabClick = (index: number) => {
    updateQueryParams({
      status: tabStatusesMap[index],
      offset: defaultOffset,
    });
    setActiveTab(index);
  };

  useDidUpdate(
    () => {
      const handleStatusUpdate = (batch: BatchStatusData) => {
        setListPayload((prevPayload) => {
          if (!prevPayload) return prevPayload;
          return {
            ...prevPayload,
            data: prevPayload.data.map((item) => {
              if (item.batchId === batch.batchId) {
                return { ...item, status: batch.status };
              }

              return item;
            }),
          };
        });
      };

      if (socket) {
        socket.on<AllocationFileListenerEventsKeys>("batchStatusUpdate", handleStatusUpdate);

        return () => {
          socket.off<AllocationFileListenerEventsKeys>("batchStatusUpdate", handleStatusUpdate);
        };
      }
    },
    [socket],
    true,
  );

  useDidUpdate(() => {
    if (!query.status) {
      setActiveTab(0);
    }
  }, [queryString]);

  const tabs = [
    {
      tab: {
        name: `All Batches (${total})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: {
        name: `Ready to allocate (${readyToAllocate})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: {
        name: `On Calculation (${calculation})`,
      },
      tabComponent: <Table {...tableProps} />,
    },
  ];

  return (
    <Container>
      <div className={styles.filterWrap}>
        <Search searchValue={searchValue} setSearch={setSearch} />
      </div>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={onTabClick}
        tabClassName={styles.tabWrapAllocation}
      />
    </Container>
  );
});

export { AllocationListPage };
