import { getFileExtension } from "@epcnetwork/core-ui-kit";
import { FileQueueItem } from "@epcnetwork/core-ui-kit";

import { FileUploadStateModel } from "models";

import scheduledImg from "assets/images/clock-black.svg";
import EmptyImg from "assets/images/circle-exclamation-red.svg";
import doneImg from "assets/images/check-mark-rounded-green.svg";
import suppressionYellowImg from "assets/images/broom-yellow.svg";
import suppressionBlueImg from "assets/images/broom-blue.svg";

// FILE UTILS
export const getFileUuidName = ({ id, originalFile }: FileQueueItem): string => {
  return id + getFileExtension(originalFile.name);
};

// FILE DISPLAYING

type StageType = {
  name: "Scheduled" | "Processing" | "File Suppression" | "Global Suppression" | "Done" | "Empty";
  img: string;
};

export const stage1: FileUploadStateModel[] = ["scheduled"];
export const stage2: FileUploadStateModel[] = ["email-processing-started"];
export const stage3: FileUploadStateModel[] = [
  "file-suppression-in-progress",
  "file-suppression-completed",
];
export const stage4: FileUploadStateModel[] = ["global-suppression-in-progress"];
export const stage5: FileUploadStateModel[] = ["global-suppression-completed"];

export const getFileStage = (state: FileUploadStateModel): StageType => {
  if (stage1.includes(state)) {
    return { name: "Scheduled", img: scheduledImg };
  } else if (stage2.includes(state)) {
    return { name: "Processing", img: suppressionBlueImg };
  } else if (stage3.includes(state)) {
    return { name: "File Suppression", img: suppressionYellowImg };
  } else if (stage4.includes(state)) {
    return { name: "Global Suppression", img: suppressionYellowImg };
  } else if (stage5.includes(state)) {
    return { name: "Done", img: doneImg };
  }
  return { name: "Empty", img: EmptyImg };
};

export const formatFileBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
