import { FC, useRef, useState } from "react";
import { Button, Modal, Search, Table, TableContentRef } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types/common.types";
import { ReusableFileModel } from "models";
import { useInfiniteScrollFetch } from "hooks/use-infinite-scroll-fetch";
import { CANCEL_TEXT } from "constants/notification.constants";
import { getReusableFiles } from "api";
import { ReuseJobTableRow } from "./reuse-job-table-row/reuse-job-table-row";

import styles from "./reuse-job.module.css";

type ReuseJobModalProps = {
  onCancelClick: () => void;
  onSubmitClick: (item: ReusableFileModel) => void;
  handleModalClose: VoidFunction;
  isModalOpen: boolean;
};

const tableColumns: ColumnsType = [
  { label: "Job Name" },
  { label: "Job Method" },
  { label: "Api type" },
];

const ReuseJobModal: FC<ReuseJobModalProps> = ({
  onCancelClick,
  onSubmitClick,
  handleModalClose,
  isModalOpen,
}) => {
  const tableContentRef = useRef<TableContentRef>(null);

  const { loading, list, searchValue, setSearchValue, refresh, error } = useInfiniteScrollFetch<
    typeof tableContentRef,
    ReusableFileModel,
    typeof getReusableFiles
  >({
    containerRef: tableContentRef,
    asyncApiCall: getReusableFiles,
  });

  const [selectedItem, setSelectedItem] = useState<ReusableFileModel | undefined>(undefined);

  const handleCancelClick = () => onCancelClick();

  const handleSubmitClick = () => {
    selectedItem && onSubmitClick(selectedItem);
  };

  const handleSelect = (items: ReusableFileModel[]) => {
    setSelectedItem(items[0]);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setClose={handleModalClose}
      contentClassName={styles.modalContent}
      portalClassName={styles.portal}
    >
      <div className={styles.reuseJobModalTitle}>Select job to reuse</div>
      <Search
        className={styles.reuseJobModalSearch}
        searchValue={searchValue}
        setSearch={setSearchValue}
        stretched
      />
      <div className={styles.reuseJobModalTableWrap}>
        <Table
          entityName="job-create-reuse-file-modal"
          contentClassName={styles.reuseJobModalTable}
          columns={tableColumns}
          list={list}
          refresh={refresh}
          loading={loading}
          error={error?.message}
          itemIdKey="name"
          row={(item) => <ReuseJobTableRow item={item} />}
          onSelect={handleSelect}
          sharedTableContentRef={tableContentRef}
        />
      </div>
      <div className={styles.reuseJobModalActionsWrap}>
        <Button appearance="secondary" onClick={handleCancelClick}>
          {CANCEL_TEXT}
        </Button>
        <Button onClick={handleSubmitClick} disabled={!selectedItem}>
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export { ReuseJobModal };
