import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { FC, MouseEvent } from "react";
import cn from "classnames";

import { isActiveRoute } from "utils";
import { JOBS_FORM_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { useSidebar } from "../../navigation/use-sidebar.hook";

import styles from "../sidebar.module.css";

interface LinkProps {
  src: string;
  text: string;
  to: string;
  exact?: boolean;
  onClick?: VoidFunction;
}

const Link: FC<LinkProps> = ({ src, text, to, exact, onClick }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isMenuClosed } = useSidebar();

  const getActiveStyle = () => {
    const isActive = isActiveRoute(to, location);

    if (to === JOBS_LIST_PAGE.path && location.pathname === JOBS_FORM_PAGE.path) {
      return "";
    }

    return isActive ? styles.activeLink : "";
  };

  const handlerOnLinkClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onClick?.();
    navigate(to);
  };

  const activeStyle = getActiveStyle();
  const linkStyles = cn(styles.link, activeStyle, { [styles.collapsed]: isMenuClosed });

  return (
    <RouterLink to={to} onClick={handlerOnLinkClick} className={linkStyles}>
      <img src={src} alt="" />
      {text}
    </RouterLink>
  );
};

export { Link };
