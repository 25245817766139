import { memo } from "react";
import { Loader as CoreLoader } from "@epcnetwork/core-ui-kit";

import styles from "./loader.module.css";

type LoaderProps = {
  isAbsolute?: boolean;
  size?: number;
};

const Loader = memo<LoaderProps>(({ isAbsolute = true, size = 75 }) => {
  const style = isAbsolute ? styles.absolute : styles.normal;

  return (
    <div className={style}>
      <CoreLoader size={size} type="puff-loader" />
    </div>
  );
});

export { Loader };
