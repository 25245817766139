import { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { PermissionsModel } from "models";
import { useAuthorization } from "hooks";
import { RoleEntitiesType, RoleFormValues } from "../roles-form";

import globalStyles from "assets/styles/global.module.css";
import styles from "../roles-form.module.css";

type FormTableProps = {
  permissions: PermissionsModel;
  permissionAll: RoleEntitiesType["permissionAll"];
};

const FormTable: FC<FormTableProps> = ({ permissions }) => {
  const ability = useAuthorization();

  const { values, setFieldValue, validateForm } = useFormikContext<RoleFormValues>();

  const usedPermissions = [permissions.create, permissions.read, permissions.delete];
  const hasFullAccess =
    usedPermissions.filter((permission) => values.permissions.includes(permission)).length ===
    usedPermissions.length;

  useDidUpdate(() => {
    validateForm();
  }, [values]);

  const handleSelect = (value: string, checked: boolean) => {
    if (checked && !values.permissions?.includes(value)) {
      setFieldValue("permissions", [...values.permissions, value]);
    } else if (!checked) {
      const newPermissions = values.permissions.filter((permission) => value !== permission);
      setFieldValue("permissions", newPermissions);
    }
  };

  return (
    <div className={`${styles.responsiveTable} ${globalStyles.addScrollStyles}`}>
      <table className={styles.roleTable}>
        <thead>
          <tr>
            <td>Section</td>
            <td>Read</td>
            <td>Create</td>
            <td>Delete</td>
          </tr>
        </thead>
        <tbody>
          <tr key={permissions.name}>
            <td>{permissions.name}</td>
            <td>
              <FormField
                disabled={hasFullAccess || ability.cannot("create", "Users")}
                type="checkbox"
                name={permissions.read}
                onFieldChange={(value) => handleSelect(permissions.read, !!value)}
                disableError
              />
            </td>
            <td>
              <FormField
                disabled={hasFullAccess || ability.cannot("create", "Users")}
                type="checkbox"
                name={permissions.create}
                onFieldChange={(value) => handleSelect(permissions.create, !!value)}
                disableError
              />
            </td>
            <td>
              <FormField
                disabled={hasFullAccess || ability.cannot("create", "Users")}
                type="checkbox"
                name={permissions.delete}
                onFieldChange={(value) => handleSelect(permissions.delete, !!value)}
                disableError
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { FormTable };
