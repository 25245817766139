import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import {
  addDays,
  addHours,
  DateArgument,
  DateRangeFormat,
  isSameDate,
  isSameDay,
  notification,
  Nullable,
  roundToMinutes,
  Timezones,
  useCall,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { convertToTimezone, getISODateWithAmericaTimezone } from "utils";
import store from "store";
import { AllocationEndpointType, ReusableFileModel } from "models";
import { CONFIRM_TEXT } from "constants/notification.constants";
import { postReuseHourlySpread } from "api";
import { DaySpread, DayUnitType, HoursUnitsType } from "../days-allocation/days-allocation.types";
import { getConfirmReuseJobHeaderText, getInitialDateHoursSpread } from "../allocation-form.utils";
import { ParamsType } from "../allocation-form.types";
import { getStartDateForReusedJob } from "./endpoints-allocation.utils";
import { EndpointsAllocationValues, EndpointsRangeType } from "./endpoints-allocation.types";
import {
  allocationJobDaysLimit,
  confirmReuseJobText,
  daysOfOneYear,
  ENDPOINT_STOPPED_STATUSES,
  reuseJobErrorTitleText,
} from "./endpoints-allocation.constants";

type EndpointsAllocationReturnData = {
  hasMoreEmailsError: boolean;
  allEmails: boolean;
  minDate: Nullable<DateArgument>;
  maxDateFrom: Nullable<DateArgument>;
  maxDateTo: Nullable<DateArgument>;
  totalAmount: number;
  remainingAmount: number;
  usedAmount: number;
  canShowSwitched: boolean;
  sameSpread: boolean;
  copyFile: boolean;
  handleReuseJob: (job: ReusableFileModel) => void;
  allEmailsError: string;
  status: string;
  isStatusStopped: boolean;
  jobId: string;
  resetReusedJob: VoidFunction;
  endpoints: AllocationEndpointType[];
  endpointsRanges: EndpointsRangeType[];
  handleCopyFileChange: (value: string, isChecked: boolean) => void;
  handleRangeChange: (value: number, index: number) => void;
  handleSameSpreadChange: (value: string, isChecked: boolean) => void;
  timezone: Timezones;
};

export const useEndpointsAllocationData = (
  maxEmails: number,
  setReusedJob: (job: Nullable<ReusableFileModel>) => void,
  reusedJob: Nullable<ReusableFileModel>,
): EndpointsAllocationReturnData => {
  const [maxDateFrom, setMaxDateForm] = useState<Date>();
  const [maxDateTo, setMaxDateTo] = useState<Date>();
  const [isStatusStopped, setIsStatusStopped] = useState(false);

  const { jobId } = useParams<ParamsType>();
  const { values, touched, errors, setFormikState, submitCount, setFieldValue } =
    useFormikContext<EndpointsAllocationValues>();

  const state = store.getState();
  const timezone = state.ui.appTimezone;

  const {
    endpoints,
    endpointsRanges,
    totalAmount,
    remainingAmount,
    usedAmount,
    allEmails,
    copyFile,
    sameSpread,
    startDate,
    status,
  } = values;

  const { submit, onCallSuccess, onCallError } = useCall(postReuseHourlySpread);

  const handleRangeChange = (value: number, index: number) => {
    let used = 0;

    const newRangeArr = endpointsRanges.map((range, rangeIndex) => {
      const isChanged = index === rangeIndex;
      used += isChanged ? value : range.value;
      return isChanged ? { ...range, value } : range;
    });

    const totalToCheck = copyFile ? totalAmount * endpointsRanges.length : totalAmount;
    const remainingAmountToSet = totalToCheck - used;

    setFormikState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        endpointsRanges: newRangeArr,
        remainingAmount: remainingAmountToSet,
        usedAmount: used,
      },
    }));
  };

  const handleReuseJob = (job: ReusableFileModel) => {
    notification.confirm(getConfirmReuseJobHeaderText(job.name), confirmReuseJobText, {
      onOk: () => onSubmitReuseJob(job),
      okText: CONFIRM_TEXT,
    });
  };

  const submitReuseJob = (reusedJob: ReusableFileModel) => {
    const startDateForReusedJob = getStartDateForReusedJob(reusedJob, startDate);
    setFieldValue("startDate", startDateForReusedJob);

    const data = {
      jobId: reusedJob.id,
      startDate: getISODateWithAmericaTimezone(startDateForReusedJob),
      jobs: endpointsRanges.map(({ jobId, value }) => ({
        jobId,
        emailCount: value || totalAmount,
      })),
    };
    submit({ data });
  };

  const resetReusedJob = () => {
    setReusedJob(null);
    setFieldValue("endDate", "");
    setFieldValue("startDate", "");
    setFieldValue("dateHoursSpread", []);
  };

  const onSubmitReuseJob = (job: ReusableFileModel) => {
    setReusedJob(job);
    submitReuseJob(job);
  };

  const handleCopyFileChange = (_: string, isChecked: boolean) => {
    const shouldResetEndpoints = !isChecked && usedAmount > totalAmount;

    const endpointsRangesToSet = !shouldResetEndpoints
      ? allEmails && isChecked
        ? endpointsRanges.map((endpoint) => ({ ...endpoint, value: totalAmount }))
        : endpointsRanges
      : endpointsRanges.map((endpoint) => ({ ...endpoint, value: 0 }));

    const totalEdgeCaseAmount = totalAmount * endpointsRanges.length;
    const totalToCheck = isChecked ? totalEdgeCaseAmount : totalAmount;

    const usedAmountToSet = shouldResetEndpoints
      ? 0
      : allEmails && isChecked
        ? totalEdgeCaseAmount
        : usedAmount;

    const remainingAmountToSet = shouldResetEndpoints
      ? totalToCheck
      : !allEmails
        ? totalToCheck - usedAmountToSet
        : 0;

    setFormikState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        endpointsRanges: endpointsRangesToSet,
        remainingAmount: remainingAmountToSet,
        usedAmount: usedAmountToSet,
        copyFile: isChecked,
      },
    }));
  };

  const handleSameSpreadChange = (_: string, isChecked: boolean) => {
    const valueToSet = allEmails ? totalAmount : 0;

    const batchEndpoint = {
      jobId: -1,
      name: "Batch",
      apiType: `(${endpointsRanges.length} endpoints)`,
      value: valueToSet,
    };

    const endpointsRangesToSet: EndpointsRangeType[] = isChecked
      ? [batchEndpoint]
      : endpoints.map(({ jobId, name, apiType }) => ({
          jobId,
          name,
          apiType,
          value: 0,
        }));

    setFormikState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        endpointsRanges: endpointsRangesToSet,
        remainingAmount: isChecked ? 0 : totalAmount,
        usedAmount: isChecked ? totalAmount : 0,
        copyFile: isChecked,
      },
    }));
  };

  onCallError((error) => {
    notification.error(reuseJobErrorTitleText, error.message);
    setReusedJob(null);
  });

  onCallSuccess(({ jobs }) => {
    const endDate = convertToTimezone(jobs[0]?.endDate);
    setFieldValue("endDate", endDate);

    const initialDateHoursSpread = getInitialDateHoursSpread(
      startDate || minDate,
      endDate,
      endpointsRanges,
    );

    const dateHoursSpread: DaySpread[] = initialDateHoursSpread.map((dateSpread) => {
      const foundJob = jobs.find((el) => String(el.jobId) === String(dateSpread.jobId));
      if (!foundJob) return dateSpread;
      let used = 0;
      const dayUnitsToSet = dateSpread.dayUnits.map<DayUnitType>((dayUnit) => {
        const dayToCheck = foundJob.spread.find(({ day }) =>
          isSameDay(new Date(day), dayUnit.date),
        );
        if (!dayToCheck) return dayUnit;
        let dayTraffic = 0;

        const hoursUnitsToSet: HoursUnitsType = [];

        // fill initial values with data from server
        for (let i = 0; i < dayUnit.hoursUnits.length; i++) {
          const initialHourSpread = dayUnit.hoursUnits[i];
          const traffic = dayToCheck.hours[i]?.traffic ?? initialHourSpread.value;

          dayTraffic += traffic;

          hoursUnitsToSet.push({
            ...initialHourSpread,
            value: traffic,
          });
        }

        used += dayTraffic;

        return {
          ...dayUnit,
          value: dayTraffic,
          used: dayTraffic,
          hoursUnits: hoursUnitsToSet,
          date: dayUnit.date,
        };
      });

      return {
        ...dateSpread,
        dayUnits: dayUnitsToSet,
        used,
        remaining: dateSpread.total - used,
      };
    });
    setFieldValue("dateHoursSpread", dateHoursSpread);
  });

  const hasEnoughEndpoints = endpointsRanges.length > 1;
  const canShowSwitched = hasEnoughEndpoints || sameSpread;

  const allEmailsError = ((touched.allEmails || submitCount) && errors.allEmails) || "";

  // minDate takes date in a user timezone and converts it to DEFAULT_TIME_ZONE time but the timezone stay as was
  const minDate = convertToTimezone(roundToMinutes(addHours(1), 60));
  const hasMoreEmailsError = !!values.endpointsRanges.find((range) => range.value > maxEmails);

  useEffect(() => {
    setIsStatusStopped(!!ENDPOINT_STOPPED_STATUSES[status]);
  }, [status]);

  useDidUpdate(() => {
    if (!startDate) return;
    setMaxDateForm(addDays(daysOfOneYear, startDate));
    setMaxDateTo(addDays(allocationJobDaysLimit, startDate));
  }, [startDate]);

  return {
    hasMoreEmailsError,
    allEmails,
    minDate,
    maxDateFrom,
    maxDateTo,
    totalAmount,
    canShowSwitched,
    handleCopyFileChange,
    handleRangeChange,
    handleSameSpreadChange,
    sameSpread,
    allEmailsError,
    remainingAmount,
    usedAmount,
    endpoints,
    endpointsRanges,
    copyFile,
    status,
    isStatusStopped,
    timezone,
    handleReuseJob,
    resetReusedJob,
    jobId: jobId || "",
  };
};
