import { UserModel } from "models";
import { apiMiddleware } from "api";
import {
  LoginData,
  LoginResponse,
  ChangePasswordData,
  ResetPasswordData,
  ResetPasswordVerify,
} from "./auth.interface";

export const login = apiMiddleware<LoginResponse, LoginData>()({
  method: "post",
  endpoint: "/auth/login",
});

export const getMyProfile = apiMiddleware<UserModel>()({
  method: "get",
  endpoint: "/auth/me",
});

export const resetPassword = apiMiddleware<null, ResetPasswordData>()({
  method: "post",
  endpoint: `/reset-password`,
});

export const isTokenValid = apiMiddleware<ResetPasswordVerify>()({
  method: "get",
  endpoint: `/reset-password/verify/:key`,
});

export const changePassword = apiMiddleware<null, ChangePasswordData>()({
  method: "post",
  endpoint: `/reset-password/:key`,
});
