import { AxisDomain } from "recharts/types/util/types";
import { Bar, Rectangle } from "recharts";
import { ReactElement } from "react";
import cn from "classnames";
import { getJSDate } from "@epcnetwork/core-ui-kit";

import { JobTrafficModel, JobTrafficRescheduleReasons, JobTrafficTypes } from "models";
import { JOB_PROGRESS_STATUSES } from "constants/jobs.constants";
import {
  BarShapeProps,
  ChartBarConfig,
  ChartBarDataType,
  ChartTimeUnits,
  ChartTrafficOption,
  ChartTrafficTypesKeys,
  CreateBarConfigParams,
  FrozenGroup,
  FrozenGroupItem,
  FrozenGroupsBoundaryDate,
  ParsedJobData,
  ParseJobDataToChartDataParams,
} from "./chart.types";
import { chartTrafficTypes } from "./chart.constants";

import styles from "./chart.module.css";

export const getChartTrafficOptionFromType = (
  chartTrafficType: ChartTrafficTypesKeys,
): ChartTrafficOption => ({ value: chartTrafficType, label: chartTrafficTypes[chartTrafficType] });

export const chartTrafficOptions: ChartTrafficOption[] = Object.keys(chartTrafficTypes).map((key) =>
  getChartTrafficOptionFromType(key as ChartTrafficTypesKeys),
);

const findFirstFrozenItemIndex = (jobTrafficData: JobTrafficModel[]): number =>
  jobTrafficData.findIndex((jobTrafficDataItem) => jobTrafficDataItem.frozenTrafficRescheduled);

const createFrozenGroups = (jobTrafficData: JobTrafficModel[]): FrozenGroup[] => {
  const groups: FrozenGroup[] = [];

  if (!jobTrafficData.length) return groups;

  const firstFrozenItemIndex = findFirstFrozenItemIndex(jobTrafficData);

  if (firstFrozenItemIndex === -1) return groups;

  let frozenIndexCounter = firstFrozenItemIndex;

  const createGroup = (data: JobTrafficModel[]) => {
    const group: FrozenGroup = [];

    while (data[frozenIndexCounter]?.frozenTrafficRescheduled) {
      const groupItem = data[frozenIndexCounter];
      const transformedGroupItem: FrozenGroupItem = {
        ...groupItem,
        date: +getJSDate(groupItem.date),
        groupItemIndex: jobTrafficData.indexOf(groupItem),
      };
      group.push(transformedGroupItem);
      frozenIndexCounter++;
    }

    const slicedChartBarData = data.slice(frozenIndexCounter);
    const nextFrozenItemIndex = findFirstFrozenItemIndex(slicedChartBarData);

    frozenIndexCounter = nextFrozenItemIndex;

    groups.push(group);

    const notLastFreezeGroup =
      nextFrozenItemIndex !== -1 &&
      slicedChartBarData[nextFrozenItemIndex]?.frozenTrafficRescheduled;

    if (notLastFreezeGroup) {
      createGroup(slicedChartBarData);
    }
  };

  createGroup(jobTrafficData);

  return groups;
};

const calculateCompensatedTraffic = (traffic: number, percent: number): number => {
  if (!traffic) return 0;
  return traffic - (traffic * percent) / 100;
};

export const parseJobDataToChartData = ({
  jobTrafficData,
  yAxisMaxValue,
  jobConfiguration,
}: ParseJobDataToChartDataParams): ParsedJobData => {
  const defaultValue: ParsedJobData = {
    originalData: [],
    chartStackedBarsData: [],
  };
  if (!jobTrafficData.length) return defaultValue;

  const isJobStatusManualFrozen = jobConfiguration?.status === JOB_PROGRESS_STATUSES.frozen;
  const isJobStatusAutoFrozen = jobConfiguration?.status === JOB_PROGRESS_STATUSES.autofrozen;

  const frozenTraffic = (yAxisMaxValue / 100) * 2.5; // height

  const frozenGroups = createFrozenGroups(jobTrafficData);

  const frozenGroupsBoundaryDates: FrozenGroupsBoundaryDate[] = frozenGroups
    .map((frozenGroup) =>
      frozenGroup.filter((_, index) => index === 0 || index === frozenGroup.length - 1),
    )
    .map((frozenGroup) => {
      const consistOfOneItemPerFreezeGroup = frozenGroup.length === 1;
      if (consistOfOneItemPerFreezeGroup) {
        return { startDate: frozenGroup[0].date, endDate: frozenGroup[0].date };
      }
      return { startDate: frozenGroup[0].date, endDate: frozenGroup[1].date };
    });

  const unfrozenGroupsTimestampSet = new Set<number>();

  return jobTrafficData.reduce<ParsedJobData>(
    (
      acc,
      {
        date,
        target,
        successful,
        failed,
        suppressedButSent,
        frozenTrafficRescheduled,
        rescheduleReason,
        rescheduledFrom,
      },
      accIndex,
    ) => {
      const isTrafficManualFrozen = frozenTrafficRescheduled && isJobStatusManualFrozen;
      const isTrafficAutoFrozen = frozenTrafficRescheduled && isJobStatusAutoFrozen;
      const wasTrafficManualFrozen =
        rescheduleReason === JobTrafficRescheduleReasons.frozenTrafficCompensation;

      const isTrafficUnfrozen = !isTrafficManualFrozen && !isTrafficAutoFrozen && !!rescheduledFrom;

      const shouldRenderChartFreezeLine =
        ((isJobStatusManualFrozen || isJobStatusAutoFrozen) &&
          (isTrafficManualFrozen || isTrafficAutoFrozen)) ||
        isTrafficUnfrozen;

      const jobTrafficDate = getJSDate(date);

      const jobTrafficTimestamp = +jobTrafficDate;

      const xAxis = String(jobTrafficTimestamp);

      const commonFields: Omit<ChartBarDataType, "targetTraffic"> = {
        date: jobTrafficDate,
        xAxis,
        succeededTraffic: successful,
        failedTraffic: failed,
        suppressedButSentTraffic: suppressedButSent,
        rescheduledFrom,
        isTrafficManualFrozen,
        isTrafficAutoFrozen,
        wasTrafficManualFrozen,
        ...(shouldRenderChartFreezeLine ? { frozenTraffic } : {}),
      };

      if (isTrafficUnfrozen) {
        const rescheduledFromTimestamp = rescheduledFrom && +getJSDate(rescheduledFrom);
        if (rescheduledFromTimestamp) unfrozenGroupsTimestampSet.add(rescheduledFromTimestamp);
        frozenGroupsBoundaryDates.forEach(({ startDate, endDate }) => {
          // fill dates for unfrozen groups
          if (
            rescheduledFromTimestamp &&
            rescheduledFromTimestamp >= startDate &&
            rescheduledFromTimestamp <= endDate
          ) {
            commonFields.frozenStartDate = startDate;
            commonFields.frozenEndDate = endDate;
          }
        });
      }

      acc.originalData.push({
        targetTraffic: target,
        ...commonFields,
      });

      // make bars height on the correct level towards chart "y" axis
      const percent = target === 0 ? 0 : Math.round((frozenTraffic * 100) / target);
      const targetTrafficCompensated = calculateCompensatedTraffic(target, percent);
      const successfulTrafficCompensated = calculateCompensatedTraffic(
        successful - suppressedButSent,
        percent,
      );
      const failedTrafficCompensated = calculateCompensatedTraffic(failed, percent);
      const suppressedButSentTrafficCompensated = calculateCompensatedTraffic(
        suppressedButSent,
        percent,
      );
      const composedChartTargetTraffic =
        targetTrafficCompensated -
        successfulTrafficCompensated -
        failedTrafficCompensated -
        suppressedButSentTrafficCompensated;

      acc.chartStackedBarsData.push({
        ...commonFields,
        targetTraffic: composedChartTargetTraffic,
        succeededTraffic: successfulTrafficCompensated,
        failedTraffic: failedTrafficCompensated,
        suppressedButSentTraffic: suppressedButSentTrafficCompensated,
      });

      // mark if item was already unfrozen
      const isLastIteration = accIndex === jobTrafficData.length - 1;
      if (isLastIteration) {
        frozenGroups.flat().forEach((frozenGroup) => {
          if (
            unfrozenGroupsTimestampSet.has(frozenGroup.date) &&
            typeof frozenGroup.groupItemIndex !== "undefined"
          ) {
            acc.chartStackedBarsData[frozenGroup.groupItemIndex].wasUnfrozen = true;
            acc.originalData[frozenGroup.groupItemIndex].wasUnfrozen = true;

            // reset data responsive for rendering chart bar/area for unfrozen (already rescheduled) emails
            delete acc.chartStackedBarsData[frozenGroup.groupItemIndex].frozenTraffic;
            delete acc.originalData[frozenGroup.groupItemIndex].frozenTraffic;
          }
        });
      }

      return acc;
    },
    defaultValue,
  );
};

export const handleChartDomains = (
  domainValue: number,
  type: "min" | "max",
  chartTimeUnit: ChartTimeUnits,
): number => {
  if (type === "min") return 0;

  const reservedCoefficient = isHourlyChartTimeUnit(chartTimeUnit) ? 0.2 : 0.3;

  const reservedValue = domainValue * reservedCoefficient;

  let valueToSet = Math.round(domainValue + reservedValue);

  const smallValueToCheck = 4;

  if (valueToSet < smallValueToCheck) {
    valueToSet++;
  }

  return valueToSet;
};

export const getYAxisDomain = (
  chartTimeUnit: ChartTimeUnits,
  callback?: (maxValue: number) => void,
): AxisDomain => {
  const maxDomainValue = (domainValue: number): number => {
    const maxDomainValue = handleChartDomains(domainValue, "max", chartTimeUnit);

    callback?.(maxDomainValue);

    return maxDomainValue;
  };

  const minDomainValue = (domainValue: number): number =>
    handleChartDomains(domainValue, "min", chartTimeUnit);
  return [minDomainValue, maxDomainValue];
};

export const isHourlyChartTimeUnit = (chartTimeUnit: ChartTimeUnits): boolean =>
  chartTimeUnit === ChartTimeUnits.hourly;

export const isDailyChartTimeUnit = (chartTimeUnit: ChartTimeUnits): boolean =>
  chartTimeUnit === ChartTimeUnits.daily;

export const createBarConfig = ({
  dataKey,
  className,
  radius = [0, 0, 0, 0],
}: CreateBarConfigParams): ChartBarConfig => ({
  dataKey,
  className,
  radius,
  barSize: 32,
});

export const succeededTrafficBarConfig = createBarConfig({
  dataKey: JobTrafficTypes.succeededTraffic,
  className: "succeededTraffic",
});
export const failedTrafficBarConfig = createBarConfig({
  dataKey: JobTrafficTypes.failedTraffic,
  className: "failedTraffic",
});
export const targetTrafficBarConfig = createBarConfig({
  dataKey: JobTrafficTypes.targetTraffic,
  className: "targetTraffic",
});
export const suppressedButSentTrafficBarConfig = createBarConfig({
  dataKey: JobTrafficTypes.suppressedButSentTraffic,
  className: "suppressedButSentTraffic",
});

export const createBar = ({
  dataKey,
  barSize,
  radius,
  stackId,
  className,
}: ChartBarConfig): ReactElement => {
  return (
    <Bar
      key={dataKey}
      dataKey={dataKey}
      stackId={stackId}
      barSize={barSize}
      radius={radius}
      className={className}
      shape={({
        x,
        y,
        width,
        height,
        radius,
        isAnimationActive,
        isUpdateAnimationActive,
        animationBegin,
        animationDuration,
        animationEasing,
        className: shapeClassName,
        payload,
      }: BarShapeProps) => {
        const { rescheduledFrom, isTrafficManualFrozen, isTrafficAutoFrozen, wasUnfrozen } =
          payload;

        const yCoordinateCompensation =
          rescheduledFrom || isTrafficManualFrozen || isTrafficAutoFrozen ? 0 : 1;
        const yCoordinate = y && y - yCoordinateCompensation; // make frozen bar height same as frozen area

        return (
          <Rectangle
            x={x}
            y={yCoordinate}
            width={width}
            height={height}
            radius={radius}
            isAnimationActive={isAnimationActive}
            isUpdateAnimationActive={isUpdateAnimationActive}
            animationBegin={animationBegin}
            animationDuration={animationDuration}
            animationEasing={animationEasing}
            className={cn(shapeClassName, {
              [styles.barHidden]: wasUnfrozen,
            })}
          />
        );
      }}
    />
  );
};
