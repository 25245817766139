import { memo } from "react";
import { useFormikContext } from "formik";
import cn from "classnames";
import { MessageField, Nullable } from "@epcnetwork/core-ui-kit";

import { convertToTimezone } from "utils";
import { ReusableFileModel } from "models";
import { CustomInfo } from "../information/custom-info";
import { HoursUnitsType } from "../days-allocation/days-allocation.types";
import { AllocationUnit, AllocationUnitType } from "../allocation-unit/allocation-unit";
import { InitialValues } from "../allocation-form.types";
import { maxHourAllocation } from "../allocation-form.constants";

import globalStyles from "assets/styles/global.module.css";
import styles from "./allocation-spead-table.module.css";

export type { AllocationUnitType };

type AllocationSpreadTableProps = {
  className?: string;
  allocationUnitClassName?: string;
  allEmailsError?: string;
  detailsText?: string;
  total: number;
  remaining: number;
  used: number;
  units: Array<AllocationUnitType & { hoursUnits?: HoursUnitsType }>;
  showRemainingValue?: boolean;
  disableRestrict?: boolean;
  onChange: (value: number, rangeIndex: number) => void;
  maxValue?: number;
  reusedJob?: Nullable<ReusableFileModel>;
};

const AllocationSpreadTable = memo<AllocationSpreadTableProps>(
  ({
    className = "",
    allocationUnitClassName = "",
    allEmailsError = "",
    detailsText = "",
    total,
    remaining,
    used,
    units,
    showRemainingValue = false,
    disableRestrict = false,
    onChange,
    maxValue,
    reusedJob,
  }) => {
    const { values } = useFormikContext<InitialValues>();

    const showRemaining = showRemainingValue || !!allEmailsError;

    const tableWrapStyles = cn(styles.allocationRangeWrap, className);
    const remainingStyles = cn(globalStyles.addTextStatus, { [globalStyles.red]: allEmailsError });

    const maxAllocation =
      typeof maxValue === "number" && maxValue !== 0 && maxValue < total ? maxValue : total;

    const hasMoreEmailsError =
      values.startDate && values.endDate && units.find((unit) => unit.value > maxAllocation);

    return (
      <>
        {hasMoreEmailsError && (
          <CustomInfo
            type="error"
            text={`One of allocations will cause service limit issues - make sure you setup maximum value of ${maxValue} for this time range`}
          />
        )}
        <div className={tableWrapStyles}>
          <div className={styles.headerWrap}>
            <div className={styles.headerTextsWrap}>
              {detailsText && <span>{detailsText} &nbsp; &nbsp;</span>}
              <span>Total to allocate: {total}</span>
              <span className={remainingStyles}>Remaining: {showRemaining ? remaining : 0}</span>
              <span>Used: {used}</span>
            </div>
          </div>
          <div className={styles.distributionWrap}>
            {units.map((item, index) => {
              const now = new Date();
              const itemDate = item?.date;
              now.setHours(now.getHours() + 1);
              now.setMinutes(0, 0, 0);
              const minDate = convertToTimezone(now);

              const isDisabledRange = itemDate ? itemDate < minDate : false;

              const hoursLimit = item?.hoursUnits?.length;

              const maxAllocationValue =
                typeof hoursLimit === "number"
                  ? Math.min(hoursLimit * maxHourAllocation, maxAllocation)
                  : maxAllocation;
              const invalidUnit = item.value > maxAllocationValue && typeof hoursLimit === "number";

              return (
                <AllocationUnit
                  key={item.name + index}
                  className={allocationUnitClassName}
                  item={item}
                  index={index}
                  maxValue={maxAllocationValue}
                  remainingAmount={remaining}
                  onValueChange={onChange}
                  disableRestrict={disableRestrict}
                  isDisabledInput={isDisabledRange}
                  invalid={invalidUnit}
                  reusedJob={reusedJob}
                />
              );
            })}
          </div>
        </div>
        <MessageField message={allEmailsError} />
      </>
    );
  },
);

export { AllocationSpreadTable };
