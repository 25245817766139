import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types/common.types";
import { defaultLimit, defaultOffset } from "constants/query.constants";
import { searchMinLength } from "constants/form.constants";
import { FilesFiltersQueryType, FileSupportedStatuses } from "./files-list.types";

export const tableColumns: ColumnsType = [
  { label: "File Name", required: true, queryKey: "fileName" },
  { label: "Suppression File" },
  { label: "Columns" },
  { label: "Upload Date", queryKey: "uploadDate" },
  { label: "Stage" },
  { label: "" },
];

export const supportedFormats = [
  ".csv",
  "text/csv",
  "application/csv",
  "text/x-csv",
  "application/x-csv",
];

export const tabStatesMap: Record<number, FileSupportedStatuses> = {
  0: "all",
  1: "email-processing-started",
  2: "global-suppression-completed",
};
export const errorDeleteFileTitle = "Cannot delete file";
export const TABLE_ENTITY_NAME = "files-list";
export const fileUploadTitleText = "Does it have the suppression list?";
export const listTypesSelectLabelText = "Does it have mergex suppressions list?";

export const initialFilters: UseFiltersProps<FilesFiltersQueryType> = {
  searchOptions: { searchMinLength, searchKey: "search", searchDebounce: 1200 },
  initialState: {
    search: "",
    state: "all",
    limit: defaultLimit,
    offset: defaultOffset,
  },
};

export const essentialColumn = "Email";
