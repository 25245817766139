import { FC, useEffect } from "react";
import {
  CollapseSelfControlled,
  FetchingError,
  NoContent,
  Pagination,
  TransitionWrapper,
  useDevice,
  useFetch,
  useFilters,
  UseFiltersProps,
  usePagination,
  useQuery,
} from "@epcnetwork/core-ui-kit";

import { generateOptions, getInitialStorageFilters } from "utils";
import { QueryType, StorageTableType } from "types";
import { usePayload, useShowContent, useTablePagination } from "hooks";
import { defaultOffset } from "constants/query.constants";
import { Loader } from "components";
import { getJobActivityLogs } from "api";
import {
  fetchingErrorText,
  noContentFound,
  perPageOptionsList,
  TABLE_ENTITY_NAME,
} from "./logs-activity-panel.constants";
import { LogsActivityItem } from "./logs-activity-item";

import { ReactComponent as ArrowIcon } from "assets/images/triangle-arrow-right-grey.svg";
import styles from "./logs-activity-panel.module.css";

export type LogsActivityPanelProps = {
  jobId: number;
};

const getInitialFilters = (): UseFiltersProps<QueryType> => {
  const tableDataJson = localStorage.getItem(TABLE_ENTITY_NAME);
  const limit =
    tableDataJson && (JSON.parse(tableDataJson) as StorageTableType).tableElementsPerPage;

  return {
    initialState: {
      limit: String(limit ?? perPageOptionsList[0]),
      offset: defaultOffset,
      type: "all",
      provider: "all",
      order: "desc",
    },
  };
};

const LogsActivityPanel: FC<LogsActivityPanelProps> = ({ jobId }) => {
  const { isMobileDevice } = useDevice();
  const { stringify } = useQuery();
  const initialFilters = getInitialFilters();

  const { state, currentState, setValue } = useFilters<QueryType>({
    ...getInitialStorageFilters<QueryType>(TABLE_ENTITY_NAME, initialFilters),
    isStateBased: true,
  });

  const logsResponse = useFetch(
    getJobActivityLogs.setParams({ jobId }).setQueryParams(stringify(state)),
    {
      dependencies: [state],
    },
  );

  const { list, payload, loading, error } = usePayload(logsResponse);
  const { showContent, showNoContent, showError } = useShowContent(logsResponse);

  const pagination = usePagination({
    listPayload: payload,
    isStateBased: true,
    initialState: currentState,
  });

  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_ENTITY_NAME,
    updateElementsPerPage: true,
  });

  useEffect(() => {
    setValue("offset")(pagination.state.offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.state.offset]);

  useEffect(() => {
    setValue("limit")(pagination.state.limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.state.limit]);

  return (
    <div className={styles.activityLogWrap}>
      <CollapseSelfControlled
        initiallyOpened
        triggerElement={<span className={styles.activityLogText}>Activity Log</span>}
      >
        <>
          <ArrowIcon className={styles.logItemsArrowIcon} />
          <div className={styles.logItemsWrap}>
            {loading && <Loader size={50} />}
            {showContent &&
              list.map((item) => (
                <LogsActivityItem
                  key={item.id}
                  item={item}
                  isReconfigure={item.field === "reconfigureDate"}
                />
              ))}
            {showNoContent && (
              <TransitionWrapper isIn>
                <NoContent subtitle={noContentFound} size="small" showButton={false} />
              </TransitionWrapper>
            )}
            {showError && error && (
              <TransitionWrapper isIn>
                <FetchingError title={fetchingErrorText} subtitle={error.message} size="small" />
              </TransitionWrapper>
            )}
          </div>
          {showContent && (
            <div className={styles.paginationWrap}>
              <Pagination
                {...pagination}
                elementsPerPage={currentElementsPerPage}
                perPageTitle=""
                perPageInfo={false}
                shouldScrollAfterPageChange={false}
                pageNumControls={!isMobileDevice}
                controls={isMobileDevice ? "arrows" : "text"}
                perPageSelectClassName={styles.perPageSelect}
                onElementsPerPageChange={handlePerPageChange}
                perPageOptionsList={generateOptions(perPageOptionsList)}
                className={styles.logsInfoPagination}
                dropdownPositionCalculationDeps={[list]}
              />
            </div>
          )}
        </>
      </CollapseSelfControlled>
    </div>
  );
};

export { LogsActivityPanel };
