import { useNavigate } from "react-router-dom";
import { readString } from "react-papaparse";
import { memo, useRef, useState, useCallback } from "react";
import { ParseResult } from "papaparse";
import cn from "classnames";
import {
  Modal,
  addClassesToElement,
  removeClassesFromElement,
  FileUpload,
  FileItem,
  DropAcceptedFunc,
  useWillUnmount,
  useFileQueue,
  Button,
  notification,
  isDeepEmpty,
  bytesConverter,
  CoreKitFilesReader,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { ExcludesFalse } from "types";
import { JOBS_FORM_PAGE } from "constants/routes.constants";
import { CANCEL_TEXT, SUCCESS_TEXT } from "constants/notification.constants";
import { FileData } from "api/upload/upload.interface";
import { postUploadedFiles } from "api";
import { FileItemType } from "../files-list.types";
import { supportedFormats } from "../files-list.constants";
import { FileItemModal } from "./file-item-modal";

import { ReactComponent as SuppressionFileImg } from "assets/images/document-with-cross-grey.svg";
import styles from "./file-upload.module.css";

const MAX_FILE_SIZE = 30;

export type FileUploadProps = {
  refreshTable: VoidFunction;
};

const FileUploading = memo<FileUploadProps>(({ refreshTable }) => {
  const navigate = useNavigate();

  const componentIsMounted = useRef(true);
  useWillUnmount(() => {
    componentIsMounted.current = false;
  });

  const dropzoneRef = useRef<HTMLDivElement>(null);

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    submitSeparately,
    hasInvalidFile,
    hasAllConfigured,
    isEntityInConfiguration,
    isEntityFinished,
  } = useFileQueue<FileItemType, FileData>("files-upload", postUploadedFiles, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalFile, setCurrentModalFile] = useState<FileItemType | undefined>(undefined);
  const [isProcessingFiles, setIsProcessingFiles] = useState(false);

  const handleWindowEnter = useCallback(() => {
    !files.length && addClassesToElement(dropzoneRef.current, styles.isDragging);
  }, [files.length]);

  const handleWindowLeave = useCallback(() => {
    removeClassesFromElement(dropzoneRef.current, styles.isDragging);
  }, []);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      const entityFiles = acceptedFiles.map((file) => {
        return createInitialFile(file, {
          isLoading: true,
          data: {
            emailColumn: "",
            hasHeaders: false,
            suppressionList: [],
            fileParsedContent: [],
            headers: [],
            listIds: [],
          },
        });
      });

      new CoreKitFilesReader(entityFiles)
        .onSuccess(({ id, result }) => {
          readString(result, {
            preview: 6,
            worker: true,
            complete: function (parsedResult: ParseResult<string[]>) {
              const parsedFileData = parsedResult.data || [];

              const isArrErr = !Array.isArray(parsedFileData);
              const parsingError = isArrErr && parsedResult.errors?.[0];
              const hasError = parsingError || isArrErr;

              if (!hasError && Array.isArray(parsedFileData) && !isDeepEmpty(parsedFileData)) {
                updateFiles({
                  id,
                  file: {
                    isLoading: false,
                    parsed: "valid",
                    data: { fileParsedContent: parsedFileData },
                  },
                });
              } else {
                updateFiles({
                  id,
                  file: {
                    isLoading: false,
                    parsed: "invalid",
                    error: "File is invalid",
                    data: { fileParsedContent: [] },
                  },
                });
              }
            },
          });
        })
        .onFinal(() => componentIsMounted.current && setIsProcessingFiles(false))
        .read();

      setIsProcessingFiles(true);
      addFiles(entityFiles);
    },
    [addFiles, createInitialFile, updateFiles],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setIsModalOpen(true);
      setCurrentModalFile(item);
    },
    [getItem],
  );

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setCurrentModalFile(undefined);
  }, []);

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: FileItemType) => {
      updateFiles({ id, file: rest });
      setIsModalOpen(false);
      setCurrentModalFile(undefined);
    },
    [updateFiles],
  );

  const handleSubmit = () => {
    const data = files.map(({ id, originalFile, data }) => {
      const fileData: FileData = {
        file: originalFile,
        emailColumn: {
          name: data.emailColumn,
          key: data.headers.findIndex(({ value }) => value === data.emailColumn),
        },
        headers: data.headers
          .map(({ value, label }, index) => {
            if (!value || !label) return;
            return { name: value, key: index };
          })
          .filter(Boolean as unknown as ExcludesFalse),
        hasHeaders: data.hasHeaders,
        suppress: !!data.suppressionList.length,
        suppressionFile: data.suppressionList.map(({ originalFile }) => originalFile),
        listIds: data.listIds,
      };

      const formData = new FormData();
      formData.append("file", fileData.file);
      formData.append("emailColumn", JSON.stringify(fileData.emailColumn));
      formData.append("headers", JSON.stringify(fileData.headers));
      formData.append("hasHeaders", String(fileData.hasHeaders));
      formData.append("suppress", String(!!data.suppressionList.length));
      formData.append("listIds", JSON.stringify(fileData.listIds));
      fileData.suppressionFile.forEach((file) => {
        formData.append("suppressionFile", file, file.name);
      });

      return { id, data: formData };
    });

    submitSeparately(data);
  };

  const handleRefresh = () => {
    clearEntity();

    notification.confirm(SUCCESS_TEXT, "File upload is successful", {
      onOk: () => navigate(JOBS_FORM_PAGE.path),
      okText: "Create a job",
      onCancel: refreshTable,
      cancelText: "Upload another file",
      icon: "success",
    });
  };

  const handleSingleFileError = () => {
    notification.confirm("Could not upload your file", "File upload has failed", {
      icon: "danger",
      onOk: clearEntity,
    });
  };

  const handleMultipleFilesError = (allFailed: boolean, failedFiles: FileItemType[]) => {
    const title = allFailed ? "Could not upload your files" : "Could not upload some of your files";
    const description = `Several files failed to upload: ${failedFiles.map(
      (file) => file.originalFile.name,
    )}`;
    notification.confirm(title, description, {
      icon: "danger",
      onOk: clearEntity,
    });
  };

  useDidUpdate(() => {
    const singular = files.length === 1;

    if (isEntityFinished) {
      const hasEveryFailed = files.every((file) => file.uploadingInfo.uploadingResult === "failed");
      const hasAnyFailed = files.some((file) => file.uploadingInfo.uploadingResult === "failed");
      const failedFiles = files.filter((file) => file.uploadingInfo.uploadingResult === "failed");

      if (hasAnyFailed) {
        if (singular) handleSingleFileError();
        if (!singular) handleMultipleFilesError(hasEveryFailed, failedFiles);
      } else {
        handleRefresh();
      }
    }
  }, [isEntityFinished]);

  return (
    <div className={styles.wrapper}>
      <FileUpload
        className={cn(styles.dropZone, styles.override)}
        uploadedFilesLength={files.length}
        subtitle={`Accepted csv files up to ${MAX_FILE_SIZE}Mb`}
        accept={supportedFormats}
        maxFiles={10}
        maxSize={bytesConverter(MAX_FILE_SIZE, "Bytes", "MB")}
        forwardRef={dropzoneRef}
        onWindowEnter={handleWindowEnter}
        onWindowLeave={handleWindowLeave}
        onWindowDrop={handleWindowLeave}
        onDropAccepted={handleAcceptedDrop}
        exceedFilesOption="splice-with-error"
        disabled={isProcessingFiles || !isEntityInConfiguration}
        preventDropOnDocument
        multiple
      />
      {!!files.length && (
        <div className={styles.filesContent}>
          {files.map(({ id, originalFile, data, ...rest }) => (
            <FileItem
              {...rest}
              key={id}
              id={id}
              file={originalFile}
              onCrossClick={removeFiles}
              onSetValuesClick={handleConfigureItem}
              onEditValuesClick={handleConfigureItem}
            >
              {!!data.suppressionList.length && (
                <div className={styles.itemSuppressionWrap}>
                  <SuppressionFileImg />
                  {data.suppressionList.length} Suppression list attached
                </div>
              )}
            </FileItem>
          ))}
          <div className={styles.actionsWrap}>
            {isEntityInConfiguration && (
              <>
                <Button
                  appearance="secondary"
                  onClick={handleClearClick}
                  disabled={isProcessingFiles}
                >
                  {CANCEL_TEXT}
                </Button>
                <Button
                  appearance="primary"
                  onClick={handleSubmit}
                  disabled={hasInvalidFile || !hasAllConfigured}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {currentModalFile && (
        <Modal isOpen={isModalOpen} setClose={handleModalClose}>
          <FileItemModal
            fileItem={currentModalFile}
            onCancelClick={handleModalClose}
            onSubmitClick={handleModalSubmit}
          />
        </Modal>
      )}
    </div>
  );
});

export { FileUploading };
