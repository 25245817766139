import { GroupModel } from "models";
import { apiMiddleware } from "api";
import { GroupData } from "./groups.interface";

export const getGroups = apiMiddleware<GroupModel[]>()({
  method: "get",
  endpoint: "/groups",
});

export const getGroup = apiMiddleware<GroupModel>()({
  method: "get",
  endpoint: `/groups/:groupId`,
});

export const putGroup = apiMiddleware<GroupModel, GroupData>()({
  method: "put",
  endpoint: `/groups/:groupId`,
});

export const postGroup = apiMiddleware<GroupModel, GroupData>()({
  method: "post",
  endpoint: "/groups",
});

export const deleteGroup = apiMiddleware<GroupModel>()({
  method: "delete",
  endpoint: `/groups/:groupId`,
});
