import { useCall, useDidMount, notification } from "@epcnetwork/core-ui-kit";

import { getJob, getJobsBatch } from "api";
import { EndpointType } from "../endpoint/endpoint.types";

type EditProps = {
  jobId?: string;
  batchId?: string;
  setInitialState: (endpoints: EndpointType[], fileIds: Array<string>, tagId?: number) => void;
};

export const useEdit = ({ jobId = "", batchId = "", setInitialState }: EditProps): boolean => {
  const jobApi = useCall(getJob.setParams({ jobId }));
  const batchApi = useCall(getJobsBatch.setParams({ batchId }));

  const isInEdit = !!jobId || !!batchId;

  useDidMount(() => {
    if (batchId) {
      batchApi.submit();
    } else if (jobId) {
      jobApi.submit();
    }
  });

  batchApi.onCallSuccess((batch) => {
    const tagToSave = batch?.tag?.id ? batch?.tag?.id : undefined;
    const endpoints: EndpointType[] = batch.endpoints?.map(
      ({ name, webhookId, connector, jobId, proxyId, proxyValue }) => ({
        name,
        webhookId,
        jobId,
        tagId: batch.tag,
        proxyId,
        proxyValue,
        connector: {
          id: String(connector.id),
          apiType: connector.apiType,
          properties: connector.properties,
        },
      }),
    );

    setInitialState(endpoints, batch.fileIds, tagToSave);
  });

  jobApi.onCallSuccess((job) => {
    const { name, webhookId, connector, proxyId, proxyValue, tag, fileIds, id, connectorId } = job;
    const tagToSave = tag?.id ? tag.id : undefined;

    const singleEndpoint = {
      name,
      webhookId,
      jobId: String(id),
      tagId: tag,
      proxyId,
      proxyValue,
      connector: {
        id: String(connectorId),
        apiType: connector.apiType,
        properties: connector.properties,
      },
    };

    setInitialState([singleEndpoint], fileIds, tagToSave);
  });

  batchApi.onCallError((error) => {
    notification.error("Cannot initialize reconfiguration", error.message);
  });

  jobApi.onCallError((error) => {
    notification.error("Cannot initialize reconfiguration", error.message);
  });

  return isInEdit;
};
