export const noneOptionValue = "none";

export const allocationPresetsOptions = [
  { value: "default", label: "Auto" },
  { value: "warmup-light", label: "Warm up light" },
  { value: "warmup-standard", label: "Warm up standard" },
  { value: "sf-warmup", label: "SF Warm up" },
  { value: noneOptionValue, label: "None" },
];

export const defaultAllocationPreset = allocationPresetsOptions[0].value;
