import { ITrackProps } from "react-range/lib/types";
import { FC, ReactNode } from "react";

import styles from "./chart.module.css";

type ChartPreviewTrackProps = {
  props: ITrackProps;
  children?: ReactNode;
};

const ChartPreviewTrack: FC<ChartPreviewTrackProps> = ({ props, children }) => {
  return (
    <div {...props} className={styles.chartPreviewRangeTrack}>
      {children}
    </div>
  );
};

export { ChartPreviewTrack };
