import { FC } from "react";
import { addZero, dateInputFormat } from "@epcnetwork/core-ui-kit";

import { convertToTimezone, formatDate, getJobStatusText } from "utils";
import { JobProgressStatuses } from "types";
import { JobLogActivityModel } from "models";
import { LogsActivityStatusItem } from "./logs-activity-status-item";
import { reconfiguredText } from "./logs-activity-panel.constants";

import { ReactComponent as ClockIcon } from "assets/images/clock-grey.svg";
import styles from "./logs-activity-panel.module.css";

type LogInfoItemProps = {
  item: JobLogActivityModel;
  isReconfigure: boolean;
};

type LogsItemProps = {
  date: string;
  hours: string;
  minutes: string;
  oldValue?: string;
  newValue: string;
};

const LogsActivityItem: FC<LogInfoItemProps> = ({ item, isReconfigure }) => {
  const { newValue, oldValue, createdAt } = item;

  const date = formatDate(createdAt, dateInputFormat);
  const hours = addZero(convertToTimezone(createdAt).getHours());
  const minutes = addZero(convertToTimezone(createdAt).getMinutes());

  return isReconfigure ? (
    <LogsStatusItem date={date} hours={hours} minutes={minutes} newValue={reconfiguredText} />
  ) : (
    <LogsStatusItem
      date={date}
      hours={hours}
      minutes={minutes}
      oldValue={getJobStatusText(oldValue.data as JobProgressStatuses)}
      newValue={getJobStatusText(newValue.data as JobProgressStatuses)}
    />
  );
};

const LogsStatusItem: FC<LogsItemProps> = ({ date, hours, minutes, oldValue, newValue }) => {
  return (
    <div className={styles.logItemWrap}>
      <div className={styles.logItemTimeWrap}>
        <div>
          <span className={styles.logItemTime}>{date}</span>
        </div>
        <div className={styles.timeWrapper}>
          <ClockIcon className={styles.logItemIcon} />
          <span className={styles.clockTimeTextStyles}>
            {hours}:{minutes}
          </span>
        </div>
      </div>
      <div className={styles.logItemStatusesWrap}>
        <LogsActivityStatusItem oldValue={oldValue} newValue={newValue} />
      </div>
    </div>
  );
};

export { LogsActivityItem };
