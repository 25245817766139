import { FC } from "react";
import cn from "classnames";

import { isDailyChartTimeUnit, isHourlyChartTimeUnit } from "./chart.utils";
import { ChartTimeUnits } from "./chart.types";

import styles from "./chart.module.css";

interface ChartTimeUnitSwitcherProps {
  currentChartTimeUnit: ChartTimeUnits;
  setHourlyUnit: VoidFunction;
  setDailyUnit: VoidFunction;
}

const ChartTimeUnitSwitcher: FC<ChartTimeUnitSwitcherProps> = ({
  currentChartTimeUnit,
  setHourlyUnit,
  setDailyUnit,
}) => {
  return (
    <div className={styles.timeSwitcherGroup}>
      <button
        className={cn(
          styles.timeSwitcher,
          isHourlyChartTimeUnit(currentChartTimeUnit) && styles.active,
        )}
        onClick={setHourlyUnit}
      >
        Hourly
      </button>
      <button
        className={cn(
          styles.timeSwitcher,
          isDailyChartTimeUnit(currentChartTimeUnit) && styles.active,
        )}
        onClick={setDailyUnit}
      >
        Daily
      </button>
    </div>
  );
};

export { ChartTimeUnitSwitcher };
