import { ReactElement } from "react";

import {
  createBar,
  failedTrafficBarConfig,
  succeededTrafficBarConfig,
  suppressedButSentTrafficBarConfig,
  targetTrafficBarConfig,
} from "./chart.utils";
import { ChartTrafficTypesKeys } from "./chart.types";
import { ChartMainProps } from "./chart-main";

type ChartDataMapItem = (stackId: ChartMainProps["trafficType"]) => ReactElement;
type ChartDataMap = Record<ChartTrafficTypesKeys, ChartDataMapItem | ChartDataMapItem[]>;

export const chartDataMap: ChartDataMap = {
  all: [
    // array items order is important for drawing bars in correct sequence
    (stackId) => createBar({ ...suppressedButSentTrafficBarConfig, stackId }),
    (stackId) => createBar({ ...failedTrafficBarConfig, stackId }),
    (stackId) => createBar({ ...succeededTrafficBarConfig, stackId }),
    (stackId) => createBar({ ...targetTrafficBarConfig, stackId }),
  ] as ChartDataMapItem[],
  succeededTraffic: (stackId) => createBar({ ...succeededTrafficBarConfig, stackId }),
  failedTraffic: (stackId) => createBar({ ...failedTrafficBarConfig, stackId }),
  targetTraffic: (stackId) => createBar({ ...targetTrafficBarConfig, stackId }),
  suppressedButSentTraffic: (stackId) =>
    createBar({ ...suppressedButSentTrafficBarConfig, stackId }),
};

type ChartBarsRendererProps = Pick<ChartMainProps, "trafficType">;

const chartBarsRenderer = ({
  trafficType,
}: ChartBarsRendererProps): ReactElement | ReactElement[] => {
  const chartMapElement = chartDataMap[trafficType];
  if (Array.isArray(chartMapElement)) {
    return chartMapElement.map((chartMapItem) => chartMapItem(trafficType));
  }

  return chartMapElement(trafficType);
};

export { chartBarsRenderer };
