import { useNavigate } from "react-router-dom";
import { FC } from "react";
import cn from "classnames";
import {
  Button,
  getBackgroundColor,
  Menu,
  MenuButton,
  notification,
  TableCell,
  TableRow,
  TextEllipsis,
  useCall,
  useWindowSize,
  getLinkPath,
} from "@epcnetwork/core-ui-kit";

import { formatDate, getAllocationStatus } from "utils";
import { AllocationBatchModel } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { ALLOCATION_EDIT_PAGE, ALLOCATION_FORM_PAGE } from "constants/routes.constants";
import { timeFormat } from "constants/general.constants";
import { deleteBatch } from "api";
import { JobsList } from "./jobs-list";

import globalStyles from "assets/styles/global.module.css";
import pencilWhiteImg from "assets/images/pencil-white.svg";
import pencilGreyImg from "assets/images/pencil-grey.svg";
import clockImg from "assets/images/clock-grey.svg";
import styles from "./allocation-table-row.module.css";

const pencilImageMap = {
  grey: pencilGreyImg,
  white: pencilWhiteImg,
};

type AllocationTableRowProps = {
  index: number;
  item: AllocationBatchModel;
  refresh: VoidFunction;
};

const AllocationTableRow: FC<AllocationTableRowProps> = ({ index, item, refresh }) => {
  const navigate = useNavigate();
  const ability = useAuthorization();

  const { width } = useWindowSize();
  const { batchId, tag, fileIds, createdAt, status, endpoints } = item;
  const { name, color } = getAllocationStatus(status);

  const { submit, submitting, onCallError, onCallSuccess } = useCall(deleteBatch);
  onCallSuccess(() => {
    notification.success("Batch deleted", "Successfully deleted batch");
    refresh();
  });
  onCallError((error) => {
    notification.error("Cannot delete batch", error.message);
  });

  const handleAllocate = () => {
    navigate(getLinkPath(ALLOCATION_FORM_PAGE.path, { batchId }));
  };

  const handleEdit = () => {
    navigate(getLinkPath(ALLOCATION_EDIT_PAGE.path, { batchId }));
  };

  const handleDelete = () => {
    notification.confirm("Delete Allocation?", "Are you sure?", {
      onOk: () => {
        submit({ params: { batchId: item.batchId } });
      },
      icon: "delete",
    });
  };

  const statusIndicatorStyles = cn(
    styles.statusIndicator,
    globalStyles.addCircleStatus,
    getBackgroundColor({ status: color }),
  );

  const canAllocate =
    status === "ready-to-allocate" && !!endpoints?.length && ability.can("create", "Jobs");
  const allocationImg = pencilImageMap[canAllocate ? "white" : "grey"];

  const isMobile = width < MAX_MOBILE_WIDTH;

  const tagText = tag?.name || "-";

  return (
    <TableRow
      id={index}
      className={styles.rowWrap}
      statusIndicatorColor={color}
      collapseNode={<JobsList list={item.endpoints} />}
    >
      <TableCell>#{batchId}</TableCell>
      <TableCell>{endpoints?.length || 0}</TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
          {tagText}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.fileNameCell}>
        <div className={globalStyles.applyMultiOverflow}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
            {fileIds?.join(", ")}
          </TextEllipsis>
        </div>
      </TableCell>
      <TableCell className={styles.dateCell}>
        {formatDate(createdAt, "EEE, MMM dd, yyyy")}
        <img src={clockImg} alt="" />
        {formatDate(createdAt, timeFormat)}
      </TableCell>
      <TableCell>
        <div className={styles.flex}>
          <div className={statusIndicatorStyles} />
          {name}
        </div>
      </TableCell>
      <TableCell>
        <Button onClick={handleAllocate} disabled={!canAllocate || isMobile} btnSize="small">
          <img src={allocationImg} alt="" />
          Allocate
        </Button>
      </TableCell>
      <TableCell>
        {!isMobile && (
          <Menu>
            {ability.can("create", "Jobs") && (
              <MenuButton onClick={handleEdit} icon="edit">
                Edit
              </MenuButton>
            )}
            {ability.can("delete", "Jobs") && (
              <MenuButton
                appearance="delete"
                icon="delete"
                onClick={handleDelete}
                disabled={submitting}
              >
                Delete
              </MenuButton>
            )}
            {!ability.can("delete", "Jobs") && !ability.can("create", "Jobs") && (
              <div style={{ padding: "10px" }}>No Actions available</div>
            )}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export { AllocationTableRow };
