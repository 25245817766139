import { FC, useState } from "react";
import { Button, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { EndpointType } from "../endpoint/endpoint.types";
import { ReuseJobModal } from "./reuse-job-modal";

import crossImg from "assets/images/x-cross-rounded-grey.svg";
import { ReactComponent as FileUploadIcon } from "assets/images/file-upload-white.svg";
import styles from "./reuse-job.module.css";

type ReuseJobProps = {
  oldEndpoint: EndpointType;
  onSubmitClick: (newEndpoint: EndpointType) => void;
  onClearClick: VoidFunction;
  className?: string;
};

const ReuseJob: FC<ReuseJobProps> = ({
  className = "",
  oldEndpoint,
  onSubmitClick,
  onClearClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reusedJobName, setReusedJobName] = useState("");

  const handleModalOpen = () => setIsModalOpen(true);

  const handleModalClose = () => setIsModalOpen(false);

  const handleClearReusedJob = () => {
    setReusedJobName("");
    onClearClick();
  };

  const handleModalSubmit = (item: ReusableFileModel) => {
    handleModalClose();

    const convertReusableFileToEndpointType = (
      reusableFile: ReusableFileModel,
      oldEndpoint: EndpointType,
    ): EndpointType => {
      return {
        ...oldEndpoint,
        ...reusableFile,
        name: oldEndpoint.name,
      };
    };

    setReusedJobName(item.name);

    onSubmitClick(convertReusableFileToEndpointType(item, oldEndpoint));
  };

  return (
    <>
      <div className={styles.reuseButtonsWrapper}>
        <Button
          className={`${styles.reuseButton} ${className}`}
          onClick={handleModalOpen}
          appearance="secondary"
        >
          <FileUploadIcon />
          Reuse Job
        </Button>
        {reusedJobName && (
          <Button
            appearance="text"
            className={styles.clearReuseJobBtn}
            onClick={handleClearReusedJob}
          >
            <img src={crossImg} width="10" height="10" alt="" />
            <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
              {reusedJobName}
            </TextEllipsis>
          </Button>
        )}
      </div>
      {isModalOpen && (
        <ReuseJobModal
          onCancelClick={handleModalClose}
          onSubmitClick={handleModalSubmit}
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export { ReuseJob };
