import { Fragment, ReactNode } from "react";

import {
  convertParamsToUrls,
  formatSearchParamsToMap,
  isValidHttpUrl,
  SearchParamsMap,
} from "utils";
import { postJobFreezeStatus, postJobUnfreezeStatus } from "api";
import {
  btnFreezeText,
  btnUnfreezeText,
  errorFreezeTitle,
  errorUnfreezeTitle,
  notificationSuccessFreezeMessage,
  notificationSuccessFreezeTitle,
  notificationSuccessUnfreezeMessage,
  notificationSuccessUnfreezeTitle,
  tooltipFreezeText,
  tooltipUnfreezeText,
} from "./info-panel.constants";

import styles from "./info-panel.module.css";

type FreezeToggleApiCall = typeof postJobFreezeStatus | typeof postJobUnfreezeStatus;

type FreezeDataType = {
  freezeToggleApiCall: FreezeToggleApiCall;
  notificationSuccessTitle: string;
  notificationSuccessMessage: string;
  notificationErrorTitle: string;
  freezeBtnText: string;
  freezeTooltipText: string;
};

export const getFreezeData = (isFrozen: boolean): FreezeDataType => {
  const freezeData: FreezeDataType = {
    freezeToggleApiCall: postJobFreezeStatus,
    notificationSuccessTitle: notificationSuccessFreezeTitle,
    notificationSuccessMessage: notificationSuccessFreezeMessage,
    notificationErrorTitle: errorFreezeTitle,
    freezeBtnText: btnFreezeText,
    freezeTooltipText: tooltipFreezeText,
  };

  const unfreezeData: FreezeDataType = {
    freezeToggleApiCall: postJobUnfreezeStatus,
    notificationSuccessTitle: notificationSuccessUnfreezeTitle,
    notificationSuccessMessage: notificationSuccessUnfreezeMessage,
    notificationErrorTitle: errorUnfreezeTitle,
    freezeBtnText: btnUnfreezeText,
    freezeTooltipText: tooltipUnfreezeText,
  };

  return isFrozen ? unfreezeData : freezeData;
};
export const handleCopyText = (text: string, callback: () => void): void => {
  navigator.clipboard.writeText(text).then(() => {
    callback();
  });
};

export function convertMapInTooltipContent(mapSearchParams: SearchParamsMap): ReactNode[] {
  const resArr: ReactNode[] = [];
  let urlKey: string;

  function convert(mapSearchParams: SearchParamsMap) {
    for (const [key, value] of mapSearchParams) {
      if (typeof value === "string") {
        const urlValueParam = value.startsWith("{{") ? styles.urlValueParam : undefined;

        if (urlKey) {
          resArr.push(
            <Fragment key={value}>
              <br />
              <b className={styles.urlKey}>{key}</b>=
              <span className={styles.urlValue}>
                <span className={urlValueParam}>{value}</span>
                <span className={styles.urlSymbol}>&</span>
              </span>
            </Fragment>,
          );
          continue;
        }

        resArr.push(
          <Fragment key={value}>
            <br />
            <b className={styles.urlKey}>{key}</b>=
            <span className={styles.urlValue}>
              <span className={urlValueParam}>{value}</span>
              <span className={styles.urlSymbol}>&</span>
            </span>
          </Fragment>,
        );
        continue;
      }

      urlKey = key;
      // separator from formatSearchParamsToMap
      // should be ${key}=${origin} format
      const [urlKeyValue, urlOrigin] = key.split("=");
      resArr.push(
        <Fragment key={urlOrigin}>
          <br />
          <b className={styles.urlKey}>{urlKeyValue}</b>=
          <span className={styles.urlValue}>{urlOrigin}?</span>
        </Fragment>,
      );
      convert(value);
    }
  }

  convert(mapSearchParams);

  return resArr;
}

export const getTargetUrlView = (url: string): ReactNode => {
  if (url.length && isValidHttpUrl(url)) {
    const { searchParams, origin, pathname } = new URL(url);
    if ([...searchParams].length) {
      const searchMap = formatSearchParamsToMap(searchParams);
      const urls = convertParamsToUrls(searchMap);
      const tabsElements = convertMapInTooltipContent(urls);

      return (
        <div>
          {origin}
          {pathname}?{tabsElements}
        </div>
      );
    }
  }

  return <span>{url}</span>;
};
