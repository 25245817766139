import { AbilityBuilder, Ability } from "@casl/ability";

import { UserModel, PermissionsModel, AuthorizationActionType } from "models";
import {
  USERS_PERMISSIONS,
  OPEN_PERMISSION,
  SUPER_ADMIN_PERMISSION,
  JOBS_PERMISSIONS,
  EMAILS_SUPPRESSION_PERMISSIONS,
} from "constants/roles.constants";

export const createAuthorizationConfig = (user: UserModel | null): Ability => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  const createPermissions = <T extends PermissionsModel>(permissions: T) => {
    const userRoles = user?.rolePermissions?.permissionsNames || [];
    const actionNames = Object.keys(permissions).filter(
      (key) => key !== "name",
    ) as AuthorizationActionType[];
    const defaultPermissions = user?.roles?.includes(SUPER_ADMIN_PERMISSION)
      ? [SUPER_ADMIN_PERMISSION]
      : [];

    actionNames.forEach((actionName) => {
      const actionPermissions = permissions[actionName];

      const neededPermissions = [...defaultPermissions, actionPermissions];

      const isPermitted = !!neededPermissions.find((permission) => userRoles.includes(permission));
      const isUnlimitedAccess = neededPermissions.includes(OPEN_PERMISSION);

      if (isUnlimitedAccess || isPermitted) {
        can(actionName, permissions.name);
      } else {
        cannot(actionName, permissions.name);
      }
    });
  };

  createPermissions(USERS_PERMISSIONS);
  createPermissions(JOBS_PERMISSIONS);
  createPermissions(EMAILS_SUPPRESSION_PERMISSIONS);

  return new Ability(rules);
};
