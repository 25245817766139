import { JobTrafficTypes } from "models";
import { JOB_TRAFFIC_LABELS } from "constants/jobs.constants";
import { timeFormatEurope } from "constants/general.constants";
import { ChartTrafficType } from "./chart.types";

export const chartTrafficTypes: ChartTrafficType = {
  all: "All IPS",
  succeededTraffic: JOB_TRAFFIC_LABELS[JobTrafficTypes.succeededTraffic],
  failedTraffic: JOB_TRAFFIC_LABELS[JobTrafficTypes.failedTraffic],
  targetTraffic: JOB_TRAFFIC_LABELS[JobTrafficTypes.targetTraffic],
  suppressedButSentTraffic: JOB_TRAFFIC_LABELS[JobTrafficTypes.suppressedButSentTraffic],
};

export const chartTooltipFrozenTimeFormat = `dd MMM ${timeFormatEurope}`;
