import { FC } from "react";
import { Button, MessageField, Form, FormButtons, FormField } from "@epcnetwork/core-ui-kit";

import { ForgotPasswordHeader } from "../ forgot-password-header/forgot-password-header";
import { useResetPasswordHook } from "./reset-password.hook";
import { initialValues, validationSchema } from "./reset-password.constants";

import styles from "../send-email/send-email.module.css";

const ResetPassword: FC = () => {
  const { onSubmit, userEmail, errorMessage } = useResetPasswordHook();

  return (
    <div className={styles.container}>
      <ForgotPasswordHeader />
      <div className={styles.loginForm}>
        <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          <div className={styles.title}>Reset password</div>
          <div className={styles.subtitle}>
            Fill and confirm new password for account
            <div className={styles.userEmail}>{userEmail}</div>
          </div>
          <MessageField message={errorMessage} align="left" />
          <FormField
            type="generate-password"
            name="password"
            label="New Password"
            placeholder="Fill new password"
            required
          />
          <FormField
            type="password"
            name="confirmPassword"
            label="Confirm New Password"
            placeholder="Confirm new password"
            required
          />
          <FormButtons align="right">
            <Button className={styles.btn} type="submit">
              Reset password
            </Button>
          </FormButtons>
        </Form>
      </div>
    </div>
  );
};

export { ResetPassword };
