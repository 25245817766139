import { memo } from "react";
import cn from "classnames";
import {
  Button,
  Range,
  useFormContext,
  clamp,
  TextEllipsis,
  Nullable,
} from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";

import globalStyles from "assets/styles/global.module.css";
import styles from "./allocation-unit.module.css";

export type AllocationUnitType = {
  value: number;
  date?: Date | string;
  name: string;
};

type AllocationUnitProps = {
  className?: string;
  index: number;
  maxValue: number;
  remainingAmount: number;
  item: AllocationUnitType;
  invalid: boolean;
  size?: "big" | "medium" | "small";
  disableRestrict?: boolean;
  isDisabledInput?: boolean;
  onValueChange: (value: number, index: number) => void;
  reusedJob: Nullable<ReusableFileModel>;
};

const AllocationUnit = memo<AllocationUnitProps>(
  ({
    className = "",
    index,
    maxValue,
    remainingAmount,
    item,
    disableRestrict = false,
    isDisabledInput = false,
    onValueChange,
    invalid,
    reusedJob,
  }) => {
    const [{ isLoading, isDisabled }] = useFormContext();

    const { name, value } = item;
    const leftAmount = remainingAmount + value;
    const max = Math.min(leftAmount, maxValue);

    const handleChange = (value: number) =>
      !isNaN(value) && onValueChange(clamp(value, 0, max), index);

    const handleDecrease = () => handleChange(value - 1);

    const handleIncrease = () => handleChange(value + 1);

    const handleFill = () => onValueChange(leftAmount, index);

    const disableDecrease = value <= 0 || isDisabledInput;
    const disableIncrease = !remainingAmount || isDisabledInput;

    const allocationUnitWrapStyles = cn(styles.allocationUnitWrap, className);
    const rangeStyles = cn(styles.range, { [styles.disabled]: !!reusedJob });

    const disableIncreaseBtn = disableIncrease || !!reusedJob;

    return (
      <div
        className={allocationUnitWrapStyles}
        style={{
          opacity: isDisabledInput ? 0.5 : 1,
        }}
      >
        <div className={styles.unitName} style={{ color: invalid ? "var(--palette-error)" : "" }}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
            {name}
          </TextEllipsis>
        </div>

        <div className={styles.rangeWrap}>
          <Range
            min={0}
            max={maxValue}
            debounceTime={25}
            className={rangeStyles}
            step={maxValue / 100}
            restrictValue={!disableRestrict ? leftAmount : undefined}
            value={value}
            onChange={handleChange}
            appearance="secondary"
            disabled={isLoading || isDisabled || isDisabledInput || !!reusedJob}
          />
        </div>
        <div className={styles.distributionActionsWrap}>
          <div className={styles.distributionActionValueWrap}>
            <input
              value={value}
              onChange={(event) => handleChange(Number(event.target.value))}
              className={`${styles.distributionValue} ${globalStyles.applySingleOverflow}`}
              max={max}
              disabled={isDisabledInput}
            />
            <div className={styles.mathActions}>
              <Button
                appearance="text"
                onClick={handleDecrease}
                disabled={disableDecrease || !!reusedJob}
              >
                -
              </Button>
              <Button appearance="text" onClick={handleIncrease} disabled={disableIncreaseBtn}>
                +
              </Button>
            </div>
          </div>
          <Button
            className={styles.distributionFillButton}
            appearance="text"
            onClick={handleFill}
            disabled={disableIncreaseBtn}
          >
            Fill
          </Button>
        </div>
      </div>
    );
  },
);

export { AllocationUnit };
