import {
  array,
  object,
  TestContext,
  boolean,
  string,
  mixed,
  AnyObjectSchema,
  ValidationError,
} from "yup";

import { requiredFieldText } from "constants/form.constants";
import { InitialValues } from "./jobs-form.types";
import { createInitialEndpoint } from "./endpoint/endpoint.utils";
import { EndpointType } from "./endpoint/endpoint.types";
import { endpointSchema } from "./endpoint/endpoint.constants";

export const initialValues: InitialValues = {
  endpoints: [createInitialEndpoint()],
  attachedFiles: [],
  isInReconfiguration: false,
};

export const attachedFileSchema = object().shape({
  columns: array(string()).nullable(),
  date: string().nullable(),
  emailCount: mixed().nullable(),
  fileId: string().nullable(),
  fileName: string().nullable(),
  is_used: boolean().nullable(),
});

export const validationSchema = (isEdit: boolean): AnyObjectSchema =>
  object().shape({
    endpoints: array(endpointSchema)
      .required("Endpoints are required")
      .min(1, "Endpoints list is empty")
      .test(
        "hasUnique names",
        "test error",
        function (value: EndpointType[] | unknown, context: TestContext<InitialValues> | unknown) {
          const endpointsArr = value ? (value as EndpointType[]) : null;
          const schemaContext = context ? (context as TestContext<InitialValues>) : null;

          if (!endpointsArr || !schemaContext) return true;

          const namesArr = endpointsArr.map((endpoint) => endpoint.name);

          const isEmpty = !namesArr.length || namesArr.some((name) => !name);

          if (isEmpty) return true;

          const sameNamesArr = namesArr.filter(
            (name, index, selfArr) => index !== selfArr.indexOf(name),
          );

          let error: boolean | ValidationError = true;

          endpointsArr.forEach((endpoint, index) => {
            const endpointName = endpoint.name;

            if (sameNamesArr.includes(endpointName)) {
              error = schemaContext.createError({
                path: `endpoints[${index}].name`,
                message: "Name must be unique",
              });
              return;
            }
          });

          return error;
        },
      ),
    attachedFiles: isEdit
      ? mixed()
      : array()
          .of(attachedFileSchema)
          .min(1, "You have to select at least one file")
          .typeError(requiredFieldText)
          .required(requiredFieldText),
  });
