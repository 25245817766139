import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FC, useRef, useState, MouseEvent, ReactNode } from "react";
import { differenceInHours } from "date-fns";
import {
  addClassesToElement,
  Form,
  notification,
  removeClassesFromElement,
  useDidMount,
  getLinkPath,
} from "@epcnetwork/core-ui-kit";

import { setIsJobDataNeeded } from "store";
import { ALLOCATION_PAGE, JOBS_DETAILS_PAGE } from "constants/routes.constants";
import { Container } from "components";
import { Information } from "./information";
import { HoursAllocation } from "./hours-allocation/hours-allocation";
import { EndpointsAllocation } from "./endpoints-allocation/endpoints-allocation";
import { DaysAllocation } from "./days-allocation/days-allocation";
import { isLastPage, getMaxEmails } from "./allocation-form.utils";
import { useAllocationForm } from "./allocation-form.hook";
import {
  allocationButtonInfoText,
  allocationSetupPages,
  confirmBackToGeneralPopupCancelBtnText,
  confirmBackToGeneralPopupOkBtnText,
  confirmBackToGeneralPopupText,
  confirmBackToGeneralPopupTitle,
  goBackMessage,
  nextJobBtnText,
  pagesAmount,
  previewBtn,
  validationSchemas,
} from "./allocation-form.constants";
import { submitTextMap } from "./allocation-buttons/allocation-buttons.constants";
import { AllocationButtons } from "./allocation-buttons";

import { ReactComponent as BackToGeneralConfirmationIcon } from "assets/images/toastr-confirm-warning.svg";
import styles from "./allocation-form.module.css";

const AllocationFormPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onNextNestCallback = useRef<VoidFunction | null>(null);

  const [lastEndpoint, setLastEndpoint] = useState(false);

  const {
    batchId,
    jobId,
    remainingValue,
    onRemainingChange,
    allowToAllocate,
    containerContentRef,
    payload,
    handleSubmit,
    formProps,
    disableAllocationBtn,
    onRemainingIndexChange,
    selectedIndex,
    onSelectedIndex,
    setLoadedHourlySpread,
    loadedHourlySpread,
    reusedJob,
    setReusedJob,
  } = useAllocationForm();

  useDidMount(() => {
    dispatch(setIsJobDataNeeded(true));
  });

  const handleNextEndpointClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onNextNestCallback.current && onNextNestCallback.current();
  };

  const onNextNestItem = (callback: VoidFunction) => {
    onNextNestCallback.current = callback;
  };

  const checkLastEndpoint = (isLastEndpoint: boolean) => {
    setLastEndpoint(isLastEndpoint);
  };

  const isAllocationDayPage = (page: number): boolean => page === allocationSetupPages.day;

  return (
    <Container
      className={styles.allocationContainer}
      contentClassName={styles.containerWrap}
      contentForwardRef={containerContentRef}
    >
      {!allowToAllocate && (
        <Information
          batchId={batchId}
          jobId={jobId}
          endpoints={payload?.endpoints || []}
          endDate={payload?.endDate}
        />
      )}
      {allowToAllocate && (
        <Form
          {...formProps}
          contentClassName={styles.formContentWrap}
          validationSchema={validationSchemas}
          pages={pagesAmount}
          onSubmit={handleSubmit}
          pageValidation={isAllocationDayPage}
          error={formProps.error ? formProps.error || goBackMessage : ""}
        >
          {({ values, page, onBack, onSetPage, onClick, isFirstPage, setFieldValue }) => {
            const { maxDaysAllocationLimit, maxHoursAllocationLimit } = values;

            const handleBackClick = () => {
              if (page <= 1) {
                const routeToPush = jobId
                  ? getLinkPath(JOBS_DETAILS_PAGE.path, { jobId })
                  : ALLOCATION_PAGE.path;
                return navigate(routeToPush);
              }
              onBack();
            };

            const resetMaxDaysAllocationLimit = () => {
              maxDaysAllocationLimit && setFieldValue("maxDaysAllocationLimit", new Map());
              maxHoursAllocationLimit && setFieldValue("maxHoursAllocationLimit", new Set());
            };

            const handleDaysClick = (e: MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              onSetPage(allocationSetupPages.time);
            };

            const handleNextClick = (e: MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              onClick();
            };

            const backToGeneral = () => {
              resetMaxDaysAllocationLimit();
              dispatch(setIsJobDataNeeded(true));
              onSetPage(allocationSetupPages.general);
            };

            const openConfirmBackToGeneralPopup = () => {
              notification.confirm(confirmBackToGeneralPopupTitle, confirmBackToGeneralPopupText, {
                onOk: backToGeneral,
                okText: confirmBackToGeneralPopupOkBtnText,
                cancelText: confirmBackToGeneralPopupCancelBtnText,
                customIcon: <BackToGeneralConfirmationIcon />,
                className: styles.confirmBackToGeneralPopup,
              });
            };

            if (isFirstPage) {
              addClassesToElement(containerContentRef.current, styles.withPadding);
            } else {
              removeClassesFromElement(containerContentRef.current, styles.withPadding);
            }

            const hours =
              values.startDate && values.endDate
                ? differenceInHours(new Date(values.endDate), new Date(values.startDate))
                : 0;

            const isLastPageEndpoint = isLastPage(page) && lastEndpoint;

            const AllocationButton = (
              <div className={styles.allocationNavBtnWrapper}>
                <AllocationButtons
                  handleNextClick={handleNextClick}
                  buttonText={submitTextMap[allocationSetupPages.time]}
                  page={page}
                  className={styles.allocationNavBtnWrapper}
                  btnClassName={styles.allocationNavBtn}
                  disableButton={
                    disableAllocationBtn ||
                    !!maxDaysAllocationLimit.size ||
                    !!maxHoursAllocationLimit.size
                  }
                />
                <p className={styles.allocationNavBtnText}>{allocationButtonInfoText}</p>
              </div>
            );

            const pages: Record<number, ReactNode> = {
              [allocationSetupPages.general]: (
                <EndpointsAllocation
                  maxEmails={getMaxEmails(hours)}
                  setReusedJob={setReusedJob}
                  reusedJob={reusedJob}
                >
                  <AllocationButtons
                    handleBackClick={handleBackClick}
                    handleNextClick={handleNextClick}
                    page={page}
                    buttonText={reusedJob ? previewBtn : undefined}
                  />
                </EndpointsAllocation>
              ),
              [allocationSetupPages.day]: (
                <DaysAllocation
                  onRemainingChange={onRemainingChange}
                  onSetPage={onSetPage}
                  backToGeneral={openConfirmBackToGeneralPopup}
                  onRemainingIndexChange={onRemainingIndexChange}
                  allocationButton={AllocationButton}
                  onSelectedIndex={onSelectedIndex}
                  reusedJob={reusedJob}
                  selectedIndex={selectedIndex}
                >
                  <AllocationButtons
                    handleNextClick={handleDaysClick}
                    overlimit={remainingValue < 0}
                    disableButton={
                      remainingValue !== 0 ||
                      !!maxDaysAllocationLimit.size ||
                      !!maxHoursAllocationLimit.size
                    }
                    className={styles.allocationBtns}
                    page={page}
                  />
                </DaysAllocation>
              ),
              [allocationSetupPages.time]: (
                <HoursAllocation
                  onSetPage={onSetPage}
                  checkLastEndpoint={checkLastEndpoint}
                  onRemainingChange={onRemainingChange}
                  onRemainingIndexChange={onRemainingIndexChange}
                  backToGeneral={openConfirmBackToGeneralPopup}
                  allocationButton={AllocationButton}
                  onNextNestItemClick={onNextNestItem}
                  onSelectedIndex={onSelectedIndex}
                  selectedIndex={selectedIndex}
                  reusedJob={reusedJob}
                  setLoadedHourlySpread={setLoadedHourlySpread}
                  loadedHourlySpread={loadedHourlySpread}
                >
                  <AllocationButtons
                    handleNextClick={isLastPageEndpoint ? handleNextClick : handleNextEndpointClick}
                    overlimit={remainingValue < 0}
                    className={styles.allocationBtns}
                    buttonText={isLastPageEndpoint ? submitTextMap[page] : nextJobBtnText}
                    page={page}
                    isLastPage={isLastPage(page)}
                    disableButton={
                      (isLastPageEndpoint && disableAllocationBtn) ||
                      !!maxDaysAllocationLimit.size ||
                      !!maxHoursAllocationLimit.size
                    }
                  />
                </HoursAllocation>
              ),
            };

            return pages[page];
          }}
        </Form>
      )}
    </Container>
  );
};

export { AllocationFormPage };
