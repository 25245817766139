import Smartlook from "smartlook-client";

import { UserModel } from "models";
import { appEnvironment } from "config/environment.config";

export const useTracking = () => {
  const init = () => {
    Smartlook.init(appEnvironment.smartlookKey);
  };

  const identify = ({ id, email }: UserModel) => {
    Smartlook.identify(id, { email });
  };

  const track = (event: string, props?: Record<string, string | number | boolean>) => {
    Smartlook.track(event, props || {});
  };

  return { init, identify, track };
};
