import { useNavigate } from "react-router-dom";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { addHours, isBefore } from "date-fns";
import cn from "classnames";
import {
  Button,
  FetchingError,
  getProgressBetweenDates,
  Label,
  NoContent,
  notification,
  TextEllipsis,
  TransitionWrapper,
  useCall,
  useDevice,
  useDidUpdate,
  useFetch,
  useWindowSize,
  getLinkPath,
  Tooltip,
} from "@epcnetwork/core-ui-kit";

import { JobModelConfiguration } from "models";
import {
  LastHourStatsUpdateData,
  ListenerEventsKeys,
  SocketInstance,
  StatusUpdateData,
  useAuthorization,
  WebhookResponseEventData,
} from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { JOBS_FORM_EDIT_PAGE } from "constants/routes.constants";
import { JOB_PROGRESS_STATUSES } from "constants/jobs.constants";
import { Loader } from "components";
import { deleteJob, getJob } from "api";
import { RestAPIType } from "../../form/connectors/rest-api";
import { StatusInfo } from "./status-info";
import { getTargetUrlView, handleCopyText } from "./info-panel.utils";
import {
  copyNotificationSuccessText,
  copyNotificationSuccessTitle,
  copyText,
} from "./info-panel.constants";
import { DateInfo } from "./date-info";
import { ActionButtons } from "./action-buttons";

import globalStyles from "assets/styles/global.module.css";
import { ReactComponent as CopyIcon } from "assets/images/copy-icon.svg";
import deleteImg from "assets/images/bin-red.svg";
import styles from "./info-panel.module.css";

type InfoPanelProps = {
  jobId: number;
  className?: string;
  setJobConfiguration: Dispatch<SetStateAction<JobModelConfiguration | undefined>>;
  socket: SocketInstance | null;
};

const InfoPanel: FC<InfoPanelProps> = ({ jobId, setJobConfiguration, className, socket }) => {
  const ability = useAuthorization();
  const navigate = useNavigate();

  const { width } = useWindowSize();
  const { isMobileDevice } = useDevice();

  const [jobProgress, setJobProgress] = useState<number>(0);
  const [job, setJob] = useState<JobModelConfiguration | null>(null);

  const { submit, submitting, onCallSuccess } = useCall(
    deleteJob.setParams({ jobId: job?.id || "" }),
  );
  onCallSuccess(() => {
    navigate(-1);
  });

  const { payload, loading, error, refresh } = useFetch(getJob.setParams({ jobId }));

  useDidUpdate(() => {
    setJob(payload);
  }, [payload]);

  const onUrlClick = () => {
    if (targetUrl) {
      handleCopyText(targetUrl, () => {
        notification.success(copyNotificationSuccessTitle, copyNotificationSuccessText);
      });
    }
  };

  useDidUpdate(() => {
    if (!loading && !error && job) {
      setJobConfiguration(job);
    }
  }, [job, loading]);

  useDidUpdate(
    () => {
      const handleStatusUpdate = (data: StatusUpdateData) => {
        if (data && data?.status) {
          setJob((prevState) => {
            if (!prevState) return null;
            return { ...prevState, status: data.status };
          });
        }
      };

      if (socket) {
        socket.on<ListenerEventsKeys>("jobStatusUpdate", handleStatusUpdate);

        return () => {
          socket.off<ListenerEventsKeys>("jobStatusUpdate", handleStatusUpdate);
        };
      }
    },
    [socket],
    true,
  );

  const handleDeleteClick = () =>
    notification.confirm("Delete Job", `Are you sure you want to delete job: \n ${job?.name}`, {
      onOk: () => job?.id && submit(),
      icon: "delete",
    });

  const handleEdit = () => navigate(getLinkPath(JOBS_FORM_EDIT_PAGE.path, { jobId }));

  const { isJobFinished, isStatusForDelete } = useMemo(() => {
    const isJobFinished = job?.status === JOB_PROGRESS_STATUSES.finished;
    const isJobPaused = job?.status === JOB_PROGRESS_STATUSES.paused;
    const isJobPending = job?.status === JOB_PROGRESS_STATUSES.pending;

    const isStatusForDelete = isJobFinished || isJobPaused || isJobPending;

    return { isJobFinished, isJobPaused, isJobPending, isStatusForDelete };
  }, [job?.status]);

  const { restApiPayload, targetUrl } = useMemo(() => {
    const restApiPayload = job?.connector?.properties as RestAPIType & { apiKey: string };
    const targetUrl = (job && restApiPayload?.targetUrl) || "";

    return { restApiPayload, targetUrl };
  }, [job]);

  const canReconfigure =
    !isJobFinished && job?.endDate && isBefore(addHours(new Date(), 2), new Date(job.endDate));

  const isMobile = width < MAX_MOBILE_WIDTH || isMobileDevice;

  useDidUpdate(() => {
    if (job && job.status === "in-progress") {
      const setProgress = () => {
        const progress = getProgressBetweenDates(new Date(job.startDate), new Date(job.endDate));
        setJobProgress(progress);
      };
      setProgress();

      const progressInterval = setInterval(setProgress, 5000);

      return () => {
        clearInterval(progressInterval);
      };
    }
  }, [job]);

  if (loading) {
    return (
      <div className={styles.infoPanelWrap}>
        <Loader />
      </div>
    );
  }

  if (!loading && error) {
    return (
      <div className={cn(styles.infoPanelWrap, className)}>
        <TransitionWrapper isIn>
          <FetchingError
            size="medium"
            title="Error"
            subtitle={error.message}
            refreshClick={refresh}
            refreshText="Reload"
          />
        </TransitionWrapper>
      </div>
    );
  }

  if (!job) {
    return (
      <div className={cn(styles.infoPanelWrap, className)}>
        <TransitionWrapper isIn>
          <NoContent />
        </TransitionWrapper>
      </div>
    );
  }

  return (
    <div className={cn(styles.infoPanelWrap, className)}>
      <div className={styles.captionNoteWrap}>
        <div className={styles.captionNoteId}>#{job.id}</div>
        {ability.can("create", "Jobs") && !isMobile && (
          <Button
            className={styles.captionNoteButton}
            appearance="transparent"
            onClick={handleEdit}
            disabled={submitting || isJobFinished}
            btnSize="small"
          >
            Edit
          </Button>
        )}
        {ability.can("delete", "Jobs") && isStatusForDelete && !isMobile && (
          <Button
            className={styles.captionNoteButton}
            appearance="transparent"
            onClick={handleDeleteClick}
            disabled={submitting}
          >
            <img src={deleteImg} alt="" />
          </Button>
        )}
      </div>
      <div className={styles.infoPanelItem}>
        <Label text="Job name" className={styles.label} />
        <div className={styles.jobName}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
            {job.name}
          </TextEllipsis>
        </div>
      </div>
      {job?.tag?.name && (
        <div className={styles.infoPanelItem}>
          <Label text="Tag name" className={styles.label} />
          <div className={styles.jobName}>
            <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
              {job.tag.name}
            </TextEllipsis>
          </div>
        </div>
      )}
      <div className={styles.infoPanelItem}>
        <span>
          <Label text="File name" className={styles.label} />
          &nbsp;
          <span className={styles.filesCount}>({job.fileIds.length})</span>
        </span>
        <div className={`${styles.jobFileName} ${globalStyles.applySingleOverflow}`}>
          {job.fileIds.map((fileId, index) => {
            if (index > 2) return null;
            return (
              <div key={index.toString()}>
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={1}>
                  {index + 1}. {fileId}
                </TextEllipsis>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.infoPanelItem}>
        <Label text="Api type" className={styles.label} />
        <div className={styles.apiType}>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
            {job?.connector?.apiType}
          </TextEllipsis>
        </div>
      </div>
      {restApiPayload?.apiKey && (
        <div className={styles.infoPanelItem}>
          <Label text="Api key" className={styles.label} />
          <div className={styles.apiKey}>
            <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
              {restApiPayload.apiKey}
            </TextEllipsis>
          </div>
        </div>
      )}
      {targetUrl && (
        <div className={styles.infoPanelItem}>
          <Label text="URL" className={styles.label} />
          <div className={styles.infoPanelUrl}>
            <Tooltip
              triggerElement={<CopyIcon className={styles.copyIcon} onClick={onUrlClick} />}
              position="bottom"
              trigger="hover"
            >
              {copyText}
            </Tooltip>
            <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={1}>
              {getTargetUrlView(targetUrl)}
            </TextEllipsis>
          </div>
        </div>
      )}
      <div className={styles.infoPanelItem}>
        <Label text="Duration" className={styles.label} />
        <DateInfo type="start" date={job.startDate} />
        <DateInfo type="end" date={job.endDate} />
      </div>
      <div className={styles.infoPanelItem}>
        <Label text="Webhook" className={styles.label} />
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
          {job?.webhook?.name}: {job?.webhook?.ip}
        </TextEllipsis>
      </div>
      {job?.proxyValue && (
        <div className={styles.infoPanelItem}>
          <Label text="Proxy" className={styles.label} />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
            {job.proxyValue}
          </TextEllipsis>
        </div>
      )}

      <div className={styles.infoPanelItem}>
        <Label text="Status" className={styles.label} />
        <StatusInfo
          jobId={job.id}
          allowAutofreeze={job.allowAutofreeze}
          status={job.status}
          progress={jobProgress}
        />
      </div>

      <ActionButtons
        jobId={job.id}
        status={job.status}
        progress={jobProgress}
        onTriggerJobStatusSuccess={refresh}
        showReconfigureBtn={ability.can("create", "Jobs") && !isMobileDevice && !!canReconfigure}
        jobPayload={job}
      />
    </div>
  );
};

export { InfoPanel };
