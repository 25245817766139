import { FC } from "react";
import { TableRow, TableCell } from "@epcnetwork/core-ui-kit";

import { JobMetaDomainModel } from "models";

import globalStyles from "assets/styles/global.module.css";
import styles from "./domain-table-row.module.css";

type DomainTableRowProps = {
  index: number;
  item: JobMetaDomainModel;
};

const DomainTableRow: FC<DomainTableRowProps> = ({ index, item }) => {
  const {
    domain,
    totalEmailCount,
    successEmailCount,
    remainingEmailCount,
    failedEmailCount,
    totalSuppressed,
  } = item;

  return (
    <TableRow id={index}>
      <TableCell className={styles.nameCell}>
        <span className={globalStyles.applySingleOverflow}>{domain}</span>
      </TableCell>
      <TableCell>{totalEmailCount}</TableCell>
      <TableCell>{successEmailCount}</TableCell>
      <TableCell>{remainingEmailCount}</TableCell>
      <TableCell>{failedEmailCount}</TableCell>
      <TableCell>{totalSuppressed}</TableCell>
    </TableRow>
  );
};

export { DomainTableRow };
