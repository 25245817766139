import { format } from "date-fns";
import { isSameDay } from "@epcnetwork/core-ui-kit";

import {
  getDateWithoutGTM,
  getDatesHoursArr,
  getHourName,
  getTimezoneInformation,
  getTimezoneSign,
} from "utils";
import { JobHourlySpreadModel } from "models";
import { JobDailySpread } from "api/allocation/allocation.interface";
import { EndpointsRangeType } from "./endpoints-allocation/endpoints-allocation.types";
import { DaySpread, DayUnitType } from "./days-allocation/days-allocation.types";
import {
  DataCircleGraph,
  GetDataForCircleGraphParams,
  InitialValues,
} from "./allocation-form.types";
import {
  allocatedCircleGraphColor,
  remainCircleGraphColor,
  allocatedErrorCircleGraphColor,
  overlimitCircleGraphColor,
  allocationSetupPages,
  maxHourAllocation,
  baseHoursDayCount,
  springHoursDayCount,
  timeShiftIndex,
  autumnHoursDayCount,
} from "./allocation-form.constants";

export const getDataForCircleGraph = ({
  used,
  remaining,
}: GetDataForCircleGraphParams): DataCircleGraph => {
  const colors = {
    remain: remainCircleGraphColor,
    allocated: allocatedCircleGraphColor,
    overlimit: overlimitCircleGraphColor,
  };

  const dataCircleGraph: DataCircleGraph = {
    data: [
      { name: "remain", value: remaining },
      { name: "allocated", value: used },
    ],
    colors,
  };

  const dataCircleErrorGraph: DataCircleGraph = {
    data: [
      { name: "allocated", value: used },
      { name: "overlimit", value: Math.abs(remaining) },
    ],
    colors: { ...colors, allocated: allocatedErrorCircleGraphColor },
  };

  return remaining < 0 ? dataCircleErrorGraph : dataCircleGraph;
};

export const getErrorTextAllocatedExtra = (remaining: number): string => {
  return `You allocated extra ${Math.abs(remaining)} emails. Try to change separations between
days.`;
};

export const getConfirmReuseJobHeaderText = (jobName: string): string => {
  return `You selected "${jobName}" to reuse it's allocation`;
};

export const getReuseJobConfirmAllocatePopupText = (jobName: string | undefined = ""): string => {
  return jobName && `Are you sure that you want to allocate emails according to ${jobName} job?`;
};

export const maxDayAllocationErrorText = (maxDayAllocation: number): string => {
  return `Too many emails were allocated per day, max per day ${maxDayAllocation}`;
};

export const getActiveJobName = (activeNames?: string[]): string | undefined => {
  if (activeNames && activeNames.length) {
    const activeJobName = activeNames[0];
    return activeJobName.substring(activeJobName.indexOf(" ") + 1);
  }
};

export const getCurrentRemainValue = (dateHoursSpread: DaySpread[], name: string[]): number =>
  dateHoursSpread.find((day) => day.name === getActiveJobName(name))?.remaining || 0;

export const getDateHoursDataName = (
  dateHoursSpread: DaySpread[],
  index: number,
): string | undefined => {
  const dateHoursData: DaySpread | undefined = dateHoursSpread[index + 1];

  if (dateHoursData) {
    return `#${index + 2} ${dateHoursData.name}`;
  }
};

export const getInitialDateHoursSpread = (
  startDate: Date,
  endDate: Date,
  endpointsRanges: EndpointsRangeType[],
): DaySpread[] => {
  const datesArr = getDatesHoursArr(startDate, endDate);
  const daysArr = datesArr.map(
    ({ date, hours }): DayUnitType => ({
      name: format(date, "MMM d"),
      value: 0,
      used: 0,
      remaining: 0,
      hoursUnits: hours.map(([name, hourDate]) => ({ name, value: 0, date: hourDate })),
      date,
    }),
  );

  return endpointsRanges.map(({ value, ...rest }) => ({
    ...rest,
    total: value,
    remaining: value,
    used: 0,
    dayUnits: daysArr,
  }));
};

export const getDaySpreadWithHoursSpread = (
  values: InitialValues,
  data: JobHourlySpreadModel,
  checkMaxHoursAllocationLimit?: (value: number, date: Date) => void,
): DaySpread[] =>
  values.dateHoursSpread.map((endpointSpread) => {
    const foundJob = data.find((el) => el.jobId === endpointSpread.jobId);
    if (!foundJob) return endpointSpread;

    const dayUnitsToSet: DayUnitType[] = endpointSpread.dayUnits.map((dayUnit) => {
      const dayToCheck = foundJob.spread.find(({ day }) =>
        isSameDay(getDateWithoutGTM(day), dayUnit.date),
      );

      if (!dayToCheck) return dayUnit;

      let used = 0;

      let hoursUnitsToSet;

      // if spring hours shift
      if (
        dayUnit.hoursUnits.length - dayToCheck.hours.length === 1 &&
        dayToCheck.hours.length === springHoursDayCount
      ) {
        if (dayUnit.hoursUnits.length === baseHoursDayCount) {
          dayUnit.hoursUnits.pop();
        }

        hoursUnitsToSet = dayUnit.hoursUnits.map((hourSpread, index) => {
          const traffic = dayToCheck.hours?.[index]?.traffic || 0;
          const hour = dayToCheck.hours?.[index]?.hour || "";

          let name = getHourName(hour);
          const dateHour = getDateWithoutGTM(hour);
          if (timeShiftIndex - 1 === index || timeShiftIndex === index) {
            const timezoneInfo = getTimezoneInformation(hour);
            const timezoneSign = getTimezoneSign(hour);
            name += `(${timezoneSign}${timezoneInfo} GMT)`;
          }

          checkMaxHoursAllocationLimit && checkMaxHoursAllocationLimit(traffic, hourSpread.date);
          used += traffic;

          return {
            ...hourSpread,
            value: traffic,
            name,
            date: dateHour,
          };
        });
        // if autumn hours shift
      } else if (
        dayUnit.hoursUnits.length - dayToCheck.hours.length === -1 &&
        dayToCheck.hours.length === autumnHoursDayCount
      ) {
        if (dayUnit.hoursUnits.length === baseHoursDayCount) {
          dayUnit.hoursUnits.push({ name: "", value: 0, date: new Date() });
        }

        hoursUnitsToSet = dayUnit.hoursUnits.map((hourSpread, index) => {
          const traffic = dayToCheck.hours?.[index]?.traffic || 0;
          const hour = dayToCheck.hours?.[index]?.hour || "";

          const dateHour = getDateWithoutGTM(hour);
          let name = getHourName(hour);
          if (timeShiftIndex - 1 === index || timeShiftIndex === index) {
            const timezoneInfo = getTimezoneInformation(hour);
            const timezoneSign = getTimezoneSign(hour);
            name += `(${timezoneSign}${timezoneInfo} GMT)`;
          }

          checkMaxHoursAllocationLimit && checkMaxHoursAllocationLimit(traffic, hourSpread.date);
          used += traffic;

          return {
            ...hourSpread,
            value: traffic,
            name,
            date: dateHour,
          };
        });
      } else {
        hoursUnitsToSet = dayUnit.hoursUnits.map((hourSpread, index) => {
          const traffic = dayToCheck.hours?.[index]?.traffic || 0;

          checkMaxHoursAllocationLimit && checkMaxHoursAllocationLimit(traffic, hourSpread.date);
          used += traffic;

          return {
            ...hourSpread,
            value: traffic,
          };
        });
      }

      return {
        ...dayUnit,
        hoursUnits: hoursUnitsToSet,
        remaining: dayUnit.value - used,
        used,
      };
    });

    return {
      ...endpointSpread,
      dayUnits: dayUnitsToSet,
    };
  });

// check if emails quantity for day or dates are being changed of the first form page
export const checkFirstStepChanged = (
  initialValues: InitialValues,
  values: InitialValues,
): boolean => {
  const { endpointsRanges } = values;
  const changedDateStart = initialValues.startDate !== values.startDate;
  const changedDateEnd = initialValues.endDate !== values.endDate;
  const changedSameSpread = initialValues.sameSpread !== values.sameSpread;
  const changedSpread = initialValues.endpointsRanges.find(
    (range, index) => range.value !== endpointsRanges[index].value,
  );

  const editedJobDaySpread = values?.dateHoursSpread?.[0];
  const editedJobInitialDaySpread = values?.initialDateSpread?.[0];
  const dateSpreadChanged = editedJobDaySpread?.dayUnits?.find?.((dateUnit) => {
    const changed = editedJobInitialDaySpread?.spread?.find?.(
      (daySpread: { traffic: number; day: string }) => {
        return (
          +new Date(daySpread.day) === +new Date(dateUnit.date) &&
          daySpread.traffic !== dateUnit.value
        );
      },
    );
    return Boolean(changed);
  });

  return !!(
    changedSpread ||
    changedSameSpread ||
    changedDateEnd ||
    changedDateStart ||
    dateSpreadChanged
  );
};

export const isLastPage = (page: number): boolean => page === allocationSetupPages.time;
export const getMaxEmails = (hours: number): number => hours * maxHourAllocation;

export const checkDaySpreadChanged = (
  dateHoursSpread: DaySpread[],
  initialDateSpread: JobDailySpread[],
): boolean => {
  let isDaySpreadInitial = true;
  if (dateHoursSpread.length && initialDateSpread.length) {
    dateHoursSpread.forEach((daySpread, index) => {
      const currentInitialDateSpread = initialDateSpread[index].spread;
      daySpread.dayUnits.forEach((day, dayIndex) => {
        if (dayIndex < currentInitialDateSpread.length) {
          const currentInitialSpreadDayTraffic = currentInitialDateSpread[dayIndex].traffic;
          isDaySpreadInitial = isDaySpreadInitial && currentInitialSpreadDayTraffic === day.value;
        }
      });
    });
  }

  return !isDaySpreadInitial;
};
