import { StatusColorsKeys } from "@epcnetwork/core-ui-kit";

import { JobProgressStatuses } from "types";
import { AllocationStatusModel } from "models";
import { JOB_PROGRESS_STATUSES } from "constants/jobs.constants";

//  JOB DISPLAYING
type StatusType = {
  name: "Ready to allocate" | "Calculation in progress" | "Waiting for calculation";
  color: StatusColorsKeys;
};

// FUNCTION HELPERS
type AllocationStatusMap = Record<AllocationStatusModel, StatusType>;

export const getAllocationStatus = (state: AllocationStatusModel): StatusType => {
  const statusMap: AllocationStatusMap = {
    "waiting-for-calculation": { name: "Waiting for calculation", color: "tertiary" },
    "ready-to-allocate": { name: "Ready to allocate", color: "warning" },
    calculation: { name: "Calculation in progress", color: "success" },
  };

  return statusMap[state];
};

const JobStatusTextMap: Record<keyof typeof JOB_PROGRESS_STATUSES, string> = {
  [JOB_PROGRESS_STATUSES.finished]: "Finished",
  [JOB_PROGRESS_STATUSES.inProgress]: "In progress",
  [JOB_PROGRESS_STATUSES.paused]: "Paused",
  [JOB_PROGRESS_STATUSES.frozen]: "Frozen",
  [JOB_PROGRESS_STATUSES.autofrozen]: "Autofrozen",
  [JOB_PROGRESS_STATUSES.pending]: "Pending",
  [JOB_PROGRESS_STATUSES.hiden]: "Hidden",
  [JOB_PROGRESS_STATUSES.preparation]: "Preparation",
};

export const getJobStatusText = (status: JobProgressStatuses, progress?: number): string => {
  // if (progress === 1) return JobStatusTextMap[JOB_PROGRESS_STATUSES.finished];

  return JobStatusTextMap[status] ?? "";
};
