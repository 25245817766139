import { AuthorizationActionTypes, PermissionsModel } from "models";

// TOP LEVEL PERMISSIONS

export const SUPER_ADMIN_PERMISSION = "ADMIN";
export const OPEN_PERMISSION = "ALL";

export const jobsPermissionsMap = {
  [AuthorizationActionTypes.create]: "JOB_WRITE",
  [AuthorizationActionTypes.read]: "JOB_READ",
  [AuthorizationActionTypes.delete]: "JOB_DELETE",
} as const;

export const usersPermissionsMap = {
  [AuthorizationActionTypes.create]: "USER_WRITE",
  [AuthorizationActionTypes.read]: "USER_READ",
  [AuthorizationActionTypes.delete]: "USER_DELETE",
} as const;

// JOBS
export const JOBS_PERMISSIONS: PermissionsModel = {
  name: "Jobs",
  ...jobsPermissionsMap,
};

// USERS
export const USERS_PERMISSIONS: PermissionsModel = {
  name: "Users",
  ...usersPermissionsMap,
};

// EMAILS SUPPRESSION
export const EMAILS_SUPPRESSION_PERMISSIONS: PermissionsModel = {
  name: "Emails Suppression",
  create: "",
  read: "",
  delete: "",
};
