import React, { ChangeEvent, ClipboardEvent, FC, useMemo, useState } from "react";
import cn from "classnames";
import { CheckOption, Nullable, Tooltip, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { getMaxEmails } from "../../../allocation-form.utils";
import { CalendarDayType } from "./calendar-day.type";

import globalStyles from "assets/styles/global.module.css";
import { ClipboardPaste } from "assets/images";
import styles from "./calendar-day.module.css";

type CalendarDayProps = CalendarDayType & {
  onChange: (value: number, rangeIndex: number) => void;
  reusedJob: Nullable<ReusableFileModel>;
  onPaste: (event: ClipboardEvent<HTMLInputElement>, activeDayIndex: number) => void;
  handleCopyToAll: (value: number) => void;
  activeTooltipIndex: number | null;
  setActiveTooltipIndex: React.Dispatch<React.SetStateAction<number | null>>;
};

const CalendarDay: FC<CalendarDayProps> = ({
  onChange,
  onPaste,
  active,
  activeDayIndex,
  day,
  showMonth,
  value,
  month,
  hoursLength,
  reusedJob,
  handleCopyToAll,
  activeTooltipIndex,
  setActiveTooltipIndex,
}) => {
  // used to keep the state of uncontrolled tooltip component
  const [tooltipOpenState, setTooltipOpenState] = useState<boolean>(false);
  const [allocateValue, setAllocateValue] = useState(String(value));
  const maxDayAllocation = getMaxEmails(hoursLength);

  const ariaLabel = useMemo(() => `Allocation spread value for ${day} ${month}`, [day, month]);

  useDidUpdate(() => {
    setAllocateValue(String(value));
  }, [value]);

  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setAllocateValue(value);
    onChange(Number(value), activeDayIndex);
  };

  const onInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (e.target instanceof HTMLInputElement) {
      e.target.select();
    }
  };

  const onInputPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    onPaste(event, activeDayIndex);
  };

  const handleContextMenuClick = (event: React.MouseEvent<HTMLLIElement | HTMLOrSVGElement>) => {
    event.preventDefault();
    setActiveTooltipIndex(null);

    setTimeout(() => {
      setActiveTooltipIndex(activeDayIndex);
    }, 0);
  };

  const onCopyToAll = () => {
    handleCopyToAll(Number(value));
    setActiveTooltipIndex(null);
  };

  const isTooltipOpen = activeTooltipIndex === activeDayIndex;

  const monthClasses = (active: boolean) =>
    cn(styles.month, {
      [styles.monthActive]: active,
      [styles.monthFocused]: isTooltipOpen && tooltipOpenState,
    });

  const dayInputStyles = cn(styles.monthInput, globalStyles.numberFieldAsTextField, {
    [styles.error]: active && value > maxDayAllocation,
    [styles.disabled]: reusedJob,
  });

  return (
    <li className={monthClasses(active)} onContextMenu={handleContextMenuClick}>
      <Tooltip
        isManualControl
        isOpen={active ? isTooltipOpen : false}
        onCanShowChange={setTooltipOpenState}
        contentClassName={styles.tooltipContent}
        outsideClickOption={CheckOption.contains}
        position="bottom"
        triggerElement={
          <div className={styles.monthContent}>
            <div className={styles.dayHeader}>
              {active && !showMonth && (
                <ClipboardPaste
                  onClick={handleContextMenuClick}
                  className={styles.clipboardPasteIcon}
                />
              )}
              {showMonth && <span className={styles.monthName}>{month.slice(0, 3)}</span>}
              <span className={styles.day}>{day}</span>
            </div>
            {active && (
              <input
                type="number"
                className={dayInputStyles}
                onChange={onHandleChange}
                value={allocateValue}
                id="allocateValue"
                name="allocateValue"
                min={0}
                pattern="[0-9]"
                step="1"
                onPaste={onInputPaste}
                aria-label={ariaLabel}
                onClick={onInputClick}
                disabled={!!reusedJob}
              />
            )}
          </div>
        }
      >
        <button type="button" onClick={onCopyToAll} className={styles.pasteButton}>
          <div>
            <ClipboardPaste style={{ width: "16px", height: "16px" }} />
            Populate the other fields
          </div>
          <span>Populate the other fields with this value</span>
        </button>
      </Tooltip>
    </li>
  );
};

export { CalendarDay };
