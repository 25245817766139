export function isValidHttpUrl(str: string): boolean {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export type SearchParamsMap = Map<string, string | SearchParamsMap>;

export function formatSearchParamsToMap(searchParams: URLSearchParams): SearchParamsMap {
  const paramsMap: SearchParamsMap = new Map();
  for (const [key, value] of searchParams) {
    if (value.length && isValidHttpUrl(value)) {
      const { searchParams, origin } = new URL(value);
      paramsMap.set(`${key}=${origin}`, formatSearchParamsToMap(searchParams));
      continue;
    }
    paramsMap.set(key, value);
  }

  return paramsMap;
}

// rebuild map to nest object after url param inside it
export function convertParamsToUrls(searchParams: SearchParamsMap): SearchParamsMap {
  const map: SearchParamsMap = new Map();
  let urlKey: string;

  function convert(searchParams: SearchParamsMap) {
    for (const [key, value] of searchParams) {
      if (typeof value === "string") {
        if (urlKey) {
          if (!map.has(urlKey)) {
            map.set(urlKey, new Map());
          }
          (map.get(urlKey) as SearchParamsMap).set(key, value);
          continue;
        }
        map.set(key, value);
        continue;
      }

      urlKey = key;
      convert(value);
    }
  }

  convert(searchParams);
  return map;
}
