import { useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useFormikContext, FormikErrors } from "formik";
import { Nullable } from "@epcnetwork/core-ui-kit";

import { getISODateWithAmericaTimezone } from "utils";
import { NonNullableKeys, ReusableFileModel } from "models";
import { useSetFormikField } from "hooks";
import { postBatchHourlySpread, getJobHourlySpreadRecalculation } from "api";
import {
  getActiveJobName,
  getDaySpreadWithHoursSpread,
  checkFirstStepChanged,
  checkDaySpreadChanged,
} from "../allocation-form.utils";
import {
  InitialValues,
  ParamsType,
  MaxDaysAllocationLimit,
  MaxHoursAllocationLimit,
} from "../allocation-form.types";
import { maxHourAllocation } from "../allocation-form.constants";

type HoursAllocationReturnType = {
  onHoursSelectedValues: (values: string[]) => void;
  selectedDayIndex: number;
  isLastEndpoint: boolean;
  maxHoursAllocationLimit: MaxHoursAllocationLimit;
  maxDaysAllocationLimit: MaxDaysAllocationLimit;
  onClear: (endpointIndex: number) => void;
  errors: FormikErrors<InitialValues>;
  touched: {
    allEmails?: boolean;
  };
  submitCount: number;
  setSelectedDayIndex: Dispatch<SetStateAction<number>>;
  handleHoursChange: (
    endpointIndex: number,
    dayIndex: number,
  ) => (value: number, hourIndex: number) => void;
};

export const useHoursAllocation = (
  onSelectedValues: (values: string[]) => void,
  currentActiveItemName: string[],
  setLoadedHourlySpread: (loadedHours: boolean) => void,
  loadedHourlySpread: boolean,
  reusedJob: Nullable<ReusableFileModel>,
): HoursAllocationReturnType => {
  const { jobId } = useParams<ParamsType>();
  const { values, initialValues, setFieldValue, errors, touched, submitCount } =
    useFormikContext<InitialValues>();

  const [isLastEndpoint, setIsLastEndpoint] = useState(false);
  const [selectedDayIndex, setSelectedDayIndex] = useState(0);

  const {
    dateHoursSpread,
    startDate,
    endDate,
    minSpreadHour,
    initialDateSpread,
    maxDaysAllocationLimit,
    maxHoursAllocationLimit,
  } = values as NonNullableKeys<InitialValues>;

  const dataToSend = {
    startDate: minSpreadHour || getISODateWithAmericaTimezone(startDate),
    endDate: getISODateWithAmericaTimezone(endDate),
    jobs: dateHoursSpread.map(({ jobId, dayUnits }) => ({
      jobId,
      spread: dayUnits.map(({ value, date }) => ({
        day: getISODateWithAmericaTimezone(date),
        traffic: value,
      })),
    })),
  };

  const checkLastEndpoint = () => {
    const currentActiveName = getActiveJobName(currentActiveItemName);
    const activeEndpointIndex = dateHoursSpread.findIndex(
      (currentJob) => currentJob.name === currentActiveName,
    );

    setIsLastEndpoint(activeEndpointIndex === dateHoursSpread.length - 1);
  };

  const onHoursSelectedValues = (values: string[]) => {
    onSelectedValues(values);

    checkLastEndpoint();
  };

  const checkMaxHoursAllocationLimit = useCallback(
    (value: number, date: Date) => {
      if (value > maxHourAllocation) {
        maxHoursAllocationLimit.add(date);
        return;
      }

      if (maxHoursAllocationLimit.has(date)) {
        maxHoursAllocationLimit.delete(date);
      }
    },
    [maxHoursAllocationLimit],
  );

  const isDaySpreadChanged = checkDaySpreadChanged(dateHoursSpread, initialDateSpread);
  const postBatchHourlySpreadWithData = postBatchHourlySpread.setData(dataToSend);

  const reconfigureRequest =
    checkFirstStepChanged(initialValues, values) || isDaySpreadChanged
      ? postBatchHourlySpreadWithData
      : getJobHourlySpreadRecalculation.setParams({ jobId: jobId || "" });

  const postRequest = jobId ? reconfigureRequest : postBatchHourlySpreadWithData;
  const onSetFormikFieldSuccess = useSetFormikField(postRequest, !reusedJob);

  onSetFormikFieldSuccess((data) => {
    !loadedHourlySpread && setLoadedHourlySpread(true);
    const dataToSet = getDaySpreadWithHoursSpread(values, data, checkMaxHoursAllocationLimit);
    setFieldValue("maxHoursAllocationLimit", maxHoursAllocationLimit);
    return ["dateHoursSpread", dataToSet];
  }, []);

  const onClear = useCallback(
    (endpointIndex: number) => {
      const arrayToSet = dateHoursSpread.map((endpointSpread, index) => {
        if (index !== endpointIndex) return endpointSpread;

        let endpointUsed = 0;

        const dayUnits = endpointSpread.dayUnits.map((daySpread, index) => {
          if (index !== selectedDayIndex) {
            endpointUsed += daySpread.used;
            return daySpread;
          }

          const hoursUnits = daySpread.hoursUnits.map((hourSpread) => {
            return { ...hourSpread, value: 0 };
          });

          return {
            ...daySpread,
            hoursUnits,
            remaining: daySpread.value,
            used: 0,
          };
        });

        return {
          ...endpointSpread,
          dayUnits,
          remaining: endpointSpread.total - endpointUsed,
          maxHourAllocationError: "",
          used: endpointUsed,
        };
      });
      setFieldValue("dateHoursSpread", arrayToSet);
    },
    [setFieldValue, dateHoursSpread, selectedDayIndex],
  );

  const handleHoursChange = useCallback(
    (endpointIndex: number, dayIndex: number) => (value: number, hourIndex: number) => {
      const arrayToSet = dateHoursSpread.map((endpointSpread, index) => {
        if (index !== endpointIndex) return endpointSpread;

        let endpointUsed = 0;

        const dayUnits = endpointSpread.dayUnits.map((daySpread, index) => {
          if (index !== dayIndex) {
            endpointUsed += daySpread.used;
            return daySpread;
          }

          let used = 0;

          const hoursUnits = daySpread.hoursUnits.map((hourSpread, index) => {
            const isChanged = index === hourIndex;
            isChanged && checkMaxHoursAllocationLimit(value, hourSpread.date);
            used += isChanged ? value : hourSpread.value;
            return isChanged ? { ...hourSpread, value } : hourSpread;
          });

          endpointUsed += used;
          return {
            ...daySpread,
            hoursUnits,
            remaining: daySpread.value - used,
            used,
          };
        });

        return {
          ...endpointSpread,
          dayUnits,
          remaining: endpointSpread.total - endpointUsed,
          used: endpointUsed,
        };
      });

      setFieldValue("dateHoursSpread", arrayToSet);
      setFieldValue("maxHoursAllocationLimit", maxHoursAllocationLimit);
    },
    [setFieldValue, dateHoursSpread, checkMaxHoursAllocationLimit, maxHoursAllocationLimit],
  );

  return {
    onHoursSelectedValues,
    selectedDayIndex,
    onClear,
    handleHoursChange,
    setSelectedDayIndex,
    isLastEndpoint,
    maxDaysAllocationLimit,
    maxHoursAllocationLimit,
    touched,
    submitCount,
    errors,
  };
};
