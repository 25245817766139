import {
  roundToMinutes,
  addHours as increaseHours,
  getJSDate,
  addDays,
  Nullable,
} from "@epcnetwork/core-ui-kit";

import { convertToTimezone } from "utils";
import { ReusableFileModel } from "models";

export const getMinDateForReusedJob = (reusedJob: ReusableFileModel): Date => {
  const reusedJobStartDateHours = convertToTimezone(reusedJob.startDate).getHours();
  const minDate = convertToTimezone(roundToMinutes(increaseHours(1), 60));
  const reusedJobMinDate =
    reusedJobStartDateHours < minDate.getHours() ? addDays(1, minDate) : minDate;
  return getJSDate(reusedJobMinDate.setHours(reusedJobStartDateHours));
};

export const getStartDateForReusedJob = (
  reusedJob: ReusableFileModel,
  startDate: Nullable<Date>,
): Date => {
  if (startDate) {
    const reusedJobStartDateHours = convertToTimezone(reusedJob.startDate).getHours();
    const reusedJobStartDate =
      reusedJobStartDateHours < startDate.getHours() ? addDays(1, startDate) : startDate;
    return getJSDate(reusedJobStartDate.setHours(reusedJobStartDateHours));
  }
  return getMinDateForReusedJob(reusedJob);
};

export const getCustomInfoText = (maxHourAllocation: number): string =>
  `You will not be able to allocate all emails, the duration of this job does not match the allocation limits for our services - ${maxHourAllocation} emails per hour. Please change the start/end date or disable "Use all emails" option.`;
