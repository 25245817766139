import { object, ref, string, number, array, AnyObjectSchema } from "yup";

import { optionalValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { Values } from "./users-form.types";

export const validationSchema = (id: number | null): AnyObjectSchema =>
  object().shape({
    email: string().email("Email is not valid").required("Email is required"),
    password: string()
      .min(8, "Password is too short")
      .max(30, "Password is too long")
      .when("$password", optionalValidation(Boolean(id), "Password is required")),
    passwordConfirm: string()
      .oneOf([ref("password"), null], "Passwords must match")
      .when(
        "$passwordConfirm",
        optionalValidation(Boolean(id), "Password confirmation is required"),
      ),
    role_id: number().required("Role is required").typeError("Role is invalid"),
    groups_ids: array(number().required(requiredFieldText))
      .min(1, requiredFieldText)
      .typeError(requiredFieldText),
  });

export const initialData: Values = {
  email: "",
  password: "",
  passwordConfirm: "",
  role_id: null,
  groups_ids: [],
};
