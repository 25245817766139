import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Timezones } from "@epcnetwork/core-ui-kit";

import { DEFAULT_TIME_ZONE } from "constants/timezones.constants";

export type InitialStoreState = {
  isMenuOpen: boolean;
  appTimezone: Timezones;
};

export const initialUiState: InitialStoreState = {
  isMenuOpen: false,
  appTimezone: DEFAULT_TIME_ZONE,
};

const ui = createSlice({
  name: "ui",
  initialState: initialUiState,
  reducers: {
    resetUiStore: () => initialUiState,
    setIsMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isMenuOpen = action.payload;
    },
    setTimezone: (state, action: PayloadAction<Timezones>) => {
      state.appTimezone = action.payload;
    },
  },
});

export const { resetUiStore, setIsMenuOpen } = ui.actions;

export default ui.reducer;
