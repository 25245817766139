import { FC } from "react";
import { DateArgument } from "@epcnetwork/core-ui-kit";

import { formatDate } from "utils";
import { timeFormat } from "constants/general.constants";

import clockImg from "assets/images/clock-grey.svg";
import dateStartImg from "assets/images/bracket-arrow-start-grey.svg";
import dateEndImg from "assets/images/bracket-arrow-end-grey.svg";
import styles from "./info-panel.module.css";

type DateInfoProps = {
  type: "start" | "end";
  date: DateArgument;
};

const dateEntranceImgMap = {
  start: dateStartImg,
  end: dateEndImg,
};

const DateInfo: FC<DateInfoProps> = ({ type, date }) => {
  const entranceImg = dateEntranceImgMap[type];
  return (
    <div className={styles.jobDateWrap}>
      <img src={entranceImg} alt="" />
      <span className={styles.dayInfo}>{formatDate(date, "EEE, MMM dd, yyyy")}</span>
      <img src={clockImg} alt="" />
      <span className={styles.timeInfo}>{formatDate(date, timeFormat)}</span>
    </div>
  );
};

export { DateInfo };
