import { FC, useMemo, useState } from "react";
import { matchSorter } from "match-sorter";
import { Modal, Table, useFetch } from "@epcnetwork/core-ui-kit";

import { setListElement } from "utils";
import { ColumnsType } from "types";
import { ArrayElement, GroupModel } from "models";
import { getGroups } from "api";
import { GroupsForm } from "./groups-form";
import { GroupListTableRow } from "./group-list-table-row/group-list-table-row";

type ListGroupsProps = {
  searchValue: string;
  isAdding: boolean;
  setAdding: (value: boolean) => void;
};

const tableColumns: ColumnsType = [
  { label: "Id", required: true },
  { label: "Name", required: true },
  { label: "Users" },
  { label: "Created at" },
  { label: "" },
];

const ListGroups: FC<ListGroupsProps> = ({ searchValue, isAdding, setAdding }) => {
  const [editId, setEditId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { payload, loading, refresh, error, actions } = useFetch(getGroups);
  const list = useMemo(() => payload || [], [payload]);

  const handleEdit = (id: number) => {
    setEditId(id);
    setIsModalOpen(true);
    setAdding(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditId(null);
    setAdding(false);
  };

  const handleItemChange = (values: GroupModel) => {
    const newData = setListElement(list, values);
    actions.setData(newData);
    setIsModalOpen(false);
  };

  const filteredList = useMemo(() => {
    let listToShow: NonNullable<typeof payload> = list;

    if (searchValue) {
      const keys: (keyof ArrayElement<NonNullable<typeof payload>>)[] = ["name"];
      listToShow = matchSorter(listToShow, searchValue, { keys });
    }

    return listToShow;
  }, [list, searchValue]);

  return (
    <>
      <Table
        entityName="users-group-list"
        columns={tableColumns}
        list={filteredList}
        error={error?.message}
        loading={loading}
        refresh={refresh}
        resetColumnsOnMount={false}
        isTabTable
        row={(item, index) => (
          <GroupListTableRow index={index} item={item} handleEdit={handleEdit} />
        )}
      />
      <Modal isOpen={isModalOpen || isAdding} setClose={handleCloseModal}>
        <GroupsForm
          id={isAdding ? null : editId}
          handleItemChange={handleItemChange}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export { ListGroups };
