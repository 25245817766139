import { TestContext, AnySchema, ValidationError } from "yup";

// Validations
export const optionalValidation =
  (condition: boolean, message: string) =>
  (_field: unknown, passSchema: AnySchema): AnySchema =>
    condition ? passSchema : passSchema.required(message);

export const jsonValidation = (value: string): boolean => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }

  return true;
};

export const passwordValidation = (
  value: string | undefined,
  testContext: TestContext,
): ValidationError | boolean => {
  const password = value || "";

  const minLength = 8;
  const isRequiredLength = password.length >= minLength;
  if (!isRequiredLength) {
    return testContext.createError({ message: "Password needs to include at least 8 characters" });
  }
  const hasLowerCase = password.match(/[a-z]+/);
  const hasUpperCase = password.match(/[A-Z]+/);
  if (!hasLowerCase || !hasUpperCase) {
    return testContext.createError({
      message:
        "Password needs to include both upper and lowercase alphabetic characters (e.g. A-Z, a-z)",
    });
  }
  const hasNumber = password.match(/[0-9]+/);
  if (!hasNumber) {
    return testContext.createError({
      message: "Password needs to include at least one numerical character (e.g. 0-9)",
    });
  }
  const hasSpecialCharacter = password.match(/[_\W]+/);
  if (!hasSpecialCharacter) {
    return testContext.createError({
      message: "Password needs to include at least one special character (e.g. ~!@#$%^&*()_-+=)",
    });
  }
  return true;
};

export const tryParseJSONObject = (jsonString: string): boolean => {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === "object") {
      return true;
    }
  } catch (e) {}

  return false;
};

export const testJSONValidity = (value: string | unknown): boolean => {
  if (typeof value === "string" && value?.length) {
    return tryParseJSONObject(value);
  }

  return typeof value === "object" && !Array.isArray(value) && value !== null;
};
