import { FC, ReactNode } from "react";
import cn from "classnames";

import { errorWarnType } from "./hint.constants";

import { ReactComponent as ErrorIcon } from "assets/images/error-icon.svg";
import styles from "./hint.module.css";

type HintProps = {
  children: ReactNode;
  type?: "success" | "warning" | "error" | "errorWarn" | "info";
  className?: string;
};

const Hint: FC<HintProps> = ({ children, type = "warning", className }) => {
  const typeStyles = cn(styles[type], styles.hintWrapper, className);
  const isErrorIcon = type === errorWarnType;

  return (
    <div className={typeStyles}>
      {isErrorIcon ? <ErrorIcon /> : <div className={styles.borderWrapper} />}
      <div className={styles.hint}>{children}</div>
    </div>
  );
};

export { Hint };
