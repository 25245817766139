import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RefObject, useRef, useState } from "react";
import { FormikHelpers, useFormikContext } from "formik";
import { differenceInHours, isBefore } from "date-fns";
import {
  addHours,
  FormPropsType,
  getJSDate,
  notification,
  useCall,
  useDidUpdate,
  useFormValues,
  useSubmit,
  Nullable,
  getLinkPath,
} from "@epcnetwork/core-ui-kit";

import {
  convertToTimezone,
  getISODateWithAmericaTimezone,
  getISOHourWithAmericaDaylightTimezone,
} from "utils";
import { setAllocatedBatchesIds } from "store";
import { AllocationBatchModel, NonNullableKeys, ReusableFileModel } from "models";
import { useTracking } from "hooks";
import { events } from "constants/tracking.constants";
import { ALLOCATION_PAGE, JOBS_DETAILS_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { SUCCESS_TEXT } from "constants/notification.constants";
import {
  getAllocationBatch,
  getJobHourlySpreadRecalculation,
  getJobReconfiguration,
  postBatchHourlySpread,
  postBatchSchedule,
  postReconfigurationBatchSchedule,
} from "api";
import { EndpointsAllocationValues } from "./endpoints-allocation/endpoints-allocation.types";
import {
  DaysAllocationValues,
  DaySpread,
  NextNestItemType,
} from "./days-allocation/days-allocation.types";
import {
  checkDaySpreadChanged,
  checkFirstStepChanged,
  getCurrentRemainValue,
  getDaySpreadWithHoursSpread,
  getReuseJobConfirmAllocatePopupText,
} from "./allocation-form.utils";
import { InitialValues, ParamsType } from "./allocation-form.types";
import {
  confirmAllocatePopupCancelBtnText,
  confirmAllocatePopupOkBtnText,
  confirmAllocatePopupText,
  confirmAllocatePopupTitle,
  initialValues,
  jobAllocatedSuccessText,
  jobReconfiguredSuccessText,
  maxHourAllocation,
} from "./allocation-form.constants";

import { ReactComponent as SuccessIcon } from "assets/images/success-icon.svg";
import { ReactComponent as RightArrowIcon } from "assets/images/right-arrow-icon.svg";

type AllocationFormReturnType = {
  containerContentRef: RefObject<HTMLDivElement>;
  allowToAllocate: string | true | undefined;
  isDone?: boolean;
  payload: AllocationBatchModel | null;
  formProps: FormPropsType<EndpointsAllocationValues & DaysAllocationValues>;
  handleSubmit: (values: InitialValues, helpers: FormikHelpers<InitialValues>) => void;
  onRemainingChange: (value: number) => void;
  remainingValue: number;
  disableAllocationBtn: boolean;
  onRemainingIndexChange: (indexRemainingValue: number) => void;
  onSelectedIndex: (index: number) => void;
  setLoadedHourlySpread: (loadedHours: boolean) => void;
  loadedHourlySpread: boolean;
  selectedIndex: number;
  reusedJob: Nullable<ReusableFileModel>;
  setReusedJob: (job: Nullable<ReusableFileModel>) => void;
  batchId?: string;
  jobId?: string;
};

export const useAllocationForm = (): AllocationFormReturnType => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const containerContentRef = useRef<HTMLDivElement>(null);

  const { track } = useTracking();
  const { batchId, jobId } = useParams<ParamsType>();

  const [disableAllocationBtn, setDisableAllocationBtn] = useState(false);
  const [remainingValue, setRemainingValue] = useState(0);
  const [reusedJob, setReusedJob] = useState<Nullable<ReusableFileModel>>(null);
  const [loadedHourlySpread, setLoadedHourlySpread] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const isReconfigurationProcess = !!jobId;

  const { submit: postBatchSubmit, onCallSuccess: onPostBatchSuccess } =
    useCall(postBatchHourlySpread);

  const { submit: getJobRecalculationSubmit, onCallSuccess: onGetRecalculationSuccess } = useCall(
    getJobHourlySpreadRecalculation,
  );

  const requestData = batchId
    ? getAllocationBatch.setParams({ batchId })
    : getJobReconfiguration.setParams({ jobId: jobId || "" });

  const getDatesLimit = (startDate: Date | null, endDate: Date | null) => {
    const hours =
      startDate && endDate ? differenceInHours(new Date(endDate), new Date(startDate)) : 0;

    return hours * maxHourAllocation;
  };

  const { formProps, mapInitialValues, payload } = useFormValues(
    initialValues,
    requestData,
    !!batchId || !!jobId,
  );

  mapInitialValues((payload) => {
    const {
      totalToAllocate = 0,
      toBeSelectedEmails = 0,
      tag,
      endpoints = [],
      startDate,
      endDate,
      status,
      minSpreadHour,
    } = payload || {};

    const endpointsList = endpoints || [];
    const used = totalToAllocate - toBeSelectedEmails;

    const now = new Date();
    now.setMinutes(0, 0, 0);
    const initStartDate = getJSDate(addHours(1, now));
    const initEndDate = getJSDate(addHours(100, now));

    const calcDateStart = startDate
      ? isBefore(new Date(startDate), new Date())
        ? new Date(startDate)
        : new Date()
      : initStartDate;
    const calcDateEnd = endDate ? new Date(endDate) : initEndDate;

    const maxEmails = getDatesLimit(calcDateStart, calcDateEnd);
    const enoughEndpointsAllocation = endpointsList.length <= 1 ? Math.min(used, maxEmails) : 0;

    return {
      ...initialValues,
      startDate: startDate ? convertToTimezone(startDate) : null,
      endDate: endDate ? convertToTimezone(endDate) : null,
      tag,
      status,
      toBeSelectedEmails: toBeSelectedEmails,
      totalAmount: totalToAllocate,
      remainingAmount: enoughEndpointsAllocation
        ? totalToAllocate - enoughEndpointsAllocation
        : totalToAllocate,
      usedAmount: enoughEndpointsAllocation,
      endpoints: endpointsList,
      minSpreadHour,
      endpointsRanges: endpointsList.map((endpoint) => ({
        ...endpoint,
        value: enoughEndpointsAllocation,
      })),
    };
  });

  const handleSuccessRedirect = () => {
    const routeToPush = isReconfigurationProcess
      ? getLinkPath(JOBS_DETAILS_PAGE.path, { jobId })
      : JOBS_LIST_PAGE.path;

    if (!isReconfigurationProcess) {
      dispatch(setAllocatedBatchesIds(new Set(payload?.endpoints.map(({ jobId }) => jobId))));
    }
    navigate(routeToPush);
  };

  const handleErrorRedirect = () => {
    const routeToPush = isReconfigurationProcess ? JOBS_LIST_PAGE.path : ALLOCATION_PAGE.path;
    navigate(routeToPush);
  };

  const handleRequestSuccessNotification = () => {
    notification.success(
      SUCCESS_TEXT,
      isReconfigurationProcess ? jobReconfiguredSuccessText : jobAllocatedSuccessText,
    );
  };

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postBatchSchedule,
    postReconfigurationBatchSchedule,
    !!jobId,
  );

  const callPostBatchHourlySpread = (
    values: InitialValues,
    callback: (updateValues: InitialValues) => void,
  ) => {
    const { dateHoursSpread, initialDateSpread, startDate, endDate, minSpreadHour } =
      values as NonNullableKeys<InitialValues>;

    const data = {
      minSpreadHour,
      startDate: minSpreadHour || getISODateWithAmericaTimezone(startDate),
      endDate: getISODateWithAmericaTimezone(endDate),
      jobs: dateHoursSpread.map(({ jobId, dayUnits }) => ({
        jobId,
        spread: dayUnits.map(({ value, date }) => ({
          day: getISODateWithAmericaTimezone(date),
          traffic: value,
        })),
      })),
    };

    const isFirstStepChanged = checkFirstStepChanged(formProps.initialValues, values);
    const isDaySpreadChanged = checkDaySpreadChanged(dateHoursSpread, initialDateSpread);

    const isReconfigurationDataChanged = isFirstStepChanged || isDaySpreadChanged;
    let onFormRequestSuccess = onPostBatchSuccess;

    if (jobId && !isReconfigurationDataChanged) {
      onFormRequestSuccess = onGetRecalculationSuccess;
      getJobRecalculationSubmit({ params: { jobId } });
    } else {
      postBatchSubmit({ data });
    }

    onFormRequestSuccess((data) => {
      callback({ ...values, dateHoursSpread: getDaySpreadWithHoursSpread(values, data) });
      handleRequestSuccessNotification();
      handleSuccessRedirect();
    });
  };

  const handleSubmit = (values: InitialValues, helpers: FormikHelpers<InitialValues>) => {
    notification.confirm(
      confirmAllocatePopupTitle,
      getReuseJobConfirmAllocatePopupText(reusedJob?.name) || confirmAllocatePopupText,
      {
        onOk: () => onConfirmAllocate(values, helpers),
        okText: confirmAllocatePopupOkBtnText,
        cancelText: confirmAllocatePopupCancelBtnText,
        customIcon: <RightArrowIcon />,
      },
    );
  };

  const onConfirmAllocate = (values: InitialValues, helpers: FormikHelpers<InitialValues>) => {
    if (loadedHourlySpread || reusedJob) {
      onSubmitMapping(submitMappingFunction)(values, helpers);
    } else {
      callPostBatchHourlySpread(values, (updateValues: InitialValues) =>
        onSubmitMapping(submitMappingFunction)(updateValues, helpers),
      );
    }
  };

  const submitMappingFunction = (values: NonNullableKeys<InitialValues>) => {
    const { sameSpread, copyFile, dateHoursSpread, endpoints } = values;
    const jobIds = endpoints.map(({ jobId }) => jobId);
    return {
      sameSpread,
      copyFile,
      cancelResetForm: !jobId,
      batchId: batchId ? Number(batchId) : undefined,
      jobIds,
      jobs: dateHoursSpread.map(({ jobId, dayUnits }) => ({
        jobId,
        dayHourSpread: dayUnits.map(({ date, hoursUnits }) => ({
          day: getISODateWithAmericaTimezone(date),
          hours: hoursUnits.map(({ value, date }, index) => ({
            /* TODO: the solution here is not clean and it's better to find a proper way how to work on hour shift */
            hour:
              index > 1
                ? getISOHourWithAmericaDaylightTimezone(
                    date,
                    hoursUnits[hoursUnits.length - 1].date,
                  )
                : getISODateWithAmericaTimezone(date),
            traffic: value,
          })),
        })),
      })),
    };
  };

  onSubmitSuccess(() => {
    setIsDone(true);
    if (jobId) {
      track(events.RECONFIGURE, { jobId });
      notification.success(SUCCESS_TEXT, jobReconfiguredSuccessText);
      handleSuccessRedirect();
    } else {
      track(events.ALLOCATE);
      notification.confirm(SUCCESS_TEXT, jobAllocatedSuccessText, {
        onOk: handleSuccessRedirect,
        okText: "Okay",
        cancelBtn: false,
        customIcon: <SuccessIcon />,
      });
    }
  });

  onSubmitError((error) => {
    if (error?.message !== "errors.alreadyAllocated") {
      notification.error("Submit data error", error.message);
      handleErrorRedirect();
    } else {
      notification.confirm(
        "This batch has already been allocated.",
        "You can go to reconfiguration to edit the allocation.",
        {
          onOk: () => {
            handleErrorRedirect();
          },
          okText: "Go back to list",
          icon: "info",
        },
      );
    }
  });

  const allowToAllocate = !!payload?.totalToAllocate || formProps.loading || formProps.error;

  const onRemainingChange = (value: number) => setRemainingValue(value);

  const onRemainingIndexChange = (indexRemainingValue: number) => {
    setDisableAllocationBtn(indexRemainingValue !== -1);
  };

  const onSelectedIndex = (index: number) => {
    setSelectedIndex(index);
  };

  return {
    containerContentRef,
    allowToAllocate,
    batchId,
    jobId,
    payload,
    formProps,
    handleSubmit,
    onRemainingChange,
    remainingValue,
    isDone,
    disableAllocationBtn,
    onRemainingIndexChange,
    onSelectedIndex,
    selectedIndex,
    loadedHourlySpread,
    setLoadedHourlySpread,
    setReusedJob,
    reusedJob,
  };
};

export type AllocationDataReturnType = {
  dateHoursSpread: DaySpread[];
  remainingValue: number;
  indexRemainingValue: number;
  onNextNextItem: (item: NextNestItemType) => void;
  nextNestItem: NextNestItemType;
  onSelectedValues: (values: string[]) => void;
  currentActiveItemName: string[];
};

export const useAllocationData = (): AllocationDataReturnType => {
  const { values } = useFormikContext<InitialValues>();
  const { dateHoursSpread } = values as NonNullableKeys<InitialValues>;

  const [currentActiveItem, setCurrentActiveItem] = useState<string[]>([]);
  const [indexRemainingValue, setIndexRemainingValue] = useState(0);
  const [remainingValue, setRemainingValue] = useState<number>(0);
  const [nextNestItem, setNextNestItem] = useState<NextNestItemType>(null);

  useDidUpdate(
    () => {
      setRemainingValue(getCurrentRemainValue(dateHoursSpread, currentActiveItem));

      const remainingIndex = values.dateHoursSpread.findIndex((item) => item.remaining !== 0);
      setIndexRemainingValue(remainingIndex);
    },
    [dateHoursSpread],
    true,
  );

  const onSelectedValues = (values: string[]) => {
    if (!currentActiveItem?.length || currentActiveItem[0] !== values[0]) {
      setCurrentActiveItem(values);
    }

    setRemainingValue(getCurrentRemainValue(dateHoursSpread, currentActiveItem));
  };

  const onNextNextItem = (item: NextNestItemType) => {
    setNextNestItem(item);
  };

  return {
    dateHoursSpread,
    onSelectedValues,
    remainingValue,
    indexRemainingValue,
    onNextNextItem,
    nextNestItem,
    currentActiveItemName: currentActiveItem,
  };
};
