import { matchPath, Location } from "react-router-dom";

import { UPLOAD_LIST_PAGE } from "constants/routes.constants";
import { routes } from "config/routes.config";

export const isActiveRoute = (routePath: string, location: Location): boolean => {
  if (routePath === UPLOAD_LIST_PAGE.path && location.pathname === "/") {
    return true;
  }

  return Boolean(matchPath(routePath, location.pathname));
};

export const getActiveRoute = (location: Location): (typeof routes)[0] => {
  const route = routes.find((route) => isActiveRoute(route.path, location));

  if (route) {
    return route;
  }
  return routes[routes.length - 1];
};
