import { FC } from "react";

import { daysOnOneWeek } from "constants/general.constants";
import { DayUnitType } from "../../days-allocation/days-allocation.types";
import { Week } from "./week";

import styles from "./weeks-list.module.css";

type WeeksListProps = {
  dayUnits: DayUnitType[];
  onDaySelect: (index: number) => void;
  selectedDayIndex: number;
};

const WeeksList: FC<WeeksListProps> = ({ dayUnits, onDaySelect, selectedDayIndex }) => {
  const weekCount = Math.ceil(dayUnits.length / daysOnOneWeek);

  const weekList = [...Array(weekCount).keys()].map((_, weekIndex) => {
    const firstDayIndex = weekIndex * daysOnOneWeek;
    const lastDayIndex = firstDayIndex + daysOnOneWeek;
    const dayUnitsOnWeek = dayUnits.slice(firstDayIndex, lastDayIndex);
    const totalWeekUsed = dayUnitsOnWeek.reduce((acc, { used }) => acc + used, 0);
    const startDate = dayUnitsOnWeek[0]?.name;
    const endDate = dayUnitsOnWeek[dayUnitsOnWeek.length - 1]?.name;

    return (
      <Week
        selectedDayIndex={selectedDayIndex}
        key={`${startDate}-${endDate}`}
        startDate={startDate}
        endDate={endDate}
        totalWeekUsed={totalWeekUsed}
        dayUnitsOnWeek={dayUnitsOnWeek}
        onDaySelect={onDaySelect}
        weekIndex={weekIndex}
      />
    );
  });

  return <ul className={styles.weekList}>{weekList}</ul>;
};

export { WeeksList };
