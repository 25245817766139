import { FC, useState } from "react";
import { useFormikContext } from "formik";
import { Button, MessageField, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { AvailableFileModel } from "models";
import { InitialValues } from "../jobs-form.types";
import { AttachFileModal } from "./attach-file-modal";

import crossGreyImg from "assets/images/x-cross-rounded-grey.svg";
import { ReactComponent as ClipIcon } from "assets/images/clip-white.svg";
import styles from "./attach-file.module.css";

const AttachFile: FC = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<InitialValues>();
  const { attachedFiles } = values;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = (isOpen: boolean) => () => setIsModalOpen(isOpen);

  const handleRemoveAttachedFile = (removedFileId: string) => {
    const { attachedFiles } = values;

    setFieldValue(
      "attachedFiles",
      attachedFiles.filter(({ fileId }) => fileId !== removedFileId),
    );
  };

  const handleModalSubmit = (items: AvailableFileModel[]) => {
    setIsModalOpen(false);
    setFieldValue("attachedFiles", items);
  };

  const attachFileError = errors.attachedFiles;

  const errorText = typeof attachFileError === "string" ? attachFileError : "";
  const hasError = !!attachFileError && !!touched?.attachedFiles && !!errorText;

  const buttonText = !!attachedFiles?.length ? "Change files" : "Attach files";

  return (
    <>
      <div className={styles.attachButtonWrap}>
        <Button className={styles.attachButton} onClick={toggleModal(true)} appearance="text">
          <ClipIcon className={styles.clipIcon} />
          {buttonText}
        </Button>
        {!!attachedFiles.length && (
          <ul className={styles.attachedFilesList}>
            {attachedFiles.map(({ fileId, fileName }, index) => (
              <li key={fileId} className={styles.attachedFileItem}>
                <div className={styles.attachedFileOrder}>{index + 1}</div>
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
                  {fileName}
                </TextEllipsis>
                <Button
                  className={styles.btnRemoveAttachedFile}
                  appearance="text"
                  onClick={() => handleRemoveAttachedFile(fileId)}
                >
                  <img src={crossGreyImg} alt="" width="10" height="10" />
                </Button>
              </li>
            ))}
          </ul>
        )}
        {hasError && <MessageField message={errorText} />}
      </div>

      {isModalOpen && (
        <AttachFileModal
          onCancelClick={toggleModal(false)}
          onSubmitClick={handleModalSubmit}
          isModalOpen={isModalOpen}
          handleModalClose={toggleModal(false)}
          initialSelectedFiles={values.attachedFiles}
        />
      )}
    </>
  );
};

export { AttachFile };
