import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Dispatch, FC, SetStateAction, useMemo } from "react";
import format from "date-fns/format";
import { NoContent } from "@epcnetwork/core-ui-kit";

import { sum } from "utils/maths.utils";
import { convertToTimezone } from "utils";
import { timeFormat, timeFormatShortMonthDay } from "constants/general.constants";
import { getYAxisDomain, isHourlyChartTimeUnit } from "./chart.utils";
import { ChartPoint, ChartTimeUnits, ChartTrafficTypesKeys } from "./chart.types";
import { JobTrafficMap } from "./chart.hook";
import { ChartMainTooltip } from "./chart-main-tooltip";
import { chartBarsRenderer } from "./chart-bars-renderer";

import styles from "./chart.module.css";

type ChartTrafficTypesMap = Record<ChartTrafficTypesKeys, number | number[]>;

export type ChartMainProps = {
  currentChartTimeUnit: ChartTimeUnits;
  trafficType: ChartTrafficTypesKeys;
  data: ChartPoint[];
  parsedJobMap: JobTrafficMap;
  setYAxisMaxValue: Dispatch<SetStateAction<number>>;
};

const ChartMain: FC<ChartMainProps> = ({
  trafficType,
  data,
  parsedJobMap,
  currentChartTimeUnit,
  setYAxisMaxValue,
}) => {
  const isChartTimeUnitHourly = isHourlyChartTimeUnit(currentChartTimeUnit);

  const chartTrafficTypesData: ChartTrafficTypesMap[] = useMemo(
    () =>
      data.map((chartPoint) => {
        const {
          succeededTraffic = 0,
          failedTraffic = 0,
          targetTraffic = 0,
          suppressedButSentTraffic = 0,
        } = chartPoint;

        return {
          all: [succeededTraffic, failedTraffic, targetTraffic, suppressedButSentTraffic],
          succeededTraffic,
          failedTraffic,
          targetTraffic,
          suppressedButSentTraffic,
        };
      }),
    [data],
  );

  const yTicksAmount: number = useMemo(() => {
    const maxDataValuesArr: number[] = chartTrafficTypesData
      .map((chartTrafficTypeData) => chartTrafficTypeData[trafficType])
      .flat();

    const maxValue = Math.max(...maxDataValuesArr);

    const coefficient = isChartTimeUnitHourly ? 0.13 : 0.013;
    const ticksPercentage = maxValue * coefficient;

    const minValue = 5;

    return ticksPercentage > minValue ? ticksPercentage : minValue;
  }, [chartTrafficTypesData, isChartTimeUnitHourly, trafficType]);

  const hasRespectiveTraffic: boolean = useMemo(() => {
    const trafficSummedValue = chartTrafficTypesData.reduce((acc, dataItem) => {
      const trafficTypeItem = dataItem[trafficType];
      if (trafficType === "all" && Array.isArray(trafficTypeItem)) {
        acc += sum(trafficTypeItem);
      } else {
        acc += trafficTypeItem as number;
      }
      return acc;
    }, 0);

    return trafficSummedValue > 0;
  }, [chartTrafficTypesData, trafficType]);

  if (!data.length) {
    return (
      <div className={styles.chartWrap}>
        <NoContent showButton={false} />
      </div>
    );
  }

  function roundMinutes(date: Date, type: "to-min" | "to-max" = "to-min") {
    const builtInRoundFunc = type === "to-min" ? Math.floor : Math.round;
    date.setHours(date.getHours() + builtInRoundFunc(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0);

    return date;
  }

  const date = roundMinutes(new Date());
  const todayLine = String(+new Date(date));
  const tickFormatPattern = isChartTimeUnitHourly ? timeFormat : timeFormatShortMonthDay;

  return (
    <div className={styles.chartWrap}>
      <ResponsiveContainer width="100%">
        <ComposedChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="xAxis"
            padding={{
              right: 3,
              left: 3,
            }}
            tickLine={false}
            tickFormatter={(value) =>
              format(convertToTimezone(new Date(Number(value))), tickFormatPattern)
            }
          />

          <YAxis
            domain={getYAxisDomain(currentChartTimeUnit, (maxValue) => {
              setYAxisMaxValue(maxValue);
            })}
            tickCount={yTicksAmount}
            tickLine={false}
            width={50}
          />

          {hasRespectiveTraffic && (
            <Tooltip
              content={(internalTooltipProps) => (
                <ChartMainTooltip tooltipData={internalTooltipProps} parsedJobMap={parsedJobMap} />
              )}
            />
          )}

          <ReferenceLine
            x={todayLine}
            className={styles.todayLine}
            strokeWidth={1.5}
            label={{ position: "top", value: "Now", fontSize: 10 }}
            strokeDasharray="3 3"
          />

          {isChartTimeUnitHourly && (
            <Bar
              dataKey="frozenTraffic"
              stackId={trafficType}
              barSize={32}
              radius={[0, 0, 0, 0]}
              className={styles.frozenBar}
            />
          )}

          {chartBarsRenderer({ trafficType })}

          {isChartTimeUnitHourly && (
            <Area
              type="basis"
              dataKey="frozenTraffic"
              activeDot={false}
              className={styles.frozenArea}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export { ChartMain };
