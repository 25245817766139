import { FC } from "react";
import { TableRow, TableCell, Checkbox, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { formatDate } from "utils";
import { AvailableFileModel } from "models";
import { timeFormat } from "constants/general.constants";
import { essentialColumn } from "../../../../files/list/files-list.constants";

import globalStyles from "assets/styles/global.module.css";
import clockImg from "assets/images/clock-grey.svg";
import styles from "./attach-file-table-row.module.css";

type AttachFileTableRowProps = {
  item: AvailableFileModel;
  index: number;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const AttachFileTableRow: FC<AttachFileTableRowProps> = ({ item, index }) => {
  const { fileName, date, id, columns } = item;

  const emailColumn = essentialColumn.toLowerCase();
  const headers = columns.length ? [emailColumn, ...columns].join(", ") : emailColumn;

  return (
    <TableRow id={id} className={styles.row}>
      <TableCell className={`${styles.checkboxCell} ${styles.override}`}>
        <Checkbox
          value={`file${index}`}
          onChange={noop}
          checked={!!index}
          name={`file${index}`}
          inputSize="big"
          disableError
          showBox
        />
      </TableCell>
      <TableCell className={styles.fileNameWrap}>
        <span className={globalStyles.applyMultiOverflow}>{fileName}</span>
      </TableCell>
      <TableCell className={styles.headersCell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
          {headers}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.index}>{index || ""}</TableCell>
      <TableCell className={styles.dateCell}>
        {formatDate(date, "EEE, MMM dd, yyyy")}
        <img src={clockImg} alt="" />
        {formatDate(date, timeFormat)}
      </TableCell>
    </TableRow>
  );
};

export { AttachFileTableRow };
