import { object, string } from "yup";

import { Values } from "./login.types";

export const initialValues: Values = {
  email: "",
  password: "",
};

export const validationSchema = object().shape({
  email: string().email("Invalid Email").required("Email is required"),
  password: string().required("Password is required"),
});
