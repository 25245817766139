import { ComposedChart, ResponsiveContainer, YAxis } from "recharts";
import { FC } from "react";

import { getYAxisDomain } from "./chart.utils";
import { ChartTrafficTypesKeys } from "./chart.types";
import { ChartMainProps } from "./chart-main";
import { chartBarsRenderer } from "./chart-bars-renderer";

type ChartPreviewProps = Pick<ChartMainProps, "data" | "currentChartTimeUnit"> & {
  trafficType: ChartTrafficTypesKeys;
};

const ChartPreview: FC<ChartPreviewProps> = ({ currentChartTimeUnit, trafficType, data }) => {
  return (
    <ResponsiveContainer height={60}>
      <ComposedChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
        <YAxis domain={getYAxisDomain(currentChartTimeUnit)} width={0} />
        {chartBarsRenderer({ trafficType })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { ChartPreview };
